import styled from 'styled-components';
import { Upload, Button, Pagination } from 'antd';
import Hl7Icon from '../../../Assets/Icons/Hl7Icon';

const { Dragger } = Upload;

export const DraggerComponent = styled(Dragger)`
  & .ant-upload-list {
    height: 40px;
    overflow: scroll;
  }
`;

export const ExportDetailsContainer = styled.div`
  width: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const CommentsWrapper = styled.div`
  height: ${props => (props.isFolderView ? 'calc(100% - 62px)' : '100%')};
  width: 100%;
  overflow: auto;
`;

export const ExportNumberContainer = styled.div``;

export const GridContainer = styled.div`
  margin-top: 20px;
  height: calc(100vh - 284.39px);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px;
`;

export const CardContainer = styled.div`
  width: 100%;
  border-radius: 12px;
  background: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 8px 5px rgba(218, 218, 218, 0.25);
  transition: all 0.25s ease-in;
  border: 2px solid
    ${props => (props.isSelected ? props.primaryColor : '#e4e1e1')};
  &:hover {
    transform: scale(1.05);
  }
`;

export const CardTitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 5px 10px;
  border-radius: 0 0 12px 12px;
  background: ${props => props.isSelected && '#e8f0fe'};
  border-top: 1px solid
    ${props => (props.isSelected ? props.primaryColor : 'transparent')};
  & .ant-btn {
    border: none;
    background: ${props => props.isSelected && '#e8f0fe'};
  }
`;

export const CardImageContainer = styled.div`
  padding: 30px 16px;
  background: #eee;
  height: 140px;
  border-radius: 12px 12px 0 0;
`;

export const CardTitleText = styled.h3`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.textColor};
  margin-bottom: 0;
  @media (max-width: 568px) {
    font-size: 12px;
    font-weight: 600;
  }
`;

export const CardDescriptionText = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.textColor};
  margin-bottom: 0;

  @media (max-width: 568px) {
    font-size: 12px;
    font-weight: 300;
  }
`;

export const StyledHl7Icon = styled(Hl7Icon)`
  height: 80%;
  width: 80%;
`;

export const PageActionContainer = styled.div`
  width: 400px;
  display: flex;
  justify-content: space-between;
`;

export const ChangeFilesViewButton = styled(Button)``;

export const StyledPagination = styled(Pagination)`
  margin-top: 15px;

  & .ant-pagination li {
    border: none;
    background: transparent;
    :hover {
      border: none;
    }
  }

  & .ant-pagination .ant-pagination-prev,
  & .ant-pagination .ant-pagination-next {
    border: none;
  }

  & .ant-pagination .ant-pagination-prev:hover button,
  & .ant-pagination .ant-pagination-next:hover button {
    border: none;
  }

  & .ant-table-pagination.ant-pagination {
    margin: 16px 0 0 0;
  }

  & .ant-pagination-item-active {
    border: 0;
    background: transparent;
  }

  & .ant-pagination-item {
    background-color: transparent;
    border: none;
  }
`;

export const CommentButton = styled(Button)`
  border: none !important;
  background-color: #f0f2f5 !important;
  margin-top: 5px;
  & path {
    fill: darkgrey;
  }
`;

export const NoCommentsInfo = styled.p`
  width: 156px;
  margin: auto;
  font-size: 16px;
  font-weight: 500;
`;

export const CommentItemContainer = styled.div`
  
  height: auto;
`;

export const CommentInfoContainer = styled.div`
  background-color: #f0f2f5;
  border-radius: 3px;
`;

export const CommentedBy = styled.div`
  padding: 5px 10px 0px 10px;
  font-weight: 600 !important;
`;

export const CommentedOn = styled.div`
  padding: 0px 10px 5px 10px;
`;

export const CommentContainer = styled.div`
  min-height: 50px;
  height: auto;
  padding: 10px 10px 10px 15px;
  border: 2px solid whitesmoke;
  border-top: none;
  border-radius: 3px;
`;

export const StyledInfoButton = styled(Button)``;

export const FileInfoItem = styled.div`
  width: 100%;
  padding: 10px 0px;
  display: flex;
`;

export const InfoType = styled.div`
  width: 55%;
`;

export const InfoValue = styled.div`
  width: 45%;
  font-weight: 500;
`;

export const DocumentListContainer = styled.div`
  max-height: 150px;
  overflow-y: auto;
  margin: 5px 10px;
`;
export const DocumentInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  &:hover {
    background: #f5f5f5;
  }
  & span {
    margin: 0 10px;
  }
`;

export const DocumentInfo = styled.div`
  font-size: 14px;
  color: ${props => props.error && 'red'};
  line-height: 20px;
`;
