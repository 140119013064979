import React, { useState, useEffect } from 'react';
import PageTitle from '../../../Utils/PageTitle';
import { ContainerRow, TitleRow } from '../styledComponents';
import CustomTypography from '../../CustomComponents/CustomTypography';
import { useSelector } from 'react-redux';
import CustomSideDrawer from '../../CustomComponents/CustomSideDrawer';
import {
  TableDiv,
  StyledJobListTabPane,
  StyledJobListTab,
  StyledJobListTabPaneContent,
  StyledJobListTabPaneContentText,
  StyledJobListTabPaneContentCount,
  ConfigureButton,
  StyledContent,
  FilterButtonTittle,
  StyledResetButton,
  FilterBadge
} from './styledComponents';
import JobListTable from './JobListTable';
import {
  getJobs,
  transcriptorSelfAssignJob,
  startTranscriptorJob
} from '../../../Apis/Transcriptor';
import {
  getIncompleteJobs,
  releaseJob,
  retryIncompleteJobSubmission
} from '../../../Apis/Jobs';
import {
  reviewerSelfAssignJob,
  startReviewerJob
} from '../../../Apis/Reviewer';
import { RESULTS_PER_PAGE } from '../../../Constants/StringConstants';
import { GetJobDashboardCount } from '../../../Apis/Dashboard';
import FilterConfiguration from '../../CustomComponents/FilterConfiguration';
import VectorIcon from '../../../Assets/Icons/VectorIcon';
import { GetJobCount } from '../../../Apis/Jobs';
import { Badge, notification, Space } from 'antd';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { getReviewerJobs } from '../../../Apis/Reviewer';
import { sideBarContents } from './SidebarConfig';
import Spinner from '../../CustomComponents/Spinner';
import { clearJobNotification } from '../../../Redux/Actions/Notification';
import { useDispatch } from 'react-redux';
import ConfirmationModal from '../../CustomComponents/ConfirmationModal';
import ReleaseJobModal from '../../CustomComponents/ReleaseJobModal';
import { SubmitButton, SubmitButtonCol } from '../../Content/styledComponents';
import {
  getUserFacilities,
  getJobFilterConfig,
  saveConfiguration,
  fetchFacilityAuthor
} from '../../../Apis/User';

const doctorPrivilege = new Map([
  ['TRANSCRIPTION_ONLY', 'transcriptionist'],
  ['REVIEWER_ONLY', 'reviewer'],
  ['TRANSCRIPTION_REVIEWER', 'admin'],
  ['NONE', 'None']
]);

const JobList = () => {
  const [theme] = useSelector(state => [state.themeReducer]);
  const user = useSelector(state => state.userReducer);
  const [addDrawerVisible, setAddDrawerVisible] = useState(false);
  const notificationStatus = useSelector(state => state.notificationReducer);
  const [currentPage, setCurrentPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [availableJobs, setAvailableJobs] = useState([]);
  const [filterKey, setFilterKey] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [sortOrder, setSortOrder] = useState([]);
  const [assignButtonLoading, setAssignButtonLoading] = useState(false);
  const [sideBarItems, setSideBarItems] = useState();
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [activeTab, setActivetab] = useState('transcriptionist');
  const [activeIncompleteTab, setActiveIncompletetab] =
    useState('unsuccessful');
  const [selfAssignTranscriptorBadge, setSelfAssignedTranscriptorBadge] =
    useState(false);
  const [selfAssignReviewerBadge, setSelfAssignedReviewerBadge] =
    useState(false);
  const [userType, setUserType] = useState();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [jobStatus, setJobStatus] = useState('');
  const [counts, setCounts] = useState({});
  const [searchVal, setSearchVal] = useState('');
  const [resubmitLoading, setResubmitLoading] = useState(false);
  const [facilities, setFacilities] = useState([]);
  const [resubmitModalVisible, setResubmitModalVisible] = useState(false);
  const [resubmitModalLoading, setResubmitModalLoading] = useState(false);
  const [resubmitJobIds, setResubmitJobIds] = useState([]);
  const [filterConfig, setFilterConfig] = useState([]);
  const [updatedFilterConfig, setUpdatedFilterConfig] = useState([]);
  const [configLoader, setconfigLoader] = useState(false);
  const [filterCount, setfilterCount] = useState(0);
  const [selectedTab, setselectedTab] = useState('');

  const [activeKey, setActiveKey] = useState(() => {
    let activeItem;
    if (sideBarItems) {
      activeItem = sideBarItems.find(item => {
        let splitPath = location.pathname.split('/');
        let splitLink = item.path.split('/');
        if (splitPath[3] === splitLink[3]) return item;
        else return null;
      });
    }

    return activeItem && activeItem.key;
  });
  const [releaseJobModalVisible, setReleaseJobModalVisible] = useState(false);
  const [releaseJobId, setReleaseJobId] = useState(null);

  const [authors, setAuthors] = useState();
  const [userfacilities, setUserFacilities] = useState([]);

  const getFacilities = () => {
    getUserFacilities().then(response => {
      if (response.data.success) {
        setUserFacilities(response.data.result);
      } else {
        notification['error']({
          message: 'User Facilities Fetch Failed',
          description: response.data.message
        });
      }
    });
  };
  useEffect(() => {
    getFacilities();
    getAuthors();
    getJobConfiguration();
    setLoading(true);
  }, []);

  useEffect(() => {
    setfilterCount(0);
    Object.keys(filterConfig).forEach(function (key, index) {
      if (filterConfig[key].length > 0) {
        setfilterCount(1);
      }
    });
  }, [filterConfig]);

  function getObject(obj, value) {
    let data = { found: false, res: [] };
    const foundItem = obj.find(item => item.key === value);
    if (foundItem) {
      data.res = foundItem.values;
      data.found = true;
    }

    return data;
  }

  const modifyFilterResponse = resp => {
    let result = { facilityIds: [], doctorIds: [], status: [] };
    let found = { facilityIds: false, doctorIds: false, status: false };
    if (resp.length !== 0 && Boolean(resp[0]['filterObject'])) {
      let myList = resp[0]['filterObject'];
      Object.keys(result).map((item, index) => {
        let resp = getObject(myList, item);
        result[item] = resp.res;
        found[item] = resp.found;
      });
    }
    const isEmptyConfig = Object.values(found).every(v => v === false);
    if (user && user.userType === 'doctor' && isEmptyConfig) {
      result['doctorIds'].push(user?.id);
    }
    let res = JSON.stringify(result);
    setFilterConfig(JSON.parse(res));
    setUpdatedFilterConfig(JSON.parse(res));
  };

  const getJobConfiguration = () => {
    getJobFilterConfig('jobPage').then(response => {
      if (response.data.success) {
        modifyFilterResponse(response?.data?.result);
      } else {
        notification['error']({
          message: 'Configuration Fetch Failed',
          description: response.data.message
        });
      }
    });
  };
  const getAuthors = () => {
    fetchFacilityAuthor().then(response => {
      if (response.data.success) {
        setAuthors(response.data.result);
      } else {
        notification['error']({
          message: 'Authors Fetch Failed',
          description: response.data.message
        });
      }
    });
  };

  const onSelect = key => {
    setActiveKey(key);
  };

  const SideBarCallsMap = new Map([
    ['0', 'ready'],
    // ['1', `ready`],
    ['2', 'in-progress'],
    ['3', 'completed'],
    ['4', 'rejected'],
    ['5', 'incomplete']
  ]);

  const type = SideBarCallsMap.get(activeKey);

  const fetchSidebarList = () => {
    if (sideBarItems) {
      if (
        user.userType === 'admin' ||
        user.userType === 'clinicAdmin' ||
        (user.userType === 'doctor' &&
          user.userPrivilege === 'TRANSCRIPTION_REVIEWER')
      ) {
        GetJobCount()
          .then(response => {
            if (response.data.success) {
              const counts = response.data.result[0];
              setCounts(counts);
              sideBarItems.map(
                item =>
                  (item.count =
                    counts[item.countValueKey].reviewerJobCount +
                    counts[item.countValueKey].transcriptionistJobCount)
              );
              setLoading(false);
            }
          })
          .catch(err => {
            setLoading(false);
          });
      } else {
        GetJobDashboardCount(userType)
          .then(response => {
            if (response.data.success) {
              const counts = response.data.result[0];
              setCounts(counts);
              if (userType === 'transcriptionist')
                sideBarItems.map(
                  item => (item.count = counts[item.countValueKey])
                );
              else {
                sideBarItems.map(
                  item =>
                    (item.count =
                      counts[
                        item.countValueKey
                          .slice(0, item.countValueKey.length - 14)
                          .concat('Jobs')
                      ])
                );
              }
              setLoading(false);
            }
          })
          .catch(err => {
            setLoading(false);
          });
      }
    }
  };

  const fetchJobList = (
    pageNo = 0,
    activeIncompleteType,
    usertype = userType
  ) => {
    if (sideBarItems && activeKey) {
      setTableLoading(true);
      (type === 'incomplete'
        ? getIncompleteJobs(
            facilities,
            pageNo,
            RESULTS_PER_PAGE,
            filterKey,
            sortOrder,
            searchKey,
            activeIncompleteType === 'failed' ? 'I100' : null
          )
        : usertype === 'transcriptionist'
        ? getJobs(
            facilities,
            RESULTS_PER_PAGE,
            pageNo,
            filterKey,
            sortOrder,
            type,
            searchKey,
            type === 'ready'
              ? jobStatus === 'new'
                ? 'Ready'
                : jobStatus === 'rejected'
                ? 'Rejected'
                : ''
              : ''
          )
        : getReviewerJobs(
            facilities,
            RESULTS_PER_PAGE,
            pageNo,
            filterKey,
            sortOrder,
            type,
            searchKey,
            type === 'ready'
              ? jobStatus === 'new'
                ? 'Ready'
                : jobStatus === 'rejected'
                ? 'Rejected'
                : ''
              : ''
          )
      )
        .then(response => {
          if (response.data.success) {
            setTotal(response.data.size);
            setAvailableJobs(response.data.result);
            setTableLoading(false);
          } else {
            notification['error']({
              message: 'Failed',
              description: response.data.message
            });
            setTableLoading(false);
          }
        })
        .catch(err => {
          setTableLoading(false);
        });
    }
  };

  useEffect(() => {
    if (user) {
      const userType =
        ['admin', 'clinicAdmin'].includes(user.userType) ||
        doctorPrivilege.get(user.userPrivilege) === 'admin'
          ? 'transcriptionist'
          : user.userType === 'doctor'
          ? doctorPrivilege.get(user.userPrivilege)
          : user.userType;
      setUserType(userType);
      setSideBarItems(sideBarContents(userType));
    }
  }, [user]);

  useEffect(() => {
    if (sideBarItems) {
      const activeItem = sideBarItems.find(item => {
        let splitPath = location.pathname.split('/');
        let splitLink = item.path.split('/');
        if (splitPath[3] === splitLink[3]) return item;
        else return null;
      });
      if (activeItem) {
        setActiveKey(activeItem.key);
      } else {
        setActiveKey('0');
      }
    }
  }, [sideBarItems, location.pathname]);

  useEffect(() => {
    return () => {
      dispatch(clearJobNotification());
    };
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (type === 'completed' || 'incomplete') {
      if (
        sideBarItems?.filter(data => data.name === type).count !==
        availableJobs.length
      ) {
        fetchSidebarList();
      }
    }
    // eslint-disable-next-line
  }, [availableJobs, type, sideBarItems]);
  useEffect(() => {
    fetchSidebarList();
    // eslint-disable-next-line
  }, [sideBarItems, notificationStatus.jobNotification]);

  useEffect(() => {
    if (notificationStatus.jobNotification.newJobAvailable) {
      if (type === 'ready') fetchJobList(currentPage);
    }
    // eslint-disable-next-line
  }, [notificationStatus.jobNotification.newJobAvailable]);

  useEffect(() => {
    if (notificationStatus.jobNotification.writeFailed && type === 'incomplete')
      fetchJobList(currentPage);
    // eslint-disable-next-line
  }, [notificationStatus.jobNotification.writeFailed]);

  useEffect(() => {
    fetchJobList(0);
    // eslint-disable-next-line
  }, [
    filterKey,
    sortOrder,
    type,
    sideBarItems,
    searchKey,
    jobStatus,
    facilities
  ]);

  const pageNoChangeHandler = pageNo => {
    fetchJobList(pageNo, selectedTab);
  };

  const assignToSelf = jobId => {
    setAssignButtonLoading(true);
    userType === 'transcriptionist' &&
      transcriptorSelfAssignJob(jobId, user.sessionId)
        .then(response => {
          if (response.data.success) {
            notification['success']({
              message: 'Updated',
              description: response.data.message
            });
            fetchJobList(currentPage);
            fetchSidebarList();
            setAssignButtonLoading(false);
          } else {
            notification['error']({
              message: 'Failed',
              description: response.data.message
            });
            setAssignButtonLoading(false);
          }
        })
        .catch(err => {
          setAssignButtonLoading(false);
        });

    userType === 'reviewer' &&
      reviewerSelfAssignJob(jobId, user.sessionId)
        .then(response => {
          if (response.data.success) {
            notification['success']({
              message: 'Updated',
              description: response.data.message
            });
            fetchJobList(currentPage);
            fetchSidebarList();
            setAssignButtonLoading(false);
          } else {
            notification['error']({
              message: 'Failed',
              description: response.data.message
            });
            setAssignButtonLoading(false);
          }
        })
        .catch(err => {
          setAssignButtonLoading(false);
        });
  };

  const startAssignedJob = jobId => {
    userType === 'transcriptionist' &&
      startTranscriptorJob(jobId).then(response => {
        if (response.data.success) {
          history.push(`/dashboard/editor/${jobId}?userType=${userType}`);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
      });

    userType === 'reviewer' &&
      startReviewerJob(jobId).then(response => {
        if (response.data.success) {
          history.push(`/dashboard/editor/${jobId}?userType=${userType}`);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
      });
  };

  const startAssignedJobWithNewEditor = jobId => {
    userType === 'transcriptionist' &&
      // startTranscriptorJob(jobId).then(response => {
      transcriptorSelfAssignJob(jobId, user.sessionId).then(response => {
        if (response.data.success) {
          history.push(
            `/dashboard/new-editor/${jobId}?jobStage=${SideBarCallsMap.get(
              activeKey
            )}&userType=${userType}`
          );
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
      });

    userType === 'reviewer' &&
      // startReviewerJob(jobId).then(response => {
      reviewerSelfAssignJob(jobId, user.sessionId).then(response => {
        if (response.data.success) {
          history.push(
            `/dashboard/new-editor/${jobId}?jobStage=${SideBarCallsMap.get(
              activeKey
            )}&userType=${userType}`
          );
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
      });
  };

  const resumeJob = jobId => {
    history.push(`/dashboard/editor/${jobId}?userType=${userType}`);
  };
  const resumeJobWithNewEditor = jobId => {
    history.push(
      `/dashboard/new-editor/${jobId}?jobStage=${SideBarCallsMap.get(
        activeKey
      )}&userType=${userType}`
    );
  };

  const viewJob = jobId => {
    history.push(
      `/dashboard/new-editor/${jobId}?jobStage=${SideBarCallsMap.get(
        activeKey
      )}&userType=${userType}`
    );
  };

  const releaseJobHandler = jobId => {
    setReleaseJobId(jobId);
    setReleaseJobModalVisible(true);
  };
  const releaseJobConfirmHandler = () => {
    releaseJob(releaseJobId)
      .then(response => {
        if (response.data.success) {
          notification['success']({
            message: 'Job released',
            description: response.data.message
          });
          fetchJobList(currentPage);
          fetchSidebarList();
          setReleaseJobModalVisible(false);
        } else {
          notification['error']({
            message: 'Section Fetch Failed',
            description: response.data.message
          });
        }
      })
      .catch(err => {
        // console.log(err);
      });
  };

  const resubmit = jobIds => {
    setResubmitJobIds(jobIds);
    setResubmitLoading(true);
    setResubmitModalLoading(true);
    setResubmitModalVisible(true);
    setResubmitModalLoading(false);
  };

  const handleResubmitConfirm = jobIds => {
    console.log('new', jobIds);
    setResubmitModalLoading(true);
    retryIncompleteJobSubmission(jobIds, user.sessionId)
      .then(response => {
        if (response.data.success) {
          fetchSidebarList();

          fetchJobList(0, selectedTab);
          notification['success']({
            message: 'Success',
            description: response.data.message
          });
          setResubmitModalVisible(false);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
        setResubmitModalLoading(false);
        setResubmitLoading(false);
      })
      .catch(err => {
        setResubmitLoading(false);
      });
  };

  const modifyConfigObject = updatedFilterConfig => {
    let ConfigData = [];
    for (const key in updatedFilterConfig) {
      if (updatedFilterConfig.hasOwnProperty(key)) {
        let obj = {
          key: String(key),
          type: 'string',
          values: updatedFilterConfig[key]
        };
        ConfigData.push(obj);
      }
    }
    return ConfigData;
  };
  const submitConfiguration = async () => {
    setLoading(true);
    setconfigLoader(true);
    let postObject = await modifyConfigObject(updatedFilterConfig);
    saveConfiguration('jobPage', postObject)
      .then(response => {
        if (response.data.success) {
          notification['success']({
            message: 'Configured Job Filters',
            description: response.data.message
          });
          setconfigLoader(false);
          setAddDrawerVisible(false);
          modifyFilterResponse(response?.data?.result);
          fetchJobList(0, selectedTab);
          fetchSidebarList();
          setCurrentPage(0);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
          setconfigLoader(false);
        }
      })
      .catch(err => {
        setconfigLoader(false);
      });
  };

  const resetFilter = async () => {
    let resetData = JSON.stringify(filterConfig);
    setUpdatedFilterConfig(JSON.parse(resetData));
  };

  return (
    <>
      <PageTitle
        title={
          userType === 'transcriptionist' ? 'Joblist' : 'Transcription List'
        }
      />
      {loading && tableLoading ? (
        <Spinner />
      ) : (
        <ContainerRow>
          <TitleRow justify='space-between'>
            <CustomTypography color={theme['@text-color']}>
              {userType === 'transcriptionist'
                ? 'Job list'
                : 'Transcription List'}
            </CustomTypography>
            <ConfigureButton
              type={'default'}
              shape='round'
              onClick={() => setAddDrawerVisible(true)}
            >
              <StyledContent>
                <FilterButtonTittle>Filter</FilterButtonTittle>
                <VectorIcon />
                {filterCount > 0 ? <FilterBadge /> : ''}
              </StyledContent>
            </ConfigureButton>
          </TitleRow>

          <div>
            <StyledJobListTab
              onChange={onSelect}
              defaultActiveKey={activeKey}
              activeKey={activeKey}
            >
              {sideBarItems?.map(sideBarItem => {
                const isTranscriptionPermission =
                  user.userType === 'admin' ||
                  user.userType === 'clinicAdmin' ||
                  (user.userType === 'doctor' &&
                    user.userPrivilege === 'TRANSCRIPTION_REVIEWER');
                return (
                  <StyledJobListTabPane
                    className='tab-pane'
                    tab={
                      <Link
                        to={sideBarItem.path}
                        onClick={() => {
                          setCurrentPage(0);
                          setActiveIncompletetab('unsuccessful');
                          //setActiveIncompletetab('failed');
                          setActivetab(
                            isTranscriptionPermission
                              ? 'transcriptionist'
                              : userType
                          );
                          setUserType(
                            isTranscriptionPermission
                              ? 'transcriptionist'
                              : userType
                          );
                        }}
                      >
                        <StyledJobListTabPaneContent className='tab-content'>
                          <StyledJobListTabPaneContentText className='tab-content-text'>
                            {sideBarItem.name}
                          </StyledJobListTabPaneContentText>
                          <Badge
                            dot={
                              sideBarItem.name === 'Ready' &&
                              notificationStatus.newReadyJobs?.length > 0
                            }
                            color={theme['@primary-color']}
                            className='tab-content-count-badge'
                          >
                            <StyledJobListTabPaneContentCount className='tab-content-count'>
                              {sideBarItem.count}
                            </StyledJobListTabPaneContentCount>
                          </Badge>
                        </StyledJobListTabPaneContent>
                      </Link>
                    }
                    key={sideBarItem.key}
                  />
                );
              })}
            </StyledJobListTab>
          </div>

          <TableDiv>
            <JobListTable
              setselectedTab={setselectedTab}
              titleKey={activeKey}
              currentPage={currentPage}
              total={total}
              filterKey={filterKey}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              setCurrentPage={setCurrentPage}
              pageNoChangeHandler={pageNoChangeHandler}
              setFilterKey={setFilterKey}
              setSearchKey={setSearchKey}
              availableJobs={availableJobs}
              activeKey={activeKey}
              assignToSelf={assignToSelf}
              assignButtonLoading={assignButtonLoading}
              startAssignedJob={startAssignedJob}
              startAssignedJobWithNewEditor={startAssignedJobWithNewEditor}
              resumeJob={resumeJob}
              resumeJobWithNewEditor={resumeJobWithNewEditor}
              viewJob={viewJob}
              releaseJobHandler={releaseJobHandler}
              tableLoading={tableLoading}
              activeTab={activeTab}
              setActivetab={setActivetab}
              activeIncompleteTab={activeIncompleteTab}
              setActiveIncompletetab={setActiveIncompletetab}
              fetchJobList={fetchJobList}
              setUserType={setUserType}
              userType={userType}
              jobStatus={jobStatus}
              setJobStatus={setJobStatus}
              counts={counts}
              selfAssignTranscriptorBadge={selfAssignTranscriptorBadge}
              setSelfAssignedTranscriptorBadge={
                setSelfAssignedTranscriptorBadge
              }
              selfAssignReviewerBadge={selfAssignReviewerBadge}
              setSelfAssignedReviewerBadge={setSelfAssignedReviewerBadge}
              searchVal={searchVal}
              setSearchVal={setSearchVal}
              type={type}
              resubmit={resubmit}
              resubmitLoading={resubmitLoading}
              setFacilities={setFacilities}
            />
          </TableDiv>
        </ContainerRow>
      )}
      <ConfirmationModal
        antdModalProps={{ centered: true, width: '300', closable: false }}
        visible={resubmitModalVisible}
        onCancel={() => {
          setResubmitModalVisible(false);
          setResubmitLoading(false);
        }}
        onOk={() => handleResubmitConfirm(resubmitJobIds)}
        header={
          <CustomTypography color={theme['@text-color']}>
            Are you sure?
          </CustomTypography>
        }
        confirmation={true}
        loading={resubmitModalLoading}
      >
        <h1>{`Do you really want to resubmit?`}</h1>
      </ConfirmationModal>
      <ReleaseJobModal
        releaseJobModalVisible={releaseJobModalVisible}
        setReleaseJobModalVisible={setReleaseJobModalVisible}
        onConfirm={releaseJobConfirmHandler}
      />
      <CustomSideDrawer
        filter={true}
        title={'Filter By'}
        placement='right'
        closable={true}
        onClose={() => {
          setAddDrawerVisible(false);
        }}
        visible={addDrawerVisible}
        width={400}
        destroyOnClose={true}
        extra={
          <Space>
            <StyledResetButton
              danger
              type='text'
              onClick={event => resetFilter()}
            >
              Reset Filter
            </StyledResetButton>
          </Space>
        }
        footer={
          <SubmitButtonCol span={24}>
            <SubmitButton
              htmlType='submit'
              type='primary'
              size='large'
              shape='round'
              onClick={event => submitConfiguration()}
              disabled={
                configLoader ||
                JSON.stringify(filterConfig) ===
                  JSON.stringify(updatedFilterConfig)
              }
              loading={configLoader}
            >
              Save Configurations
            </SubmitButton>
          </SubmitButtonCol>
        }
      >
        <FilterConfiguration
          jobStatus={type}
          Modified={updatedFilterConfig}
          setModified={setUpdatedFilterConfig}
          facilities={userfacilities}
          Authors={authors}
        />
      </CustomSideDrawer>
    </>
  );
};

export default JobList;
