import React from 'react';
import {  useEffect } from 'react';
import { TimerText } from './styledComponents';

const Timer = ({ color, minutes, setMinutes, seconds, setSeconds }) => {
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <div>
      {minutes === 0 && seconds === 0 ? null : (
        <TimerText color={color}>
          Resend OTP in {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </TimerText>
      )}
    </div>
  );
};

export default Timer;
