import React from 'react'
import {
  SmallCardDiv,
  SmallCardImageDiv,
  SmallCardText
} from './styledComponents'

const SmallCard = ({ item }) => {
  return (
    <>
      <SmallCardDiv>
        <SmallCardImageDiv color={item.background}>
          <item.icon color={item.iconColor} />
        </SmallCardImageDiv>
        <SmallCardText>{item.title}</SmallCardText>
      </SmallCardDiv>
    </>
  )
}

export default SmallCard
