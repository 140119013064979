import moment from 'moment';
import React, { useState } from 'react';
import {
  CURRENCY_SYMBOL,
  PLAN_EXP_DATE_FORMAT
} from '../../../../Constants/StringConstants';
import {
  ActivePlanIconContainer,
  AlertContainer,
  CancelSubscriptionButton,
  ChoosePlanButton,
  ContentTableContainer,
  HeaderContainer,
  HeaderContent,
  HeaderSpecilOfferContainer,
  HeaderSwitchButton,
  HeaderSwitchButtonContainer,
  HeaderSwitchContainer,
  ManageSubscriptionContainer,
  PlanNotes,
  SubscriptionDetailsFeatureContent,
  SubscriptionDetailsTable,
  SubscriptionDetailsTableHeading,
  SubscriptionStatusAlert
} from './styledComponents';
import planDetails from './planDetails.json';
import StarFillIcon from '../../../../Assets/Icons/StarFillIcon';
import CheckIcon from '../../../../Assets/Icons/CheckIcon';
import CloseIcon from '../../../../Assets/Icons/CloseIcon';

const NewManageSubscription = ({
  trialPeriod,
  planEndDate,
  subscriptionId,
  transactionStatus,
  startSubscriptionHandler,
  setCancelModalVisible,
  subscriptionPlans
}) => {
  const [activeTab, setActiveTab] = useState(
    subscriptionPlans.find(plan => plan.id === subscriptionId)?.type ===
      'annual'
      ? 1
      : 0
  );

  const getCurrentTabPlans = () => {
    return {
      ...planDetails,
      plans: planDetails.plans.filter(plan => {
        return plan.id === '0'
          ? trialPeriod
          : activeTab === 0
          ? plan.type === 'monthly'
          : plan.type === 'annual';
      })
    };
  };
  // const test = (planId,featureId) => {

  //   return subscriptionPlans.map(item =>
  //     planId == item.id ? featureId == 1? <span key={item.id}>{item.dictationTemplatesLimit}</span> : <span key={item.id}>{item.cannedTextsLimit}</span>
  //   );
  // };
  const planLimit = (planId, featureId) => {
    const matchingPlan = subscriptionPlans.find(item => item.id === planId);

    if (!matchingPlan) {
      return <span style={{ fontWeight: 'bold' }}>Plan Not Found</span>;
    }

    if (featureId === 1) {
      return (
        <span style={{ fontWeight: 'bold' }} key={matchingPlan.id}>
          {matchingPlan.dictationTemplatesLimit}
        </span>
      );
    } else if (featureId === 3) {
      return (
        <span style={{ fontWeight: 'bold' }} key={matchingPlan.id}>
          {matchingPlan.cannedTextsLimit}
        </span>
      );
    } else if (featureId === 4) {
      return matchingPlan.accessInferenceService ? (
        <CheckIcon color={'#1CEDC3'} />
      ) : (
        <CloseIcon color={'#F95757'} />
      );
    }
    return null;
  };

  return (
    <>
      <ManageSubscriptionContainer>
        <AlertContainer>
          {trialPeriod ? (
            moment(planEndDate).isBefore(new Date()) ? (
              <SubscriptionStatusAlert
                description={`Your Free Plan is expired on ${moment(
                  planEndDate
                ).format(PLAN_EXP_DATE_FORMAT)}`}
                type='error'
                showIcon
              />
            ) : (
              <SubscriptionStatusAlert
                description={`Your Free Plan will be expired in ${Math.floor(
                  moment
                    .duration(planEndDate - moment().startOf('day'))
                    .asDays()
                )} days`}
                type='warning'
                showIcon
              />
            )
          ) : subscriptionId !== null ? (
            <SubscriptionStatusAlert
              description={`Plan is Active. Your subscription will auto renew on ${moment(
                planEndDate
              ).format(PLAN_EXP_DATE_FORMAT)}`}
              type='success'
              showIcon
            />
          ) : moment(planEndDate).isBefore(new Date()) ? (
            <SubscriptionStatusAlert
              description={`Your Plan has expired on ${moment(
                planEndDate
              ).format(PLAN_EXP_DATE_FORMAT)}`}
              type='error'
              showIcon
            />
          ) : (
            <SubscriptionStatusAlert
              description={`No active Subscription, Your Current plan will be expired after ${Math.floor(
                moment.duration(planEndDate - moment().startOf('day')).asDays()
              )} Days`}
              type='warning'
              showIcon
            />
          )}

          {transactionStatus === 'PENDING' ? (
            <SubscriptionStatusAlert
              description='We are awaiting payment confirmation from the Payment Gateway. If the matter is not resolved within 24 hours, please contact the Administrator.'
              type='info'
              showIcon
            />
          ) : null}
        </AlertContainer>
        <HeaderContainer>
          <HeaderContent></HeaderContent>
          <HeaderSwitchContainer>
            <HeaderSwitchButtonContainer>
              <HeaderSwitchButton
                className={activeTab === 0 ? 'active' : ''}
                onClick={() => {
                  setActiveTab(0);
                }}
              >
                Monthly
              </HeaderSwitchButton>
              <HeaderSwitchButton
                className={activeTab === 1 ? 'active' : ''}
                onClick={() => {
                  setActiveTab(1);
                }}
              >
                Annual
              </HeaderSwitchButton>
            </HeaderSwitchButtonContainer>
            <HeaderSpecilOfferContainer>
              {planDetails.specialOffer}
            </HeaderSpecilOfferContainer>
          </HeaderSwitchContainer>
        </HeaderContainer>
        <ContentTableContainer>
          <SubscriptionDetailsTable>
            <tr>
              <th>
                <SubscriptionDetailsTableHeading className='plan-features'>
                  Plan
                  <br />
                  Features
                </SubscriptionDetailsTableHeading>
              </th>
              {getCurrentTabPlans().plans.map(plan => {
                return (
                  <th
                    className={
                      plan.id === subscriptionId ||
                      (plan.id === '0' && trialPeriod)
                        ? 'active-plan'
                        : ''
                    }
                  >
                    {plan.id === subscriptionId ||
                    (plan.id === '0' && trialPeriod) ? (
                      <ActivePlanIconContainer>
                        <StarFillIcon fill={'#1CEDC3'} stroke={'#1CEDC3'} />
                      </ActivePlanIconContainer>
                    ) : null}

                    <SubscriptionDetailsTableHeading>
                      {plan.title}
                    </SubscriptionDetailsTableHeading>
                    <SubscriptionDetailsTableHeading className='plan-price'>
                      {plan.id === '0'
                        ? ``
                        : `${CURRENCY_SYMBOL + plan.amount}/${plan.duration}`}
                    </SubscriptionDetailsTableHeading>
                  </th>
                );
              })}
            </tr>
            {planDetails.planFeatures.map(feature => {
              return (
                <tr key={feature.id}>
                  <td>
                    <SubscriptionDetailsFeatureContent>
                      {feature.details}
                    </SubscriptionDetailsFeatureContent>
                  </td>
                  {getCurrentTabPlans().plans.map(plan => {
                    return (
                      <td
                        key={plan.id + feature.id}
                        className={
                          plan.id === subscriptionId ||
                          (plan.id === '0' && trialPeriod)
                            ? 'active-plan'
                            : ''
                        }
                      >
                        {feature.id == 1 ||
                        feature.id == 3 ||
                        feature.id == 4 ? (
                          planLimit(plan.id, feature.id)
                        ) : plan.features.find(
                            planFeature => planFeature.id === feature.id
                          ).available ? (
                          <CheckIcon color={'#1CEDC3'} />
                        ) : (
                          <CloseIcon color={'#F95757'} />
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            <tr className='no-border'>
              <td>
                <PlanNotes>{planDetails.notes}</PlanNotes>
              </td>
              {getCurrentTabPlans().plans.map(plan => {
                console.log('sub', plan.id, subscriptionId);
                return (
                  <td
                    key={`plan-cancel-select-${plan.id}`}
                    className={
                      plan.id === subscriptionId ||
                      (plan.id === '0' && trialPeriod)
                        ? 'active-plan'
                        : ''
                    }
                  >
                    {plan.id === subscriptionId ? (
                      <CancelSubscriptionButton
                        onClick={() => setCancelModalVisible(true)}
                      >
                        Cancel Subscription
                      </CancelSubscriptionButton>
                    ) : subscriptionId === null &&
                      plan.id !== '0' &&
                      transactionStatus !== 'PENDING' ? (
                      <ChoosePlanButton
                        onClick={() => {
                          startSubscriptionHandler(
                            subscriptionPlans.find(item => {
                              return item.id === plan.id;
                            })
                          );
                        }}
                      >
                        Choose Plan
                      </ChoosePlanButton>
                    ) : null}
                  </td>
                );
              })}
            </tr>
          </SubscriptionDetailsTable>
        </ContentTableContainer>
      </ManageSubscriptionContainer>
    </>
  );
};

export default NewManageSubscription;
