import React, { useEffect, useRef, useState } from 'react';
import { ContainerRow, TitleRow, DividerStyle } from '../styledComponents';
import { AddButton } from '../styledComponents';
import { billJob } from '../../../Apis/Jobs';
import { ControlsDiV } from '../styledComponents';
// import {
//   HeadingRow,
//   ContentRow,
//   ActionButton,
//   StyledTextArea,
//   CustomMoreButtonStyle,
//   CustomHeadingCol,
//   DictationTemplateContainer,
//   DictationTemplateHeader,
//   DictationTemplateContent
// } from './styledComponents';
import { ContentRow } from '../Editor/styledComponents';
import { useSelector } from 'react-redux';
import { Col, Dropdown, notification, Row } from 'antd';
import { useParams } from 'react-router-dom';
import { getDemographics, sectionFetch } from '../../../Apis/Jobs';

import PageTitle from '../../../Utils/PageTitle';
import CustomTypography from '../../CustomComponents/CustomTypography';

import Demographics from '../Editor/Demographics';
import Spinner from '../../CustomComponents/Spinner';

import { useHistory, useLocation } from 'react-router-dom';

import moment from 'moment';
import { DATE_TIME_FORMAT } from '../../../Constants/StringConstants';
import NewBillerEditorComponent from '../../CustomComponents/NewBillerEditorComponent';

import MoreHorizIcon from '../../../Assets/Icons/MoreHorizIcon';

const BillerJobs = props => {
  const [theme] = useSelector(state => [state.themeReducer]);
  const { jobId } = useParams();
  // const { sessionId } = useParams('sessionId');
  const user = useSelector(state => state.userReducer);
  const audioRef = useRef();
  const [readOnly, setReadOnly] = useState(false);
  const [demographicsData, setDemographicsData] = useState([]);
  const [localDemographicsData, setLocalDemographicsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sections, setSections] = useState(null);
  const [headings, setHeadings] = useState(null);

  const [inferenceActive, setInferenceActive] = useState(false);

  const [demographicsLoading, setDemographicsLoading] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const [activeSectionContent, setActiveSectionContent] = useState('');
  const [inferenceData, setInferenceData] = useState([]);
  const [inferenceAnnotation, setInferenceAnnotation] = useState([]);
  const [saveStatus, setSaveStatus] = useState(null);
  const [changeStatus, setChangeStatus] = useState(false);

  const [jobName, setJobName] = useState('');
  const [editorLoading, setEditorLoading] = useState(true);
  const [lastUpdateTime, setLastUpdateTime] = useState(null);

  const [emptySection, setEmptySection] = useState(false);
  const [updateInferenceLoading, setUpdateInferenceLoading] = useState(false);
  const [editorDataPreviewValue] = useState([]);

  const location = useLocation();
  const [userType, setUserType] = useState('');

  const [localSectionIdMap] = useState(new Map());
  const [newInferenceActive, setNewInferenceActive] = useState(false);

  const [jobStage, setJobStage] = useState('');
  const [refetchDemographics, setRefetchDemographics] = useState(false);
  const history = useHistory();
  useEffect(() => {
    getEditorData();

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getDemographicsData();
  }, [localDemographicsData]);
  const handleBillJob = async () => {
    // history.pushState();
    setLoading(true); // Set loading to true while the API call is in progress
    try {
      const response = await billJob(jobId, user.sessionId);
      if (response.data.success === true) {
        notification.success({
          message: 'Success',
          description: response.data.message
        });
        setLoading(false);
        history.push('/dashboard/biller');
      } else {
        notification.error({
          message: 'error',
          description: response.data.message
        });
        setLoading(false);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };
  const getDemographicsData = () => {
    setDemographicsData(localDemographicsData);
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setUserType(searchParams.get('userType'));
    setJobStage(searchParams.get('jobStage'));
    // setViewOnly(
    //   searchParams.get('jobStage') &&
    //     (searchParams.get('jobStage') === 'ready' ||
    //     searchParams.get('jobStage') === 'in-progress'
    //       ? false
    //       : true)
    // );

    setDemographicsLoading(true);
    getDemographics(jobId)
      .then(response => {
        if (response.data.success === true) {
          setLocalDemographicsData(response.data.result);
          setDemographicsLoading(false);
        } else {
          notification['error']({
            message: 'Demographics Fetch Failed',
            description: response.data.message
          });
          setDemographicsLoading(false);
        }
        setRefetchDemographics(false);
      })
      .catch(err => {
        console.log(err);
        setDemographicsLoading(false);
      });

    // eslint-disable-next-line
  }, [refetchDemographics]);
  const formatTime = time => {
    const savedTime = moment(time);
    const now = moment(new Date());
    const diff = now.diff(savedTime, 'hours');

    if (diff > 12) {
      return savedTime.format(DATE_TIME_FORMAT);
    } else {
      return 'Today ' + savedTime.format('HH:mm:ss');
    }
  };

  function processValue(value) {
    return value.replace(/<\/p>/g, '</p>\n').replace(/<\/?p>/g, '');
  }

  const getEditorData = () => {
    setEditorLoading(true);
    sectionFetch(jobId)
      .then(response => {
        if (response.data.success) {
          for (let i = 0; i < response.data.result.length; i++) {
            const resultItem = response.data.result[i];
            for (let j = 0; j < resultItem.sections.length; j++) {
              const section = resultItem.sections[j];
              for (let k = 0; k < section.content.length; k++) {
                const contentItem = section.content[k];
                if (contentItem.type === 'Text') {
                  response.data.result[i].sections[j].content[k].value =
                    processValue(contentItem.value);
                }
              }
            }
          }

          setSections(
            response.data.result[0] && response.data.result[0].sections
          );

          setJobName(response.data.jobName);
          setLastUpdateTime(response.data.result[0].lastUpdatedTime);
          setEditorLoading(false);
        } else {
          notification['error']({
            message: 'Section Fetch Failed',
            description: response.data.message
          });
          setEditorLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setEditorLoading(false);
      });
  };

  return loading && (editorLoading || demographicsLoading) ? (
    <Spinner />
  ) : (
    <>
      <PageTitle title='billerjobs' />
      <ContainerRow gutter={[0, 40]}>
        <TitleRow justify='space-between'>
          <CustomTypography color={theme['@text-color']}>
            Review
          </CustomTypography>
          <ControlsDiV>
            <AddButton type='primary' shape='round' onClick={handleBillJob}>
              Mark As Billed
            </AddButton>
          </ControlsDiV>
        </TitleRow>

        <ContentRow gutter={[24, 24]}>
          <Col span={18}>
            <NewBillerEditorComponent
              jobId={jobId}
              demographicsData={demographicsData}
              sections={sections}
              setSections={setSections}
              headings={headings}
              inferenceActive={inferenceActive}
              setInferenceActive={setInferenceActive}
              audioRef={audioRef}
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              saveStatus={saveStatus}
              setSaveStatus={setSaveStatus}
              setChangeStatus={setChangeStatus}
              jobName={jobName}
              loading={editorLoading}
              inferenceAnnotation={inferenceAnnotation}
              setInferenceAnnotation={setInferenceAnnotation}
              lastUpdateTime={lastUpdateTime}
              setLastUpdateTime={setLastUpdateTime}
              // saveTimeFormat={saveTimeFormat}
              // setEditorDataPreviewValue={setEditorDataPreviewValue}
              // setEditorData={setEditorData}
              setEditorLoading={setEditorLoading}
              formatTime={formatTime}
              activeSectionContent={activeSectionContent}
              setActiveSectionContent={setActiveSectionContent}
              viewOnly={false}
              localSectionIdMap={localSectionIdMap}
              newInferenceActive={newInferenceActive}
              setNewInferenceActive={setNewInferenceActive}
              setRefetchDemographics={setRefetchDemographics}
            />
          </Col>
          <Col span={6}>
            <Demographics
              theme={theme}
              editorData={editorDataPreviewValue}
              content={demographicsData}
              inference={inferenceActive}
              loading={demographicsLoading}
              // inferenceAnnotation={inferenceAnnotation}
              // setInferenceAnnotation={setInferenceAnnotation}
              // activeSection={activeSection}
              // emptySection={emptySection}
              // updateInferenceLoading={updateInferenceLoading}
              // setUpdateInferenceLoading={setUpdateInferenceLoading}
              readOnly={readOnly}
            />
          </Col>
        </ContentRow>
      </ContainerRow>
    </>
  );
};

export default BillerJobs;
