import React from "react";

function MicIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 85 206"
    >
      <path
        fill="#0B3854"
        d="M63.774 12.81c-.492 0-.985-.123-1.354-.492-6.895-5.05-15.882-7.884-25.116-7.884-9.233 0-17.975 2.833-24.992 8.007-.985.739-2.34.492-3.201-.493-.862-.986-.37-2.464.616-3.203C17.483 3.08 27.332 0 37.427 0 47.523 0 57.373 3.203 65.13 8.745c.984.74 1.23 2.094.492 3.203-.492.616-1.108.862-1.847.862z"
      ></path>
      <path
        fill="#0B3854"
        d="M58.972 21.803c-.492 0-1.108-.246-1.477-.493-5.54-4.804-12.681-7.39-20.068-7.39-7.387 0-14.528 2.71-20.068 7.39-.985.74-2.339.74-3.2-.246-.74-.986-.74-2.34.245-3.203 6.402-5.543 14.528-8.499 23.023-8.499s16.62 2.957 22.9 8.5c.985.738 1.108 2.216.246 3.202-.37.493-.985.739-1.6.739z"
      ></path>
      <path
        fill="#092E49"
        d="M83.965 128.102a11.969 11.969 0 00-8.372-8.376c-9.972-2.833-18.96 6.282-15.636 16.382 1.231 3.695 4.063 6.652 7.757 7.76.985.247 1.97.493 2.954.616v41.51c0 9.484-7.263 17.121-16.25 17.121-8.988 0-16.252-7.637-16.252-17.121v-51.733c2.585-.493 4.555-2.71 4.555-5.297v-7.021c17.606-2.71 31.395-18.23 31.395-36.829V73.412v-2.71-.123-.369s0-.123-.123-.123l-.124-.124s-.123 0-.123-.123h-.492-.369s-.124 0-.124.123c0 0 0 .124-.123.124 0 0 0 .123-.123.123 0 0 0 .123-.123.123v.739c-.985.246-.985.616-.985.616a5.996 5.996 0 00-5.786 6.035c0 3.203 2.585 5.79 5.786 6.036v.862c0 17.491-12.68 31.902-29.055 34.489-1.724.247-3.447.37-5.294.37-1.847 0-3.57-.123-5.294-.37C15.39 116.4 2.709 101.989 2.709 84.498v-.862A5.996 5.996 0 008.495 77.6c0-3.202-2.585-5.789-5.786-6.035 0 0-.37-2.094-1.355-2.217h-.123-.246C.123 69.47 0 70.456 0 70.703V84.745c0 18.845 13.789 34.488 31.64 37.198v7.021c0 2.464 1.601 4.558 3.94 5.173v52.719c0 10.347 8.495 18.846 18.837 18.846s18.837-8.499 18.837-18.846v-42.495c7.387-.739 13.05-8.13 10.71-16.259zm-15.636 13.549c-2.955-.985-5.417-3.326-6.402-6.405-2.708-8.253 4.679-15.766 12.927-13.426a10.2 10.2 0 016.895 6.898c2.462 8.252-5.171 15.643-13.42 12.933z"
      ></path>
      <path
        fill="#092E49"
        d="M74.115 124.283c-6.278-1.847-11.819 3.942-9.849 10.224.739 2.34 2.586 4.064 4.802 4.803 6.279 2.094 11.942-3.572 10.218-9.853-.615-2.464-2.585-4.435-5.17-5.174z"
      ></path>
      <path
        fill="#47A4EA"
        d="M37.426 108.268c13.79 0 25.239-11.332 25.239-25.251V53.332c0-1.232-1.477-1.848-2.34-1.109L15.512 93.117c-.492.493-.615 1.109-.246 1.724 4.186 8.007 12.558 13.427 22.161 13.427zM14.526 42.495l18.837-17.122c.615-.492.123-1.478-.739-1.355-9.233 1.848-16.744 8.746-19.329 17.614-.246.74.616 1.355 1.231.863zM41.366 24.88L13.049 50.623c-.615.493-.985 1.355-.985 2.094 0 2.464 2.955 3.695 4.802 2.094l28.316-25.743c1.6-1.479 1.108-4.188-1.108-4.804h-.123c-.739-.247-1.847 0-2.585.616zM51.338 30.793L13.172 65.528c-.616.493-.862 1.355-.862 2.094v.124c0 2.463 2.955 3.695 4.679 2.093l38.166-34.735a2.796 2.796 0 00.123-4.064c-1.108-1.232-2.832-1.355-3.94-.247zM57.247 40.154L13.048 80.31c-.492.493-.862 1.232-.862 1.971v.616c0 2.217 2.709 3.449 4.31 1.847l44.444-40.4c.739-.617 1.108-1.602.739-2.587 0-.247-.123-.37-.123-.616-.616-1.725-2.832-2.34-4.31-.986z"
      ></path>
    </svg>
  );
}

export default MicIcon;
