import React, { useState, useEffect } from 'react';
import { Badge, Col, Popover, Spin } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import {
  NotificationTitle,
  NotificationContentDiv,
  NotificationRowComponent,
  GlobalStyle,
  NotificationElementStyle,
  NotificationProfilePictureStyle,
  NotificationColumnContentStyle,
  PopOverFooterDiv,
  StyledAvatar,
  EmrIconContainer
} from './styledComponents';
import { FetchNotifications, ReadNotification } from '../../../../Apis/User';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import BellIcon from '../../../../Assets/Icons/BellIcon';
import EmrVerificationIcon from '../../../../Assets/Icons/EmrVerificationIcon';
import { useHistory } from 'react-router-dom';

const Notification = () => {
  const [theme] = useSelector(state => [state.themeReducer]);
  const user = useSelector(state => state.userReducer);
  const [notificationList, setNotificationList] = useState([]);
  const [unreadCount, setUnreadCount] = useState('0');
  const [hasMore, setHasMore] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const notificationStatus = useSelector(state => state.notificationReducer);
  const history = useHistory();
  const [newNotification, setNewNotification] = useState(false);

  const actionPathMap = new Map([
    ['TRANSCRIPTIONIST_COMPLETED', 'completed'],
    ['TRANSCRIPTIONIST_REJECTED', 'rejected'],
    ['TRANSCRIPTIONIST_READY', 'ready'],
    ['NEW_JOB_AVAILABLE', 'available']
  ]);

  const getNotification = pageNo => {
    if (pageNo === 0) {
      setInitialLoading(true);
    } else {
      setLoading(true);
    }
    FetchNotifications(user.id, pageNo, 5, 'WEB')
      .then(response => {
        if (response.data.success) {
          let newList = [];
          if (pageNo === 0) {
            newList = response.data.result;
          } else {
            newList = notificationList.concat(response.data.result);
          }
          setNotificationList(newList);
          setUnreadCount(response.data.unReadCount);
          setHasMore(newList.length < response.data.size);
          setLoading(false);
          setInitialLoading(false);
          setNewNotification(true);
        } else {
          setHasMore(false);
          setLoading(false);
          setInitialLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
        setInitialLoading(false);
      });
  };

  const readNotification = (notificationId, readStatus, target, jobId) => {
    readStatus === false &&
      ReadNotification(user && user.id, notificationId, false)
        .then(response => {
          if (response.data.success) {
            let notifications = notificationList;
            let index = notifications.findIndex(
              item => item.id === notificationId
            );
            notifications[index].readStatus = true;
            setUnreadCount(unreadCount - 1);
            setNewNotification(true);
          }
        })
        .catch(err => {
          console.log(err);
        });

    target === 'VERIFY_EMR'
      ? window.open(user.emrHandlingData.emrVerificationUrl, '_self')
      : target !== 'TRANSCRIPTION_WRITE_FAILED'
      ? history.push(`/dashboard/jobs-list/${actionPathMap.get(target)}`)
      : history.push(`/dashboard/jobs-list/incomplete`);

    setVisible(false);
  };

  const readAllNotifications = () => {
    ReadNotification(user && user.id, null, true).then(response => {
      if (response.data.success) {
        getNotification(0);
        setNewNotification(true);
      }
    });
  };

  const popoverVisibleChange = visibleStatus => {
    if (visibleStatus) {
      setVisible(true);
      if (notificationList.length === 0) getNotification(0);
    } else {
      setVisible(false);
      setNotificationList([]);
    }
  };

  useEffect(() => {
    if (notificationStatus) {
      getNotification(0);
    }

    // eslint-disable-next-line
  }, [notificationStatus.newNotification]);

  const content = (
    <>
      <NotificationContentDiv key={visible} loading={initialLoading}>
        {initialLoading ? (
          <Spin />
        ) : (
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={getNotification}
            hasMore={!loading && hasMore}
            useWindow={false}
          >
            {notificationList &&
              notificationList.map(notificationItem => {
                return (
                  <div>
                    <NotificationRowComponent
                      onClick={() =>
                        readNotification(
                          notificationItem.id,
                          notificationItem.readStatus,
                          notificationItem.target,
                          notificationItem.targetId
                        )
                      }
                      readStatus={notificationItem.readStatus}
                    >
                      {notificationItem.target === 'VERIFY_EMR' ? (
                        <EmrIconContainer>
                          <EmrVerificationIcon />
                        </EmrIconContainer>
                      ) : null}
                      <Col span={4}>
                        <NotificationProfilePictureStyle
                          shape='square'
                          size={58}
                          src={
                            notificationItem.profilePicture ? (
                              notificationItem.profilePicture
                            ) : (
                              <UserOutlined />
                            )
                          }
                        />
                      </Col>
                      <NotificationColumnContentStyle span={20}>
                        <NotificationElementStyle messageProps={false}>
                          {notificationItem && notificationItem.senderName}
                        </NotificationElementStyle>

                        <NotificationElementStyle messageProps={true}>
                          <span>
                            {notificationItem && notificationItem.body}

                            <span style={{ color: '#47A4EA'}}>
                              #
                              {notificationItem.facilities &&
                                notificationItem.facilities.facilityName}
                            </span>
                          </span>
                        </NotificationElementStyle>
                        <NotificationElementStyle
                          timeProps={true}
                          messageProps={true}
                        >
                          {moment(
                            notificationItem && notificationItem.createdDate
                          ).fromNow()}
                        </NotificationElementStyle>
                      </NotificationColumnContentStyle>
                    </NotificationRowComponent>
                  </div>
                );
              })}
          </InfiniteScroll>
        )}
      </NotificationContentDiv>
      {visible && (
        <PopOverFooterDiv>
          <p onClick={readAllNotifications}>Mark all as read</p>
        </PopOverFooterDiv>
      )}
    </>
  );

  return (
    <>
      <Popover
        overlayClassName='notification-popover-style'
        content={visible ? content : <></>}
        trigger='click'
        title={
          visible && (
            <NotificationTitle color={theme['@primary-color']}>
              NOTIFICATIONS
            </NotificationTitle>
          )
        }
        placement='bottomLeft'
        onVisibleChange={popoverVisibleChange}
      >
        <GlobalStyle />
        <Badge size={'default'} count={unreadCount}>
          <StyledAvatar
            style={{ background: 'transparent' }}
            size={26}
            icon={<BellIcon fill={visible && theme['@primary-color']} />}
            ring={newNotification ? 'true' : 'false'}
          />
        </Badge>
      </Popover>
    </>
  );
};
export default Notification;
