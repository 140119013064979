import React from "react";

function ProfileIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 233.92 233.92"
      height="25px"
      width="25px"
    >
      <path
        fill="#5d5d5d"
        d="M189.51 205.54a9.44 9.44 0 01-2.34-1c-1.87-1.37-2.4-3.3-2.42-5.49a62.3 62.3 0 00-3.65-21.09c-8.23-22.44-23.81-37.28-46.87-43.51-36.23-9.86-73.54 12.19-82.92 48.6a65.35 65.35 0 00-2.1 15.88 8.09 8.09 0 01-.56 3.3 6 6 0 01-4.19 3.31h-2A7 7 0 0139 203.2a9.18 9.18 0 01-1.1-2.67v-3c0-.44.12-.88.15-1.32A64.83 64.83 0 0139.52 184q9.16-40.14 46.81-56.88c1.59-.71 3.23-1.3 4.93-2l-.74-.5C72.78 113 64.61 96.6 66.53 75.48c1-11.32 5.88-21.11 13.77-29.32a49.83 49.83 0 0128.44-15c1.72-.29 3.48-.46 5.2-.69H120a28.31 28.31 0 003.44.4A48.72 48.72 0 01149.21 42c15.31 13.17 21.29 29.87 17.46 49.68-2.78 14.33-10.95 25.23-23.31 33-.19.11-.45.17-.55.49.36.16.7.29 1.05.42q32.64 12.41 46.33 44.48a76.47 76.47 0 015.88 29.47 5.7 5.7 0 01-3.46 5.57l-1.14.43zM117 41.13a38.14 38.14 0 00-23.78 8.12c-12.54 10.17-17.84 23.43-15 39.26 2.91 16.07 12.69 26.61 28.36 31.52a16.62 16.62 0 006.56 1 44.74 44.74 0 017.85 0 8 8 0 002 0 38 38 0 0024-13.48c9.65-11.74 12.05-25 7.4-39.45A39.34 39.34 0 00117 41.13z"
      ></path>
    </svg>
  );
}

export default ProfileIcon;
