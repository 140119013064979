import CardIcon from '../../../Assets/Icons/CardIcon';
import PaymentLogsIcon from '../../../Assets/Icons/PaymentLogsIcon';
import SubscriptionIcon from '../../../Assets/Icons/SubscriptionIcon';

export const BILLING_CONTENTS = [
  {
    title: 'Manage Cards',
    background: '#CBFFE7',
    iconColor: '#05C46B',
    icon: CardIcon,
    path: '/dashboard/billing/manage-cards',
  },
  {
    title: 'Manage Subscription',
    background: '#DAD2FF',
    iconColor: '#6440FF',
    icon: SubscriptionIcon,
    path: '/dashboard/billing/manage-subscription',
  },
  {
    title: 'Past Payments',
    background: '#D0FFFE',
    iconColor: '#00D8D6',
    icon: PaymentLogsIcon,
    path: '/dashboard/billing/payment-logs',
  },
];
