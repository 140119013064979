import styled from 'styled-components';
import { Layout } from 'antd';
import CascadeLogo from '../../../Assets/Icons/CascadeLogo';
import MicIcon from '../../../Assets/Icons/MicIcon';

const { Sider, Content } = Layout;

export const StyledSider = styled(Sider)``;

export const StyledContent = styled(Content)`
  width: 100%;
  margin: auto;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: calc(100vh - 70px);
  max-height: calc(100vh - 70px);
`;

export const StyledLogo = styled(CascadeLogo)`
  & svg {
    height: 150px !important;
  }
`;

export const StyledMicLogo = styled(MicIcon)``;

export const LogoContainer = styled.div`
  margin: ${props => (props.collapsed ? '0px 5px' : '0px 30px')};
  transition: all 0.2s ease-in;
  height: 70px;
  width: ${props => (props.collapsed ? '100px' : '190px')};
  transform: width 0.25s ease-in;
  ${props =>
    props.collapsed &&
    `
  padding:8px;
  & svg{
    height:54px;
  }
  `}
`;
