import React from "react";

function SettingsIcon() {
  return (
    <span className="anticon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
      >
        <g fill="#666" clip-path="url(#clip0)">
          <path d="M8.502 16H7.498c-.812 0-1.473-.66-1.473-1.473v-.34c-.345-.11-.68-.25-1.003-.416l-.24.24a1.473 1.473 0 01-2.084 0l-.71-.71a1.473 1.473 0 010-2.082l.24-.241a6.459 6.459 0 01-.415-1.003h-.34C.66 9.975 0 9.315 0 8.502V7.498c0-.812.66-1.473 1.473-1.473h.34c.11-.345.25-.68.416-1.003l-.24-.24a1.473 1.473 0 010-2.084l.71-.71a1.473 1.473 0 012.082 0l.241.24a6.466 6.466 0 011.003-.415v-.34C6.025.66 6.685 0 7.498 0h1.004c.812 0 1.473.66 1.473 1.473v.34c.345.11.68.25 1.003.416l.24-.24a1.473 1.473 0 012.084 0l.71.71c.567.566.583 1.5 0 2.082l-.241.241c.167.322.306.658.416 1.003h.34c.812 0 1.473.66 1.473 1.473v1.004c0 .812-.66 1.473-1.473 1.473h-.34c-.11.345-.25.68-.416 1.003l.24.24a1.473 1.473 0 010 2.084l-.71.71a1.473 1.473 0 01-2.082 0l-.241-.24a6.461 6.461 0 01-1.003.415v.34c0 .812-.66 1.473-1.473 1.473zM5.18 12.787c.447.264.93.464 1.432.594.207.053.352.24.352.454v.692c0 .295.24.536.535.536h1.004c.295 0 .536-.24.536-.536v-.692c0-.214.144-.4.351-.454.503-.13.985-.33 1.432-.594a.469.469 0 01.57.072l.49.49c.212.211.551.207.758 0l.71-.71a.535.535 0 000-.757l-.49-.49a.469.469 0 01-.072-.57c.264-.448.464-.93.594-1.433a.469.469 0 01.454-.351h.692c.295 0 .536-.24.536-.536V7.498a.536.536 0 00-.536-.535h-.692a.469.469 0 01-.454-.352 5.523 5.523 0 00-.594-1.432.469.469 0 01.072-.57l.49-.49a.535.535 0 000-.758l-.71-.71a.535.535 0 00-.757 0l-.49.49a.469.469 0 01-.57.072 5.525 5.525 0 00-1.433-.594.469.469 0 01-.351-.454v-.692a.536.536 0 00-.536-.536H7.498a.536.536 0 00-.535.536v.692c0 .214-.145.4-.352.454-.503.13-.985.33-1.432.594a.469.469 0 01-.57-.072l-.49-.49a.535.535 0 00-.758 0l-.71.71a.535.535 0 000 .757l.49.49c.152.152.181.386.072.57-.264.448-.464.93-.594 1.433a.469.469 0 01-.454.351h-.692a.536.536 0 00-.536.536v1.004c0 .295.24.535.536.535h.692c.214 0 .4.145.454.352.13.503.33.985.594 1.432a.469.469 0 01-.072.57l-.49.49a.535.535 0 000 .758l.71.71a.535.535 0 00.757 0l.49-.49a.47.47 0 01.57-.072z"></path>
          <path d="M8 11.481A3.485 3.485 0 014.519 8a3.485 3.485 0 013.48-3.481 3.485 3.485 0 013.482 3.48A3.485 3.485 0 018 11.482zm0-6.025A2.547 2.547 0 005.456 8 2.547 2.547 0 008 10.544 2.547 2.547 0 0010.544 8 2.547 2.547 0 008 5.456z"></path>
        </g>
        <defs>
          <clipPath id="clip0">
            <path fill="#fff" d="M0 0H16V16H0z"></path>
          </clipPath>
        </defs>
      </svg>
    </span>
  );
}

export default SettingsIcon;
