import axios from 'axios';
import { notification } from 'antd';

export const baseUrl = 'https://dev-api.azure.cascade.md';

// export const baseUrl = 'https://stage-api.cascade.md';
// export const baseUrl = 'http://10.10.1.145:8083';
//'https://8c3b-2402-3a80-19f4-aa5a-1d60-f1e4-23ad-258d.ngrok.io';

export const getBaseUrl = () => {
  if (process?.env?.NODE_ENV === 'development') {
    return baseUrl;
  } else {
    return process.env.REACT_APP_API_SERVER;
  }
  // switch (process.env.NODE_ENV) {
  //   case 'development':
  //     return baseUrl;
  //   default:
  //     return process.env.REACT_APP_API_SERVER;
  // }
};
const axiosInstance = axios.create({
  baseURL: getBaseUrl()
});

const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled
    ? false
    : true;
};

//request handler
const requestHandler = request => {
  if (isHandlerEnabled(request)) {
    // inject access token here

    const user = JSON.parse(localStorage.getItem('user'));
    let url = request.url.split('/');
    if (url[2] === 'clinicId') {
      url[2] = user.clinicId;
      request.url = url.join('/');
    }
    if (user && user.token)
      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${user.token}`
      };
  }
  return request;
};

const errorHandler = error => {
  console.log(error);
  if (isHandlerEnabled(error.config)) {
    // Handle errors
  }
  if (!error.response) {
    notification['error']({
      message: 'Server Error',
      description: 'Unable to connect to the  server'
    });
  } else if (error.response.status === 403) {
    notification['error']({
      message: 'Permission Denied',
      description: 'You are not permitted to do this operation'
    });
  } else if (error.response.status === 401) {
    window.location.href = '/login';
    localStorage.clear();
    notification['error']({
      message: 'Token Expired',
      description: 'Please Login to continue'
    });
  } else {
    notification['error']({
      message: 'Try Again',
      description: 'Please try again later'
    });
  }
  return Promise.reject({ ...error });
};

const successHandler = response => {
  // if (isHandlerEnabled(response.config)) {
  // }
  return response;
};

axiosInstance.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
);
axiosInstance.interceptors.request.use(request => requestHandler(request));
export default axiosInstance;
