import { ADD_USER, CLEAR_USER, USER_SET_UNSAVEDSTATUS } from "../Actions/User";

const initialState = {};
export const userReducer = (state = initialState,action) => {
  switch (action.type) {
    case ADD_USER:
      return action.payload;

    case USER_SET_UNSAVEDSTATUS:
        return {...state, unsavedStatus: action.payload.user.unsavedStatus};

    case CLEAR_USER:
      return {};
    default:
      return state;
  }
};
