import React from 'react';
import { useState, useEffect } from 'react';
import { TitleRow, ContainerRow } from '../styledComponents';
import CustomTypography from '../../CustomComponents/CustomTypography';
import { useSelector } from 'react-redux';
import SmallCard from '../../CustomComponents/SmallCard';
import { Col, Row } from 'antd';

import { Link } from 'react-router-dom';
import PageTitle from '../../../Utils/PageTitle';
import { settingsContents } from './SettingsConfig';
import FacilityIcon from '../../../Assets/Icons/FacilityIcon';

const Settings = () => {
  const [theme] = useSelector(state => [state.themeReducer]);
  const [user] = useSelector(state => [state.userReducer]);
  const [settingsItems, setSettingsItems] = useState([]);

  useEffect(() => {
    if (user) setSettingsItems(settingsContents(user.userType));
    // eslint-disable-next-line
  }, [user]);

  return (
    <>
      <PageTitle title='Settings' />
      <ContainerRow gutter={[0, 40]}>
        <TitleRow span={24}>
          <CustomTypography color={theme['@text-color']}>
            Settings
          </CustomTypography>
        </TitleRow>
        <Col span={24}>
          <Row gutter={[36, 36]}>
            {settingsItems.map(item => (
              // (user.userType !== ('admin' || 'clinicAdmin') &&
              //   ['Config', 'Key to Phrase', 'Dictation Template'].includes(
              //     item.title
              //   )) ||
              // (user.userType !== 'clinicAdmin' && item.title === 'Facility') ? (
              //   ''
              // ) : (
              <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={3}>
                <Link to={item.path}>
                  <SmallCard item={item} />
                </Link>
              </Col>
            ))}
            {
              (user.userType === 'admin' && user.facilityAccessEnable) ?
                <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={3}>
                  <Link to={'/dashboard/settings/facility'}>
                    <SmallCard item={{
                      title: 'Facility',
                      background: '#c2ffcf',
                      iconColor: '#4d8759',
                      icon: FacilityIcon,
                      path: '/dashboard/settings/facility'
                    }} />
                  </Link>
                </Col>
                :
                null
            }
          </Row>
        </Col>
      </ContainerRow>
    </>
  );
};
export default Settings;
