import axiosInstance from '../Config/AxiosInstance';

const PREFIX = `/clinic/doctor`;

export const listAll = (
  facilities,
  name,
  pageSize,
  pageNo,
  sortFitler,
  sortOrder
) => {
  let url =
    PREFIX +
    `?name=${name}&sortKey=${sortFitler}&page=${pageNo}&size=${pageSize}&sortOrder=${sortOrder}`;
  if (facilities.length > 0) {
    url = url + `&facilityIds=${facilities}`;
  }
  return axiosInstance.get(url);
};

export const saveDoctor = data => {
  return axiosInstance.post(PREFIX, data);
};

export const editDoctor = data => {
  return axiosInstance.put(PREFIX, data);
};

export const deleteReviewer = id => {
  return axiosInstance.delete(PREFIX + `/${id}`);
};

export const fetchEhrDoctors = () => {
  return axiosInstance.get(PREFIX + '/ehr');
};

