import React from 'react';
import {
  CardContainer,
  CardTitleDiv,
  CardTitleText,
  CardImageContainer,
  StyledHl7Icon,
  StyledInfoButton
} from './styledComponents';
import { useSelector } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';

const GridItem = ({
  file,
  getComments,
  setCommentsDrawerVisible,
  setShowDetails,
  setSelectedFile,
  selected,
  setSelected,
  setFileExportList,
  setListSelection
}) => {
  const [theme] = useSelector(state => [state.themeReducer]);

  const SelectCards = (event, value) => {
    let selectedSet = new Set(selected);
    if (event.ctrlKey) {
      if (selectedSet.has(value)) {
        selectedSet.delete(value);
      } else {
        selectedSet.add(value);
      }
    } else {
      if (selectedSet.has(value)) {
        selectedSet.delete(value);
      } else {
        selectedSet.clear();
        selectedSet.add(value);
      }
    }

    let arr = Array.from(selectedSet);
    setSelected(arr);
    setFileExportList(arr);
    arr.length > 0 ? setListSelection(true) : setListSelection(false);
  };

  return (
    <CardContainer
      primaryColor={theme['@primary-color']}
      isSelected={selected.includes(file && file.id)}
      onClick={event => {
        SelectCards(event, file && file.id);
      }}
    >
      <CardImageContainer>
        <StyledHl7Icon />
      </CardImageContainer>
      <CardTitleDiv
        isSelected={selected.includes(file && file.id)}
        primaryColor={theme['@primary-color']}
      >
        <CardTitleText textColor={theme['@text-color']}>
          {file.fileName}
        </CardTitleText>
        <StyledInfoButton
          title='Show Details'
          icon={<InfoCircleOutlined />}
          onClick={() => {
            setSelectedFile(file);
            setShowDetails(true);
            setCommentsDrawerVisible(true);
            getComments(file.fileExportDataList);
          }}
        />
      </CardTitleDiv>
    </CardContainer>
  );
};

export default GridItem;
