import React from 'react';
import { StyledInput } from './styledComponents';
import { useSelector } from 'react-redux';

const CustomInput = (props) => {
  const [theme] = useSelector((state) => [state.themeReducer]);

  return (
    <StyledInput
      primaryTextColor={props.color ? props.color : theme['@text-color']}
      secondaryTextColor={theme['@text-color-secondary']}
      size="large"
      {...props}
    />
  );
};
export default CustomInput;
