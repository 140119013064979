import React from 'react';
import {
  CardContainer,
  CardFooterDiv,
  CardTitleDiv,
  CardIconDiv,
  CardTitleText,
  CardFooterText
} from './styledComponents';

function MediumCard({ theme, item }) {
  return (
    <CardContainer>
      <CardTitleDiv>
        <CardTitleText textColor={theme['@text-color']}>
          {item.title}
        </CardTitleText>
        <CardIconDiv background={item.background}>
          <item.icon color={item.iconColor} size='30' />
        </CardIconDiv>
      </CardTitleDiv>
      <CardFooterDiv>
        <CardFooterText>{item.count ? item.count : '--'}</CardFooterText>
      </CardFooterDiv>
    </CardContainer>
  );
}

export default MediumCard;
