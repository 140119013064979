import React from 'react';
import Avatar from 'antd/lib/avatar/avatar';
import DefaultPic from '../../../Assets/Images/default.png';
import { AvatarContainer } from './styledComponents';
import { StarFilled } from '@ant-design/icons';

function CustomAvatar({ size, url, admin }) {
  return (
    <AvatarContainer>
      <Avatar size={size} src={url ? url : DefaultPic} />
      {admin && <StarFilled />}
    </AvatarContainer>
  );
}

export default CustomAvatar;
