import React from 'react';

import { Row, Col, Popconfirm, Tooltip } from 'antd';

import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import { ActionButton } from '../../CustomComponents/Table/styledComponents';

import { PopoverGlobalStyle } from '../JobList/styledComponents';

function removeHTMLTags(inputString) {
  return inputString.replace(/<[^>]*>/g, '');
}

export const columns = (
  setSelectedItem,
  showModal,
  setAddDrawerVisible,

  deleteMultipleDictionaryCannedTexts,

  reInvite
) => [
  {
    title: 'Key',

    dataIndex: ['key'],

    key: 'id',

    render: val => {
      return (
        <span
          title={val}
          style={{ fontSize: '14px', fontWeight: '600', color: '#1E1E1E' }}
        >
          {val?.length > 30 ? val.slice(0, 30) + '...' : val}
        </span>
      );
    }
  },

  {
    title: 'Phrase',

    dataIndex: ['phrase'],

    key: 'phrase',

    // render: val => {

    //   return (

    //     <span title={val}>

    //       {val?.length > 120 ? val.slice(0, 120) + '...' : val}

    //     </span>

    //   );

    // }

    render: val => {
      const sanitizedText = removeHTMLTags(val);

      const data = [];

      for (let i = 0; i < 46; i++) {
        data.push({
          key: i,

          Phrase: `Edward King ${i}`
        });
      }

      return (
        <span
          title={sanitizedText}
          style={{ fontSize: '14px', fontWeight: '400', color: '#6D6D6D' }}
        >
          {sanitizedText?.length > 90
            ? sanitizedText.slice(0, 90) + '...'
            : sanitizedText}
        </span>
      );
    }
  },

  {
    title: 'Action',

    width: '180px',

    render: row => {
      return (
        <Row gutter={12}>
          <Col>
            <Tooltip title='Edit Key to Phrase'>
              <ActionButton
                onClick={() => {
                  setSelectedItem(row);

                  showModal();
                }}
                icon={<EditOutlined />}
              />
            </Tooltip>
          </Col>

          <Col>
            <Popconfirm
              title={<>Delete {row.fullName} ?</>}
              onConfirm={() => deleteMultipleDictionaryCannedTexts(row.id)}
              okText='Yes'
              cancelText='No'
              overlayClassName='popover-delete-confirm'
            >
              <PopoverGlobalStyle />

              <Tooltip title='Delete Key to Phrase' placement='bottom'>
                <ActionButton icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </Col>
        </Row>
      );
    }
  }
];
