import React from 'react';
import { Row, Col } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import CustomAvatar from '../../CustomComponents/CustomAvatar';
import { ActionButton } from '../../CustomComponents/Table/styledComponents';

export const columns = inviteDoctor => [
  {
    title: ' ',
    dataIndex: ['signedUrl'],
    width: '35px',
    render: signedUrl => {
      return <CustomAvatar url={signedUrl} size={35} />;
    }
  },
  {
    title: 'Name',
    dataIndex: ['fullName'],
    key: 'id'
    // sorter: true
  },
  {
    title: 'Email',
    dataIndex: ['email'],

    key: 'email'
  },
  {
    title: 'Contact',
    dataIndex: ['phoneNumber'],
    key: 'phoneNumber',
    render: value => {
      return value.countryCode === null || value.number === null
        ? ''
        : value && <div>+{value.countryCode + '-' + value.number}</div>;
    }
  },
  {
    title: 'Action',
    width: '150px',
    render: row => {
      return (
        row && (
          <Row gutter={12} key={row.invited}>
            <Col>
              <ActionButton
                icon={<MailOutlined />}
                invite={true}
                key={row.invited}
                disabled={row.invited || row.email === null}
                type='primary'
                onClick={() => {
                  inviteDoctor(row);
                }}
              >
                {row.invited ? 'Invited' : 'Invite'}
              </ActionButton>
            </Col>
          </Row>
        )
      );
    }
  }
];
