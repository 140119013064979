import React from "react";

function UndoIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" fill="#525e6f">
     <path d="M14.1 19H7v-2h7.1q1.575 0 2.737-1Q18 15 18 13.5T16.837 11q-1.162-1-2.737-1H7.8l2.6 2.6L9 14 4 9l5-5 1.4 1.4L7.8 8h6.3q2.425 0 4.163 1.575Q20 11.15 20 13.5q0 2.35-1.737 3.925Q16.525 19 14.1 19Z"/>
    </svg>
  );
}

export default UndoIcon;
