import axiosInstance from '../Config/AxiosInstance';

const PREFIX = `/clinic/biller`;

export const listAll = (name, pageSize, pageNo, sortFitler, sortOrder) => {
  let url =
    PREFIX +
    `/list?name=${name}&sortKey=${sortFitler}&page=${pageNo}&size=${pageSize}&sortOrder=${sortOrder}`;

  return axiosInstance.get(url);
};
export const AddBiller = data => {
  return axiosInstance.post(PREFIX, data);
};
export const AddBillerinUser = page => {
  return axiosInstance.get(PREFIX + `?page=${page}&size=100 `);
};

export const billiersJobList = (
  name,
  pageSize,
  pageNo,
  sortFitler,
  sortOrder
) => {
  let url =
    PREFIX +
    `/jobs/list?name=${name}&sortKey=${sortFitler}&page=${pageNo}&size=${pageSize}&sortOrder=${sortOrder}`;

  return axiosInstance.get(url);
};
export const fetchJobsForBillersAudit = payload => {
  return axiosInstance.post(PREFIX + `/jobs/audit`, payload);
};
export const fetchDoctors = () => {
  return axiosInstance.get(PREFIX + `/doctors`);
};
