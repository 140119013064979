import styled from 'styled-components';
import { Row, Button, Input } from 'antd';
import {
  CheckOutlined,
  SearchOutlined,
  CloseOutlined,
  EditOutlined,
  SyncOutlined
} from '@ant-design/icons';
import ConfirmationIcon from '../../../Assets/Icons/ConfirmationIcon';

const { TextArea } = Input;

export const HeadingRow = styled(Row)`
  margin: 0 !important;
  padding: 5px 0 10px 0;
  & .ant-col {
    padding: 0 10px !important;
  }
`;

export const ContentRow = styled(Row)`
  margin: 0 !important;
  padding: 0;
  max-height: calc(100vh - 243px);
  & .ant-col {
    padding: 0 10px !important;
  }
`;

export const ActionButton = styled(Button)`
  height: 40px;
  width: 100%;
  font-weight: 900;
  font-size: 17px;

  &.ant-btn {
    background: ${props => props.reject && '#A0ABB3'};
    color: white;
    &:hover,
    &:active,
    &:focus {
      background: ${props => props.reject && '#A0ABB385'};
      color: white;
      border: none;
    }
  }

  @media (max-width: 1280px) {
    &.ant-btn {
      font-weight: 800;
      font-size: 14px;
    }
  }

  @media (max-width: 1090px) {
    &.ant-btn {
      font-weight: 800;
      font-size: 14px;
      padding: 4px 6px;
    }
  }
`;

export const DemographicsContainer = styled.div`
  background: white;
  border-radius: 5px;
  border: 1px solid #cdcdcd;
`;

export const DemographicsTitleDiv = styled.div`
  height: 50px;
  background: ${props => props.background};
  color: white;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  padding: 10px 15px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  @media (max-width: 1280px) {
    font-size: 12px;
    line-height: 12px;
  }
`;

export const DemographicsIconDiv = styled.div``;

export const IconButton = styled(Button)`
  ${props =>
    !props.border &&
    `
  &.ant-btn,
  &.ant-btn:hover {
    border: none;
  }
  `}

  &.ant-btn:hover {
    background-color: rgba(60, 64, 67, 0.08);
  }
`;

export const DemographicsContentDiv = styled.div`
  border-radius: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const SourceDiv = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #a2a3a5;
  border-bottom: 1px solid #d3d3d3;
  padding: ${props => (props.inference ? '8px 0px' : '15px 0px')};
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1280px) {
    font-size: 12px;
    line-height: 12px;
  }
`;

export const ItemsContainer = styled.div`
  min-height: calc(100vh - 343px);
  max-height: calc(100vh - 343px);
  overflow-y: auto;

  ${props =>
    props.loading &&
    `
  display:flex;
  justify-content:center;
  align-items:center;
  `}

  ${props =>
    props.emptySection &&
    `
  display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

export const DemographicsItem = styled.div`
  padding: 10px 15px;
  ${props =>
    props.selected &&
    `
  background: #f1f3f4 !important;
  border-left: 5px solid ${props.headingTextColor};
  `}
  cursor: ${props => props.inference && props.annotationAvailable && 'pointer'};
  transition: all 0.1s ease-in;
  &:hover {
    background: ${props =>
      props.inference && props.annotationAvailable && '#f1f3f477'};
  }
`;

export const ItemTitle = styled.p`
  color: ${props => props.textColor};
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  @media (max-width: 1280px) {
    font-size: 14px;
    line-height: 14px;
  }
`;

export const ItemTitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const EditTextButton = styled(Button)`
  &.ant-btn {
    font-size: 12px;
    color: ${props => props.textColor};
    padding: 0;
    height: 24px;
  }
  &:hover > span {
    text-decoration: underline;
  }
`;

export const ItemValue = styled.p`
  color: ${props => props.textColor};
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  margin-top: 10px;
  margin-bottom: 0;
  @media (max-width: 1280px) {
    font-size: 14px;
    line-height: 14px;
  }
`;

export const ItemValueDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledCheckIcon = styled(CheckOutlined)`
  color: #4b9460 !important;
`;

export const StyledSearchIcon = styled(SearchOutlined)`
  color: #fff !important;
`;

export const StyledCloseIcon = styled(CloseOutlined)`
  color: #fff !important;
`;

export const StyledEditIcon = styled(EditOutlined)`
  color: #fff !important;
`;

export const StyledReloadIcon = styled(SyncOutlined)``;

export const StyledTextArea = styled(TextArea)`
  border-radius: 5px !important;
`;

export const StyledConfirmationIcon = styled(ConfirmationIcon)`
  margin-right: 5px;
`;

export const ConfirmationIconWrapper = styled.div`
  margin-right: 5px;
  display: flex;
  align-items: center;
`;
