import axiosInstance from '../Config/AxiosInstance';

const PREFIX = `/clinic`;

export const GetCount = userType => {
  return axiosInstance.get(PREFIX + `/${userType}/dashboard`);
};

export const GetJobDashboardCount = userType => {
  return axiosInstance.get(PREFIX + `/${userType}/job-count`);
};
