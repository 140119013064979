import styled from 'styled-components';
import { Drawer } from 'antd';

export const StyledDrawer = styled(Drawer)`
  & .ant-drawer-header {
    height: 70px;
    display: flex;
  }

  & .ant-drawer-title {
    margin: auto;
    color: ${props => props.textColor};
    font-weight: 600;
    font-size: ${props => props.filter?'16px':'26px'};
    line-height: 30px;

  }
  & .ant-drawer-body {
    padding: 40px;
  }

  & textarea {
    min-height: 250px;
  }
`;
