import React from 'react';
import PageTitle from '../../../Utils/PageTitle';
import { TitleRow, ContainerRow } from '../styledComponents';
import CustomTypography from '../../CustomComponents/CustomTypography';
import Spinner from '../../CustomComponents/Spinner';
import { useSelector } from 'react-redux';
import { notification } from 'antd';
import { StyledText, FormWrapper } from './styledComponents';
import { authenticateEmrUser } from '../../../Apis/Auth';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect } from 'react';

const VeriyfEmr = () => {
  const [theme] = useSelector(state => [state.themeReducer]);
  const { clinicId, userId } = useParams();
  const history = useHistory();
  function replaceHostname(originalURL, newHostname) {
    try {
      const url = new URL(originalURL);

      url.hostname = newHostname;

      return url.href;
    } catch (error) {
      console.error('Invalid URL:', originalURL);

      return originalURL;
    }
  }

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const code = query.get('code');
    const state = query.get('state');
    if (window.location.hostname === 'stage.cascade.md' && state === 'DEV') {
      const redirecturi = window.location.href;

      const newURL = replaceHostname(redirecturi, 'dev.cascade.md');
      console.log('redirect uri', newURL);
      window.location.href = newURL;
    } else {
      authenticateEmrUser(clinicId, userId, code, state)
        .then(response => {
          if (response.data.success) {
            history.push('/dashboard');
            notification['success']({
              message: 'Success',
              description: response.data.message
            });
          } else {
            history.push('/dashboard');
            notification['error']({
              message: 'Failed',
              description: response.data.message
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageTitle title='VerifyEmr' />
      <ContainerRow gutter={[0, 40]}>
        <TitleRow span={24}>
          <CustomTypography color={theme['@text-color']}>
            Verify EMR
          </CustomTypography>
        </TitleRow>
        <FormWrapper>
          <Spinner height={'0px'} />
          <StyledText>
            Wait for the authentication to get completed. Do not navigate to
            another page.
          </StyledText>
        </FormWrapper>
      </ContainerRow>
    </>
  );
};

export default VeriyfEmr;
