import styled from 'styled-components';
import { Menu, Row, Col, Button, Select} from 'antd';


export const StatusDiv = styled.div`
  background-color: ${props =>
    props.status?.toUpperCase() === 'SUCCESS'
      ? '#9CF2C0'
      : props.status?.toUpperCase() === 'FAILED'
      ? '#FFB7B4'
      : props.status?.toUpperCase() === 'PROCESSING'
      ? '#ffd589'
      // : props.status?.toUpperCase() === 'INPROGRESS'
      // ? '#ffd589'
      // : props.status?.toUpperCase() === 'AVAILABLE'
      // ? '#9CF2C0'
      // : props.status?.toUpperCase() === 'INCOMPLETE'
      // ? '#afafaf'
      // : props.status?.toUpperCase() === 'COMPLETED'
      // ? '#76a4e7'
      : ''};
  border-radius: 40px;
  text-align: center;
  padding: 5px;
  color: ${props =>
    props.status?.toUpperCase() === 'SUCCESS'
      ? '#14A951'
      : props.status?.toUpperCase() === 'FAILED'
      ? '#F2554E'
      : props.status?.toUpperCase() === 'PROCESSING'
      ? '#c47f00'
      : props.status?.toUpperCase() === 'INPROGRESS'
      ? '#c47f00'
      // : props.status?.toUpperCase() === 'AVAILABLE'
      // ? '#14A951'
      // : props.status?.toUpperCase() === 'INCOMPLETE'
      // ? '#000000'
      // : props.status?.toUpperCase() === 'COMPLETED'
      // ? '#000000'
      : ''};
  width: ${props => (props.audit ? '140px' : '9vw')};
  text-transform: capitalize;
  @media (max-width: 1500px) {
    width: 14vw;
  }

  @media (max-width: 1366px) {
    width: ${props => (props.audit ? '140px' : '16vw')};
  }

  @media (max-width: 994px) {
    width: ${props => (props.audit ? '140px' : '18vw')};
  }
`;

export const InnerButtonDiv = styled.div`
  display: flex;
`;

export const IconDiv = styled.div`
  margin-left: 5px;
`;

export const StatusMenuItem = styled(Menu.Item)`
  background: ${props => (props.background ? '#ececec' : 'white')};
`;

export const Container = styled.div`
  border: 1px solid #d5d5d5;
  margin: 0;
  padding: 8px;
  position: relative;
  border-radius: 8px;
  page-break-after: avoid;

  @media print {
    html,
    body {
      height: 99%;
      page-break-after: avoid;
      page-break-before: avoid;
    }
    border: none;
    margin: 0;
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;

  @media print {
    height: 20px;
  }
`;

export const LogoContainer = styled.div`
  width: 40%;
  margin: 0 auto;
  @media print {
    height: 100px;
  }
`;

export const NameDiv = styled.div`
  font-size: 18px;
  text-transform: capitalize;
  margin-left: 10px;
  & span {
    font-weight: 600;
  }
`;

export const DateDiv = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-right: 10px;
`;

export const TableContainer = styled.div`
  max-height: 250px;
  overflow-y: auto;
  @media print {
    max-height: none;
    height: auto;
  }
`;

export const StyledRow = styled(Row)`
  background: ${props => (props.transcription ? '#f5f5f5' : '#d5d5d5')};
  padding: 10px;
  margin-top: ${props => (props.transcription ? '0' : '20px')};
`;

export const StyledCol = styled(Col)`
  color: #000;
  font-size: ${props => (props.transcription ? '14px' : '16px')};
  font-weight: ${props => (props.transcription ? 300 : 500)};
  text-transform: uppercase;
  text-align: ${props => (props.first ? 'left' : 'center')};
`;

export const PrintButtonDiv = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;

  @media print {
    display: none;
  }
`;

export const PrintButton = styled(Button)``;

export const StyledSelect = styled(Select)`
  border-radius: 5px;
  margin-right: 20px;
  width: 140px;
  padding-left: 10px;
`;
