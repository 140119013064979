import React from 'react';
import { StyledText } from './styledComponents';

const CustomTypography = props => {
  return (
    <StyledText color={props.color} hl7={props.hl7}>
      {props.children}
    </StyledText>
  );
};
export default CustomTypography;
