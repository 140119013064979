import { notification, Popover, Select } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getClinicAdmins, getDashboardData } from '../../../Apis/SuperAdmin';
import { getUserNotificationDashboard } from '../../../Apis/User';
import PageTitle from '../../../Utils/PageTitle';
import Spinner from '../../CustomComponents/Spinner';
import DashboardCard from './DashboardCard';
import { getSuperAdminDashboardConfig } from './DashboardConfig';
import FacilityStatusCard from './FacilityStatusCard';
import {
  StyledDashboardCardContainer,
  StyledDashboardClinicSelect,
  StyledDashboardClinicSelectContainer,
  StyledDashboardClinicSelectContent,
  StyledDashboardClinicSelectHeading,
  StyledDashboardHeading,
  StyledDashboardHeadingContainer,
  StyledFacilitiesStatusCardContainer,
  StyledFacilityCardContainer
} from './styledComponent';

const NewDashboard = () => {
  // const [theme] = useSelector(state => [state.themeReducer]);
  const user = useSelector(state => state.userReducer);
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState([]);
  const [clinicFacilitiesStatus, setClinicFacilitiesStatus] = useState([]);
  const [clinicAdmins, setClinicAdmins] = useState([]);
  const [facilitiesOfCurrentUser, setfacilitiesOfCurrentUser] = useState([]);
  const [selectedClinicAdminId, setSelectedClinicAdminId] = useState(user.id);

  useEffect(() => {
    getClinicAdminsData();
    getUserFacilityStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setClinicFacilitiesStatus([]);
    getClinicData(selectedClinicAdminId);
  }, [selectedClinicAdminId]);

  const getClinicAdminsData = () => {
    setIsLoading(true);
    getClinicAdmins()
      .then(response => {
        if (response.data.success) {
          setClinicAdmins(response.data.result);
          setIsLoading(false);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
          setIsLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getClinicData = clinicAdminsUserId => {
    setIsLoading(true);
    getDashboardData(clinicAdminsUserId)
      .then(response => {
        if (response.data.success) {
          setDashboardData(getSuperAdminDashboardConfig(response.data.result));
          setClinicFacilitiesStatus(response.data.result.facilitiesInfo);
          setIsLoading(false);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
          setIsLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getUserFacilityStatus = () => {
    setIsLoading(true);
    getUserNotificationDashboard(user.id)
      .then(response => {
        if (response.data.success) {
          setfacilitiesOfCurrentUser(response.data.result);
          setIsLoading(false);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
          setIsLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleClinicAdminSelectFilter = (input, option) => {
    return (
      option['data-name']?.toLowerCase().includes(input.toLowerCase()) ||
      option['data-email']?.toLowerCase().includes(input.toLowerCase())
    );
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <PageTitle title='Dashboard' />
      <StyledDashboardHeadingContainer>
        <StyledDashboardHeading>Hello {user?.firstName}</StyledDashboardHeading>
        <StyledDashboardClinicSelectContainer>
          <StyledDashboardClinicSelect
            showSearch
            value={selectedClinicAdminId}
            onChange={value => {
              setSelectedClinicAdminId(value);
            }}
            filterOption={handleClinicAdminSelectFilter}
          >
            {clinicAdmins?.map(admin => {
              return (
                <Select.Option
                  value={admin.id}
                  key={admin.id}
                  data-name={admin.fullName}
                  data-email={admin.email}
                >
                  <Popover
                    title={`${admin.fullName} <${admin.email}>`}
                    placement='left'
                  >
                    <StyledDashboardClinicSelectHeading className='select-heading'>
                      {admin.fullName}
                    </StyledDashboardClinicSelectHeading>
                    <StyledDashboardClinicSelectContent className='select-content'>
                      {admin.email}
                    </StyledDashboardClinicSelectContent>
                  </Popover>
                </Select.Option>
              );
            })}
          </StyledDashboardClinicSelect>
        </StyledDashboardClinicSelectContainer>
      </StyledDashboardHeadingContainer>
      <StyledDashboardCardContainer>
        {dashboardData.map(item => {
          return <DashboardCard item={item} key={item.heading} />;
        })}
      </StyledDashboardCardContainer>
      <StyledDashboardHeadingContainer>
        {(user.id === selectedClinicAdminId &&
          facilitiesOfCurrentUser.filter(
            item => item?.facility?.disabled === false
          ).length > 0) ||
        clinicFacilitiesStatus.length > 0 ? (
          <StyledDashboardHeading>Facility</StyledDashboardHeading>
        ) : null}
      </StyledDashboardHeadingContainer>
      {user.id === selectedClinicAdminId ? (
        <StyledFacilityCardContainer>
          {facilitiesOfCurrentUser
            .filter(item => item?.facility?.disabled === false)
            .map(item => {
              return (
                <FacilityStatusCard
                  clinicFacilityStatus={{
                    facilityId: item.facility.facilityId,
                    facilityName: item.facility.facilityName,
                    facilitySubcriptionExpiredOn:
                      item.facility.facilityExpireDate,
                    emrHandlingData: item.emrHandlingData
                  }}
                  key={item.facility.facilityName}
                />
              );
            })}
        </StyledFacilityCardContainer>
      ) : (
        <StyledFacilitiesStatusCardContainer>
          {clinicFacilitiesStatus?.map(clinicFacilityStatus => {
            return (
              <FacilityStatusCard
                clinicFacilityStatus={clinicFacilityStatus}
                key={clinicFacilityStatus?.facilityName}
              />
            );
          })}
        </StyledFacilitiesStatusCardContainer>
      )}
    </>
  );
};

export default NewDashboard;
