import styled from 'styled-components';
import { Input, Select } from 'antd';

export const StyledInput = styled(Input)`
  &.ant-input,
  &.ant-input:focus,
  &.ant-input:active {
    background: transparent;
    border: none !important;
    border-bottom: 2px solid #d0d0d0 !important;
    color: ${props => props.primaryTextColor};
    box-shadow: none !important;
    ::placeholder {
      color: ${props => props.secondaryTextColor};
    }
    width: ${props => props.inference && 'calc(100% - 70px)'};
  }

  .ant-form-item-has-error .ant-input:focus,
  .ant-form-item-has-error {
    border-bottom: 2px solid #ff7875 !important;
  }
`;

export const StyledSelect = styled(Select)`
  &.ant-select,
  &.ant-select:focus,
  &.ant-select:active {
    background: transparent;
    border: none !important;
    border-bottom: 2px solid #d0d0d0 !important;
    color: ${props => props.primaryTextColor};
    box-shadow: none !important;
    ::placeholder {
      color: ${props => props.disabled && props.secondaryTextColor};
    }

    & label {
      color: red;
    }

    .ant-select-selector {
      background: transparent !important;
      border: none !important;
      box-shadow: none !important;
    }
  }

  .ant-form-item-has-error .ant-input:focus,
  .ant-form-item-has-error {
    border-bottom: 2px solid #ff7875 !important;
  }
`;
