import styled from "styled-components";

export const CardContainer = styled.div`
  width: 100%;
  border-radius: 12px;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-shadow: 0px 0px 8px 5px rgba(218, 218, 218, 0.25);
  transition: all 0.25s ease-in;
  
  &:hover {
    transform: scale(1.05);
  }
`;

export const CardTitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardFooterDiv = styled.div``;

export const CardFooterText = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  color: #303030;
  margin-bottom: 0;

  @media (max-width: 568px) {
    font-size: 26px;
  }
`;

export const CardTitleText = styled.h3`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${(props) => props.textColor};

  @media (max-width: 568px) {
    font-size: 16px;
    font-weight: 600;
  }
`;

export const CardIconDiv = styled.div`
  width: 60px;
  height: 60px;
  background: ${(props) => props.background};
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 568px) {
    width: 40px;
    height: 40px;
    & svg {
      width: 25px;
      height: 25px;
    }
  }
`;
