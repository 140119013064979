import React from 'react';
import { GridContainer } from './styledComponents';
import { Col, Row } from 'antd';
import GridItem from './GridItem';
import { StyledPagination } from './styledComponents';
import Spinner from '../../CustomComponents/Spinner';
import {
  LeftOutlined,
  RightOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined
} from '@ant-design/icons';
import {  Button, Tooltip } from 'antd';
import { RESULTS_PER_PAGE } from '../../../Constants/StringConstants';

const DocumentGridView = ({
  fileList,
  pageSize,
  total,
  getComments,
  setCommentsDrawerVisible,
  setShowDetails,
  setSelectedFile,
  currentPage,
  setCurrentPage,
  setFileExportList,
  setListSelection,
  selected,
  setSelected,
  loading
}) => {
  const itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return (
        <>
          <Tooltip title='First Page'>
            <Button
              type='text'
              icon={<DoubleLeftOutlined />}
              onClick={e => {
                e.stopPropagation();
                setCurrentPage(0);
              }}
            />
          </Tooltip>
          <Tooltip title='Previous Page'>
            <Button type='text' icon={<LeftOutlined />} />
          </Tooltip>
        </>
      );
    }
    if (type === 'next') {
      return (
        <>
          <Tooltip title='Next Page'>
            <Button type='text' icon={<RightOutlined />} />
          </Tooltip>
          <Tooltip title='Last Page'>
            <Button
              type='text'
              icon={<DoubleRightOutlined />}
              onClick={e => {
                e.stopPropagation();
                setCurrentPage(Math.floor(total / RESULTS_PER_PAGE));
              }}
            />
          </Tooltip>
        </>
      );
    }
    return originalElement;
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <GridContainer>
            <Col span={24}>
              <Row gutter={[36, 36]}>
                {fileList.map(file => {
                  return (
                    <Col xxl={4} xl={6} lg={6} md={8} sm={24} xs={24}>
                      <GridItem
                        file={file}
                        getComments={getComments}
                        setCommentsDrawerVisible={setCommentsDrawerVisible}
                        setShowDetails={setShowDetails}
                        setSelectedFile={setSelectedFile}
                        selected={selected}
                        setSelected={setSelected}
                        setFileExportList={setFileExportList}
                        setListSelection={setListSelection}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </GridContainer>
        </>
      )}
      <StyledPagination
        showSizeChanger={false}
        pageSize={pageSize}
        current={currentPage + 1}
        onChange={(page) => {
          setCurrentPage(page - 1);
        }}
        total={total}
        itemRender={itemRender}
      />
    </>
  );
};

export default DocumentGridView;
