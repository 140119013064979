import React from "react";

function CancelIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width ? props?.width : "17"}
      height={props?.height ? props?.height : "17"}
      fill="none"
      viewBox="0 0 17 17"
    >
      <path stroke="#fff" d="M.5.5l12 12m-12 0l12-12"></path>
    </svg>
  );
}

export default CancelIcon;
