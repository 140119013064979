import React from 'react';

function DictionaryIcon({ size, color }) {
  return (
    <span className='anticon'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 384 383.96'
        width={size ? size : '18'}
        height={size ? size : '18'}
      >
        <g data-name='Layer 2'>
          <g data-name='Layer 1'>
            <path
              fill={color ? color : '#666'}
              d='M2.66699 12.9997C2.66699 12.5576 2.84259 12.1337 3.15515 11.8212C3.46771 11.5086 3.89163 11.333 4.33366 11.333H13.3337'
            ></path>
            <path
              fill={color ? color : '#666'}
              d='M2.66699 12.9997C2.66699 12.5576 2.84259 12.1337 3.15515 11.8212C3.46771 11.5086 3.89163 11.333 4.33366 11.333H13.3337'
            ></path>
          </g>
        </g>
      </svg>
    </span>
  );
}

export default DictionaryIcon;
