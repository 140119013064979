import React from "react";

function HeadingIcon({fill}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 16 14"
    >
      <path
        fill={fill ? fill :"#8D8989"}
        d="M3.938.875h-3.5a.438.438 0 010-.875h3.5a.438.438 0 010 .875zM3.938 14h-3.5a.438.438 0 010-.875h3.5a.438.438 0 010 .875zM10.938 14h-3.5a.438.438 0 010-.875h3.5a.438.438 0 010 .875zM10.938.875h-3.5a.438.438 0 010-.875h3.5a.438.438 0 010 .875zM3.063 6.563h5.25v.875h-5.25v-.875z"
      ></path>
      <path
        fill={fill ? fill :"#8D8989"}
        d="M3 14H1V0h2v14zM10 14H8V0h2v14zM16 14h-1.402V7.6h-.41L12.99 8.966 12 7.834 13.607 6H16v8z"
      ></path>
    </svg>
  );
}

export default HeadingIcon;
