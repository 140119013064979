import React from 'react';

function ConfirmationIcon({height,width}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 66 66"
    >
      <path
        fill="#44C4A1"
        d="M33 66c18.225 0 33-14.775 33-33S51.225 0 33 0 0 14.775 0 33s14.775 33 33 33z"
      ></path>
      <path
        fill="#EBF0F3"
        d="M50.388 14.523l-23.329 23.33-11.447-11.448L9.8 32.217l17.26 17.26L56.2 20.334l-5.812-5.812z"
      ></path>
    </svg>
  );
}

export default ConfirmationIcon;
