import { Button, Input } from 'antd';
import styled from 'styled-components';

export const DropdownWrapper = styled.div`
  height: 361.55px;
  width: 224.93px;
  padding: 15px 10px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 2px 2px 2px lightgray, 2px 2px 2px lightgray,
    2px 2px 2px lightgray, 2px 2px 2px lightgray;
`;

export const StyledSearch = styled(Input)``;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between !important;
  padding: 0 15px;
  position: absolute;
  bottom: 14px;
  width: 100%;
`;

export const FacilitiesContainer = styled.div`
  margin: 10px 0;
  height: 210px;
  overflow-y: scroll;
`;

export const FacilityItem = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  height: fit-content;
  width: 100%;
  padding: 0 15px;
  background-color: ${({ selected }) => (selected ? '#47A4EA29' : '')};
`;

export const FacilityName = styled.span`
  opacity: ${({ disabled }) => (disabled ? .5 : 1)};
  padding-left: 5px;
  font-size: 14px !important ;
  word-break: break-all;
`;

export const HeadingText = styled.h1`
  font-size: 11px;
`;

export const StyledButton = styled(Button)`
  background-color: ${props =>
    props.apply ? '#47A4EA' : '#C0C0C0'} !important;
  border-radius: 5px !important;
  color: white !important;
  font-size: 14px !important;
  width: ${props => (props.apply ? '60%' : '40%')} !important;
  margin-left: ${props => (props.apply ? '10px' : '0')} !important;
  margin-right: ${props => (props.apply ? '10px' : '0')} !important;
  height: 35px;
`;

export const StyledFilterButton = styled(Button)`
  margin:0 10px 0 5px;
  width: 120px;
   &.ant-dropdown-trigger {
    background: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 6px;
  }
  align-items: center;
  height: 33px;

   @media (max-width: 925px) {
     display: flex;
    align-self: end;
    margin:0 ;
  }
 
`;

export const FilterButtonTittle = styled.div`
  margin-right: 10px;
  color: #666666;
  font-weight: 500;
  font-family: Krub;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const FilterCountValue = styled.div`
  position:absolute;
  top: -10px;
  right: -4px;
  background-color: #47a4ea;
  color:white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 12px;
  font-family: Krub;
  margin-right:3px;
`;

export const FilterButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:space-between;
`;

