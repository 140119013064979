import { Descriptions, notification, Popover, Skeleton} from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { getFacilityDetails } from '../../../Apis/Facility';
import HeartIcon from '../../../Assets/Icons/HeartIcon';
import OpenNewArrowIcon from '../../../Assets/Icons/OpenNewArrowIcon';
import { PLAN_EXP_DATE_FORMAT } from '../../../Constants/StringConstants';
import {
    FacilityDetailsButton,
    FacilityDetailsModal,
    PccSigninButton,
    StyledAlert,
    StyledFacilityStatusCard,
    StyledFacilityStatusCardContent,
    StyledFacilityStatusCardHeader,
    StyledFacilityStatusCardHeaderFacilityIcon,
    StyledFacilityStatusCardHeaderFacilityName,
    StyledFacilityStatusCardHeaderItem,
} from './styledComponent';




const FacilityStatusCard = ({
    clinicFacilityStatus,
}) => {
    const [isFacilityDetailsModalOpen, SetIsFacilityDetailsModalOpen] = useState(false);
    const [facilityDetailsModalData, setFacilityDetailsModalData] = useState(null);

    const facilityDetails = (facilityId) => {
        setFacilityDetailsModalData(null);
        SetIsFacilityDetailsModalOpen(true);
        getFacilityDetails(facilityId)
            .then(response => {
                if (response.data.success) {
                    console.log("facility ", response.data.result);
                    setFacilityDetailsModalData(response.data.result[0]);
                } else {
                    notification['error']({
                        message: 'Failed',
                        description: response.data.message
                    });
                }

            })
            .catch(err => {
                console.log(err);
            });

    };


    return (
        <>
            <StyledFacilityStatusCard>
                <StyledFacilityStatusCardHeader>

                    <StyledFacilityStatusCardHeaderItem>
                        <StyledFacilityStatusCardHeaderFacilityIcon>
                            <HeartIcon />
                        </StyledFacilityStatusCardHeaderFacilityIcon>
                        <Popover title={clinicFacilityStatus.facilityName} placement='top'>
                            <StyledFacilityStatusCardHeaderFacilityName>
                                {clinicFacilityStatus.facilityName}
                            </StyledFacilityStatusCardHeaderFacilityName>
                        </Popover>
                    </StyledFacilityStatusCardHeaderItem>
                    <StyledFacilityStatusCardHeaderItem>
                        <StyledFacilityStatusCardHeaderFacilityIcon >
                            <FacilityDetailsButton onClick={() => facilityDetails(clinicFacilityStatus.facilityId)}>
                                <OpenNewArrowIcon />
                            </FacilityDetailsButton>
                        </StyledFacilityStatusCardHeaderFacilityIcon>
                    </StyledFacilityStatusCardHeaderItem>

                </StyledFacilityStatusCardHeader>
                <StyledFacilityStatusCardContent>
                    {
                        clinicFacilityStatus?.emrHandlingData?.emrVerificationStatus === "NOT_VERIFIED" ?
                            <StyledAlert
                                description={`Please verify EMR to continue using the app.`}
                                type="error"
                                showIcon
                                action={
                                    <a href={clinicFacilityStatus.emrHandlingData.emrVerificationUrl} target={'_self'}>
                                        <PccSigninButton />
                                    </a>
                                }
                            />
                            :
                            null
                    }
                    {
                        moment(clinicFacilityStatus?.facilitySubcriptionExpiredOn).isBefore(new Date()) ?
                            <StyledAlert
                                description={`Plan has expired on ${moment(clinicFacilityStatus?.facilitySubcriptionExpiredOn).format(PLAN_EXP_DATE_FORMAT)}`}
                                type="error"
                                showIcon
                            />
                            :
                            <StyledAlert
                                description={`Plan will be expired on ${moment(clinicFacilityStatus?.facilitySubcriptionExpiredOn).format(PLAN_EXP_DATE_FORMAT)}`}
                                type="warning"
                                showIcon
                            />
                    }

                </StyledFacilityStatusCardContent>
            </StyledFacilityStatusCard>
            <FacilityDetailsModal
                title={'Facility Details'}
                open={isFacilityDetailsModalOpen}
                closable={true}
                footer={null}
                onCancel={() => { SetIsFacilityDetailsModalOpen(false) }}
                width={'80%'}
            >
                <div>
                    {
                        <div>
                            <Descriptions
                                bordered
                            >
                                <Descriptions.Item label="Name" span={2}>
                                    {
                                        facilityDetailsModalData ? facilityDetailsModalData?.facilityName : <Skeleton active title={false} paragraph={{ rows: 1, width: '80px' }} />
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label="Id" >
                                    {
                                        facilityDetailsModalData ? facilityDetailsModalData?.ehrConfiguration.facilityId : <Skeleton active title={false} paragraph={{ rows: 1, width: '20px' }} />
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label="Org Id" span={3}>
                                    {
                                        facilityDetailsModalData ? facilityDetailsModalData?.ehrConfiguration.organisationId : <Skeleton active title={false} paragraph={{ rows: 1, width: '20px' }} />
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label="Note Type" span={3}>
                                    {
                                        facilityDetailsModalData ? facilityDetailsModalData?.ehrConfiguration.noteType : <Skeleton active title={false} paragraph={{ rows: 1, width: '65px' }} />
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label="Transcription Flow" span={2}>
                                    {
                                        facilityDetailsModalData ? facilityDetailsModalData?.ehrConfiguration.transcriptionFlow : <Skeleton active title={false} paragraph={{ rows: 1, width: '100px' }} />
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label="Reviewer Count" >
                                    {
                                        facilityDetailsModalData ? facilityDetailsModalData?.ehrConfiguration.reviewerCount : <Skeleton active title={false} paragraph={{ rows: 1, width: '20px' }} />
                                    }
                                </Descriptions.Item>
                            </Descriptions>
                        </div>
                    }
                </div>

            </FacilityDetailsModal>
        </>
    )
}

export default FacilityStatusCard;
