import React from "react";

function DashboardIcon() {
  return (
    <span className="anticon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <g fill="#666" clip-path="url(#clip0)">
          <path d="M7.708 6.667h-6.25A1.46 1.46 0 010 5.208v-3.75A1.46 1.46 0 011.458 0h6.25a1.46 1.46 0 011.459 1.458v3.75a1.46 1.46 0 01-1.459 1.459zM1.458 1.25a.208.208 0 00-.208.208v3.75c0 .115.093.209.208.209h6.25a.209.209 0 00.209-.209v-3.75a.208.208 0 00-.209-.208h-6.25zM7.708 20h-6.25A1.46 1.46 0 010 18.542v-8.75a1.46 1.46 0 011.458-1.459h6.25a1.46 1.46 0 011.459 1.459v8.75A1.46 1.46 0 017.708 20zM1.458 9.583a.208.208 0 00-.208.209v8.75c0 .115.093.208.208.208h6.25a.209.209 0 00.209-.208v-8.75a.209.209 0 00-.209-.209h-6.25zM18.542 20h-6.25a1.46 1.46 0 01-1.459-1.458v-3.75a1.46 1.46 0 011.459-1.459h6.25A1.46 1.46 0 0120 14.792v3.75A1.46 1.46 0 0118.542 20zm-6.25-5.417a.208.208 0 00-.209.209v3.75c0 .115.094.208.209.208h6.25a.208.208 0 00.208-.208v-3.75a.208.208 0 00-.208-.209h-6.25zM18.542 11.667h-6.25a1.46 1.46 0 01-1.459-1.459v-8.75A1.46 1.46 0 0112.292 0h6.25A1.46 1.46 0 0120 1.458v8.75a1.46 1.46 0 01-1.458 1.459zM12.292 1.25a.208.208 0 00-.209.208v8.75c0 .115.094.209.209.209h6.25a.209.209 0 00.208-.209v-8.75a.208.208 0 00-.208-.208h-6.25z"></path>
        </g>
        <defs>
          <clipPath id="clip0">
            <path fill="#fff" d="M0 0H20V20H0z"></path>
          </clipPath>
        </defs>
      </svg>
    </span>
  );
}

export default DashboardIcon;
