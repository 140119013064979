import axiosInstance from '../Config/AxiosInstance';
const PREFIX = '/clinic/facility';

export const getSubscriptionPlans = facilityId => {
  return axiosInstance.get(
    PREFIX + `/${facilityId}/payment/subscription-plans`
  );
};

export const getCustomerProfile = facilityId => {
  return axiosInstance.get(PREFIX + `/${facilityId}/payment/customer-profile`);
};

export const saveCard = (data, facilityId) => {
  return axiosInstance.post(
    PREFIX + `/${facilityId}/payment/payment-profile?fullName=${data.fullName}`,
    data.opaqueData
  );
};

export const deleteCard = (id, facilityId) => {
  return axiosInstance.delete(
    PREFIX +
      `/${facilityId}/payment/payment-profile?customerPaymentProfileId=${id}`
  );
};

export const createSubscription = (data, facilityId) => {
  return axiosInstance.post(
    PREFIX +
      `/${facilityId}/payment/subscription?paymentProfileId=${data.paymentProfileId}&planId=${data.planId}`
  );
};

export const getCurrentSubscriptionPlan = id => {
  return axiosInstance.get(
    PREFIX + `/payment/subscription?subscriptionId=${id}`
  );
};

export const cancelSubscription = (id, facilityId) => {
  return axiosInstance.delete(
    PREFIX + `/${facilityId}/payment/subscription?subscriptionId=${id}`
  );
};

export const fetchPaymentLogs = (
  facilityId,
  name,
  pageSize,
  pageNo,
  sortFitler,
  sortOrder,
  transactionStatus
) => {
  return axiosInstance.get(
    PREFIX +
      `/${facilityId}/payment/transactions?filterKey=${transactionStatus}&sortKey=${sortFitler}&page=${pageNo}&size=${pageSize}&sortOrder=${sortOrder}&transactionId=${name}    `
  );
};

export const fetchStatement = transactionId => {
  return axiosInstance.get(
    PREFIX + `/payment/transactions/${transactionId}/transcriptions`
  );
};
