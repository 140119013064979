import React from 'react';
import { Row, Breadcrumb, Col } from 'antd';
import { StyledBreadCrumb } from './styledComponents';
import { Link } from 'react-router-dom';

const BreadCrumbComponent = ({ items }) => {
  return (
    <>
      <StyledBreadCrumb>
        <Row>
          <Col>
            <Breadcrumb separator='>'>
              {items.map((item, index) => {
                if (index === items.length - 1) {
                  return <Breadcrumb.Item>{item.name}</Breadcrumb.Item>;
                } else {
                  return (
                    <Breadcrumb.Item onClick={item.onClick}>
                      <Link to={`${item.path}`}>{item.name}</Link>
                    </Breadcrumb.Item>
                  );
                }
              })}
            </Breadcrumb>
          </Col>
        </Row>
      </StyledBreadCrumb>
    </>
  );
};

export default BreadCrumbComponent;
