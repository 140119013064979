import React, { useState, useEffect } from 'react';
import {
  FormStyle,
  FormDiv,
  FormContent,
  LoginFormStyle,
  LoginButtonStyle,
  SignUpButtonRow,
  ForgotPasswordText,
  ForgotPasswordStyle,
  ForgotPasswordColumn,
  VerifyButton,
  FooterStyle,
  FooterText,
} from './styledComponents';
import { Form, Row, Col, notification } from 'antd';
import FloatLabel from '../CustomComponents/FloatingLabel';
import { useSelector } from 'react-redux';
import { useLocation, useHistory,Link } from 'react-router-dom';
import CustomTypography from '../CustomComponents/CustomTypography';
import Timer from '../../Utils/Timer';
import { signUp, verifyOtp } from '../../Apis/Auth';

const OTPVerification = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [theme] = useSelector((state) => [state.themeReducer]);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(10);
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);

  const SubmitForm = (values) => {
    const payload = {
      id: query.get('id'),
      ...values,
    };
    setLoading(true);
    verifyOtp(payload)
      .then((response) => {
        if (response.data.success === true) {
          setLoading(false);
          notification['success']({
            message: 'OTP Verified',
            description: 'Please login to continue',
          });
          history.push('/login', true);
        } else {
          notification['error']({
            message: "Couldn't verify OTP",
            description: response.data.message,
          });
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const ResendOtp = () => {
    signUp({ id: query.get('id') })
      .then((response) => {
        if (response.data.success === true) {
          notification['success']({
            message: 'OTP sent',
            description: 'Please check your email for OTP',
          });
        } else {
          notification['error']({
            message: 'Failed to resend OTP',
            description: response.data.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (query) {
      let otp = query.get('otp');
      if (otp) {
        form.setFieldsValue({ otp: otp });
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <FormDiv>
      <FormStyle
        span={16}
        xs={{ span: 14 }}
        sm={{ span: 14 }}
        md={{ span: 10 }}
        lg={{ span: 10 }}
      >
        <CustomTypography color={theme['@text-color']}>
          Verify OTP
        </CustomTypography>
        <ForgotPasswordText>
          OTP has been sent to your registered email. Please enter the secured
          code below to complete registration.
        </ForgotPasswordText>

        <FormContent>
          <Row justify="center">
            <Col span={24}>
              <LoginFormStyle
                layout="vertical"
                form={form}
                onFinish={SubmitForm}
              >
                <Row gutter={[24, 12]}>
                  <Col span={24}>
                    <Form.Item
                      name="otp"
                      rules={[{ required: true, message: 'OTP is required' }]}
                    >
                      <FloatLabel label="Enter OTP" type="input" />
                    </Form.Item>
                  </Col>
                  <SignUpButtonRow>
                    <Col span={12}>
                      <LoginButtonStyle
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                      >
                        Verify
                      </LoginButtonStyle>
                    </Col>
                    <ForgotPasswordColumn span={12}>
                      <ForgotPasswordStyle>
                        {seconds === 0 && minutes === 0 ? (
                          <VerifyButton type="link" onClick={ResendOtp}>
                            Resend
                          </VerifyButton>
                        ) : (
                          <Timer
                            color={theme['@text-color']}
                            minutes={minutes}
                            seconds={seconds}
                            setMinutes={setMinutes}
                            setSeconds={setSeconds}
                          />
                        )}
                      </ForgotPasswordStyle>
                    </ForgotPasswordColumn>
                  </SignUpButtonRow>
                </Row>
              </LoginFormStyle>
            </Col>
          </Row>
        </FormContent>
      </FormStyle>
      <FooterStyle>
        <FooterText>
          Already have an account?<Link to="/login"> Sign In</Link>
        </FooterText>
      </FooterStyle>
    </FormDiv>
  );
};

export default OTPVerification;
