import React from 'react';
import DynamicTableElement from './DynamicTableElement';
import {
  EditableCellValueDivStyle,
  EditableFormItemStyle
} from './styledComponents';
const EditableCell = ({
  editing,
  title,
  editable,
  children,
  dataIndex,
  record,
  inputType,
  possibleValues,
  mandatory,
  colIndex,
  highlightInference,
  form,
  ...restProps
}) => {
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <EditableFormItemStyle
        name={dataIndex}
        rules={[
          {
            required: mandatory,
            message: `Required field.`
          }
        ]}
      >
        <DynamicTableElement
          inputType={inputType}
          possibleValues={possibleValues}
          title={title}
          form={form}
          dataIndex = {dataIndex}
        />
      </EditableFormItemStyle>
    ) : (
      <EditableCellValueDivStyle
        className='editable-cell-value-wrap'
        onClick={() => highlightInference(record.index, colIndex)}
      >
        {children}
      </EditableCellValueDivStyle>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default EditableCell;
