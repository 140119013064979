import React, { useState } from 'react';
import CustomTable from '../../CustomComponents/Table';
import { Input } from 'antd';
const ModalContent = ({
  columns,
  theme,
  loading,
  onSearch,
  filteredData,
  total,
  key
}) => {
  const { Search } = Input;
  const [currentPage, setCurrentPage] = useState(0);

  return (
    <>
      <Search
        placeholder='Search'
        defaultValue=""
        key={key}
        allowClear
        onChange={e => {
          onSearch(e.target.value);
        }}
        style={{ width: 200 }}
      />
      <CustomTable
        invite={true}
        theme={theme}
        columns={columns}
        dataSource={filteredData}
        centered={true}
        // pageNoChangeHandler={pageNoChangeHandler}
        loading={loading}
        setPageNo={current => {
          setCurrentPage(current);
        }}
        pageNo={currentPage}
        pageSize={10}
        total={total}
        // onChange={filterTableChange}
      />
    </>
  );
};

export default ModalContent;
