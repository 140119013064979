import styled from 'styled-components';
import mask from '../../Assets/Images/mask_1.png';
import {
  Row,
  Col,
  Button,
  DatePicker,
  Input,
  Divider,
  Tag,
  Select
} from 'antd';

export const ContainerRow = styled(Row)`
  height: ${props => (props.device ? 'calc(100vh - 110px)' : null)};
  margin: 20px 20px !important;
  flex-direction: column;
`;

export const TitleRow = styled(Row)`
  padding-top: ${props => (props.facility ? '25px' : '0px')};
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  width: 100%;
`;

export const SubContainerRow = styled(Row)`
  margin: 20px 0px 0 0 !important;
  flex-direction: column;
  & tr {
    height: 66px;
  }
`;

export const SubPageTitle = styled(Row)`
  padding-top: 0 !important;
`;

export const ImportExportButton = styled(Button)`
  &.ant-btn-primary {
    border-radius: 20px;
    width: 100px;
    font-weight: 600;
  }
`;

export const ProfileComponent = styled(Row)`
  position: relative;
  padding: 25px 0;
  @media (max-width: 1280px) {
    flex-direction: column;
  }
`;

export const PicturePreview = styled.div`
  width: 250px;
  height: 250px;
  background-image: ${props => `url(${props.bgImage})`};
  background-repeat: no-repeat;
  background-position: 0;
  overflow-x: hidden;
  background-size: cover;
  border-radius: 8px;
  border: 1px solid #dfdfdf;
  overflow: hidden;
  z-index: -10;
  -webkit-mask-image: url(${mask});
  mask-image: url(${mask});
  mask-size: contain;
`;

export const DeleteIconButton = styled.div`
  font-size: 22px;
  text-align: right;
  margin-left: 252px;
  position: absolute;
  z-index: 1;
`;

export const ProfileStyle = styled.div`
  width: calc(100vw - 610px);
  padding: 0 50px 0 50px;
  position: relative;

  @media (max-width: 1280px) {
    width: 100%;
    padding: 0;
  }
`;

export const PictureComponent = styled.div`
  @media (max-width: 1280px) {
    margin: 0 auto;
    position: relative;
  }
`;
export const SelectItem = styled(Select)`
  width: 50%;
`;
export const AddButton = styled(Button)`
  margin: auto 0;
  align-items: center;
  margin-right: 15px;
  border: 1px solid #e1e1e1 !important;
`;
export const CloseButton = styled(Button)`
  width: 10px;
  border: 'none';
  border-color: white !important;
  position: relative;
  top: 0;
  right: 0;
`;
export const InviteButton = styled(Button)`
  margin: auto 0;
  align-items: center;
  margin-right: 15px;
  background: none !important;
  border: 1px solid #47a4ea !important;
  & .anticon {
    color: #47a4ea;
  }

  & span {
    color: #47a4ea;
  }

  &:hover {
    background: white !important;
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;

export const SubmitButton = styled(Button)`
  width: auto;
  &.ant-btn-primary {
    border-radius: 10px;
  }
`;

export const SubmitButtonCol = styled(Col)`
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const PhoneNumberCol = styled(Col)`
  & .react-tel-input .form-control {
    width: 100%;
    height: 40px;
  }

  & .react-tel-input .selected-flag {
  }
`;

export const SearchInputStyle = styled(Input)`
  width: 299.56px;
  height: 34px;
  margin: auto 0;
  align-items: center;
  &.ant-input-affix-wrapper {
    background: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
  }
  @media (max-width: 925px) {
    width: 100%;
  }
`;

export const ControlsDiV = styled.div`
  /* @media (max-width: 925px) {
   display:flex;
  }
  @media (max-width: 925px) {
   padding-top:10px;
  } */
  display: flex;
  align-items: center;
`;
export const DividerStyle = styled(Divider)`
  border: 1px solid #c0c0c0;
  height: ${props => (props.vertical ? ' calc(100vh - 172px);' : '')};
  &.ant-divider-horizontal {
    margin: 5px 0;
  }
  &.ant-divider-vertical {
    margin: 10px 20px;
  }
`;

export const StyledTag = styled(Tag)`
  &.ant-tag {
    height: 30px;
    flex-direction: row-reverse;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: auto;
    color: #a1a2ac;
    background: #e9e9e9;
    border: none;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 600;
    margin-right: 0;
    margin-left: 10px;
  }

  &.ant-tag > .anticon + span {
    margin-left: 0;
    margin-right: 10px;
  }
`;
