import React from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { DraggerComponent } from './styledComponents';
import UploadList from './UploadList';
import CustomTypography from '../../CustomComponents/CustomTypography';
import { useSelector } from 'react-redux';
import { Input } from 'antd';

const { TextArea } = Input;

const ImportBody = ({
  folderImport,
  uploadDocuments,
  setUploadDocuments,
  setUploadError,
  listSelection,
  setExportComment
}) => {
  const props = {
    name: 'file',
    multiple: true,
    accept: '.hl7',
    customRequest: () => {},
    directory: folderImport,
    beforeUpload: (file, fileList) => {
      console.log(file, fileList, 'file in bef');
      if (!file.remove) {
        if (fileList.length > 1) {
          const newList = fileList.map(files => {
            if (files.name.slice(files.name.length - 4) === '.hl7') {
              console.log('file in filelist');
              files.status = '';
              return files;
            } else {
              files.status = 'error';
              setUploadError(true);
              return files;
            }
          });
          setUploadDocuments([...newList]);
          return false;
        } else {
          if (file.name.slice(file.name.length - 4) === '.hl7') {
            console.log('file in ifg');
            file.status = '';
            const newList = [...uploadDocuments];
            newList.push(file);
            setUploadDocuments(newList);
          } else {
            file.status = 'error';
            uploadDocuments.push(file);
            setUploadError(true);
          }
        }
      }
      return false;
    },
    showUploadList: false
  };

  const onRemove = file => {
    let index = uploadDocuments.findIndex(item => item.uid === file.uid);
    file.remove = true;
    let documents = [...uploadDocuments];
    documents.splice(index, 1);
    setUploadDocuments([...documents]);
    let errorFileIndex = documents.findIndex(item => item.status === 'error');
    if (errorFileIndex === -1) {
      setUploadError(false);
    }
  };

  const [theme] = useSelector(state => [state.themeReducer]);

  return (
    <>
      {listSelection ? (
        <>
          <CustomTypography color={theme['@text-color']} hl7={true}>
            Comments:
          </CustomTypography>
          <TextArea
            rows={4}
            onChange={event => {
              setExportComment(event.target.value);
            }}
          />
        </>
      ) : (
        <>
          <DraggerComponent {...props}>
            <p className='ant-upload-drag-icon'>
              <InboxOutlined />
            </p>
            <p className='ant-upload-text'>
              Click or drag '<i>.hl7</i>' file(s) to this area to upload.
            </p>
          </DraggerComponent>
          <UploadList uploadDocuments={uploadDocuments} onRemove={onRemove} />
        </>
      )}
      ,
    </>
  );
};

export default ImportBody;
