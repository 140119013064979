import moment from 'moment';
import React from 'react';
import { DATE_TIME_FORMAT } from '../../../Constants/StringConstants';
import CustomAvatar from '../../CustomComponents/CustomAvatar';
import { StatusDiv } from '../Billing/PaymentLog/styledComponents';
import { AssignButton } from '../JobList/styledComponents';
import { strikeDate } from '../../../Apis/Jobs';
import { notification } from 'antd';
import ConfirmationModal from '../../CustomComponents/ConfirmationModal';
import {
  FacilityStatusDiv,
  StyledFacilityBlocks,
  StyledFacilityContainer
} from '../Audit/styledComponents';

export const columns = (tab, handleStartButtonClick) => {
  const userString = localStorage.getItem('user');
  const user = userString ? JSON.parse(userString) : null;
  switch (tab) {
    case 1:
      return [
        {
          title: ' ',
          dataIndex: ['profilePic'],
          width: '35px',
          render: profilePic => {
            return <CustomAvatar url={profilePic} size={35} />;
          }
        },
        {
          title: 'Name',
          dataIndex: ['fullName'],
          key: 'id',
          render: val => {
            return (
              <span title={val}>
                {val?.length > 20 ? val.slice(0, 20) + '...' : val}
              </span>
            );
          }
        },
        {
          title: 'Email',
          dataIndex: ['email'],
          key: 'email'
        },
        {
          title: 'Login',
          dataIndex: ['loginTime'],
          key: 'loginTime',
          render: loginTime => {
            return <div>{moment(loginTime).format(DATE_TIME_FORMAT)}</div>;
          },
          sorter: true
        },
        {
          title: 'IP Address',
          dataIndex: ['loggedUserIp'],
          key: 'loggedUserIp'
        },
        {
          title: 'Status',
          dataIndex: ['loggingStatus'],
          key: 'loggingStatus',
          render: loggingStatus => {
            return (
              <StatusDiv status={loggingStatus} audit={true}>
                {loggingStatus?.toLowerCase()}
              </StatusDiv>
            );
          }
        },
        {
          title: 'Logout',
          dataIndex: ['logoutTime'],
          key: 'logoutTime',
          render: (logoutTime, row) => {
            return (
              <div>
                {logoutTime === 0
                  ? row.loggingStatus === 'FAILED'
                    ? '-'
                    : 'Active Session'
                  : moment(logoutTime).format(DATE_TIME_FORMAT)}
              </div>
            );
          },
          sorter: true
        }
      ];
    case 2:
      return [
        {
          title: 'Job ID',
          dataIndex: ['jobNumber'],
          key: 'id'
        },
        // {
        //   title: 'TAT',
        //   dataIndex: ['tat'],
        //   key: 'tat',
        //   sorter: { multiple: 2 },
        //   render: val => {
        //     return <div> {Math.floor(moment.duration(val).asHours())} H</div>;
        //   }
        // },
        {
          title: 'Submitted Time',
          dataIndex: ['submissionTime'],
          key: 'submissionTime',
          sorter: { multiple: 3 },
          render: val => {
            return new Date(val).toLocaleString();
          }
        },
        {
          title: 'Audio Length',
          dataIndex: ['audioLength'],
          key: 'audioLength'
        },
        {
          title: 'Author',
          dataIndex: ['author'],
          key: 'author'
        },
        {
          title: 'Patient Name',
          dataIndex: ['patientName'],
          key: 'patientName'
        },
        // {
        //   width: '140px',
        //   title: 'TAT Status',
        //   dataIndex: ['tatStatus'],
        //   key: 'tatStatus',
        //   sorter: { multiple: 1 }
        // },
        {
          width: '100px',
          title: 'Facility',
          dataIndex: ['facility'],
          key: 'facilityId',
          render: FacilityList => {
            return (
              <div>
                {FacilityList !== null && (
                  <StyledFacilityContainer>
                    <StyledFacilityBlocks>
                      {FacilityList?.facilityName}
                    </StyledFacilityBlocks>
                  </StyledFacilityContainer>
                )}
              </div>
            );
          }
        },

        {
          width: '140px',
          title: 'Status',
          dataIndex: ['status'],
          key: 'status',
          render: (jobStatus, row) => {
            const isStrikeOut =
              (row.progressNote && row.progressNote.strikeStatus) ||
              (row.practitionerNote && row.practitionerNote.strikeStatus);

            return jobStatus ? jobStatus : '--';
            // return isStrikeOut ? 'striked' : jobStatus ? jobStatus : '--';

            // return (
            //               <StatusDiv status={jobStatus} audit={true}>
            //     {jobStatus?.toLowerCase()}
            //   </StatusDiv>
            // );
          }
        }
      ];
    case 3:
      return [
        {
          title: 'Facility Name',
          dataIndex: ['facilityName'],
          key: 'facilityName'
        },
        {
          title: 'Last Updated',
          dataIndex: ['facilityDisableStatusLastUpdated'],
          key: 'facilityDisableStatusLastUpdated',
          render: val => {
            return (
              <div>{val ? moment(val).format(DATE_TIME_FORMAT) : `--`}</div>
            );
          }
        },
        {
          title: 'Facility Status',
          dataIndex: ['disabled'],
          key: 'disabled',
          render: disabled => {
            return (
              <FacilityStatusDiv status={!disabled}>
                {disabled ? 'Disabed' : 'Enabled'}
              </FacilityStatusDiv>
            );
          }
        }
      ];

    default:
      return [];
  }
};
