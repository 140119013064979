import styled from 'styled-components';
import { Button, Row, Select } from 'antd';

export const StyledRow = styled(Row)`
  min-height: calc(100vh - 200px);
  max-height: calc(100vh - 200px);
  margin-top: 25px;
`;

export const SaveCardContainer = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 8px 5px rgba(218, 218, 218, 0.25);
  padding: ${props => (props.saved ? '40px 30px 30px 30px' : '40px 30px')};
  display: flex;
  justify-content: center;
  margin: 20px 0;
  & .ant-form {
    width: 100%;
  }

  & .ant-form-item-explain.ant-form-item-explain-error {
    padding-bottom: 15px;
    font-size: 14px;

    @media (max-width: 1500px) {
      font-size: 12px;
    }

    @media (max-width: 1410px) {
      font-size: 11px;
    }
  }
`;

export const ButtonRow = styled(Row)``;

export const StyledButton = styled(Button)`
  background: ${props => (props.cancel ? '#C0C0C0 !important' : null)};
  border-color: ${props => (props.cancel ? '#C0C0C0 !important' : null)};
  &.ant-btn {
    border-radius: 5px;
  }
`;

export const CardVendorLogo = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  & img {
    height: 40px;
  }
`;

export const SubscriptionCard = styled.div`
  border-radius: 10px;
  border: none;
  box-shadow: 0px 0px 16px 4px rgba(232, 232, 232, 1);
  background: #fff;
  padding: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 240px;
  height: 240px;
`;

export const SubscriptionDescription = styled.span`
  font-size: 16px;
  
  color: ${props => props.color};
`;

export const CardManagementContainer = styled.div`
  min-height: ${props =>
    props.subscriptionDetails ? 'calc(100vh - 390px)' : 'calc(100vh - 200px)'};
  max-height: ${props =>
    props.subscriptionDetails ? 'calc(100vh - 390px)' : 'calc(100vh - 200px)'};
  overflow-y: auto;
`;

export const AddCardDiv = styled.div`
  border-radius: 12px;
  border: 1px solid ${props => props.color};
  height: 90px;
  display: flex;

  justify-content: center;
  align-items: center;
  color: ${props => props.color};
  font-size: 18px;
  font-weight: 600;
  -webkit-transition: background-color 0.5s ease-out, color 0.5s ease-out;
  -moz-transition: background-color 0.5s ease-out, color 0.5s ease-out;
  -o-transition: background-color 0.5s ease-out, color 0.5s ease-out;
  transition: background-color 0.5s ease-out, color 0.5s ease-out;
  margin: 20px 15px;
  &:hover {
    background-color: ${props => props.color};
    color: #fff;
    cursor: pointer;
  }

  @media (max-width: 1200px) {
    font-size: 2vmin;
  }
`;

export const CardDetailsDiv = styled.div`
  border-radius: 12px;
  border: ${props => (props.selected ? 'none' : '1px solid #c0c0c0')};
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${props => (props.selected ? props.color : '#c0c0c0')};
  font-size: 18px;
  font-weight: 600;
  background: ${props => (props.selected ? '#fff' : 'transparent')};
  margin: 20px 15px;
  padding: 0 30px;
  line-height: 70px;
  transition: all 0.25s ease-in;
  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 1200px) {
    font-size: 2vmin;
  }
`;

export const CardNumber = styled.span`
  font-size: 20;
  font-weight: 600;

  @media (max-width: 1200px) {
    font-size: 2vmin;
  }
`;

export const CardIcon = styled.img`
  height: 42px;

  @media (max-width: 1200px) {
    height: 5.6vh;
    width: 5vw;
  }
`;

export const DisabledCircle = styled.div`
  height: 35px;
  width: 35px;
  border: 1px solid #c0c0c0;
  border-radius: 50%;
`;

export const PaymentDetails = styled.div`
  width: 100%;
  border-top: 1px solid #c0c0c0;
  border-bottom: 1px solid #c0c0c0;
  height: 25%;
  padding: 30px 60% 30px 10px;
  display: flex;
  justify-content: space-between;
  color: #525e6f;
`;

export const PaymentDetailItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Description = styled.span``;

export const SubscriptionType = styled.span`
  font-size: 24px;
  font-weight: 600;
`;

export const Amount = styled.span`
  font-size: 30px;
  font-weight: 900;
`;

export const Month = styled.span`
  font-size: 14px;
  font-weight: normal;
`;

export const SelectCardMessage = styled.div`
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #525e6f;
  font-weight: 500;
`;
export const StyledSelect = styled(Select)`
  border-radius: 5px;
  margin-right: 20px;
  width: 140px;
`;
