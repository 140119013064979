import React, { useState } from "react";
import {
  FormStyle,
  FormDiv,
  FormContent,
  LoginFormStyle,
  LoginButtonStyle,
  SignUpButtonRow,
  FooterStyle,
  FooterText,
  ForgotPasswordText,
  ForgotPasswordStyle,
  ForgotPasswordColumn,
} from "./styledComponents";
import { Form, Row, Col, notification } from "antd";
import FloatLabel from "../CustomComponents/FloatingLabel";
import { forgotPassword } from "../../Apis/Auth";
import { Link } from "react-router-dom";
import CustomTypography from "../CustomComponents/CustomTypography";

const ForgotPasswordForm = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const SubmitForm = (values) => {
    setLoading(true);
    forgotPassword(values.email)
      .then((response) => {
        if (response.data.success === true) {
          notification["success"]({
            message: "Link Generated!",
            description:
              "Password reset link generated, please check your inbox for details",
          });
          setLoading(false);
        } else {
          notification["error"]({
            message: "Failed",
            description: response.data.message,
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <FormDiv>
      <FormStyle
        span={16}
        xs={{ span: 14 }}
        sm={{ span: 14 }}
        md={{ span: 10 }}
        lg={{ span: 10 }}
      >
        <CustomTypography color="#525e6f">Forgot password</CustomTypography>
        <ForgotPasswordText>
          Enter the email address associated with your account.
        </ForgotPasswordText>

        <FormContent>
          <Row justify="center">
            <Col span={24}>
              <LoginFormStyle
                layout="vertical"
                form={form}
                onFinish={SubmitForm}
              >
                <Row gutter={[24, 12]}>
                  <Col span={24}>
                    <Form.Item
                      name="email"
                      rules={[
                        { required: true, message: "Email is required" },
                        {
                          type: "email",
                          message: "Please enter valid Email",
                        },
                      ]}
                    >
                      <FloatLabel
                        label="Email"
                        type="input"
                      />
                    </Form.Item>
                  </Col>
                  <SignUpButtonRow>
                    <Col span={12}>
                      <LoginButtonStyle
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                      >
                        Send
                      </LoginButtonStyle>
                    </Col>
                    <ForgotPasswordColumn span={12}>
                      <ForgotPasswordStyle>
                        <Link to="/login">Login to Cascade MD</Link>
                      </ForgotPasswordStyle>
                    </ForgotPasswordColumn>
                  </SignUpButtonRow>
                </Row>
              </LoginFormStyle>
            </Col>
          </Row>
        </FormContent>
      </FormStyle>
      <FooterStyle>
        <FooterText>
          Dont have an account?<Link to="/signup"> Sign Up</Link>
        </FooterText>
      </FooterStyle>
    </FormDiv>
  );
};

export default ForgotPasswordForm;
