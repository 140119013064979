import React from "react";

function TranscriptionistsIcon({ size, color }) {
  return (
    <span className="anticon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size ? size : "18"}
        height={size ? size : "18"}
        fill="none"
        viewBox="0 0 18 18"
      >
        <path
          fill={color ? color : "#666"}
          d="M13.728 9.564a3.366 3.366 0 001.655-2.896 3.368 3.368 0 00-3.89-3.323 3.6 3.6 0 00-2.493-1 3.6 3.6 0 00-2.493 1 3.368 3.368 0 00-3.89 3.323c0 1.231.665 2.31 1.655 2.896A5.992 5.992 0 000 15.297c0 .198.16.358.359.358H17.64c.198 0 .359-.16.359-.358a5.992 5.992 0 00-4.272-5.733zm-1.68-5.542a2.65 2.65 0 012.617 2.646 2.65 2.65 0 01-3.801 2.382 3.613 3.613 0 001.185-5.028zm-5.296.114A2.878 2.878 0 019 3.062c.619 0 1.192.196 1.663.527.22.155.416.339.585.547a2.88 2.88 0 01.647 1.82A2.898 2.898 0 018.566 8.82a2.898 2.898 0 01-2.461-2.862c0-.69.243-1.324.647-1.821zM3.335 6.668A2.65 2.65 0 015.95 4.022 3.613 3.613 0 007.136 9.05a2.65 2.65 0 01-3.802-2.382zm-2.606 8.27a5.274 5.274 0 014.478-4.849 6.443 6.443 0 00-2.642 4.85H.729zm2.554 0a5.74 5.74 0 014.7-5.279 5.737 5.737 0 013.37.416 5.74 5.74 0 013.364 4.863H3.283zm12.152 0a6.443 6.443 0 00-2.642-4.849 5.274 5.274 0 014.478 4.85h-1.836z"
        ></path>
        <path
          fill={color ? color : "#666"}
          d="M6.88 10.935a.359.359 0 00-.492-.123 5.119 5.119 0 00-2.341 3.177.359.359 0 10.697.167 4.397 4.397 0 012.012-2.729.358.358 0 00.124-.492zM9.055 10.1a.36.36 0 00-.062-.005h-.058c-.379.005-.756.05-1.12.137a.357.357 0 00-.267.432.359.359 0 00.432.266 4.372 4.372 0 011.013-.118.359.359 0 00.062-.712z"
        ></path>
      </svg>
    </span>
  );
}

export default TranscriptionistsIcon;
