import React from 'react';

function ChatIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      enableBackground='new 0 0 60.016 60.016'
      version='1.1'
      viewBox='0 0 60.016 60.016'
      xmlSpace='preserve'
      height='15px'
      width='15px'
    >
      <path d='M42.008 0h-24c-9.925 0-18 8.075-18 18v14c0 9.59 7.538 17.452 17 17.973v8.344c0 .937.764 1.699 1.703 1.699.449 0 .874-.178 1.195-.499l1.876-1.876C26.708 52.714 33.259 50 40.227 50h1.781c9.925 0 18-8.075 18-18V18c0-9.925-8.075-18-18-18zm-25 29c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.795 4-4 4zm13 0c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.795 4-4 4zm13 0c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.795 4-4 4z'></path>
    </svg>
  );
}

export default ChatIcon;
