import React from 'react';

function IncompleteJobsIcon() {
  return (
    <span className='anticon'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='30'
        height='30'
        fill='none'
        viewBox='0 0 30 30'
      >
        <g fill='#C67E0C' clipPath='url(#clip0_2434_3686)'>
          <path d='M26.645 0H9.119A1.821 1.821 0 007.3 1.819v4.07c-3.324.952-5.765 4.019-5.765 7.645 0 3.626 2.44 6.692 5.765 7.644v7.003c0 1.003.816 1.82 1.82 1.82H23.44a.64.64 0 00.453-.189l4.382-4.382a.64.64 0 00.188-.453V1.82A1.82 1.82 0 0026.644 0zM9.119 1.281h17.526c.296 0 .538.241.538.538v1.933H8.582V1.82c0-.297.24-.538.537-.538zM2.816 13.534a6.676 6.676 0 016.669-6.669 6.676 6.676 0 016.668 6.669 6.676 6.676 0 01-6.668 6.668 6.676 6.676 0 01-6.669-6.668zM8.582 28.18v-6.75a7.933 7.933 0 006.894-2.679H19.4a.64.64 0 100-1.28h-3.013a7.89 7.89 0 001.016-3.25H19.4a.64.64 0 100-1.28h-1.989a7.893 7.893 0 00-.97-3.249h2.96a.64.64 0 100-1.281h-3.843A7.935 7.935 0 008.58 5.636v-.602h18.601v19.303H24.62a1.821 1.821 0 00-1.819 1.819v2.563H9.12a.538.538 0 01-.538-.538zm15.5-.368v-1.657c0-.297.24-.538.537-.538h1.657l-2.194 2.195z'></path>
          <path d='M21.918 9.693h2.128a.64.64 0 100-1.28h-2.128a.64.64 0 100 1.28zM21.918 14.223h2.128a.64.64 0 100-1.282h-2.128a.64.64 0 100 1.282zM21.918 18.753h2.128a.64.64 0 000-1.281h-2.128a.64.64 0 100 1.281zM24.046 23.282a.64.64 0 000-1.281h-5.993a.64.64 0 100 1.281h5.993zM8.95 9.02c.183-.034.365-.025.547.027.407.104.684.35.832.74.034.13.026.4-.026.807l-.3 1.937c-.242 1.577-.394 2.392-.454 2.444-.061.026-.269.03-.624.013-.07 0-.109-.005-.117-.013-.044-.026-.083-.126-.117-.3-.026-.155-.139-.87-.338-2.144-.252-1.647-.369-2.527-.351-2.64 0-.017.008-.051.026-.103.112-.364.377-.607.793-.728a.741.741 0 01.13-.04zm.014 6.813a1.03 1.03 0 01.507.026c.381.095.645.33.793.702a.405.405 0 01.052.143c.052.2.052.394 0 .585a1.106 1.106 0 01-.728.806 1.116 1.116 0 01-.702.039 1.106 1.106 0 01-.806-.728 1.116 1.116 0 01-.039-.702c.069-.26.199-.464.39-.611.147-.121.325-.208.533-.26z'></path>
        </g>
        <defs>
          <clipPath id='clip0_2434_3686'>
            <path fill='#fff' d='M0 0H30V30H0z'></path>
          </clipPath>
        </defs>
      </svg>
    </span>
  );
}

export default IncompleteJobsIcon;
