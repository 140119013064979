import React, { useEffect, useState, useCallback } from 'react';
import PageTitle from '../../../Utils/PageTitle';

import Spinner from '../../CustomComponents/Spinner';

import { StyledSelect } from '../KeyToPhrase/styledComponents';
import { SearchInputStyle } from '../styledComponents';
import {
  TemplateWrapper,
  ItemsListContainer,
  SelectedItemsListContainer,
  ListTitle,
  SelectedHeadingValues,
  ListDiv,
  MappedValues,
  TemplateName,
  TemplateActionButton,
  DeleteConfirmationMessage,
  EmptyMessage,
  ListTitleContainer,
  InputDiv,
  TemplateNameContainer,
  TemplateButton,
  StyledTabs,
  SelectedItemsListContainerOne,
  TemplateDriven,
  InputTextOverallArea
} from './styledComponents';
import { ContainerRow } from '../styledComponents';
import { useSelector } from 'react-redux';

import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined
} from '@ant-design/icons';

import AddDictionaryTemplatesForm from './AddDictionaryTemplatesForm';
import { notification, Tooltip, Tabs, Divider, Switch } from 'antd';
import { useDebounce } from '../../../Utils/Debounce';
import { SEARCH_DELAY } from '../../../Constants/StringConstants';
import {
  dictationTemplatesDictionary,
  editDictationTemplateDictionary,
  listDictationTemplate,
  getSupportedSections,
  deleteDictationTemplate
} from '../../../Apis/Dictionary';
import BiDirectionalArrow from '../../../Assets/Icons/BiDirectionalArrow';
import ConfirmationModal from '../../CustomComponents/ConfirmationModal';
import DeleteIcon from '../../../Assets/Icons/DeleteIcon';

import { useHistory } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';
const DictionaryTemplates = () => {
  const [addDrawerVisible, setAddDrawerVisible] = useState(false);
  const [supportedSections, setSupportedSections] = useState([]);
  const [dictationTemplate, setDictationTemplate] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [editItem, setEditItem] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(0);
  const [searchString, setSearchString] = useState('');

  const onChangeHandler = value => {
    setCurrentPage(0);
    setSearchString(value);
    console.log('setSearchString');
  };

  const debounceOnChange = useCallback(
    useDebounce(onChangeHandler, SEARCH_DELAY),
    []
  );

  const submitHandler = (val, sectionHeadings) => {
    let data = {
      templateDescription: val.templateDescription,
      templateName: val.templateName,
      sectionHeadings: sectionHeadings,
      required: val.required,
      defaultValue: val.defaultValue,
      templateDrivenFlowEnabled: val.templateDrivenFlowEnabled
    };
    setSaveLoading(true);
    if (editItem) {
      data.id = editItem.id;
      data.clinicId = editItem.clinicId;
      editDictationTemplateDictionary(data).then(response => {
        if (response.data.success) {
          setAddDrawerVisible(false);
          setEditItem(null);
          fetchDictationTemplate(editItem.id);
          notification['success']({
            message: 'Success',
            description: response.data.message
          });
          setSaveLoading(false);
        } else {
          setSaveLoading(false);
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
      });
    } else {
      dictationTemplatesDictionary(data)
        .then(response => {
          if (response.data.success) {
            fetchDictationTemplate();
            notification['success']({
              message: 'Success',
              description: response.data.message
            });
            setAddDrawerVisible(false);
            setSaveLoading(false);
          } else {
            setSaveLoading(false);
            notification['error']({
              message: 'Failes',
              description: response.data.message
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const fetchDictationTemplate = templateId => {
    setLoading(true);
    listDictationTemplate(0, 20, searchString)
      .then(response => {
        if (response.data.success) {
          let templates = response.data.result;
          if (templateId) {
            setSelectedItem(
              templates.find(template => template.id === templateId)
            );
          } else setSelectedItem(templates[0]);
          setDictationTemplate(templates);
        }
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  const deleteTemplate = () => {
    setDeleteLoading(true);
    deleteDictationTemplate(deleteItem.id)
      .then(response => {
        if (response.data.success) {
          fetchDictationTemplate();
          notification['success']({
            message: 'Success',
            description: response.data.message
          });
          setDeleteItem(null);
          setShowDeleteConfirmation(false);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
        setDeleteLoading(false);
      })
      .catch(err => {
        setDeleteLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    fetchDictationTemplate();
  }, [searchString]);

  useEffect(() => {
    getSupportedSections()
      .then(response => {
        if (response.data.success) setSupportedSections(response.data.result);
      })
      .catch(error => console.log(error));
  }, []);
  const { TabPane } = Tabs;
  const handleTabChange = key => {
    if (key === '2') {
      history.push(`/dashboard/dictionary-templates`);
    }
    if (key === '1') {
      history.push(`/dashboard/dictionary`);
    }
  };
  return (
    <>
      <PageTitle title='Dictation Template' />

      <StyledTabs onChange={handleTabChange} defaultActiveKey='2'>
        <TabPane tab='Key-to-phrase' key='1'>
          <a href='/dashboard/dictionary'></a>
        </TabPane>
        <TabPane tab='Dictation Templates' key='2'>
          <a href='/dashboard/dictionary-templates'></a>
        </TabPane>
      </StyledTabs>
      <ContainerRow device={true}>
        <TemplateWrapper fullWidth={loading}>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <>
                <ItemsListContainer>
                  <ListTitleContainer>
                    <ListTitle> Dictation Template</ListTitle>
                    {/* <PageTitle title='Dictation Template' /> */}
                  </ListTitleContainer>
                  <SearchInputStyle
                    placeholder='Search Templates'
                    // key={selectedTab}
                    prefix={<SearchOutlined />}
                    onChange={event => debounceOnChange(event.target.value)}
                    allowClear={true}
                    value={searchString}
                    style={{ width: '94%' }}
                  />
                  <Divider></Divider>
                  <ListDiv>
                    <TemplateButton onClick={() => setAddDrawerVisible(true)}>
                      <span className='icon-wrapper'>
                        <PlusOutlined />
                      </span>
                      New Template
                    </TemplateButton>
                    {dictationTemplate.length > 0 ? (
                      dictationTemplate.map(item => {
                        return (
                          <>
                            <TemplateNameContainer
                              onClick={() => setSelectedItem(item)}
                              selected={item.id === selectedItem?.id}
                            >
                              <TemplateName>{item.templateName}</TemplateName>

                              {item.id === selectedItem?.id ? (
                                <>
                                  <Tooltip title='Edit Template'>
                                    <TemplateActionButton
                                      onClick={() => {
                                        setEditItem(item);
                                        setAddDrawerVisible(true);
                                      }}
                                    >
                                      <EditOutlined />
                                    </TemplateActionButton>
                                  </Tooltip>
                                  <Tooltip title='Delete Template'>
                                    <TemplateActionButton
                                      delete={true}
                                      onClick={() => {
                                        setDeleteItem(item);
                                        setShowDeleteConfirmation(true);
                                      }}
                                    >
                                      <DeleteOutlined />
                                    </TemplateActionButton>
                                  </Tooltip>
                                </>
                              ) : null}
                            </TemplateNameContainer>
                          </>
                        );
                      })
                    ) : (
                      <EmptyMessage>No dictation template found</EmptyMessage>
                    )}
                  </ListDiv>
                </ItemsListContainer>

                {!addDrawerVisible ? (
                  <SelectedItemsListContainer>
                    <ListTitle>
                      {selectedItem && selectedItem.templateName
                        ? selectedItem.templateName
                        : ''}
                    </ListTitle>
                    <SelectedHeadingValues></SelectedHeadingValues>
                    <ListTitle selected={true}>Description</ListTitle>

                    {selectedItem ? (
                      <InputDiv style={{ marginLeft: '-19px' }}>
                        {selectedItem.templateDescription
                          ? selectedItem.templateDescription
                          : 'No template description'}
                      </InputDiv>
                    ) : (
                      <InputDiv>No description found</InputDiv>
                    )}
                    <SelectedHeadingValues> </SelectedHeadingValues>
                    <TemplateDriven>
                      <ListTitle selected={true}>Directional Flow</ListTitle>
                      {selectedItem ? (
                        <Switch
                          checked={
                            selectedItem.templateDrivenFlowEnabled
                              ? selectedItem.templateDrivenFlowEnabled
                              : false
                          }
                        />
                      ) : (
                        <Switch checked={false} />
                      )}
                    </TemplateDriven>
                    <ListTitle selected={true} second={true}>
                      Headings
                    </ListTitle>
                    <InputTextOverallArea>
                      {selectedItem &&
                        selectedItem.sectionHeadings.map(item => (
                          <div key={item.id}>
                            <MappedValues>
                              <span>{item.customUIHeading}</span>
                              <BiDirectionalArrow />
                              <span>{item.systemHeading}</span>
                            </MappedValues>
                            <TemplateDriven>
                              <ListTitle>Required</ListTitle>
                              <Switch checked={item.required} />
                            </TemplateDriven>
                            {item.description && (
                              <div>
                                <ListTitle selected={true}>
                                  Description
                                </ListTitle>
                                <TextArea
                                  rows={4}
                                  // value={item.defaultValue}
                                  value={item.description}
                                  style={{
                                    backgroundColor: '#F0F2F5',
                                    padding: '10px',
                                    borderRadius: '8px',
                                    marginTop: '5px',
                                    width: '100%'
                                  }}
                                  readOnly
                                />
                              </div>
                            )}
                            {item.defaultValue && (
                              <div>
                                <ListTitle
                                  selected={true}
                                  style={{ marginTop: '10px' }}
                                >
                                  Default Value
                                </ListTitle>
                                <TextArea
                                  rows={4}
                                  // value={item.defaultValue}
                                  value={item.defaultValue}
                                  style={{
                                    backgroundColor: '#F0F2F5',
                                    padding: '10px',
                                    borderRadius: '8px',
                                    marginTop: '5px',
                                    width: '100%'
                                  }}
                                  readOnly
                                />
                              </div>
                            )}
                          </div>
                        ))}
                    </InputTextOverallArea>
                  </SelectedItemsListContainer>
                ) : (
                  <SelectedItemsListContainerOne>
                    <AddDictionaryTemplatesForm
                      supportedSections={supportedSections}
                      selectedItem={editItem}
                      setSelectedItem={setEditItem}
                      submitHandler={submitHandler}
                      setAddDrawerVisible={setAddDrawerVisible}
                      saveLoading={saveLoading}
                    />
                  </SelectedItemsListContainerOne>
                )}
              </>
            </>
          )}
        </TemplateWrapper>
      </ContainerRow>

      <ConfirmationModal
        visible={showDeleteConfirmation}
        onOk={() => deleteTemplate()}
        onCancel={() => {
          setShowDeleteConfirmation(false);
          setDeleteItem(null);
        }}
        loading={deleteLoading}
        confirmation={true}
        antdModalProps={{ closable: false }}
      >
        <DeleteIcon />
        <DeleteConfirmationMessage>
          Are you sure you want to delete?
        </DeleteConfirmationMessage>
      </ConfirmationModal>
    </>
  );
};

export default DictionaryTemplates;
