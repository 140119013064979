import styled from 'styled-components';
import { Button, Select, Tree } from 'antd';

export const DeviceConfigurationWrapper = styled.div`
  margin-top: 70px;
  display: flex;

  height: calc(100% - 62px - 70px);
  width: 100%;
`;

export const ItemsListContainer = styled.div`
  height: 100%;
  width: 250px;
  border-right: 1px solid #c0c0c0;
`;

export const SelectedItemsListContainer = styled.div`
  position: relative;
  height: 100%;
  width: calc(100% - 20px - 251px);
  padding-left: 22px;
  padding-right: 50px;
`;

export const ListTitle = styled.h6`
  font-size: ${props => (props.selected ? '14px' : '18px')};
  font-weight: 600;
  color: #525e6f;
  margin-top:0px;
`;

export const AvailableEhrFieldsContainer = styled.div`
  position: relative;
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: calc(100% - 62px - 70px);
  overflow: auto;

  width: 100%;
  & .ant-checkbox-wrapper {
    margin-top: 27px;
    margin-left: 0;
    & .ant-checkbox + span {
      font-size: 14px;
      font-weight: 500;
      color: #36383b;
    }
    & .ant-checkbox-checked + span {
      color: #47a4ea;
    }
  }

  ul{
    list-style-type: none;
  }
`;

export const SelectedEhrFieldsContainer = styled.div`
  position: relative;
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  height: auto;

  width: 100%;
  padding-top: 25px;
  border-top: 1px solid #c0c0c0;
`;

export const SelectedEhrField = styled.div`
  margin: 10px;
  height: 41px;
  width: auto;
  line-height: 21px;
  vertical-align: middle;
  text-align: center;
  padding: 10px 10px;
  color: #525e6f;
  font-weight: 500;
  font-size: 14px;
  background: #e6e8e9;
  border-radius: 7px;
`;

export const StyledButton = styled(Button)`
  position: absolute !important;
  right: 50px;
  bottom: 80px;

  font-weight: 600;
  width: 100px;
  font-size: 14px;
  height: 40px;
  padding: 8px 16px;

  &.ant-btn-primary {
    background: ${props => props.reset && 'red'};
    border-color: ${props => props.reset && 'red'};
    margin-left: 10px;

    &:hover {
      background: ${props => props.reset && '#f38358'};
      border-color: ${props => props.reset && '#f38358'};
    }

    &:active,
    :focus {
      background: ${props => props.reset && '#f38358'};
      border-color: ${props => props.reset && '#f38358'};
    }
  }

  &.ant-btn {
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
  }

  svg {
    height: 20px;
    width: 16px;
  }
`;

export const TitleUnderLine = styled.div`
  position: absolute;
  top: 36px;
  left: 19px;
  height: 3px;
  width: 111px;
  background: #47a4ea;
`;
export const EmptyMessage = styled.div`
  margin: ${props => (props.selected ? '100px' : '0px')};
  position: absolute;

  left: ${props => (props.selected ? '40%' : '20%')};
  top: ${props => (props.selected ? '37%' : '35%')};
`;
export const StyledSelect = styled(Select)`
  border-radius: 5px;
  margin-right: 20px;
  width: 140px;
  font-size:16px;
  margin-top: 30px;
`;

export const StyledTree = styled(Tree)`
  background-color: #f0f2f5;

  & .ant-tree-list{
    background-color: #f0f2f5;
  }

  & .ant-tree-switcher-leaf-line{
    background-color: #f0f2f5;
  }

  & .ant-tree-switcher{
    background-color: #f0f2f5 !important;
  }
`;
