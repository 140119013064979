import React, { useState, useEffect, useCallback, useRef } from 'react';
import { TitleRow, ContainerRow, SearchInputStyle } from '../styledComponents';
import CustomTypography from '../../CustomComponents/CustomTypography';
import { useSelector } from 'react-redux';
import PageTitle from '../../../Utils/PageTitle';
import CustomTable from '../../CustomComponents/Table';

import { StyledTabs } from '../DictionaryTemplates/styledComponents.js';

import {
  CustomDicModal,
  StyledModalContent
} from '../Dictionary/styledComponets';
import {
  RESULTS_PER_PAGE,
  SEARCH_DELAY
} from '../../../Constants/StringConstants';
import { columns } from './tableConfig';

import AddDictionaryKeyToPhraseForm from './AddDictionaryKeyToPhraseForm.jsx';

import {
  editDictionaryCannedText,
  fetchAllDictionaryCannedText,
  deleteMultipleDictionaryCannedTexts,
  saveDictionaryCannedText
} from '../../../Apis/Dictionary';
import { notification, Row, Col, Modal, Tabs, Tooltip } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { targetEndPoints } from '../../../Apis/Author.js';
import { useDebounce } from '../../../Utils/Debounce';
import { AddButton } from '../styledComponents';
import BreadCrumbComponent from './../../CustomComponents/BreadCrumbComponent';
import { useHistory } from 'react-router-dom';
const Dictionary = () => {
  const [theme] = useSelector(state => [state.themeReducer]);
  const [searchString, setSearchString] = useState('');
  const [total, setTotal] = useState(0);
  const [cannedText, setCannedTexts] = useState([]);
  const [dynamicEndPoints, setDynamicEndPoints] = useState([]);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [addDrawerVisible, setAddDrawerVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [filterKey, setFilterKey] = useState('name');
  const [sortOrder, setSortOrder] = useState('');
  const [selectedJobIds, setSelectedJobIds] = useState([]);

  const [loader, setLoader] = useState(false);

  const [activeTab, setActiveTab] = useState('tab1'); // Manage the active tab
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const showDeleteModal = () => {
    setDeleteModalVisible(true);
  };

  const hideDeleteModal = () => {
    setDeleteModalVisible(false);
  };

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState('Content of the modal');
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setModalText('The modal will be closed after two seconds');
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    setSelectedItem(null);

    setOpen(false);
  };
  useEffect(() => {
    fetchTarget();
  }, []);

  const fetchTarget = pageNo => {
    // setLoading(true);
    targetEndPoints()
      .then(response => {
        setLoading(false);
        if (response.data.success) {
          // setTotal(response.data.size);
          // setCannedTexts(response.data.result);
          setDynamicEndPoints(response.data.result);
          // const keys = response.data.result.map(item => {
          //   return { ...item, column: COLUMN_NAMES.KEY_TO_PHRASE };
          // });

          // setKeyToPhraseItems(keys);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };
  const submitHandler = values => {
    values.phrase = values.phrase.replace(/<\/p>/g, '</p><br>');

    setSubmitLoading(true);
    const payload = values;
    if (selectedItem) {
      payload.id = selectedItem.id;
      editDictionaryCannedText(payload)
        .then(response => {
          if (response.data.success) {
            notification['success']({
              message: 'Success',
              description: response.data.message
            });
            setSubmitLoading(false);
            setAddDrawerVisible(false);
            setSelectedItem(null);
            fetchAll(currentPage);
            setOpen(false);
          } else {
            notification['error']({
              message: 'Failed',
              description: response.data.message
            });
            setSubmitLoading(false);
          }
        })
        .catch(err => {
          setSubmitLoading(false);
          console.log(err);
        });
    } else {
      saveDictionaryCannedText(payload)
        .then(response => {
          if (response.data.success) {
            notification['success']({
              message: 'Success',
              description: response.data.message
            });
            setSubmitLoading(false);
            setAddDrawerVisible(false);
            setSelectedItem(null);
            fetchAll(0);
            setCurrentPage(0);
            // fetchAll(currentPage);
            setOpen(false);
          } else {
            notification['error']({
              message: 'Failed',
              description: response.data.message
            });
            setSubmitLoading(false);
          }
        })
        .catch(err => {
          setSubmitLoading(false);
          console.log(err);
        });
    }
  };

  const deleteKey = id => {
    setLoading(true);
    deleteMultipleDictionaryCannedTexts(id)
      .then(response => {
        if (response.data.success) {
          notification['success']({
            message: 'Deleted',
            description: response.data.message
          });
          fetchAll(0);
          setCurrentPage(0);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedJobIds(selectedRowKeys);
    },
    getCheckboxProps: record => ({
      name: record.id
    })
  };
  const handleDelete = () => {
    setLoading(true);
    deleteMultipleDictionaryCannedTexts(selectedJobIds).then(res => {
      if (res?.data?.success) {
        notification.success({ message: res?.data?.message });
        fetchAll(0);
        setSelectedJobIds([]);
      } else {
        setLoading(false);
        notification.error({ message: res?.data?.message });
      }
    });

    hideDeleteModal(); // Close the modal after deletion
  };
  const fetchAll = pageNo => {
    setLoading(true);
    fetchAllDictionaryCannedText(pageNo, RESULTS_PER_PAGE, searchString)
      .then(response => {
        setLoading(false);
        if (response.data.success) {
          setTotal(response.data.size);
          setCannedTexts(response.data.result);
          // setDynamicEndPoints(response.data.endPoints);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchAll(0);
    setCurrentPage(0);
  }, [filterKey, searchString, sortOrder]);
  const filterTableChange = (pagination, filters, sorter, event, event2) => {
    let sortByKey;
    let sortByOrder;
    if (sorter.length > 1) {
      sortByKey =
        sorter &&
        sorter.map(item => {
          return item.columnKey;
        });
      sortByOrder =
        sorter &&
        sorter.map(item => {
          return item.order;
        });

      setFilterKey(sortByKey);
      setSortOrder(sortByOrder);
    } else {
      sortByKey = sorter.columnKey;
      sortByOrder = sorter.order;
      if (sorter.columnKey !== undefined) setFilterKey(sortByKey);
      if (sorter.order !== undefined) setSortOrder(sortByOrder);
    }
  };
  const onChangeHandler = value => {
    setCurrentPage(0);
    setSearchString(value);
  };

  const debounceOnChange = useCallback(
    useDebounce(onChangeHandler, SEARCH_DELAY),
    []
  );

  const pageNoChangeHandler = pageNo => {
    fetchAll(pageNo);
  };

  function removeHTMLTags(inputString) {
    return inputString.replace(/<[^>]*>/g, '');
  }
  const { TabPane } = Tabs;
  const handleTabChange = key => {
    if (key === '2') {
      history.push(`/dashboard/dictionary-templates`);
    }
    if (key === '1') {
      history.push(`/dashboard/dictionary`);
    }
  };
  return (
    <>
      <StyledTabs defaultActiveKey='1' onChange={handleTabChange}>
        <TabPane tab='Key-to-phrase' key='1'></TabPane>
        <TabPane tab='Dictation Templates' key='2'>
          {/* Content for Dictation Templates can go here if needed */}
        </TabPane>
      </StyledTabs>
      <PageTitle title='Key to Phrase' />
      <ContainerRow gutter={[0, 40]}>
        <TitleRow justify='space-between'>
          <CustomTypography color={theme['@text-color']}>
            Key to Phrase
          </CustomTypography>
          <div>
            {selectedJobIds < 1 ? (
              <AddButton
                type='primary'
                shape='round'
                //   onClick={() => setAddDrawerVisible(true)}
                onClick={showModal}
              >
                Add New Key-to-Phrase
              </AddButton>
            ) : (
              <AddButton type='primary' shape='round' onClick={showDeleteModal}>
                Delete Selected
              </AddButton>
            )}
            <StyledModalContent
              open={deleteModalVisible}
              centered
              mask={false}
              footer={null}
              closable={false}
              onCancel={hideDeleteModal}
            >
              <h2 className='styledHeading'>Delete selected Key-to-Phrase?</h2>
              <p className='styledParagraph'>
                Are you sure you want to delete the selected Key-to-Phrase? This
                will not be reversible.
              </p>
              <div className='button-container'>
                <AddButton
                  ghost
                  type='primary'
                  shape='round'
                  onClick={hideDeleteModal}
                >
                  <span className='buttonValue'> No,</span>Cancel
                </AddButton>
                <AddButton danger shape='round' onClick={handleDelete}>
                  <span className='buttonValue'>Yes,</span>Delete
                </AddButton>
              </div>
            </StyledModalContent>

            {open && (
              <CustomDicModal
                title={
                  selectedItem ? 'Edit Key-to-phrase ' : 'Add Key-to-phrase '
                }
                footer={null}
                visible={open}
                // width={'60%'}
                // destroyOnClose={true}
                onCancel={handleCancel}
              >
                <AddDictionaryKeyToPhraseForm
                  selectedItem={selectedItem}
                  submitHandler={submitHandler}
                  submitLoading={submitLoading}
                  dynamicEndPoints={dynamicEndPoints}
                  handleCancel={handleCancel}
                />
              </CustomDicModal>
            )}
            <SearchInputStyle
              placeholder='Search'
              prefix={<SearchOutlined />}
              onChange={event => debounceOnChange(event.target.value)}
            />
          </div>
        </TitleRow>

        <CustomTable
          theme={theme}
          columns={columns(
            setSelectedItem,
            showModal,
            deleteMultipleDictionaryCannedTexts,
            deleteKey
          )}
          dataSource={cannedText}
          pageNoChangeHandler={pageNoChangeHandler}
          loading={loading}
          setPageNo={current => {
            setCurrentPage(current);
          }}
          selectRow={true}
          rowSelection={rowSelection}
          rowKey='id'
          pageNo={currentPage}
          // showModal={showModal}
          pageSize={RESULTS_PER_PAGE}
          total={total}
          onChange={filterTableChange}
        />
      </ContainerRow>
    </>
  );
};

export default Dictionary;
