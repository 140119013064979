import React from "react";

function ReportsIcon() {
  return (
    <span className="anticon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          fill="#666"
          d="M12.284 7.23H8.56a.594.594 0 100 1.19h3.725a.594.594 0 100-1.19zM13.71 10.104H8.56a.594.594 0 100 1.188h5.152a.594.594 0 100-1.188z"
        ></path>
        <path
          fill="#666"
          d="M16.405 3.505H5.884a.594.594 0 00-.595.595v5.607H4.24A2.242 2.242 0 002 11.947v3.309a2.242 2.242 0 002.537 2.219h9.808A2.658 2.658 0 0017 14.82V4.1a.594.594 0 00-.595-.595zM5.29 15.255c0 .51-.364.936-.847 1.031H4.24v.02c-.58 0-1.05-.471-1.05-1.05v-3.31c0-.578.471-1.05 1.05-1.05h1.05v4.36zm10.522-.435c0 .795-.671 1.466-1.466 1.466H6.227c.16-.309.251-.659.251-1.03V4.694h9.333V14.82z"
        ></path>
        <path
          fill="#666"
          d="M12.284 12.799H8.56a.594.594 0 100 1.189h3.725a.594.594 0 100-1.19z"
        ></path>
      </svg>
    </span>
  );
}

export default ReportsIcon;
