import React from 'react';

function FacilityIcon({ size, color }) {
  return (
    <span className='anticon'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={size ? size : '18'}
        height={size ? size : '18'}
        fill='none'
        viewBox='0 0 15 13'
      >
        <path
          fill={color ? color : '#666'}
          fillRule='evenodd'
          d='M7.064.29c-.285.08-.406.171-.994.748-3.6 3.529-5.494 5.4-5.544 5.48a.394.394 0 00.261.589c.182.036.268-.007.549-.28l.266-.258.01 2.452c.009 2.255.014 2.466.058 2.615A1.824 1.824 0 002.951 12.9c.204.053.319.054 4.463.054s4.258-.001 4.462-.054c.479-.122.9-.454 1.123-.886.217-.418.206-.267.216-3.006l.01-2.439.267.26c.28.272.366.315.548.279a.395.395 0 00.261-.59C14.225 6.396 8.327.597 8.155.475 7.953.332 7.76.271 7.468.26a1.334 1.334 0 00-.404.03zm.55.812c.057.023.995.927 2.443 2.35l2.351 2.313v2.691c0 1.72-.01 2.754-.028 2.865a.974.974 0 01-.542.734l-.15.07H3.14l-.15-.07a.973.973 0 01-.541-.734c-.018-.11-.028-1.145-.029-2.865V5.765l2.352-2.313c1.294-1.272 2.39-2.329 2.436-2.35a.597.597 0 01.408 0zM6.416 4.638a.48.48 0 00-.149.128c-.058.081-.06.11-.068.81l-.008.727h-.684c-.774.001-.855.017-.965.197-.057.092-.059.12-.059 1.03 0 1.29-.063 1.216 1.011 1.216h.7v.699c0 .781.009.83.186.95.092.06.1.06 1.032.06.894 0 .943-.002 1.029-.054.176-.107.194-.198.195-.969v-.684l.726-.008c1.05-.012.984.071.984-1.223 0-.897 0-.909-.06-.997-.14-.204-.134-.203-.931-.212L8.638 6.3l-.008-.716c-.01-.797-.008-.792-.212-.93-.088-.06-.1-.061-1.002-.06-.784 0-.924.006-1 .044zm1.406 1.481c0 .664.004.72.054.801.03.05.094.113.142.143.083.05.138.054.801.054h.713v.814h-.687c-.658 0-.69.003-.805.061-.21.108-.218.144-.218.95v.7h-.812l-.008-.718c-.008-.675-.011-.722-.064-.792A.777.777 0 006.807 8c-.071-.053-.118-.057-.793-.064l-.718-.008v-.812h.693c.77 0 .827-.01.939-.167.064-.09.065-.1.073-.817l.008-.726h.812v.712z'
          clipRule='evenodd'
        ></path>
      </svg>
    </span>
  );
}

export default FacilityIcon;
