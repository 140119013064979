export const EditorDataFormatter = (editorData) => {
  const newSection =
    editorData &&
    editorData.map((section) => {
      return [
        {
          id: section.id,
          type: 'heading',
          class: 'section-heading',
          children: [{ text: section.heading.customUIHeading }],
        },
        {
          id: section.id,
          type: 'paragraph',
          class: 'section-content',
          children: [{ text: section.content ? section.content[0].value : '' }],
        },
      ];
    });
  return newSection;
};
