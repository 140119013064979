import styled from 'styled-components';
import { Row, Col, Button, Divider, Select, Typography, Alert } from 'antd';
import Check from '../../../../Assets/Images/check.svg';
const { Text } = Typography;

export const StyledRow = styled(Row)`
  margin: ${props =>
    props.subscription
      ? '0 !important'
      : props.renewal && '20px -20px -20px !important'};
`;

export const StyledCol = styled(Col)`
  padding: 0 20px !important;
`;

export const SubscriptionCardContainer = styled.div`
  border-radius: 10px;
  border: ${props => (props.active ? '1.5px solid #94E061' : 'none')};
  box-shadow: 0px 0px 16px 4px rgba(232, 232, 232, 1);
  background: #fff;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  height: 50vh;
  min-height: 500px;
  position: relative;
  transition: all 0.25s ease-in;
  &:hover {
    transform: scale(1.05);
  }
`;

export const SubscriptionCancelDiv = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledActiveLabel = styled.div`
  position: absolute;
  right: 20px;
  top: -1px;
  & svg {
    width: 30px;
    height: 40px;
  }
`;

export const SubscriptionTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  color: ${props => props.color};
`;

export const AmountDiv = styled.div`
  display: flex;
  height: 40px;
  margin: 15px 0;
`;

export const AmountDisplay = styled.h2`
  font-size: 30px;
  font-weight: 900;

  color: ${props => props.color};
  margin-bottom: 0;
`;

export const DurationDisplay = styled.h2`
  font-size: 16px;
  font-weight: 700;

  color: ${props => props.color};
  margin-bottom: 0;
  margin-top: 16px;
`;

export const StartButton = styled(Button)`
  &.ant-btn {
    border-radius: 10px;
    background-color: white;
    color: ${props => props.color};
    width: 150px;
    font-weight: 600;
    &:hover,
    &:active,
    &:focus {
      background-color: ${props => props.color};
      color: white;
    }
  }
`;

export const StyledDivider = styled(Divider)`
  width: 50%;
  min-width: 50%;
  margin: 0px;
  &.ant-divider {
    color: ${props => props.color};
    border-top: 1px solid ${props => props.color};
  }
`;

export const PlanDetails = styled.ul`
  padding: 0;
  margin-top: 15px;

  & li {
    position: relative;
    list-style: none;
    font-size: 1.7vmin;
    color: ${props => props.color};
    font-weight: 500;
    line-height: 1.4;
    padding: 10px 0 10px 30px;
    text-align: initial;
    &:before {
      content: '';
      position: absolute;
      top: 14px;
      left: 0px;
      background-image: url(${Check});
      width: 15px;
      height: 15px;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  @media only screen and (max-width: 1440px) {
    & li {
      font-size: 12px;
    }
  }

  @media only screen and (max-width: 1200px) {
    & li {
      font-size: 10px;
    }
  }
`;

export const ExpiryMessageDiv = styled.div`
  border-radius: 20px;
  background-color: ${props => (props.expired ? '#ffe3e2' : '#ffefd7')};
  height: ${props => (props.expired ? null : '140px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${props => (props.expired ? '70% !important' : null)};
  margin: 40px auto !important;
  padding: ${props => (props.expired ? '25px' : null)};
  margin: ${props => (props.expired ? '40px 20px' : '20px')};
  color: ${props => (props.expired ? '#fa4643' : '#f59504')};
  justify-content: center;
  font-size: 20px;
  & span {
    margin: 10px 0;
    & svg {
      font-size: 30px;
    }
  }

  & p {
    font-size: 16px;
  }

  @media only screen and (max-width: 1200px) {
    width: 98% !important;
  }
`;

export const RenewButton = styled(Button)`
  &.ant-btn {
    border-radius: 12px;
    background-color: ${props => (props.expired ? '#FA4643' : '#f59504')};
    color: white;
    width: 175px;
    height: 40px;
    line-height: 40px;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: 900;
    font-size: 15px;
    border: none;
    &:hover,
    &:active,
    &:focus {
      border: ${props =>
    props.expired ? '1px solid #FA4643' : '1px solid #f59504'};
      background-color: ${props => (props.expired ? '#fa4643c7' : '#e7910eb3')};
      color: white;
    }
    & span {
      margin: 0px 0;
    }
  }
`;

export const ExpiredMessageContainer = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CancelButton = styled(Button)`
  &.ant-btn {
    border-radius: 4px;
    font-size: 14px;
    width: 180px;
    font-weight: 400;
    border: 1px solid #d2d2d2;

    &:hover {
      border: 1px solid lightgray;
    }
  }
`;

export const PlanDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledSelect = styled(Select)`
  border-radius: 5px;
  margin-right: 20px;
  width: 140px;
`;

export const StyledExpMsg = styled(Text)`
  margin: 15px 0;
  font-weight: 600;
`;

export const StyledAlert = styled(Alert)`
  margin: 10px;
`;

export const SubscriptionStatusAlert = styled(Alert)`
  margin-top: 10px;
`;

export const ManageSubscriptionContainer = styled.div`
  width: 80%;
  margin-left: 10%;
  margin-top: 10px;

`;

export const AlertContainer = styled.div`
`;

export const HeaderContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: col;
  flex-direction: row;
  justify-content: space-between;
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  // border-left: 3px solid #D9D9D9;
  width: 309px;
  height: 68px;
  font-weight: 600;
  font-size: 20px;
  color: #000000;
`;
export const ContentTableContainer = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 18px;
`;

export const HeaderSwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const HeaderSwitchButtonContainer = styled.div`
  height: 44px;
  width: 294px;
  background: #FFFFFF;
  border-radius: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const HeaderSwitchButton = styled(Button)`
  width: 143px;
  height: 38px;
  
  &.ant-btn{
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    border-radius: 190px ;
    border: none;
    color: #47A4EA;

    &:focus{
      background: #47A4EA ;
      color: #FFFFFF ;
    }
  }

  &.active{
    background: #47A4EA ;
    color: #FFFFFF ;
  }
`;

export const HeaderSpecilOfferContainer = styled.div`
  display:flex;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #47A4EA;
  align-items: center;
  margin: 5px;
  padding: 10px;
`;

export const SubscriptionDetailsTable = styled.table`
  margin-top: 30px;
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 18px;

  & tr{
    height: 55px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &.no-border{
      border: none;
    }
  }

  & th{
    text-align: center;
    padding: 30px;

    &.active-plan{
      background-color: #F8F8F8;
    }
  }

  & td{
    text-align: center;

    &.active-plan{
      background-color: #F8F8F8;
    }
  }

`;

export const SubscriptionDetailsFeatureContent = styled.div`
  padding-left: 30px;
  padding-right: 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 54px;
  text-align: start;


  color: #78828F;
`;

export const SubscriptionDetailsTableHeading = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #000000;

  &.plan-features{
    // padding-left: 30px;
    text-align: start;
  }
  &.plan-price{
    color: #BDBDBD;
    & .active{
      color: #47A4EA;
    }
  }
`;

export const ActivePlanIconContainer = styled.div`
  margin-top: -50px;
`;

export const PlanNotes = styled.div`
  background: rgba(189, 189, 189, 0.15);
  border-radius: 5px;
  padding: 10px;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #00000078;
  width: 350px;
`;

export const CancelSubscriptionButton = styled(Button)`
  width: 185px;
  height: 38px;
  &.ant-btn{
    background: #FFDBDB;
    border-radius: 5px;
    color: #FF6B6B;
    border: none;

    &:hover, :focus{
      border-color: #FF6B6B;
      color: #FF6B6B;
  
    }
  }

  
  
`;

export const ChoosePlanButton = styled(Button)`
  width: 143px;
  height: 38px;
  background: #FFFFFF;
  border: 1px solid #BDBDBD;
  border-radius: 5px;
  color: #BDBDBD;
`;

export const NoFacilityAlert = styled.div`
  width: 100%;
  height: 100%;
  height: calc(100vh - 400px);
  display: flex;
  justify-content: center;
  align-items: center;
`;
