import axiosInstance from '../Config/AxiosInstance';

const PREFIX = `/clinic/superadmin`;

export const getDashboardData = (clinicAdminUserId) => {
  return axiosInstance.get(PREFIX+`/tenant-dashboard?userId=${clinicAdminUserId}`);
};

export const getClinicAdmins = () => {
    return axiosInstance.get(PREFIX+`/clinic-admins`);
};

