import { Button, Card, Col, Select, Modal, Row } from 'antd';

import styled from 'styled-components';

export const StyledTag = styled.span`
  padding: 10px;

  display: flex;

  justify-content: left;

  align-items: center;

  border: 1px solid #d9d9d9;

  font-size: 16px;

  width: 100%;

  &:hover {
    background-color: #47a4ea;

    color: #ffffff;
  }
`;
// export const StyledTagContainer = styled.div`
//   overflow-y: auto;
//   height: 33vh !important;
//   width: 100%;
//   margin-top: 2px;
//   border: 1px solid #d9d9d9;
//   border-radius: 10px;
//   background-color: white;
// `;
export const StyledTagContainer = styled.div`
  overflow-y: auto;
  height: 286px;
  width: 100%;
  /* margin-top: 31px; */
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  background-color: white;
`;

export const SubmitCol = styled(Card)`
  min-height: 70vh;

  /* width:436px; */

  /* overflow-y: scroll; */
`;

export const ActiveKeytophraseCol = styled(Card)`
  /* min-height: 80vh; */

  min-height: 500px;

  height: 500px;

  background: #f0f2f5 !important;

  overflow-y: scroll;
`;

export const StyledSelect = styled(Select)`
  border-radius: 5px;

  margin-right: 20px;

  width: 140px;

  font-size: 16px;

  margin-top: 30px;
`;

export const CardTitle = styled.h6`
  font-size: 14px;
  font-weight: 600;
  color: #525e6f;
  padding-top: 20px;
  padding-bottom: 8px;
`;

export const SubmitButton = styled(Button)`
  width: 150px;

  &.ant-btn-primary {
    border-radius: 10px;
  }
`;
export const CustomDicModal = styled(Modal)`
  width: 800px !important;
  .ant-modal-body {
    background-color: #f5f7fa;
    border-radius: 8px;
  }
  .ant-modal-title {
    font-size: 20px;
    font-weight: 700;
  }
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-header {
    border-radius: 8px;
  }
`;
export const StyledDiv = styled.div`
  display: flex;

  flex-direction: ${props => props.direction};

  justify-content: ${props => props.justify};

  width: ${props => props.width};
`;
export const StyledModalContent = styled(Modal)`
  .ant-modal-content {
    width: 448px;
    height: 262px;
    border-radius: 16px;
    padding: 24px;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: center;
  }
  .styledHeading {
    font-family: Krub;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-align: center;
  }
  .styledParagraph {
    font-family: Krub;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: center;
  }
  .button-container {
    display: flex;
    justify-content: space-between;
  }
  .buttonValue {
    margin-right: 5px;
  }
`;
