import React, { useState } from 'react';
import { Tabs } from 'antd';
import {
  CustomTabs,
  FormComponent,
  EditButton,
  EditField
} from './styledComponents';
import BasicInformation from './BasicInformation';
import UserCredentials from './UserCredentials';
import EditIcon from '../../../../Assets/Icons/EditIcon';
import TranscriptionFlow from './TranscriptionFlow';
import { useSelector } from 'react-redux';
const { TabPane } = Tabs;

const ProfileBasicInformation = ({
  setSelectedTab,
  facilities,
  saveProfileInformation1,
  saveProfileInformation,
  profile,
  changePassword,
  edit,
  setEdit,
  passwordEditDisable,
  setPasswordEditDisable,
  saveLoading
}) => {
  const [activeTab, setActivetab] = useState('1');
  const [user] = useSelector(state => [state.userReducer]);
  const handleChangeTab = activeKey => {
    setActivetab(activeKey);
    if (activeKey === '1') {
      setSelectedTab('Basic Information');
    } else {
      setSelectedTab('User Credentials');
    }
    setEdit(false);
    setPasswordEditDisable(false);
  };

  const handleEdit = () => {
    setEdit(!edit);
  };

  return (
    <>
      <CustomTabs
        defaultActiveKey='1'
        size='large'
        activeKey={activeTab}
        onChange={handleChangeTab}
      >
        <TabPane
          tab={<div className='tabs-wrapper'>Basic Information</div>}
          key='1'
        />
        <TabPane
          tab={<div className='tabs-wrapper'>User Credentials</div>}
          key='2'
        />
        {(user.userType === 'clinicAdmin' || user.userType === 'admin') && (
          <TabPane
            tab={<div className='tabs-wrapper'>Transcription Flow</div>}
            key='3'
          />
        )}
      </CustomTabs>
      <EditField>
        {activeTab === '1' || activeTab === '3' ? (
          <EditButton
            type='primary'
            icon={<EditIcon />}
            onClick={handleEdit}
            disabled={edit === true || passwordEditDisable === true}
          />
        ) : null}
      </EditField>
      <FormComponent>
        {activeTab === '1' && (
          <BasicInformation
            saveProfileInformation={saveProfileInformation}
            profile={profile}
            edit={edit}
            setEdit={setEdit}
            saveLoading={saveLoading}
          />
        )}
        {activeTab === '2' && (
          <UserCredentials
            changePassword={changePassword}
            profile={profile}
            edit={edit}
            setEdit={setEdit}
            setPasswordEditDisable={setPasswordEditDisable}
            passwordEditDisable={passwordEditDisable}
          />
        )}
        {activeTab === '3' && (
          <TranscriptionFlow
            saveProfileInformation1={saveProfileInformation1}
            profile={profile}
            facilities={facilities}
            edit={edit}
            setEdit={setEdit}
            saveLoading={saveLoading}
          />
        )}
      </FormComponent>
    </>
  );
};

export default ProfileBasicInformation;
