import React, { useRef, useEffect } from 'react';
import {
  Row,
  Form,
  Input,
  Col,
  Switch,
  Select,
  Alert,
  Button,
  Divider,
  Checkbox
} from 'antd';
import { SubmitButtonCol, AddButton } from '../styledComponents.js';
import ReactQuill from 'react-quill';
import { DeleteConfirmationMessage } from '../Dictation/styledComponents.js';
import Spinner from '../../CustomComponents/Spinner';
import {
  StyledTag,
  StyledTagContainer,
  SubmitButton,
  StyledTree,
  Keytophraseduplication,
  RestrictColumImport
} from './styledComponents.js';
import { useState } from 'react';
import DragIndicatorIcon from '../../../Assets/Icons/DragIndicatorIcon.js';
import ConfirmationModal from '../../CustomComponents/ConfirmationModal';
import CustomTypography from '../../CustomComponents/CustomTypography/index.jsx';
import { useSelector } from 'react-redux';
import { DownOutlined } from '@ant-design/icons';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.snow.css'; // Add css for snow theme

const AddKeyToPhraseForm = ({
  handleCheck,
  selectedItem,
  setSubmitDisabled,
  handleActiveClick,
  selectedItemColumn,
  handleDeleteClick,
  toggleTreeVisibility,
  handleRemoveFromSelected,
  submitHandler,
  treeVisible,
  loadingTree,
  submitLoading,
  dynamicEndPoints,
  handleActiveClickChange,
  showTable,
  selectedPhrase,
  k2pMapping,
  handleSubmit,
  toggleActive,
  toActive,
  submitDisabled,
  handleFormValuesChange,
  handleMineDelete,
  selectedFields,
  deleteModal,
  updatedK2pMappingkeys
}) => {
  const [theme] = useSelector(state => [state.themeReducer]);
  const [form] = Form.useForm();

  const [dynamicEnable, setDynamicEnable] = useState(
    selectedItem ? selectedItem.dynamic : false
  );
  const [expandedFields, setExpandedFields] = useState(['']);
  const [globallyEnable, setGloballyEnable] = useState(
    selectedItem ? selectedItem.global : false
  );
  const user = useSelector(state => state.userReducer);
  const handleModalOk = () => {
    toggleActive();
  };
  const [exportToOtherAuthors, setExportToOtherAuthors] = useState(
    selectedItem ? selectedItem.exportToOtherAuthors : false
  );
  const k2pMappingTreeData = k2pMapping.map(template => {
    return {
      title: template.templateName,

      selected: template.selected,
      key: template.id,
      children: template.sectionHeadings.map(heading => {
        return {
          title: heading.customUIHeading,
          selected: heading.selected,

          key: `${template.id}-${heading.customUIHeading}`
        };
      })
    };
  });

  const [selectedDynamicEndPoint, setSelectedDynamicEndPoint] = useState(() => {
    if (selectedItem) {
      return selectedItem.targetEndPoint;
    } else if (dynamicEndPoints && dynamicEndPoints.length > 0) {
      return dynamicEndPoints[0].fieldUiValue;
    } else {
      return '';
    }
  });
  // const phaseTextAreaRef = useRef(null);
  const quillRef = useRef(null);
  const isVisible =
    user.userType === 'admin' || user.userType === 'clinicAdmin';
  const CustomLabel = () => (
    <div>
      <p
        style={{
          height: '18px',
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '18.2px',
          color: '#2B2B2B'
        }}
      >
        Globally Available
      </p>

      <p
        style={{
          fontSize: '12px',
          lineHeight: '15.6px',
          color: '#5C5C5C'
        }}
      >
        Making it globally available will enable all users in the clinic to use
        this Key-to-phrase.
      </p>
    </div>
  );

  const CustomLabel2 = () => (
    <div>
      <p
        style={{
          height: '18px',
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '18.2px',
          color: '#2B2B2B'
        }}
      >
        Restrict to selected Templates
      </p>

      <p
        style={{
          fontSize: '12px',
          lineHeight: '15.6px',
          color: '#5C5C5C'
        }}
      >
        Key-to-Phrase will be triggered only on these selected templates and
        heading inside them.
      </p>
    </div>
  );
  const [endpointChangeModalVisible, setEndpointChangeModalVisible] =
    useState(false);

  const handleEndPointChange = value => {
    const regex = /(\$\{(.*?)\})/g;
    if (
      selectedDynamicEndPoint !== value &&
      form.getFieldValue('phrase')?.match(regex)?.length > 0
    ) {
      setEndpointChangeModalVisible(true);
    } else {
      setSelectedDynamicEndPoint(value);
    }
  };
  const onExpand = (expandedKeys, expanded) => {
    setExpandedFields(expandedKeys);
  };
  const handleEndPointChangeConfirm = () => {
    const phraseData = form.getFieldValue('phrase');
    const data = form.getFieldsValue();
    form.setFieldsValue({
      ...data,
      phrase: phraseData.replace(/(\$\{(.*?)\})/g, '')
    });
    setSelectedDynamicEndPoint(form.getFieldValue('targetEndPoint'));
    setEndpointChangeModalVisible(false);
  };

  const handleEndPointChangeCancel = () => {
    const data = form.getFieldsValue();
    form.setFieldsValue({
      ...data,
      targetEndPoint: selectedDynamicEndPoint
    });
    setEndpointChangeModalVisible(false);
  };

  const dropHandler = e => {
    const end = quillRef.current.getEditor().getLength();

    quillRef.current.getEditor().setSelection(end);

    quillRef.current.getEditor().focus();

    const quill = quillRef.current.getEditor();

    const data = e.dataTransfer.getData('text/html');

    const range = quill.getSelection();

    quill.clipboard.dangerouslyPasteHTML(range.index, data, 'user');
  };
  const dragStartHandler = e => {
    e.dataTransfer.setData('text/html', '${' + e.target.textContent + '}');
    if (!showTable) dropHandler(e);
  };
  const dynamicStyle = !dynamicEnable
    ? { height: '270px' }
    : { height: '270px', width: '320px' };

  const modules = {
    toolbar: [['bold', 'italic', 'underline', 'strike', 'clean']],
    clipboard: {
      matchVisual: false
    }
  };
  const removeHTMLTags = str => {
    return str.replace(/<[^>]*>?/gm, '');
  };
  const activeEdit = () => {
    if (submitDisabled) {
      toggleActive();
    } else {
      handleActiveClick();
    }
  };

  useEffect(() => {}, [form, dynamicEnable]); // Add dependencies to ensure useEffect triggers when form or dynamicEnable changes
  const onSelect = (selectedKeys, info) => {};

  const onCheck = (checkedKeys, info) => {};

  return (
    <>
      <Form
        form={form}
        layout='vertical'
        onFinish={values => submitHandler(values)}
        onValuesChange={handleFormValuesChange}
        initialValues={{
          key: selectedItem ? selectedItem.key : '',
          global: selectedItem ? selectedItem.global : false,
          restrictedToTemplates: selectedItem
            ? selectedItem.restrictedToTemplates
            : false,
          active: selectedItem ? selectedItem.active : false,
          dynamic: selectedItem ? selectedItem.dynamic : false,
          templateMapping: updatedK2pMappingkeys,
          targetEndPoint:
            selectedItem && selectedItem.targetEndPoint
              ? selectedItem.targetEndPoint
              : selectedDynamicEndPoint,
          phrase: selectedItem ? selectedItem.phrase : ''
        }}
      >
        <Row gutter={24}>
          <Col span={14}>
            <Row justify={'center'}>
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label='Key'
                      name='key'
                      rules={[{ required: true, message: 'Key is required' }]}
                    >
                      <Input
                        size='large'
                        onDrop={event => {
                          event.preventDefault();
                        }}
                        readOnly={
                          !(selectedItem ? selectedItem.editable : true)
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  gutter={16}
                  className='DynamicRow'
                  align={'middle'}
                  style={{ marginBottom: '16px' }}
                >
                  <Col span={5}>
                    <label
                      style={{
                        fontSize: '16px',
                        fontWeight: '600',
                        color: '#2B2B2B'
                      }}
                    >
                      Phrase
                    </label>
                  </Col>
                  <Col span={2}>
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: '500',
                        color: '#2B2B2B',
                        display: 'inline-block',
                        verticalAlign: 'middle'
                      }}
                    >
                      Type
                    </span>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name='dynamic'
                      style={{ marginBottom: 0, marginRight: 'auto' }}
                    >
                      <Switch
                        checked={dynamicEnable}
                        checkedChildren='Dynamic'
                        unCheckedChildren='Static'
                        onChange={() => {
                          form.setFieldValue({
                            ...form.getFieldsValue(),
                            dynamic: !dynamicEnable
                          });
                          setDynamicEnable(!dynamicEnable);
                        }}
                        disabled={
                          !(selectedItem ? selectedItem.editable : true)
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: '500',
                        color: '#2B2B2B',
                        display: 'inline-block',
                        verticalAlign: 'middle'
                      }}
                    >
                      Category:
                    </span>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name='targetEndPoint'
                      style={{
                        marginBottom: 0,
                        marginRight: 'auto'
                      }}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: 'Dynamic Category is required'
                      //   }
                      // ]}
                    >
                      <Select
                        placeholder={'Please select Dynamic Category'}
                        disabled={!dynamicEnable}
                        onChange={handleEndPointChange}
                        style={{ borderRadius: '8px' }}
                      >
                        {dynamicEndPoints &&
                          dynamicEndPoints.map(endPoint => (
                            <Select.Option
                              key={endPoint.fieldUiValue}
                              value={endPoint.fieldUiValue}
                            >
                              {endPoint.fieldUiName}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Row justify={'space-between'}>
                      <Col span={dynamicEnable ? 10 : 24}>
                        <div
                          id='quilldiv'
                          onDrop={dropHandler}
                          onDragOver={e => e.preventDefault()}
                        >
                          <Form.Item
                            // label='Phrase'
                            name='phrase'
                            rules={[
                              { required: true, message: 'Phrase is required' }
                            ]}
                          >
                            <ReactQuill
                              style={dynamicStyle}
                              modules={modules}
                              ref={quillRef}
                              theme='snow'
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      {dynamicEnable ? (
                        <>
                          <Col span={8}>
                            <StyledTagContainer>
                              {dynamicEndPoints &&
                                dynamicEndPoints
                                  .find(
                                    endpoint =>
                                      endpoint.fieldUiValue ===
                                      selectedDynamicEndPoint
                                  )
                                  ?.avilableFields.map(dynamicFields => {
                                    return (
                                      <StyledTag
                                        key={dynamicFields}
                                        draggable='true'
                                        onDragStart={dragStartHandler}
                                      >
                                        <DragIndicatorIcon color={'#000000'} />
                                        {dynamicFields}
                                      </StyledTag>
                                    );
                                  })}
                            </StyledTagContainer>
                          </Col>
                        </>
                      ) : null}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <RestrictColumImport span={10}>
            <div className='configurationHead'>Configuration</div>
            <div
              className='RestrictColum'
              style={{
                backgroundColor: '#F5F7FA',
                padding: '20px',
                borderRadius: '8px',
                marginBottom: '15px'
              }}
            >
              <Row gutter={16} style={{ marginBottom: '-60px' }}>
                <Col span={20}>
                  <Form.Item label={<CustomLabel />}></Form.Item>
                </Col>

                <Form.Item name='active'>{}</Form.Item>

                <Col span={4}>
                  <Form.Item
                    name='global'
                    style={{ marginBottom: 0, marginRight: '0px' }}
                  >
                    <Switch
                      checked={globallyEnable}
                      onChange={() => {
                        form.setFieldValue({
                          ...form.getFieldsValue(),
                          global: !globallyEnable
                        });
                        setGloballyEnable(!globallyEnable);
                      }}
                      disabled={!(selectedItem ? selectedItem.editable : true)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <div
              className='RestrictColum'
              style={{
                backgroundColor: '#F5F7FA',
                padding: '20px',
                borderRadius: '8px',
                maxHeight: '270px',
                overflow: 'scroll'
              }}
            >
              <Row gutter={16}>
                <Col span={20}>
                  <Form.Item label={<CustomLabel2 />}></Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name='restrictedToTemplates'
                    style={{ marginBottom: 0, marginRight: '0px' }}
                  >
                    <Switch
                      checked={treeVisible}
                      onChange={newValue => toggleTreeVisibility(newValue)}
                      disabled={!(selectedItem ? selectedItem.editable : true)}
                    />
                  </Form.Item>
                </Col>
                {loadingTree ? (
                  <Spinner height={'3vh'} />
                ) : (
                  treeVisible && (
                    <Col span={24}>
                      <Form.Item name='templateMapping'>
                        <StyledTree
                          checkable
                          selectable={false}
                          onCheck={handleCheck}
                          switcherIcon={<DownOutlined />}
                          autoExpandParent
                          expandedKeys={[...expandedFields]}
                          onExpand={onExpand}
                          checkedKeys={selectedFields.map(selectedField => {
                            return selectedField.id;
                          })}
                          treeData={k2pMapping?.map(item => {
                            return {
                              title: item.templateName,
                              key: item.id,

                              children: item?.sectionHeadings?.map(subItem => {
                                return {
                                  title: subItem.customUIHeading,
                                  key: subItem.id,
                                  switcherIcon: (
                                    <span className='dot-switcher' />
                                  )
                                };
                              })
                            };
                          })}
                        />
                      </Form.Item>
                    </Col>
                  )
                )}
              </Row>
            </div>
            {isVisible && (selectedItem ? selectedItem.editable : true) && (
              <Row style={{ marginBottom: '-60px' }}>
                <Col span={24}>
                  <Form.Item
                    name='exportToOtherAuthors'
                    style={{ marginBottom: 0, marginRight: '0px' }}
                    className='custom-checkbox' // Add this class to apply custom styles
                  >
                    <Checkbox
                      checked={exportToOtherAuthors}
                      onChange={() => {
                        setSubmitDisabled(true);
                        form.setFieldsValue({
                          ...form.getFieldsValue(),
                          exportToOtherAuthors: !exportToOtherAuthors
                        });
                        setExportToOtherAuthors(!exportToOtherAuthors);
                      }}
                    >
                      Activate for all users
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </RestrictColumImport>

          <Divider></Divider>
        </Row>

        <Row style={{ marginTop: '-49px' }}>
          {selectedItem === null ? (
            <SubmitButtonCol span={24}>
              <SubmitButton
                htmlType='submit'
                type='primary'
                size='large'
                shape='round'
                loading={submitLoading}
                onClick={handleSubmit}
                style={{ marginTop: '60px', marginRight: '12px' }}
              >
                Submit
              </SubmitButton>
            </SubmitButtonCol>
          ) : selectedItemColumn === 'Active' ? (
            <SubmitButtonCol span={24}>
              <>
                <Button
                  type='primary'
                  size='large'
                  shape='round'
                  onClick={handleMineDelete}
                  style={{ marginTop: '60px', marginRight: '12px' }} // Added marginRight to create space between buttons
                >
                  Delete
                </Button>
                {selectedItem.editable && (
                  <SubmitButton
                    htmlType='submit'
                    type='primary'
                    size='large'
                    shape='round'
                    disabled={!submitDisabled}
                    loading={submitLoading}
                    onClick={handleSubmit}
                    style={{ marginTop: '60px', marginRight: '12px' }}
                  >
                    Submit
                  </SubmitButton>
                )}
                <AddButton
                  type='primary'
                  size='large'
                  shape='round'
                  onClick={e => handleRemoveFromSelected(e, selectedItem.id)}
                  style={{ marginTop: '60px' }}
                >
                  Remove from Active List
                </AddButton>
              </>
            </SubmitButtonCol>
          ) : selectedItemColumn === 'Mine' ? (
            <SubmitButtonCol span={24}>
              <>
                <Button
                  type='primary'
                  size='large'
                  shape='round'
                  onClick={handleMineDelete}
                  style={{ marginTop: '60px', marginRight: '12px' }} // Added marginRight to create space between buttons
                >
                  Delete
                </Button>
                <AddButton
                  htmlType='submit'
                  type='primary'
                  size='large'
                  disabled={!submitDisabled}
                  shape='round'
                  onClick={handleSubmit}
                  style={{ marginTop: '60px', marginRight: '12px' }} // Added marginRight to create space between buttons
                >
                  Save
                </AddButton>
                <AddButton
                  type='primary'
                  size='large'
                  shape='round'
                  onClick={activeEdit}
                  style={{ marginTop: '60px' }}
                >
                  Add to Active List
                </AddButton>
              </>
            </SubmitButtonCol>
          ) : null}
        </Row>
      </Form>
      <ConfirmationModal
        antdModalProps={{ centered: true, width: '500', closable: false }}
        visible={endpointChangeModalVisible}
        onCancel={handleEndPointChangeCancel}
        onOk={handleEndPointChangeConfirm}
        header={
          <CustomTypography color={theme['@text-color']}>
            Are you sure?
          </CustomTypography>
        }
        confirmation={true}
        buttonAlignCenter={true}
      >
        <h1>{`Do you really want to change Dynamic Category ?`}</h1>
        <Alert
          message={<b>Warning</b>}
          description='The tags associated with phrase will be removed.'
          type='warning'
          showIcon
          closable={false}
        />
      </ConfirmationModal>

      <Keytophraseduplication
        title='Save Changes'
        visible={submitDisabled && toActive}
        onCancel={toggleActive}
        footer={[
          <Button key='save' type='primary' onClick={handleModalOk}>
            Ok
          </Button> // Button to save changes
        ]}
      >
        <DeleteConfirmationMessage>
          Please save the changes to proceed?
        </DeleteConfirmationMessage>
      </Keytophraseduplication>
      <ConfirmationModal
        visible={deleteModal}
        onOk={handleDeleteClick}
        onCancel={toggleActive}
        // maskStyle={{ backgroundColor: 'transparent' }}

        centered
        // loading={deleteLoading}
        confirmation={true}
        antdModalProps={{ closable: false, mask: false }}
      >
        <DeleteConfirmationMessage>
          Are you sure you want to delete?
        </DeleteConfirmationMessage>
      </ConfirmationModal>
    </>
  );
};

export default AddKeyToPhraseForm;
