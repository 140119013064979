import React from "react";

function MoreHorizIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
      <path d="M9.45 26.85q-1.15 0-2-.825-.85-.825-.85-1.975 0-1.25.85-2.075.85-.825 2-.825t2.05.825q.9.825.9 1.975 0 1.25-.9 2.075-.9.825-2.05.825Zm14.6 0q-1.25 0-2.075-.825-.825-.825-.825-1.975 0-1.25.825-2.075.825-.825 1.975-.825 1.25 0 2.075.825.825.825.825 1.975 0 1.25-.825 2.075-.825.825-1.975.825Zm14.5 0q-1.25 0-2.1-.825-.85-.825-.85-1.975 0-1.25.875-2.075.875-.825 2.075-.825 1.2 0 2.05.825.85.825.85 1.975 0 1.25-.85 2.075-.85.825-2.05.825Z"/>
    </svg>
  );
}

export default MoreHorizIcon;
