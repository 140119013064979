import React from "react";

function ReadyJobsIcon({size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ? size : "16"}
      height={size ? size : "16"}
      fill="none"
      viewBox="0 0 33 31"
    >
      <path
        fill="#7582E5"
        d="M29.014 8.219V4.865a.967.967 0 00-.967-.967h-8.703v-2.9a.967.967 0 00-.967-.967h-7.74a.967.967 0 00-.967.967v2.9H.967A.967.967 0 000 4.865V27.65c0 .534.433.967.967.967h22.845a2.906 2.906 0 002.848 2.353c1.6 0 2.902-1.301 2.902-2.9V19.76c4.83-2.476 4.502-9.536-.548-11.542zm-17.41-6.254h5.806v1.933h-5.806V1.965zm-9.67 3.867H27.08v1.952a6.336 6.336 0 00-6.742 5.782H3.547a1.613 1.613 0 01-1.613-1.61V5.831zM13.54 15.5h1.934v1.934H13.54V15.5zm-11.606-.389a3.525 3.525 0 001.612.389h8.06v2.9c0 .534.432.967.966.967h3.869a.967.967 0 00.967-.967v-2.9h3.058a6.325 6.325 0 003.293 4.26v6.923H1.934V15.11zM26.66 29.035a.968.968 0 01-.967-.967v-3.864h1.934v3.864a.968.968 0 01-.967.967zm.967-6.764h-1.934v-1.886c.63.097 1.265.103 1.934 0v1.886zm-.967-3.742a4.413 4.413 0 01-4.416-4.415A4.413 4.413 0 0126.66 9.7a4.413 4.413 0 014.417 4.415 4.413 4.413 0 01-4.417 4.415z"
      ></path>
    </svg>
  );
}

export default ReadyJobsIcon;
