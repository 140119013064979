import React from 'react';

function SaveEditorIcon({ fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 170 170"
    >
      <g fill={fill ? fill : '#525e6f'}>
        <path d="M162.91 44.305c-.033-.035-42.542-42.875-42.506-42.839A4.99 4.99 0 00116.875 0H15.605c-5.492 0-9.96 4.468-9.96 9.96V160.04c0 5.493 4.468 9.961 9.96 9.961h138.79c5.492 0 9.96-4.468 9.96-9.961V47.813a4.979 4.979 0 00-1.445-3.508zM90.644 9.96v21.914a4.98 4.98 0 009.961 0V9.961h11.29v38.184h-53.79V9.96h32.54zm63.751 150.078H15.605V9.961h32.54v43.164a4.98 4.98 0 004.98 4.98h63.75a4.98 4.98 0 004.98-4.98V17.071l32.54 32.793V160.04z"></path>
        <path d="M31.875 80.02a4.98 4.98 0 00-4.98 4.98v58.438a4.98 4.98 0 004.98 4.98h106.25a4.98 4.98 0 004.98-4.98V85a4.98 4.98 0 00-4.98-4.98H31.875zm101.27 58.437h-96.29V89.981h96.29v48.476z"></path>
        <path d="M53.125 111.23h15.938a4.98 4.98 0 100-9.96H53.124a4.98 4.98 0 100 9.96z"></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H170V170H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default SaveEditorIcon;
