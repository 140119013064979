import React, { useEffect, useState, useCallback } from 'react';
import { useDebounce } from '../../../Utils/Debounce';
import { useSelector } from 'react-redux';
import PageTitle from '../../../Utils/PageTitle';
import { FacilityCardsContainer, PaginationContainer } from './styledComponents';
import { ContainerRow, TitleRow } from '../styledComponents';
import { StyledPagination } from '../Hl7/styledComponents';
import { SearchInputStyle } from './styledComponents';
import CustomTypography from '../../CustomComponents/CustomTypography';
import { getFacilites } from '../../../Apis/Facility';
import { notification } from 'antd';
import FacilityCard from './FacilityCard';
import { PlusOutlined } from '@ant-design/icons';
import { AddButton } from '../Dictation/styledComponents';
import { useHistory } from 'react-router-dom';
import Spinner from '../../CustomComponents/Spinner';
import { SearchOutlined } from '@ant-design/icons';
import { SEARCH_DELAY } from '../../../Constants/StringConstants';
import BreadCrumbComponent from '../../CustomComponents/BreadCrumbComponent';
import { Button, Tooltip } from 'antd';
import {
  LeftOutlined,
  RightOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined
} from '@ant-design/icons';

const Facility = () => {
  const [theme] = useSelector(state => [state.themeReducer]);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [facilities, setFacilities] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [currentPage, setCurrentPage] = useState(0);

  const RESULTS_PER_PAGE = 12;

  const fetchAll = pageNo => {
    setLoading(true);
    getFacilites(pageNo, searchString, RESULTS_PER_PAGE)
      .then(response => {
        setLoading(false);
        if (response.data.success) {
          setTotal(response.data.size);
          setFacilities(response.data.result);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };
  const onChangeHandler = value => {
    setCurrentPage(0);
    setSearchString(value);
  };

  const debounceOnChange = useCallback(
    useDebounce(onChangeHandler, SEARCH_DELAY),
    []
  );

  const pageNoChangeHandler = pageNo => {
    fetchAll(pageNo === 0 ? 0 : pageNo - 1);
  };


  const itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return (
        <>
          <Tooltip title='First Page'>
            <Button
              type='text'
              icon={<DoubleLeftOutlined />}
              onClick={e => {
                e.stopPropagation();
                setCurrentPage(0);
                pageNoChangeHandler(0);
              }}
            />
          </Tooltip>
          <Tooltip title='Previous Page'>
            <Button type='text' icon={<LeftOutlined />} />
          </Tooltip>
        </>
      );
    }
    if (type === 'next') {
      return (
        <>
          <Tooltip title='Next Page'>
            <Button type='text' icon={<RightOutlined />} />
          </Tooltip>
          <Tooltip title='Last Page'>
            <Button
              type='text'
              icon={<DoubleRightOutlined />}
              onClick={e => {
                e.stopPropagation();
                setCurrentPage(Math.floor((total / RESULTS_PER_PAGE) + 1));
                pageNoChangeHandler(Math.floor((total / RESULTS_PER_PAGE) + 1));
              }}
            />
          </Tooltip>
        </>
      );
    }
    return originalElement;
  };

  useEffect(() => {
    fetchAll(0);
    // eslint-disable-next-line
  }, [searchString]);

  let BreadCrubItems = [
    {
      name: 'Settings',
      path: '/dashboard/settings'
    },
    {
      name: 'Facility'
    }
  ];

  return (
    <>
      <PageTitle title='Facility' />
      <ContainerRow gutter={[0, 40]}>
        <TitleRow justify='space-between'>
          <CustomTypography color={theme['@text-color']}>
            Facility
          </CustomTypography>
          <div>
            <AddButton
              icon={<PlusOutlined />}
              type='primary'
              shape='round'
              onClick={() => history.push('/dashboard/facility/new')}
            >
              Add
            </AddButton>
            <SearchInputStyle
              placeholder='Search'
              prefix={<SearchOutlined />}
              onChange={event => debounceOnChange(event.target.value)}
            />
          </div>
        </TitleRow>
        <BreadCrumbComponent items={BreadCrubItems} />
        {loading ? (
          <Spinner />
        ) : facilities.length > 0 ? (
            <FacilityCardsContainer>
              {facilities.map(facility => (
                <FacilityCard facility={facility} key={facility.facilityId} />
              ))}
            </FacilityCardsContainer>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '250px'
            }}
          >
            {' '}
            <h1>No facilities added</h1>
          </div>
        )}

            <PaginationContainer>
              <StyledPagination
                setPageNo={current => {
                  setCurrentPage(current);
                }}
                pageNo={currentPage}
                onChange={pageNoChangeHandler}
                pageSize={RESULTS_PER_PAGE}
                total={total}
                itemRender={itemRender}
              />
            </PaginationContainer>
      </ContainerRow>

    </>
  );
};

export default Facility;
