import React from 'react';

function EnableUserIcon({ disabled }) {
  return (
    <span className='anticon'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        height={16}
        width={16}
        viewBox='0 0 9 9'
      >
        <path
          fill={disabled ? '#d9d9d9' : '#3f434a'}
          d='M7.546 8.746L7.09 8.29a3.28 3.28 0 00.532-.923c.138-.355.206-.742.206-1.162 0-.478-.1-.932-.298-1.363-.2-.43-.498-.809-.896-1.135l.478-.477c.42.362.753.798.999 1.308s.369 1.066.369 1.667a3.85 3.85 0 01-.934 2.54zm-5.95 0a3.85 3.85 0 01-.934-2.54c0-1.087.38-2.008 1.14-2.765.76-.756 1.683-1.134 2.769-1.134a4.68 4.68 0 01.423.021l-.88-.879.457-.456L6.308 2.73 4.571 4.468 4.104 4l.999-1a4.044 4.044 0 00-.532-.043c-.905 0-1.674.315-2.307.945-.634.63-.95 1.397-.95 2.302 0 .37.061.738.184 1.107.123.37.311.692.565.967l-.467.467z'
        ></path>
        <path
          fill={disabled ? '#d9d9d9' : '#3f434a'}
          d='M6.548 5.449L4.11 7.887 2.591 6.358l.47-.47 1.081 1.08L6.1 5.01l.45.438z'
        ></path>
      </svg>
    </span>
  );
}

export default EnableUserIcon;
