import React, { useCallback, useEffect, useState } from 'react';
import PageTitle from '../../../../Utils/PageTitle';
import CustomTypography from '../../../CustomComponents/CustomTypography';
import {
  AddButton,
  ContainerRow,
  SearchInputStyle,
  TitleRow
} from '../../styledComponents';
import { useSelector } from 'react-redux';
import { SearchOutlined, CaretDownFilled } from '@ant-design/icons';
import BreadCrumbComponent from '../../../CustomComponents/BreadCrumbComponent';
import CustomTable from '../../../CustomComponents/Table';
import { columns } from './tableConfig';
import { fetchPaymentLogs, fetchStatement } from '../../../../Apis/Payment';
import {
  RESULTS_PER_PAGE,
  SEARCH_DELAY
} from '../../../../Constants/StringConstants';
import { Dropdown, notification, Menu } from 'antd';
import { useDebounce } from '../../../../Utils/Debounce';
import { InnerButtonDiv, IconDiv, StatusMenuItem } from './styledComponents';
import TransactionDetails from './TransactionDetails';
import { getUserFacilities } from '../../../../Apis/User';
import { StyledSelect } from './styledComponents';

const PaymentLog = () => {
  const [theme] = useSelector(state => [state.themeReducer]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [paymentData, setPaymentData] = useState([]);
  const [filterKey, setFilterKey] = useState('submitTimeLocal');
  const [sortOrder, setSortOrder] = useState('');
  const [transactionStatus, setTransactionStatus] = useState('');
  const [searchString, setSearchString] = useState('');
  const [statementLoading, setStatementLoading] = useState(false);
  const [statementModal, setStatementModal] = useState(false);
  const [statementData, setStatementData] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [facilities, setFacilities] = useState([]);
  const [facilityIds, setFacilityIds] = useState();
  const { Option } = StyledSelect;

  const filterTableChange = (pagination, filters, sorter) => {
    if (sorter.field !== undefined)
      setFilterKey(sorter.field && sorter.field[0]);
    if (sorter.order !== undefined) setSortOrder(sorter.order);
  };

  useEffect(() => {
    if (facilityIds) {
      fetchAll(0);
      setCurrentPage(0);
    }

    // eslint-disable-next-line
  }, [filterKey, searchString, sortOrder, facilityIds]);

  const filterChangeHandler = filter => {
    if (filter === transactionStatus) {
      setTransactionStatus('');
      setCurrentPage(0);
      fetchAll(0, '');
    } else {
      setTransactionStatus(filter);
      setCurrentPage(0);
      fetchAll(0, filter);
    }
  };

  const fetchAll = (pageNo, filter) => {
    setLoading(true);
    fetchPaymentLogs(
      facilityIds,
      searchString,
      RESULTS_PER_PAGE,
      pageNo,
      filterKey,
      sortOrder,
      filter === '' || (filter && filter.length > 0)
        ? filter
        : transactionStatus
    )
      .then(response => {
        if (response.data.success) {
          setTotal(response.data.size);
          setPaymentData(response.data.result);
          setLoading(false);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
          setLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const onChangeHandler = value => {
    setSearchString(value);
    setCurrentPage(0);
  };

  const pageNoChangeHandler = pageNo => {
    fetchAll(pageNo);
  };

  const debounceOnChange = useCallback(
    useDebounce(onChangeHandler, SEARCH_DELAY)
  );

  let BreadCrubItems = [
    {
      name: 'Billing',
      path: '/dashboard/billing'
    },
    {
      name: 'Manage Payment Logs',
      path: '/dashboard/billing/payment-logs'
    }
  ];

  const statementClickHandler = transactionId => {
    setStatementLoading(true);
    setStatementModal(true);
    fetchStatement(transactionId)
      .then(response => {
        if (response.data.success) {
          setStatementData(response.data.result);
          setStatementLoading(false);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
          setStatementLoading(false);
          setStatementModal(false);
        }
      })
      .catch(err => {
        console.log(err);
        setStatementLoading(false);
        setStatementModal(false);
      });
  };

  const onRow = (record, index) => {
    return {
      onClick: event => {
        setSelectedRecord(record);
        statementClickHandler(record.transId);
      }
    };
  };
  const getFacilities = () => {
    setLoading(true);
    getUserFacilities().then(response => {
      if (response.data.success) {
        setFacilities(response.data.result);
        setFacilityIds(response.data.result[0]?.facilityId);
        setLoading(false);
      } else {
        notification['error']({
          message: 'User Facilities Fetch Failed',
          description: response.data.message
        });
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    getFacilities();
  }, []);

  const menu = () => {
    return (
      <Menu onClick={({ key }) => filterChangeHandler(key)}>
        <StatusMenuItem
          key='Success'
          background={transactionStatus === 'Success' && true}
        >
          Success
        </StatusMenuItem>
        <StatusMenuItem
          key='Processing'
          background={transactionStatus === 'Processing' && true}
        >
          Processing
        </StatusMenuItem>
        <StatusMenuItem
          key='Failed'
          background={transactionStatus === 'Failed' && true}
        >
          Failed
        </StatusMenuItem>
        <StatusMenuItem
          key='Refund_processing'
          background={transactionStatus === "'Refund_processing'" && true}
        >
          Refund Processing
        </StatusMenuItem>
      </Menu>
    );
  };

  return (
    <>
      <PageTitle title='Payment Logs' />
      <ContainerRow gutter={[0, 40]}>
        <TitleRow justify='space-between'>
          <CustomTypography color={theme['@text-color']}>
            Payment Logs
          </CustomTypography>
          <div>
            <Dropdown overlay={menu} placement='bottomLeft' trigger='click'>
              <AddButton>
                <InnerButtonDiv>
                  <div>
                    Filter : &nbsp;
                    {transactionStatus === '' ? 'None' : transactionStatus}
                  </div>
                  <IconDiv>
                    <CaretDownFilled />
                  </IconDiv>
                </InnerButtonDiv>
              </AddButton>
            </Dropdown>
            <SearchInputStyle
              placeholder='Search'
              prefix={<SearchOutlined />}
              onChange={event => debounceOnChange(event.target.value)}
            />

            <StyledSelect
              value={facilityIds}
              placeholder='Filter'
              onSelect={setFacilityIds}
            >
              {facilities.length > 0 &&
                facilities.map(item => {
                  return (
                    <Option key={item.facilityId} value={item.facilityId}>
                      <div style={{ opacity: item.disabled ? 0.5 : 1 }}>
                        {item.facilityName}
                      </div>
                    </Option>
                  );
                })}
            </StyledSelect>
          </div>
        </TitleRow>
        <BreadCrumbComponent items={BreadCrubItems} />
        <CustomTable
          theme={theme}
          columns={columns()}
          dataSource={paymentData}
          pageNoChangeHandler={pageNoChangeHandler}
          loading={loading}
          setPageNo={current => {
            setCurrentPage(current);
          }}
          pageNo={currentPage}
          pageSize={RESULTS_PER_PAGE}
          total={total}
          onChange={filterTableChange}
          payment={true}
          onRow={onRow}
          hover={true}
        />
      </ContainerRow>
      <TransactionDetails
        visible={statementModal}
        setVisible={setStatementModal}
        TranscriptionDetails={statementData}
        theme={theme}
        loading={statementLoading}
        record={selectedRecord}
        footer={null}
        payment={true}
      />
    </>
  );
};

export default PaymentLog;
