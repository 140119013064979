import React from 'react';
import 'antd/dist/antd.less';
import './App.css';
import Content from './Components/Content';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect
} from 'react-router-dom';
import Login from './Components/Login';

function App() {
  return (
    <Router>
      <Switch>
        {/* <Route path="/dashboard" component={Content} /> */}
        <Route path='/login' render={props => <Login />} />
        <Route path='/signup' render={props => <Login signup={true} />} />
        <Route
          path='/forgot-password'
          render={props => <Login type='FORGOT' />}
        />
        <Route
          path='/reset-password/:token'
          render={props => <Login type='RESET' />}
        />
        <Route
          path='/otp-verification'
          render={props => <Login type='OTP' />}
        />
        <Route path='/' render={() => <Content />} />
        <Redirect to='/login' />
      </Switch>
    </Router>
  );
}

export default App;
