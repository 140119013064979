import React from 'react';

function BillingsIcon() {
  return (
    <span className='anticon'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        x='0'
        y='0'
        enableBackground='new 0 0 512.001 512.001'
        version='1.1'
        viewBox='0 0 512.001 512.001'
        xmlSpace='preserve'
        fill='#666'
      >
        <path d='M266.712 176.733h-21.424c-9.447 0-17.133-7.686-17.133-17.134 0-9.447 7.686-17.133 17.133-17.133h42.844c8.284 0 15-6.716 15-15s-6.716-15-15-15h-17.134V95.334c0-8.284-6.716-15-15-15s-15 6.716-15 15v17.337c-23.985 2.175-42.843 22.387-42.843 46.929 0 25.99 21.144 47.134 47.133 47.134h21.424c9.447 0 17.133 7.686 17.133 17.133S276.16 241 266.712 241h-42.847c-8.284 0-15 6.716-15 15s6.716 15 15 15h17.133v17.133c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15v-17.337c23.987-2.174 42.847-22.386 42.847-46.93 0-25.989-21.143-47.133-47.133-47.133zM336.333 353.467H175.667c-8.284 0-15 6.716-15 15s6.716 15 15 15h160.666c8.284 0 15-6.716 15-15s-6.716-15-15-15z'></path>
        <path d='M432.733 0H79.267c-8.284 0-15 6.716-15 15v437.816c0 3.978 1.581 7.793 4.394 10.607l44.184 44.184c5.857 5.858 15.355 5.858 21.213 0l33.577-33.577 33.576 33.577a15 15 0 0021.214 0L256 474.03l33.577 33.577a15 15 0 0021.213 0l33.576-33.577 33.577 33.577c2.929 2.929 6.767 4.394 10.606 4.394s7.678-1.464 10.607-4.394l44.184-44.184a15.002 15.002 0 004.394-10.607V15c-.001-8.284-6.716-15-15.001-15zm-44.184 475.787l-33.577-33.577a15.002 15.002 0 00-21.214 0l-33.576 33.577-33.577-33.577c-5.857-5.858-15.355-5.858-21.213 0l-33.577 33.577-33.576-33.577a15 15 0 00-21.213 0l-33.577 33.577-29.184-29.184V30h323.467v416.603h.001l-29.184 29.184z'></path>
      </svg>
    </span>
  );
}

export default BillingsIcon;
