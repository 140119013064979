import React, { useEffect, useState } from 'react';
import { Row, Form, Input, Col, Tag, Checkbox } from 'antd';
import {
  SubmitButton,
  SubmitButtonCol,
  PhoneNumberCol
} from '../styledComponents.js';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { NAME_REGEX_CHECK } from '../../../Constants/StringConstants.js';
import { StyledSelectFacility } from './styledComponents.js';
import CancelIcon from '../../../Assets/Icons/CancelIcon.js';

const AddBillersForm = ({ selectedItem, submitHandler, submitLoading }) => {
  const [form] = Form.useForm();
  const [Updated, setUpdated] = useState({ phone: false, other: false });
  const [changedValues, setchangedValues] = useState();
  useEffect(() => {
    if (selectedItem) {
      const data = selectedItem;
      if (data.phoneNumber?.number) {
        data.phoneNumber.number = data.phoneNumber.countryCode.concat(
          data.phoneNumber.number
        );
      } else {
        data.phoneNumber = '';
      }
      data.facilityIds = data.facilities.map(item => item.facilityId);
      form.setFieldsValue({ ...data });
      setchangedValues({ ...data });
    }
    // eslint-disable-next-line
  }, [selectedItem]);

  const phoneNumberChanged = (value, country, e, formattedValue) => {
    let countryCode = country.dialCode;
    form.setFieldsValue({
      phoneNumber: {
        countryCode: countryCode
      }
    });
    let changedPHCode =
      String(selectedItem?.phoneNumber?.countryCode) !==
      String(country.dialCode);
    let changedPH =
      String(selectedItem?.phoneNumber?.number).slice(0, 11) !== String(value);

    setUpdated({ ...Updated, phone: changedPHCode || changedPH });
  };

  const tagRender = props => {
    const { label, disabled, onClose } = props;
    const onPreventMouseDown = event => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        className={`ant-select-selection-item ${
          disabled ? `disabled-facility-tag` : ``
        }`}
        onMouseDown={onPreventMouseDown}
        closable={true}
        onClose={onClose}
        closeIcon={
          <div className='ant-select-selection-item-remove'>
            <CancelIcon height={12} width={12} />
          </div>
        }
      >
        <p className='ant-select-selection-item-label'>{label}</p>
      </Tag>
    );
  };

  const onvalueUpdate = values => {
    if (!Boolean(values?.phoneNumber)) {
      setchangedValues({ ...changedValues, ...values });
      setUpdated({
        ...Updated,
        other:
          JSON.stringify({ ...changedValues, ...values }) !==
          JSON.stringify(selectedItem)
      });
    }
  };
  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={values => submitHandler(values)}
      onValuesChange={values => onvalueUpdate(values)}
    >
      <Row justify='center'>
        <Col span={24}>
          <Form.Item
            label='First Name'
            name='firstName'
            rules={[
              { required: true, message: 'First Name is required' },
              {
                pattern: new RegExp(NAME_REGEX_CHECK),
                message: 'Please enter only alphabets'
              }
            ]}
          >
            <Input size='large' />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label='Last Name'
            name='lastName'
            rules={[
              { required: true, message: 'Last Name is required' },
              {
                pattern: new RegExp(NAME_REGEX_CHECK),
                message: 'Please enter only alphabets'
              }
            ]}
          >
            <Input size='large' />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label='Email'
            name='email'
            rules={[
              { required: true, message: 'Email is required' },
              {
                type: 'email',
                message: 'Please enter valid email'
              }
            ]}
          >
            <Input size='large' />
          </Form.Item>
        </Col>
        <PhoneNumberCol span={24}>
          <Form.Item noStyle>
            <Form.Item
              name={['phoneNumber', 'countryCode']}
              noStyle
            ></Form.Item>
            <Form.Item
              label='Phone Number'
              name={['phoneNumber', 'number']}
              rules={[{ required: true, message: 'Phone Number is required' }]}
            >
              <PhoneInput country={'us'} onChange={phoneNumberChanged} />
            </Form.Item>
          </Form.Item>
        </PhoneNumberCol>
        {/* <Col span={24}>
          <Form.Item
            name='isFacilityAccessAllowed'
            valuePropName='checked'
            initialValue={selectedItem?.isFacilityAccessEnabled}
          >
            <Checkbox>Facility Access</Checkbox>
          </Form.Item>
        </Col> */}
        <SubmitButtonCol span={24}>
          <SubmitButton
            htmlType='submit'
            type='primary'
            size='large'
            shape='round'
            disabled={
              !(Updated.phone || Updated.other) && Boolean(selectedItem)
            }
            loading={submitLoading}
          >
            Submit
          </SubmitButton>
        </SubmitButtonCol>
      </Row>
    </Form>
  );
};

export default AddBillersForm;
