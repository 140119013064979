import axiosInstance from '../Config/AxiosInstance';

const PREFIX = '/clinic/authors';
export const saveCannedText = data => {
  return axiosInstance.post(PREFIX + `/canned-text`, data);
};

export const getCannedtextCollections = (page, size, type, search) => {
  return axiosInstance.get(PREFIX + `/canned-text`, {
    params: { page, size, type, search }
  });
};
export const targetEndPoints = () => {
  return axiosInstance.get(PREFIX + '/canned-text/target-end-points');
};

export const saveCannedTextActiveList = cannedTextIds => {
  return axiosInstance.put(
    PREFIX + `/canned-text/active?cannedTextIds=${cannedTextIds}`
  );
};

export const removeCannedTextActiveList = cannedTextIds => {
  return axiosInstance.put(
    PREFIX + `/canned-text/in-active?cannedTextIds=${cannedTextIds}`
  );
};
export const editCannedText = data => {
  return axiosInstance.put(PREFIX + `/canned-text`, data);
};
export const authorsCollectionToMyCollection = (
  cannedTextIds,
  overRideExisting
) => {
  return axiosInstance.post(
    PREFIX +
      `/canned-text/import?cannedTextIds=${cannedTextIds}&overRideExisting=${overRideExisting}`
  );
};
export const removeCannedTexts = cannedTextIds => {
  return axiosInstance.delete(
    PREFIX + `/canned-text?cannedTextIds=${cannedTextIds}`
  );
};

export const saveDictationTemplate = data => {
  return axiosInstance.post(PREFIX + `/section-template`, data);
};
export const myCollectionToActive = templateIds => {
  return axiosInstance.put(
    PREFIX + `/section-template/active?templateIds=${templateIds}`
  );
};
export const removeTemplatesFromActiveList = templateIds => {
  return axiosInstance.put(
    PREFIX + `/section-template/in-active?templateIds=${templateIds}`
  );
};
export const editDictationTemplate = data => {
  return axiosInstance.put(PREFIX + `/section-template`, data);
};
// export const listDictationTemplate = (type, page, size, search) => {
//   return axiosInstance.get(
//     PREFIX +
//       `/section-template?
//       ${type ? `type=${type}` : ''}
//       ${page ? `page=${page}` : ''}
//       ${size ? `&size=${size}` : ''}
//       ${search ? `&search=${search}` : ''}`
//   );
// };
export const listDictationTemplate = (type, page, size, search) => {
  let url = PREFIX + '/section-template?';

  if (type) url += `type=${type}&`;
  if (page) url += `page=${page}&`;
  if (size) url += `size=${size}&`;
  if (search) url += `search=${search}`;

  // Remove the trailing '&' if it exists
  if (url.endsWith('&')) {
    url = url.slice(0, -1);
  }

  return axiosInstance.get(url);
};
export const deleteDictationTemplateedit = id => {
  return axiosInstance.delete(PREFIX + `/section-template/${id}`);
};
export const removeDictationTemplate = id => {
  return axiosInstance.delete(PREFIX + `/section-template?templateIds=${id}`);
};
export const authorsCollectionToMyCollectionDict = (
  cannedTextIds,
  overRideExisting
) => {
  return axiosInstance.post(
    PREFIX +
      `/section-template/import?overRideExisting=${overRideExisting}&templateIds=${cannedTextIds}`
  );
};
export const getSupportedSections = () => {
  return axiosInstance.get(PREFIX + `/section-template/system-headings`);
};
export const templateMappingStatus = () => {
  return axiosInstance.get(PREFIX + `/canned-text/template-mapping`);
};
export const getListOfCannedText = (
  heading = 'Default',
  page = 0,
  size = 10,
  templateId = 'None'
) => {
  const url = `/clinic/authors/canned-text/template-specific`;
  const params = {
    heading,
    page,
    size,
    templateId
  };

  return axiosInstance.get(url, { params });
};
