import axiosInstance from '../Config/AxiosInstance';

const PREFIX = `/clinic/dictionary`;
// export const globalCannedText = (pageNo, pageSize, search) => {
//   return axiosInstance.get(
//     PREFIX +
//       `/canned-texts-dictionary?${pageNo ? `page=${pageNo}` : ''}${
//         pageSize ? `&size=${pageSize}` : ''
//       }${search ? `&search=${search}` : ''}`
//   );
// };
export const saveDictionaryCannedText = data => {
  return axiosInstance.post(PREFIX + `/canned-texts-dictionary`, data);
};

export const editDictionaryCannedText = data => {
  return axiosInstance.put(PREFIX + `/canned-texts-dictionary`, data);
};

export const deleteDictionaryCannedText = id => {
  if (Array.isArray(id)) {
    // id is an array, safe to use join method
    return axiosInstance.delete(
      PREFIX + `/canned-texts-dictionary?dictionaryIds=${id.join(',')}`
    );
  } else {
    return axiosInstance.delete(
      PREFIX + `/canned-texts-dictionary?dictionaryIds=${id}`
    );
  }
};
export const deleteMultipleDictionaryCannedTexts = id => {
  // Assuming ids is an array of dictionary IDs to be deleted

  if (Array.isArray(id)) {
    // id is an array, safe to use join method
    return axiosInstance.delete(
      PREFIX + `/canned-texts-dictionary?dictionaryIds=${id.join(',')}`
    );
  } else {
    return axiosInstance.delete(
      PREFIX + `/canned-texts-dictionary?dictionaryIds=${id}`
    );
  }
};
// export const fetchAllDictionaryCannedText = (pageNo, pageSize, search, showTable) => {
//    return axiosInstance.get(PREFIX + '/canned-texts-dictionary?' + params.toString());
// };

export const fetchAllDictionaryCannedText = (page, size, search) => {
  return axiosInstance.get(
    PREFIX +
      `/canned-texts-dictionary?${page ? `page=${page}` : ''}${
        size ? `&size=${size}` : ''
      }${search ? `&search=${search}` : ''}`
  );
};
export const dictationTemplatesDictionary = data => {
  return axiosInstance.post(PREFIX + `/dictation-templates-dictionary`, data);
};
// export const saveDictationTemplate = data => {
//   return axiosInstance.post(PREFIX + `/section-template`, data);
// };
export const editDictationTemplateDictionary = data => {
  return axiosInstance.put(PREFIX + `/dictation-templates-dictionary`, data);
};
export const listDictationTemplate = (page, size, search) => {
  return axiosInstance.get(
    PREFIX +
      `/dictation-templates-dictionary?${page ? `page=${page}` : ''}${
        size ? `&size=${size}` : ''
      }${search ? `&search=${search}` : ''}`
  );
};
export const getSupportedSections = () => {
  return axiosInstance.get(
    PREFIX + `/dictation-templates-dictionary/section-headings`
  );
};
export const deleteDictationTemplate = id => {
  return axiosInstance.delete(
    PREFIX + `/dictation-templates-dictionary?dictionaryIds=${id}`
  );
};
