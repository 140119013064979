import React from 'react';
import { useSelector } from 'react-redux';

import CustomTable from '../../CustomComponents/Table';
import { columns } from './tableConfig';

const DocumentTableView = ({
  fileList,
  total,
  loading,
  currentPage,
  filterTableChange,
  setCurrentPage,
  getComments,
  setCommentsDrawerVisible,
  setFileExportList,
  setListSelection
}) => {
  const [theme] = useSelector(state => [state.themeReducer]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      selectedRows.length > 0
        ? setListSelection(true)
        : setListSelection(false);
      let exportList = Array.from(selectedRowKeys);
      setFileExportList(exportList);
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.fileName
    })
  };

  return (
    <>
      <CustomTable
        theme={theme}
        columns={columns(getComments, setCommentsDrawerVisible)}
        dataSource={fileList}
        loading={loading}
        setPageNo={current => {
          setCurrentPage(current);
        }}
        pageNo={currentPage}
        pageSize={10}
        total={total}
        onChange={filterTableChange}
        rowSelection={rowSelection}
        hl7={true}
      />
    </>
  );
};

export default DocumentTableView;
