import styled from 'styled-components';
import { Typography } from 'antd';

export const TimerText = styled(Typography.Text)`
  margin-top: 10px;
  font-size: 16px;
  font-weight:600;
  line-height: 26px;
  color: ${props=>props.color} !important;
`;
