import React from "react";
import { Helmet } from "react-helmet";

const APP_NAME="Cascade MD"

function PageTitle(props) {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{props.title + " | " + APP_NAME}</title>
      {/* <link rel="canonical" href="http://mysite.com/example" /> */}
    </Helmet>
  );
}
export default PageTitle;
