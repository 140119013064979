import React from "react";

function ProfileFingerPrint({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="29"
      fill={color}
      viewBox="0 0 29 29"
    >
      <g>
        <path d="M24.747 5.82a.897.897 0 01-.61-.238c-2.667-2.43-6.09-3.77-9.637-3.77-3.547 0-6.97 1.34-9.636 3.772a.907.907 0 01-1.222-1.34C6.645 1.507 10.501 0 14.5 0c4 0 7.856 1.507 10.858 4.244a.907.907 0 01-.611 1.575z"></path>
        <path d="M26.28 11.741a.903.903 0 01-.753-.4c-2.516-3.747-6.639-5.983-11.029-5.983S5.986 7.594 3.47 11.34a.906.906 0 11-1.506-1.01c2.853-4.247 7.54-6.784 12.534-6.784 4.996 0 9.682 2.537 12.534 6.785a.905.905 0 01-.752 1.41z"></path>
        <path d="M11.637 29a.905.905 0 01-.644-.268c-.198-.2-4.841-4.94-4.841-9.504 0-4.736 3.744-8.59 8.348-8.59 4.604 0 8.348 3.854 8.348 8.59a.907.907 0 01-1.812 0c0-3.737-2.932-6.777-6.536-6.777-3.604 0-6.536 3.04-6.536 6.777 0 3.827 4.274 8.184 4.317 8.228A.905.905 0 0111.637 29z"></path>
        <path d="M17.363 29c-2.18 0-7.233-4.147-7.723-8.765-.172-1.633.347-3.303 1.389-4.468.906-1.012 2.13-1.574 3.446-1.581h.025c1.284 0 2.497.504 3.42 1.42a5.066 5.066 0 011.494 3.623v.591c0 1.456 1.134 2.64 2.528 2.64 1.395 0 2.53-1.184 2.53-2.64v-.207c0-5.642-4.088-10.337-9.307-10.685-2.72-.185-5.331.755-7.346 2.638-2.09 1.955-3.289 4.749-3.289 7.663 0 2.398.812 4.733 2.286 6.577A.906.906 0 015.4 26.937a12.387 12.387 0 01-2.682-7.709c0-3.415 1.409-6.69 3.863-8.986 2.384-2.23 5.476-3.341 8.703-3.124 6.168.412 11 5.9 11 12.494v.207c0 2.455-1.948 4.453-4.342 4.453-2.393 0-4.34-1.998-4.34-4.453v-.59c0-.893-.34-1.722-.96-2.338a3.02 3.02 0 00-2.14-.894h-.018c-1.02.005-1.708.535-2.104.979-.707.789-1.058 1.937-.938 3.068.4 3.767 4.73 7.145 5.92 7.145a.907.907 0 010 1.81z"></path>
        <path d="M20.725 27.227c-3.243 0-7.203-3.963-7.203-8.59a.907.907 0 011.813 0c0 3.587 3.014 6.778 5.39 6.778a.907.907 0 010 1.813z"></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H29V29H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default ProfileFingerPrint;
