import styled from 'styled-components';
import { Menu } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

export const StyledMenu = styled(Menu)`
  margin: 40px 0;
  & .ant-menu-item {
    color: ${(props) => props.textColor};
    height: 60px;
    line-height: 60px;
    width: auto;
    margin: ${(props) => (props.collapsed ? ' 0 20px' : ' 0 15px')} !important;
    border-radius: 10px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
  }

  & .ant-menu-item a {
    color: ${(props) => props.textColor};
  }

  &:not(.ant-menu-horizontal) {
    .ant-menu-item.ant-menu-item-selected {
      background-color: ${(props) => props.color};
      color: #fff;
    }
  }

  &.ant-menu-inline-collapsed > .ant-menu-item {
    padding: 0 20px;
  }

  &.ant-menu-inline-collapsed > .ant-menu-item .anticon {
    display: flex;
    margin-right: 0;
    svg {
      height: 20px;
      width: 20px;
    }
  }

  & .ant-menu-item-selected .anticon svg path,
  & .ant-menu-item-selected:hover > .anticon svg path {
    fill: white !important;
  }

  &.ant-menu-inline-collapsed {
    width: 100px;
  }

  & .ant-menu-item:hover > .anticon svg path {
    fill: ${(props) => props.color};
  }

  & .ant-menu-item-disabled > .anticon svg path,
  & .ant-menu-item-disabled:hover > .anticon svg path {
    fill: rgba(0, 0, 0, 0.25);
  }

  & .ant-menu-item-selected a,
  & .ant-menu-item-selected a:hover {
    color: white;
  }

  &.ant-menu-inline .ant-menu-item::after {
    border-right: none;
  }

  &.ant-menu-vertical .ant-menu-item-selected::after {
    transform: scaleY(1);
    opacity: 1;
    border-right: none;
  }

  & .ant-menu-item-selected .anticon.anticon-warning svg path,
  & .ant-menu-item-selected:hover > .anticon.anticon-warning svg path {
    fill: #da1414 !important;
  }
`;

export const StyledMenuItem = styled(StyledMenu.Item)`
  &.ant-menu-item::after {
    border-left: 5px solid ${(props) => props.color};
    left: 0;
    right: unset;
    border-right: none;
    position: fixed;
    height: 60px;
    border-radius: 5px;
    top: ${(props) => `calc(110px + ${props.eventKey * 60}px )`};
  }
  @media (max-height: 616px) {
    &.ant-menu-item {
      height: 51px;
  }
    &.ant-menu-item::after {
      height: 51px;
      top: ${(props) => `calc(110px + ${props.eventKey * 51}px )`};
  }
  }
`;

export const StyledWarningIcon = styled(WarningOutlined)`
  position: absolute;
  top: 20px;
  right: 20px;
  &.anticon-warning svg {
    font-size: 20px;
    & path{
      fill: #da1414 !important;
    }
  }
`;
