import React from "react";

function InProgressIcon({size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ? size : "16"}
      height={size ? size : "16"}
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill="#CCB64C"
        d="M8.832 30.33A16.003 16.003 0 0015.076 32v-6.923a9.106 9.106 0 01-2.779-.749L8.832 30.33zM25.078 15.076H32a15.903 15.903 0 00-1.665-6.246l-6.003 3.465c.388.865.645 1.8.746 2.78zM24.332 19.733l6.003 3.465A15.898 15.898 0 0032 16.953h-6.922a9.04 9.04 0 01-.746 2.78zM23.39 10.671l6.003-3.465a16.166 16.166 0 00-4.571-4.571l-3.466 6.002a9.194 9.194 0 012.035 2.034zM21.356 23.39l3.466 6.003a16.173 16.173 0 004.572-4.571l-6.003-3.466a9.2 9.2 0 01-2.035 2.035zM8.121 20.57a9.108 9.108 0 01-1.22-4.556c0-5.024 4.088-9.112 9.113-9.112 1.324 0 2.583.284 3.719.794l3.465-6.003A15.948 15.948 0 0016.014 0 15.91 15.91 0 004.69 4.69 15.91 15.91 0 000 16.014c0 2.815.741 5.584 2.144 8.009l.001.001a16.108 16.108 0 005.064 5.365l3.463-6a9.116 9.116 0 01-2.55-2.819zm-4.795 1.686a14.149 14.149 0 01-1.45-6.242c0-7.48 5.84-13.622 13.2-14.107v3.158c-5.622.478-10.05 5.206-10.05 10.949 0 1.616.355 3.21 1.036 4.663l-2.736 1.579zM16.952 25.078V32c2.2-.127 4.317-.695 6.246-1.666l-3.465-6.003a9.03 9.03 0 01-2.78.747z"
      ></path>
    </svg>
  );
}

export default InProgressIcon;
