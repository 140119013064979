export const ObjectDeSerializer = (value) => {
  let data = [];
  value.forEach((item, index) => {
    if (item.type === 'heading') {
      let newData = {
        id: value[index-1] && value[index-1].id===item.id ? "new-aaa": item.id,
        heading:
          item.type === 'heading' && item.children[0].text
            ? item.children[0].text
            : ' ',
        content:null
      };
      let newParagraph = false;
      let count = 0;
      for (var i = index + 1; i < value.length; i++) {
        if(value[i].children[0].text !== "") count = count + 1; 
        if(count > 1) newParagraph = true; else newParagraph = false;
        if (value[i].type === 'heading') {
          break;
        }
        newData.content = [
          {
            type: 'Text',
            value: newData.content
              ? newData.content[0].value + " " + value[i].children[0].text
              : value[i].children[0].text,
            newParagraph: newParagraph,
            count: count
          },
        ];
      }
      data.push(newData);
    }
  });
  return data;
};
