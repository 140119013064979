export const READY_JOBS_MODIFIED = 'READY_JOBS_MODIFIED';
export const NEW_JOB_AVAILABLE = 'NEW_JOB_AVAILABLE';
export const TRANSCRIPTION_WRITE_FAILED = 'TRANSCRIPTION_WRITE_FAILED';
export const NEW_NOTIFICATION = 'NEW_NOTIFICATION';
export const CLEAR_JOB_NOTIFICATION = 'CLEAR_JOB_NOTIFICATION';
export const JOB_READY_INPROGRESS = 'JOB_READY_INPROGRESS';

export const readyJobsModified = data => {
  return {
    type: READY_JOBS_MODIFIED,
    payload: data
  };
};

export const newJobAvailable = data => {
  return {
    type: NEW_JOB_AVAILABLE,
    payload: data
  };
};

export const jobReadyToInprogress = data => {
  return {
    type: JOB_READY_INPROGRESS,
    payload: data
  };
};

export const transcriptionWriteFailed = data => {
  return {
    type: TRANSCRIPTION_WRITE_FAILED,
    payload: data
  };
};

export const newNotification = data => {
  return {
    type: NEW_NOTIFICATION,
    payload: data
  };
};

export const clearJobNotification = data => {
  return {
    type: CLEAR_JOB_NOTIFICATION,
    payload: data
  };
};
