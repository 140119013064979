import React from "react";

function LinkoffIcon() {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      height="20" 
      width="20"
      fill="#ffffff"
      viewBox="0 0 20 20"
    >
      <path d="m15.708 13.583-1.166-1.145q.875-.23 1.416-.907.542-.677.542-1.531 0-1.042-.729-1.771Q15.042 7.5 14 7.5h-3V6h3q1.667 0 2.833 1.167Q18 8.333 18 10q0 1.146-.615 2.104-.614.958-1.677 1.479Zm-2.833-2.833-1.5-1.5H13v1.5ZM16 18.125 1.875 4l1.063-1.062 14.124 14.124ZM9 14H6q-1.667 0-2.833-1.167Q2 11.667 2 10q0-1.333.792-2.375.791-1.042 2.25-1.646L6.5 7.5h-.479q-1.063 0-1.792.729Q3.5 8.958 3.5 10q0 1.042.729 1.771.729.729 1.771.729h3Zm-2-3.25v-1.5h1.167l1.479 1.5Z"/>
    </svg>
  );
}

export default LinkoffIcon;
