export const ADD_USER = "ADD_USER";
export const CLEAR_USER = "CLEAR_USER";
export const USER_SET_UNSAVEDSTATUS = 'USER_SET_UNSAVEDSTATUS'
export const AddUser = (data) => {
  return {
    type: ADD_USER,
    payload: data,
  };
};
export const ClearUser = () => {
  return {
    type: CLEAR_USER,
  };
};

// User unsaveedStatus
export const setUserUnsavedStatus = (unsavedStatus) => {
  return {
      type: USER_SET_UNSAVEDSTATUS,
      payload: {
          user: {
            unsavedStatus: unsavedStatus
          }
      }
  }
}

