import axiosInstance from '../Config/AxiosInstance';

const PREFIX = `/clinic/admin`;

export const listAll = (
  facilityIds,
  name,
  pageSize,
  pageNo,
  sortFitler,
  sortOrder
) => {
  let url =
    PREFIX +
    `?name=${name}&sortKey=${sortFitler}&page=${pageNo}&size=${pageSize}&sortOrder=${sortOrder}`;

  if (facilityIds.length > 0) {
    url = url + `&facilityIds=${facilityIds}`;
  }
  return axiosInstance.get(url);
};

export const saveAdmin = data => {
  return axiosInstance.post(PREFIX+`?isFacilityAccessAllowed=${data?.isFacilityAccessAllowed ? data?.isFacilityAccessAllowed : false }`, data);
};

export const editAdmin = data => {
  return axiosInstance.put(PREFIX+`?isFacilityAccessAllowed=${data?.isFacilityAccessAllowed ? data?.isFacilityAccessAllowed : false }`, data);
};

export const disableFacility = facilityId => {
  return axiosInstance.put(PREFIX+`/${facilityId}?isDisabled=true`);
};

export const enableFacility = facilityId => {
  return axiosInstance.put(PREFIX+`/${facilityId}?isDisabled=false`);
};

export const deleteAdmin = id => {
  return axiosInstance.delete(PREFIX + `/${id}`);
};
