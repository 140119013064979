import React from 'react';
import { TitleRow, ContainerRow } from '../styledComponents';
import CustomTypography from '../../CustomComponents/CustomTypography';
import { useSelector } from 'react-redux';
import SmallCard from '../../CustomComponents/SmallCard';
import { Col, Row } from 'antd';
import { BILLING_CONTENTS } from './BillingConfig';
import { Link } from 'react-router-dom';
import PageTitle from '../../../Utils/PageTitle';

const Billing = () => {
  const [theme] = useSelector((state) => [state.themeReducer]);
  return (
    <>
      <PageTitle title="Billing" />
      <ContainerRow gutter={[0, 40]}>
        <TitleRow span={24}>
          <CustomTypography color={theme['@text-color']}>
            Billing
          </CustomTypography>
        </TitleRow>
        <Col span={24}>
          <Row gutter={[36, 36]}>
            {BILLING_CONTENTS.map((item) => (
              <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={4}>
                <Link to={item.path}>
                  <SmallCard item={item} />
                </Link>
              </Col>
            ))}
          </Row>
        </Col>
      </ContainerRow>
    </>
  );
};

export default Billing;
