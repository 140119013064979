import React from 'react';
import { FileOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  DocumentListContainer,
  DocumentInfo,
  DocumentInfoContainer
} from './styledComponents';

const UploadList = ({ uploadDocuments, onRemove }) => {
  return (
    <DocumentListContainer>
      {uploadDocuments.map(document => {
        console.log('document');
        return (
          <DocumentInfoContainer>
            <DocumentInfo error={document.status === 'error'}>
              <FileOutlined />
              <span>{document.name}</span>
            </DocumentInfo>
            <DeleteOutlined
              onClick={() => onRemove(document)}
              style={{ color: document.status === 'error' ? 'red' : '#555' }}
            />
          </DocumentInfoContainer>
        );
      })}
    </DocumentListContainer>
  );
};

export default UploadList;
