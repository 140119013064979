import React, { useEffect, useState } from 'react';
import PageTitle from '../../../../Utils/PageTitle';
import CustomTypography from '../../../CustomComponents/CustomTypography';
import BreadCrumbComponent from '../../../CustomComponents/BreadCrumbComponent';
import Spinner from '../../../CustomComponents/Spinner';
import { useSelector } from 'react-redux';
import { notification } from 'antd';
import { ContainerRow, TitleRow } from '../../styledComponents';
import ConfirmationModal from '../../../CustomComponents/ConfirmationModal/index';
import AlertIcon from '../../../../Assets/Icons/AlertIcon';
import {
  getSubscriptionPlans,
  cancelSubscription
} from '../../../../Apis/Payment';
import { useHistory, useLocation } from 'react-router-dom';
import { getUserFacilities } from '../../../../Apis/User';
import { NoFacilityAlert, StyledSelect } from './styledComponents';
import NewManageSubscription from './NewManageSubscription';
const { Option } = StyledSelect;

const Subscription = () => {
  const [theme] = useSelector(state => [state.themeReducer]);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [activeSubscriptionId, setActiveSubscriptionId] = useState(null);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [planEndDate, setPlanEndDate] = useState();
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [trialPeriod, setTrialPeriod] = useState(null);
  const [transactionPlanId, setTransactionPlanId] = useState(null);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [facilities, setFacilities] = useState([]);
  const [facilityIds, setFacilityIds] = useState();
  const { currentFacilityId } = useLocation().state || {};

  let BreadCrubItems = [
    {
      name: 'Billing',
      path: '/dashboard/billing'
    },
    {
      name: 'Manage Subscription',
      path: '/dashboard/settings/manage-subscription'
    }
  ];


  const getPlans = () => {
    setLoading(true);

    getSubscriptionPlans(facilityIds)
      .then(response => {
        if (response.data.success) {
          setSubscriptionPlans(response.data.result);
          setPlanEndDate(response.data.planEndDate);
          setSubscriptionId(response.data.subscriptionId);
          setTrialPeriod(response.data.onTrialPeriod);
          setActiveSubscriptionId(response.data.activeSubscriptionId);
          setTransactionPlanId(response.data.transactionPlanId);
          setTransactionStatus(response.data.transactionStatus);
          setLoading(false);
        } else {
          setLoading(false);
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const cancelSubscriptionHandler = () => {
    setCancelLoading(true);
    cancelSubscription(activeSubscriptionId, facilityIds)
      .then(response => {
        if (response.data.success) {
          notification['success']({
            message: 'Success',
            description: 'Subscription cancelled succesfully'
          });
          setCancelLoading(false);
          setCancelModalVisible(false);
          getPlans();
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
          setCancelLoading(false);
          setCancelModalVisible(false);
        }
      })
      .catch(error => {
        console.log(error);
        setCancelLoading(false);
        setCancelModalVisible(false);
      });
  };

  const startSubscriptionHandler = plan => {
    let data = {
      facilityId: facilityIds,
      plan: plan
    };
    console.log(plan, 'plan');

    history.push('/dashboard/billing/manage-cards', data);
  };

  const onOk = () => {
    cancelSubscriptionHandler();
  };
  const onCancel = () => {
    setCancelModalVisible(false);
  };

  const getFacilities = () => {
    setLoading(true);
    getUserFacilities().then(response => {
      if (response.data.success) {
        const enabledFacilities = response.data.result.filter((facility) => {
          return !facility.disabled;
        });
        setFacilities(enabledFacilities);
        setFacilityIds(currentFacilityId || enabledFacilities[0]?.facilityId);
        setLoading(false);
      } else {
        notification['error']({
          message: 'User Facilities Fetch Failed',
          description: response.data.message
        });
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (facilityIds) {
      getPlans();
    }
    // eslint-disable-next-line
  }, [facilityIds]);

  useEffect(() => {
    getFacilities();
    // eslint-disable-next-line
  }, []);

  return loading ? (
    <Spinner />
  ) : (
    <>
      <PageTitle title='Manage Subscription' />
      <ContainerRow gutter={[0, 40]}>
        <TitleRow justify='space-between'>
          <CustomTypography color={theme['@text-color']}>
            Manage Subscription
          </CustomTypography>
          <StyledSelect
            value={facilityIds}
            placeholder='Filter'
            onSelect={setFacilityIds}
          >
            {facilities.length > 0 &&
              facilities.map(item => {
                return (
                  <Option value={item.facilityId}>{item.facilityName}</Option>
                );
              })}
          </StyledSelect>
        </TitleRow>
        <BreadCrumbComponent items={BreadCrubItems} />
        {
          facilities.length > 0 ?
            <NewManageSubscription
              trialPeriod={trialPeriod}
              planEndDate={planEndDate}
              subscriptionId={subscriptionId}
              transactionStatus={transactionStatus}
              startSubscriptionHandler={startSubscriptionHandler}
              subscriptionPlans={subscriptionPlans}
              cancelSubscriptionHandler={cancelSubscriptionHandler}
              setCancelModalVisible={setCancelModalVisible}
              transactionPlanId={transactionPlanId}
            />
            :
            <NoFacilityAlert>
              No facilities added
            </NoFacilityAlert>
        }


      </ContainerRow>
      <ConfirmationModal
        visible={cancelModalVisible}
        onOk={onOk}
        onCancel={onCancel}
        antdModalProps={{ centered: true, width: '300', closable: false }}
        deleteCard={true}
        loading={cancelLoading}
        confirmation={true}
      >
        <AlertIcon width={60} height={60} />
        <p>Are you sure you want to cancel your subscription?</p>
      </ConfirmationModal>
    </>
  );
};

export default Subscription;
