import React from 'react'
import ConfirmationModal from '../ConfirmationModal'


const ReleaseJobModal = ({ releaseJobModalVisible, setReleaseJobModalVisible , onConfirm}) => {
    return (
        <>
            <ConfirmationModal
                visible={releaseJobModalVisible}
                onCancel={() => { setReleaseJobModalVisible(false) }}
                onOk={onConfirm}
                confirmation={true}
                antdModalProps={{ centered: true, width: '300', closable: false }}
            >
                Your changes will be discarded after release.
            </ConfirmationModal>
        </>
    )
}

export default ReleaseJobModal
