import { notification, Space } from 'antd';
import moment from 'moment';
import React, { useEffect, useState, forwardRef } from 'react';
import { useSelector } from 'react-redux';

import {
  fetchJobAuditDataByJobId,
  GetSessionJobDetails
} from '../../../Apis/Facility';

import { DATE_TIME_FORMAT } from '../../../Constants/StringConstants';
import CustomAvatar from '../../CustomComponents/CustomAvatar';
import JobDetails from './JobDetails';
import {
  SessionInfoContainer,
  InformationText,
  InformationTitle,
  UserInfoContainer,
  InfoContainer,
  UserInfoRow
} from './styledComponents';

const SessionDetails = forwardRef((props, ref) => {
  const [theme] = useSelector(state => [state.themeReducer]);
  const [loading, setLoading] = useState(false);
  const [scrollLoading, setScrollLoading] = useState(false);
  const [jobData, setJobData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const userString = localStorage.getItem('user');
  const userType = userString ? JSON.parse(userString) : null;

  const getSessionJobDetails = pageNo => {
    if (pageNo === 0) {
      setLoading(true);
    } else {
      setScrollLoading(true);
    }
    if (props.selectedTab === 1) {
      GetSessionJobDetails(props.session.id, pageNo, 13, props.searchValue)
        .then(response => {
          if (response.data.success) {
            let newList = [];
            if (pageNo === 0) {
              newList = response.data.result;
            } else {
              newList = jobData.concat(response.data.result);
            }
            setJobData(newList);
            setHasMore(response.data.result.length > 12);
            setLoading(false);
            setScrollLoading(false);
          } else {
            notification['error']({
              message: 'Payment Profile Fetch Failed',
              description: response.data.message
            });
            setLoading(false);
            setScrollLoading(false);
          }
        })
        .catch(err => {
          console.log(err);
          setLoading(false);
          setScrollLoading(false);
        });
    } else {
      fetchJobAuditDataByJobId(props.session.jobId)
        .then(response => {
          if (response.data.success) {
            let newList = [];
            if (pageNo === 0) {
              newList = response.data.result;
            } else {
              newList = jobData.concat(response.data.result);
            }
            setJobData(newList);
            setHasMore(false);
            setLoading(false);
            setScrollLoading(false);
          } else {
            notification['error']({
              message: 'Payment Profile Fetch Failed',
              description: response.data.message
            });
            setLoading(false);
            setScrollLoading(false);
          }
        })
        .catch(err => {
          console.log(err);
          setLoading(false);
          setScrollLoading(false);
        });
    }
  };

  useEffect(() => {
    if (props.session) getSessionJobDetails(0);

    // eslint-disable-next-line
  }, [props.session]);

  return (
    props.session && (
      <div ref={ref}>
        <SessionInfoContainer>
          {props.selectedTab === 1 && (
            <UserInfoContainer>
              <Space>
                <CustomAvatar url={props.session.profilePic} size={30} />
                <InformationText color={theme['@table-text-color']} name={true}>
                  {props.session.fullName}
                </InformationText>
              </Space>
            </UserInfoContainer>
          )}
          <UserInfoRow>
            <InfoContainer>
              <InformationTitle color={theme['@text-color']}>
                {props.selectedTab === 1 ? 'Email' : 'Job ID'}&nbsp;&nbsp;
              </InformationTitle>
              <InformationText color={theme['@table-text-color']}>
                {props.selectedTab === 1
                  ? props.session.email
                  : props.session.jobNumber}
              </InformationText>
            </InfoContainer>
            <InfoContainer>
              <InformationTitle color={theme['@text-color']}>
                {props.selectedTab === 1 ? 'IP Address' : ''}&nbsp;&nbsp;
              </InformationTitle>
              {/* <InformationText color={theme['@table-text-color']}>
                {props.selectedTab === 1
                  ? props.session.loggedUserIp
                  : `${Math.floor(
                      moment.duration(props.session.tat).asHours()
                    )} H`}
              </InformationText> */}
            </InfoContainer>
          </UserInfoRow>

          <UserInfoRow>
            <InfoContainer>
              <InformationTitle color={theme['@text-color']}>
                {props.selectedTab === 1 ? 'Start Time' : 'Audio Length'}
                &nbsp;&nbsp;
              </InformationTitle>
              <InformationText color={theme['@table-text-color']}>
                {props.selectedTab === 1
                  ? moment(props.session.loginTime).format(DATE_TIME_FORMAT)
                  : props.session.audioLength}
              </InformationText>
            </InfoContainer>
            <InfoContainer>
              <InformationTitle color={theme['@text-color']}>
                {props.selectedTab === 1 ? 'End Time' : 'Author'}&nbsp;&nbsp;
              </InformationTitle>
              <InformationText color={theme['@table-text-color']}>
                {props.selectedTab === 1
                  ? props.session.logoutTime === 0
                    ? 'Active Session'
                    : moment(props.session.logoutTime).format(DATE_TIME_FORMAT)
                  : props.session.author}
              </InformationText>
            </InfoContainer>
          </UserInfoRow>
          {props.selectedTab === 2 && (
            <UserInfoRow>
              <InfoContainer>
                <InformationTitle color={theme['@text-color']}>
                  Patient Name&nbsp;&nbsp;
                </InformationTitle>
                <InformationText color={theme['@table-text-color']}>
                  {props.session.patientName}
                </InformationText>
              </InfoContainer>
              <InfoContainer>
                {/* <InformationTitle color={theme['@text-color']}>
                  TAT Status&nbsp;&nbsp;
                </InformationTitle> */}
                {/* <InformationText color={theme['@table-text-color']}>
                  {props.session.tatStatus}
                </InformationText> */}
              </InfoContainer>
            </UserInfoRow>
          )}
          {props.selectedTab === 2 && (
            <UserInfoRow>
              <InfoContainer>
                <InformationTitle color={theme['@text-color']}>
                  Status&nbsp;&nbsp;
                </InformationTitle>
                <InformationText color={theme['@table-text-color']}>
                  {props.session.status}
                </InformationText>
              </InfoContainer>
            </UserInfoRow>
          )}
        </SessionInfoContainer>

        <JobDetails
          loading={loading}
          jobData={jobData}
          getSessionJobDetails={getSessionJobDetails}
          hasMore={hasMore}
          scrollLoading={scrollLoading}
          sessionId={props.session.id}
          selectedTab={props.selectedTab}
        />
      </div>
    )
  );
});

export default SessionDetails;
