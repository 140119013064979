export const RESULTS_PER_PAGE = 10;

export const DATE_FORMAT = 'MM/DD/YY';

export const PLAN_EXP_DATE_FORMAT = 'DD MMM YYYY';

export const DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm a';

export const SEARCH_DELAY = '400';

export const API_CALL_DELAY = '400';

export const AUTO_SAVE_DELAY = '3000';

export const NAME_REGEX_CHECK =
  "^([A-Za-z]|(([A-Za-z]+)([.,] |[-']| )*)+[A-Za-z]+?)$";

export const ZIPCODE_REGEX_CHECK = '^[a-zA-Z0-9]*$';

export const CLIENT_KEY = process.env.REACT_APP_CLIENT_KEY;

export const CURRENCY_SYMBOL = '$';

export const NAME = process.env.REACT_APP_LOGIN_ID;

export const IDLE_LOGOUT_TIME_MINUTES = 15;
