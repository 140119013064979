import styled from 'styled-components';
import { Col, Row, Form, Button, Table } from 'antd';

export const CustomRow = styled(Row)`
  width: 100%;
  margin: ${props => (props.button ? '20px 0 ' : '0 !important')};
  & .ant-col {
    padding: 15px 18px 0 18px !important;
  }
`;

export const StyledCol = styled(Col)`
  & div {
    width: 100%;
  }
  &.ant-col {
    padding: ${props => props.noPadding && '0 !important'};
  }
`;

export const StyledForm = styled(Form)`
  margin-top: 20px;
  width: 100%;
`;

export const InfoButton = styled(Button)`
  &.ant-btn {
    background: transparent !important;
    margin-left: 6px;
    border: 0;
    span svg {
      height: 20px;
      width: 20px;
    }
  }

  & .ant-btn :active,
  :focus {
    background: transparent !important;
  }

  & .ant-btn :hover {
    background: transparent !important;
  }
`;

export const StyledEnableButton = styled(Button)`
  font-weight: 600;
  width: 110px;
  font-size: 14px;
  height: 40px;
  padding: 8px 16px;

  &.ant-btn {
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    border: 0;
    color: white;
  }

  svg {
    height: 20px;
    width: 16px;
  }
`;

export const StyledDisableButton = styled(Button)`
  font-weight: 600;
  width: 100px;
  font-size: 14px;
  height: 40px;
  padding: 8px 16px;

  &.ant-btn {
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    background-color: #c0c0c0;
    font-weight: 600;
    border: 0;
    color: white;
  }

  svg {
    height: 20px;
    width: 16px;
  }
`;

export const StyledDisableFacilityModalDiv = styled.div`
`;

export const StyledTable = styled(Table)`
  padding: 10px; 
  & tbody{
    background: rgb(240, 242, 245);
  }
  & tbody > tr:hover > td {
    background: none ;
  }
`;
