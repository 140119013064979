import React from 'react';
import moment from 'moment';
import {
  ExportDetailsContainer,
  ExportNumberContainer,
  CommentButton
} from './styledComponents';
import ChatIcon from '../../../Assets/Icons/ChatIcon';
import { DATE_TIME_FORMAT } from '../../../Constants/StringConstants';

export const columns = (getComments, setCommentsDrawerVisible) => [
  {
    title: 'File Name ',
    dataIndex: ['fileName'],
    key: 'fileName',
    sorter: true
  },
  {
    title: 'File Size',
    dataIndex: ['fileSize'],
    key: 'fileSize'
  },
  {
    title: 'Uploaded Time',
    dataIndex: ['uploadedTime'],
    key: 'uploadedTime',
    sorter: true,
    render: uploadTime => {
      return moment(uploadTime).format(DATE_TIME_FORMAT);
    }
  },
  {
    title: 'Uploaded By',
    dataIndex: ['uploadedBy'],
    key: 'uploadedBy'
  },
  {
    title: 'Export Count',
    dataIndex: ['noOfTimesExported'],
    key: 'noOfTimesExported',
    render: (noOfTimesExported, data) => {
      return (
        <ExportDetailsContainer>
          <ExportNumberContainer>{noOfTimesExported}</ExportNumberContainer>
          <CommentButton
            title='Export Comments'
            icon={<ChatIcon />}
            key={data.id}
            onClick={e => {
              e.stopPropagation();
              setCommentsDrawerVisible(true);
              getComments(data.fileExportDataList);
            }}
          />
        </ExportDetailsContainer>
      );
    }
  }
];
