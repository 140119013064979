import React from 'react';

function DrugIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="white"
        d="M12.5 0h-11A1.5 1.5 0 000 1.5v11A1.5 1.5 0 001.5 14h11a1.5 1.5 0 001.5-1.5v-11A1.5 1.5 0 0012.5 0zm.5 12.5a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v11z"
      ></path>
      <path
        fill="white"
        d="M4.5 3H3a.5.5 0 100 1h1.5a.5.5 0 100-1zM11 3H6.5a.5.5 0 100 1H11a.5.5 0 100-1zM4.5 6.5H3a.5.5 0 100 1h1.5a.5.5 0 100-1zM11 6.5H6.5a.5.5 0 100 1H11a.5.5 0 100-1zM4.5 10H3a.5.5 0 000 1h1.5a.5.5 0 100-1zM11 10H6.5a.5.5 0 000 1H11a.5.5 0 100-1z"
      ></path>
    </svg>
  );
}

export default DrugIcon;
