import React from 'react';
import { Col, Row } from 'antd';
import {
  LoginBackground,
  LogoDiv,
  DetailDiv,
  LogoStyle
} from './styledComponents';
import LoginForm from './LoginForm';
import CustomTypography from '../CustomComponents/CustomTypography';
import ForgotPasswordForm from './ForgotPasswordForm';
import ResetPasswordForm from './ResetPasswordForm';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import OTPVerification from './OTPVerification';
import PageTitle from '../../Utils/PageTitle';

const Login = ({ type, signup }) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const user = localStorage.getItem('user');
    const loggedIn = localStorage.getItem('loggedIn');
    if (user && loggedIn) {
      history.push('/');
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <PageTitle
        title={
          signup
            ? 'Signup'
            : type === 'FORGOT' || type === 'RESET'
            ? 'Reset password'
            : type === 'OTP'
            ? 'Verify OTP'
            : 'Login'
        }
      />
      <Row>
        <Col
          xs={{ span: 0 }}
          sm={{ span: 0 }}
          md={{ span: 0 }}
          lg={{ span: 11 }}
          xl={{ span: 9 }}
        >
          <LoginBackground>
            <LogoDiv>
              <LogoStyle />
            </LogoDiv>
            <DetailDiv>
              <CustomTypography color='#267ab9'>
                Get ready to
                <br /> revolutionize the way
                <br /> you work with your patients,
                <br />
                <br />
                The next big change is here....
              </CustomTypography>
            </DetailDiv>
          </LoginBackground>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 13 }}
          xl={{ span: 15 }}
        >
          {type === 'FORGOT' ? (
            <ForgotPasswordForm />
          ) : type === 'RESET' ? (
            <ResetPasswordForm />
          ) : type === 'OTP' ? (
            <OTPVerification />
          ) : (
            <LoginForm signup={signup} newUser={location.state} />
          )}
        </Col>
      </Row>
    </>
  );
};

export default Login;
