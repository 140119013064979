import React from 'react';
import { Row, Col, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import CustomAvatar from '../../CustomComponents/CustomAvatar';
import { ActionButton } from '../../CustomComponents/Table/styledComponents';
import ReInviteIcon from '../../../Assets/Icons/ReInviteIcon';
import { PopoverGlobalStyle } from '../JobList/styledComponents';
import { StyledFacilityBlocks } from './styledComponents';
import { StyledFacilityContainer } from './styledComponents';
import { FacilityRemainingCount } from './styledComponents';
import { StyledSpan } from './styledComponents';
import { Popover, Tooltip } from 'antd';
import EnableUserIcon from '../../../Assets/Icons/EnableUserIcon';

export const columns = (
  setSelectedItem,
  setAddDrawerVisible,
  disableUser,
  reInvite,
  notClinicAdmin
) => [
  {
    title: ' ',
    dataIndex: ['signedUrl'],
    width: '35px',

    render: signedUrl => {
      return <CustomAvatar url={signedUrl} size={35} />;
    }
  },
  {
    title: 'Name',
    key: 'id',
    sorter: true,
    dataIndex: ['fullName'],
    render: val => {
      return (
        <span title={val}>
          {val?.length > 30 ? val.slice(0, 30) + '...' : val}
        </span>
      );
    }
  },
  {
    title: 'Email',
    dataIndex: ['email'],

    key: 'email'
  },
  {
    title: 'Phone Number',
    dataIndex: ['phoneNumber'],
    key: 'phoneNumber',
    render: value => {
      return value && <div>+{value.countryCode + '-' + value.number}</div>;
    }
  },
  {
    title: 'Facility',
    key: 'facility',
    dataIndex: ['facilities'],
    render: FacilityList => {
      return (
        <div>
          {FacilityList.length > '2' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row '
              }}
            >
              <StyledFacilityContainer>
                {FacilityList.slice(0, 2).map(item => {
                  return (
                    <Popover
                      placement='bottom'
                      content={<h5>{item.facilityName}</h5>}
                    >
                      <StyledFacilityBlocks disabled={item.disabled}>
                        <StyledSpan>{item.facilityName}</StyledSpan>
                      </StyledFacilityBlocks>
                    </Popover>
                  );
                })}
              </StyledFacilityContainer>
              <Popover
                placement='bottom'
                content={
                  <div>
                    {FacilityList.slice(2, FacilityList.length).map(item => {
                      return <h5>{item.facilityName}</h5>;
                    })}
                  </div>
                }
              >
                <FacilityRemainingCount>
                  +{FacilityList.length - 2}
                </FacilityRemainingCount>
              </Popover>
            </div>
          )}
          {FacilityList.length < '3' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row '
              }}
            >
              <StyledFacilityContainer>
                {FacilityList.map(item => {
                  return (
                    <Popover
                      placement='bottom'
                      content={<h5>{item.facilityName}</h5>}
                    >
                      <StyledFacilityBlocks disabled={item.disabled}>
                        <StyledSpan>{item.facilityName}</StyledSpan>
                      </StyledFacilityBlocks>
                    </Popover>
                  );
                })}
              </StyledFacilityContainer>
            </div>
          )}
        </div>
      );
    }
  },
  {
    title: 'Action',
    width: '180px',
    render: row => {
      return (
        <Row gutter={12}>
          <Col>
            <Tooltip title='Edit user'>
              <ActionButton
                onClick={() => {
                  setSelectedItem(row);
                  setAddDrawerVisible(true);
                }}
                icon={<EditOutlined />}
                disabled={!row.isEnabled}
              />
            </Tooltip>
          </Col>
          <Col>
            <Popconfirm
              title={
                <>
                  {row.isEnabled ? 'Disable' : 'Enable'} {row.fullName} ?
                </>
              }
              onConfirm={() => disableUser(row.id)}
              okText='Yes'
              cancelText='No'
              overlayClassName='popover-delete-confirm'
              disabled={notClinicAdmin}
            >
              <PopoverGlobalStyle />
              <Tooltip title={row.isEnabled ? 'Disable user' : 'Enable user'}>
                <ActionButton
                  disabled={notClinicAdmin}
                  icon={
                    row.isEnabled ? (
                      <DeleteOutlined />
                    ) : (
                      <EnableUserIcon disabled={notClinicAdmin} />
                    )
                  }
                />
              </Tooltip>
            </Popconfirm>
          </Col>
          <Col>
            <Popconfirm
              title='Are you sure you want to re-invite?'
              onConfirm={() => reInvite(row.id)}
              okText='Yes'
              cancelText='No'
              overlayClassName='popover-delete-confirm'
            >
              <Tooltip title='Reinvite user'>
                <ActionButton
                  icon={<ReInviteIcon disabled={!row.isEnabled} />}
                  disabled={!row.isEnabled}
                />
              </Tooltip>
            </Popconfirm>
          </Col>
        </Row>
      );
    }
  }
];
