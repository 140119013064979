import { Spin } from 'antd';
import moment from 'moment';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useSelector } from 'react-redux';
import { DATE_TIME_FORMAT } from '../../../Constants/StringConstants';
import Spinner from '../../CustomComponents/Spinner';
import {
  JobTableContainer,
  JobTable,
  JobTableHeadingRow,
  JobRow,
  StyledColumn,
  InformationTitle,
  InformationText,
  JobStatusDiv
} from './styledComponents';

const JobDetails = ({
  loading,
  jobData,
  getSessionJobDetails,
  hasMore,
  scrollLoading,
  sessionId,
  selectedTab
}) => {
  const [theme] = useSelector(state => [state.themeReducer]);

  const spanLength = (tab, userType) => {
    return tab === 1 ? 6 : userType ? 4 : 5;
  };

  return (
    <JobTableContainer>
      {loading ? (
        <Spinner height={'50vh'} />
      ) : (
        <>
          <JobTableHeadingRow>
            <StyledColumn span={spanLength(selectedTab)}>
              <InformationTitle color={theme['@text-color']}>
                {selectedTab === 1 ? 'Job Id' : 'User Name'}
              </InformationTitle>
            </StyledColumn>
            {selectedTab === 2 && (
              <StyledColumn span={spanLength(selectedTab, true)}>
                <InformationTitle color={theme['@text-color']}>
                  User Type
                </InformationTitle>
              </StyledColumn>
            )}
            <StyledColumn span={spanLength(selectedTab)}>
              <InformationTitle color={theme['@text-color']}>
                Start Time
              </InformationTitle>
            </StyledColumn>
            <StyledColumn span={spanLength(selectedTab)}>
              <InformationTitle color={theme['@text-color']}>
                End Time
              </InformationTitle>
            </StyledColumn>
            <StyledColumn span={spanLength(selectedTab)}>
              <InformationTitle color={theme['@text-color']}>
                Action
              </InformationTitle>
            </StyledColumn>
          </JobTableHeadingRow>
          <JobTable key={sessionId}>
            <InfiniteScroll
              initialLoad={false}
              pageStart={0}
              loadMore={getSessionJobDetails}
              hasMore={!scrollLoading && hasMore}
              useWindow={false}
            >
              {jobData.map(job => {
                return (
                  <JobRow>
                    <StyledColumn span={spanLength(selectedTab)}>
                      <InformationText name={true}>
                        {job.jobId || job.userName}
                      </InformationText>
                    </StyledColumn>
                    {selectedTab === 2 && (
                      <StyledColumn span={spanLength(selectedTab, true)}>
                        <InformationText name={true}>
                          {job.userType}
                        </InformationText>
                      </StyledColumn>
                    )}
                    <StyledColumn span={spanLength(selectedTab)}>
                      <InformationText>
                        {moment(job.startTime).format(DATE_TIME_FORMAT)}
                      </InformationText>
                    </StyledColumn>
                    <StyledColumn span={spanLength(selectedTab)}>
                      <InformationText>
                        {moment(job.endTime).format(DATE_TIME_FORMAT)}
                      </InformationText>
                    </StyledColumn>
                    <StyledColumn span={spanLength(selectedTab)}>
                      <JobStatusDiv>{job.status || job.action}</JobStatusDiv>
                    </StyledColumn>
                  </JobRow>
                );
              })}
              {scrollLoading && <Spin size='small' />}
            </InfiniteScroll>
          </JobTable>
        </>
      )}
    </JobTableContainer>
  );
};

export default JobDetails;
