import React from 'react';

function CommentIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      fill=""
      viewBox="0 0 12 8"
    >
      <path
        fill="#C7C7C7"
        d="M0 10.582V4.711a3.297 3.297 0 013.293-3.294h5.413A3.298 3.298 0 0112 4.711v1.013a3.297 3.297 0 01-3.293 3.293H3.37L0 10.583zm3.293-8.43A2.562 2.562 0 00.734 4.71v4.72l2.474-1.149h5.499a2.562 2.562 0 002.559-2.559V4.711a2.562 2.562 0 00-2.56-2.56H3.293zm.243 2.503a.698.698 0 110 1.396.698.698 0 010-1.396zm4.928 0a.698.698 0 110 1.396.698.698 0 010-1.396zM6 4.655a.698.698 0 110 1.396.698.698 0 010-1.396z"
      ></path>
    </svg>
  );
}

export default CommentIcon;
