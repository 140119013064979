import React from 'react';

function HeartIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      fill='none'
      viewBox='0 0 20 20'
    >
      <rect
        width='19'
        height='19'
        x='0.5'
        y='0.5'
        stroke='#030229'
        opacity='0.4'
        rx='9.5'
      ></rect>
      <path
        fill='#47A4EA'
        fillRule='evenodd'
        d='M12.271 5.395c.372 0 .744.052 1.098.17 2.178.709 2.963 3.099 2.307 5.188a7.511 7.511 0 01-1.776 2.838 22.7 22.7 0 01-3.736 2.927l-.147.09-.154-.096a22.484 22.484 0 01-3.758-2.927 7.632 7.632 0 01-1.777-2.832c-.667-2.089.118-4.48 2.32-5.2.17-.059.347-.1.524-.123h.071c.166-.024.33-.035.496-.035h.065a3.68 3.68 0 011.08.194h.035a.206.206 0 01.053.035c.13.042.254.09.372.154l.224.1c.054.03.115.074.168.112.033.024.063.046.086.06l.03.017c.05.03.103.06.147.094a3.696 3.696 0 012.272-.766zm1.57 4.249a.485.485 0 00.466-.45v-.07a1.948 1.948 0 00-1.245-1.865.473.473 0 00-.596.296.483.483 0 00.295.607.988.988 0 01.632.926v.019a.507.507 0 00.112.365c.082.1.206.16.336.172z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export default HeartIcon;
