import { Button, Card, Col, Select, Modal, Divider, Tree, Input } from 'antd';
// import { Divider } from 'rc-menu';
import styled from 'styled-components';

export const StyledTag = styled.span`
  padding: 10px;
  display: flex;
  justify-content: left;
  align-items: center;
  border: 1px solid #d9d9d9;
  font-size: 16px;
  width: 100%;

  &:hover {
    background-color: #47a4ea;
    color: #ffffff;
  }
`;
// export const RestrictColum = styled.div`
//   padding: 20px;
//   border-radius: 8px;
//   background-color: #f5f7fa;
// `;
export const StyledTagContainer = styled.div`
  overflow-y: auto;
  height: 311px;
  width: 100%;
  /* margin-top: 31px; */
  border: 1px solid #d9d9d9;
`;
export const SubmitCol = styled(Card)`
  /* min-height: 88vh; */
  /* width:436px; */
  /* overflow-y: scroll; */
  .activesection {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
export const KpCol = styled(Card)`
  width: 293px;

  padding: 0px 3px 1px;
  border-radius: 15px !important;
  border: 1px solid #47a4ea; /* Border color */
  background-color: #f5f7fa !important;
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  cursor: pointer;
  box-shadow: 0px 0px 3px rgba(0.1, 0.1, 0.1, 0.1);

  &:hover {
    background-color: #e4f4ff !important; /* Change background color to blue on hover */
  }
  .keyvalue {
    font-size: 15px;
    margin-left: 8px;
    font-weight: 600;
    color: #2b2b2b;
  }
  .author-box {
    border-radius: 20px;
    border: 1px solid #e1e4eb;
    padding: 0px 8px;
    width: fit-content;
    /* padding: 12px, 12px, 12px, 12px; */
    margin-bottom: 8px;
  }
`;
export const ActiveKeytophraseCol = styled(Card)`
  /* min-height: 80vh; */
  min-height: 500px;
  max-height: 500px;
  /* height: 500px; */
  /* .ant-card-body {
    padding: 0px;
  } */
`;
export const StyledSelect = styled(Select)`
  border-radius: 5px;
  margin-right: 20px;
  width: 140px;
  font-size: 16px;
  margin-top: 30px;
`;
export const CardTitle = styled.h6`
  font-size: 14px;
  font-weight: 600;
  color: #525e6f;
  padding-top: 20px;
  padding-bottom: 8px;
`;
export const SubmitButton = styled(Button)`
  width: 150px;
  &.ant-btn-primary {
    border-radius: 10px;
  }
`;

export const DnDColumn = styled.div`
  width: 100%;
  min-height: 470px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  .keyvalue {
    font-size: 14px;
    font-weight: 600;
    color: #2b2b2b;
  }
  .movable-item {
    border-radius: 5px;
    background-color: #edeff4;
    height: 67px;
    margin: 0 0 10px 0;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 0px 3px rgba(0.1, 0.1, 0.1, 0.1);
  }
  .movable-item1 {
    border-radius: 5px;
    background-color: #ffffff;
    height: 67px;
    margin: 0 0 10px 0;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 0px 3px rgba(0.1, 0.1, 0.1, 0.1);
  }
`;

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: ${props => props.direction};
  justify-content: ${props => props.justify};
  width: ${props => props.width};

  .movable-item {
    border-radius: 5px;
    background-color: #fafdff;
    height: 75px;
    margin: 0 0 10px 0;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 0px 3px rgba(0.1, 0.1, 0.1, 0.1);
  }
  .keyvalue {
    font-size: 14px;
    font-weight: 600;
    color: #2b2b2b;
    line-height: 18.2px;
  }
  .phrasevalue {
    font-size: 12px;
    font-weight: 400;
    color: #5c5c5c;
  }
`;

export const CustomModalforAddk2p = styled(Modal)`
  width: 950px !important;
  .ant-modal-title {
    font-weight: 600;
    font-family: 'Krub', sans-serif !important;
    font-size: 16px;
  }
  .ant-modal-close-x {
    color: #2b2b2b;
  }
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-header {
    border-radius: 8px;
  }
  .ant-input {
    border-radius: 5px;
  }
`;
export const CustomModal = styled(Modal)`
  width: 910px !important;
  .ant-modal-title {
    font-weight: 600;
    font-family: 'Krub', sans-serif !important;
    font-size: 16px;
  }
  .ant-modal-close-x {
    color: #2b2b2b;
  }
  .ant-modal-body {
    /* height: 550px; */
    max-height: 550px;
    overflow-x: auto;
    padding: 0px !important;
  }
  .ant-modal-footer {
    padding: 16px !important;
  }
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-header {
    border-radius: 8px;
  }
  .ant-btn-primary {
    background: #a1a1a1;
    font-weight: 600;
  }
  .detailedheadervalue {
    font-size: 20px;
    font-weight: 600;
  }
`;
export const Keytophraseduplication = styled(Modal)`
  width: 577px !important;

  .warning-container {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #f9f9f9; /* Background color */
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #e4e4e4; /* Border color */
  }

  .warning-message {
    color: orange; /* Warning message color */
  }
  .modal-body {
    height: 314px;
    padding: 48px 32px 40px 32px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .ant-modal-content {
    border-radius: 15px;
  }
  .ant-modal-header {
    border-radius: 15px;
  }

  .button-container {
    display: flex;
    justify-content: space-between;
  }
`;
export const K2pButton = styled(Button)`
  margin: auto 0;
  align-items: center;
  margin-right: 15px;
  border: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
`;
export const K2pButtonsecnd = styled(Button)`
  margin: auto 0;
  align-items: center;
  margin-right: 15px;
  border: 1px solid #47a4ea !important;
  box-shadow: none !important;
  color: #47a4ea !important;
  &:hover {
    border-color: blue !important; /* Change border color on hover */
    color: #47a4ea !important; /* Change font color on hover */
  }
  .blue-button {
    color: #47a4ea;
  }
`;

export const Topdivide = styled(Divider)`
  margin: 1px 0 !important;
`;
export const ActiveSectionCol = styled(Col)`
  background-color: #edeff4;
  .active-k2p-head {
    font-weight: 600;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 24px;
  }
`;
export const KeytophraseMineCol = styled(Col)`
  border-right: 1px solid #e1e4eb;
`;
export const StyledTree = styled(Tree)`
  background-color: #f5f7fa;

  & .ant-tree-list {
    background-color: #f5f7fa;
  }

  & .ant-tree-switcher-leaf-line {
    background-color: #f5f7fa;
  }

  & .ant-tree-switcher {
    background-color: #f5f7fa !important;
  }
  .dot-switcher {
    display: inline-block;
    width: 8px; /* Adjust as needed */
    height: 8px; /* Adjust as needed */
    border-radius: 50%;
    background-color: #828282; /* Adjust color as needed */
    margin-right: 5px; /* Adjust spacing as needed */
  }
`;
export const SearchInputStylee = styled(Input)`
  height: 34px;
  margin: auto 0;
  border: none;
  margin-top: 12px;
  align-items: center;
  &.ant-input-affix-wrapper {
    background: #ffffff;
    border: none;
  }
  @media (max-width: 925px) {
    width: 100%;
  }
`;

export const SearchDivider = styled(Divider)`
  margin: -3px 0 10px 0;
`;

export const RestrictColumImport = styled(Col)`
  .configurationHead {
    font-size: 16px;
    font-weight: 600;
    color: #2b2b2b;
  }
`;
