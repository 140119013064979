import styled from 'styled-components';

export const DemoVideoWrap = styled.div`
  padding: 0;
  border-radius: 12px;
`;

export const DemoVideoELementWrap = styled.video`
  border-radius: 15px;
  border: 1px solid #d0d0d0;
  overflow: hidden;

  &:focus,
  :active {
    outline: none;
  }
  @media (max-height: 800px) {
    height: 65vh;
  }
`;
