import React, { useEffect, useState } from 'react';
import PageTitle from '../../../../Utils/PageTitle';
import { TitleRow, ContainerRow } from '../../styledComponents';
import CustomTypography from '../../../CustomComponents/CustomTypography';
import { useSelector } from 'react-redux';
import SaveCard from './SaveCard';
import { Col, notification } from 'antd';
import {
  StyledRow,
  PaymentDetails,
  PaymentDetailItemContainer,
  Description,
  Amount,
  SubscriptionType,
  Month,
  SelectCardMessage
} from './styledComponents';
import CardManagement from './CardManagement';
import BreadCrumbComponent from '../../../CustomComponents/BreadCrumbComponent';
import Spinner from '../../../CustomComponents/Spinner';
import { useLocation } from 'react-router-dom';
import SavedCard from './SavedCard';
import { getCustomerProfile } from '../../../../Apis/Payment';
import { CURRENCY_SYMBOL } from '../../../../Constants/StringConstants';

import { StyledSelect } from './styledComponents';
import { getUserFacilities } from '../../../../Apis/User';

const { Option } = StyledSelect;

const Payment = () => {
  const [theme] = useSelector(state => [state.themeReducer]);
  const [addCard, setAddCard] = useState(false);
  const [loading, setLoading] = useState(false);
  const [savedCards, setSavedCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [facilities, setFacilities] = useState([]);
  const [facilityIds, setFacilityIds] = useState();
  const { facilityId, plan } = useLocation().state || {};

  let BreadCrubItems = [
    {
      name: 'Billing',
      path: '/dashboard/billing'
    },
    {
      name: 'Manage Cards',
      path: '/dashboard/settings/manage-cards'
    }
  ];

  useEffect(() => {
    if (facilityIds) {
      getPaymentProfiles();
    }
    // eslint-disable-next-line
  }, [facilityIds]);

  useEffect(() => {
    getFacilities();
    // eslint-disable-next-line
  }, []);

  const getPaymentProfiles = () => {
    setLoading(true);

    getCustomerProfile(facilityIds)
      .then(response => {
        if (response.data.success) {
          setSavedCards(response.data.result[0].paymentProfiles);
          setSelectedCard(response.data.result[0].paymentProfiles[0]);
          setLoading(false);
        } else {
          notification['error']({
            message: 'Payment Profile Fetch Failed',
            description: response.data.message
          });
          setLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
        setSavedCards([]);
        setSelectedCard(null);
      });
  };

  const getFacilities = () => {
    setLoading(true);
    getUserFacilities().then(response => {
      if (response.data.success) {
        const enabledFacilities = response.data.result.filter((facility) => {
          return !facility.disabled;
        });
        setFacilities(enabledFacilities);
        setFacilityIds(facilityId || enabledFacilities[0]?.facilityId);
        setLoading(false);
      } else {
        notification['error']({
          message: 'User Facilities Fetch Failed',
          description: response.data.message
        });
        setLoading(false);
      }
    });
  };

  return loading ? (
    <Spinner />
  ) : (
    <>
      <PageTitle title='Manage Cards' />
      <ContainerRow gutter={[0, 40]}>
        <TitleRow justify='space-between'>
          <CustomTypography color={theme['@text-color']}>
            Manage Cards
          </CustomTypography>

          <StyledSelect
            value={facilityIds}
            placeholder='Filter'
            onSelect={setFacilityIds}
          >
            {facilities.length > 0 &&
              facilities.map(item => {
                return (
                  <Option value={item.facilityId}>{item.facilityName}</Option>
                );
              })}
          </StyledSelect>
        </TitleRow>
        <BreadCrumbComponent items={BreadCrubItems} />
        <StyledRow justify='center'>
          {plan && (
            <PaymentDetails>
              <PaymentDetailItemContainer>
                <Description> Subscription Plan </Description>
                <SubscriptionType>{plan.details.title}</SubscriptionType>
              </PaymentDetailItemContainer>
              <PaymentDetailItemContainer>
                <Description>Bill Amount</Description>
                <Amount>
                  {CURRENCY_SYMBOL + plan.details.amount}
                  <Month>{`/${plan.details.duration}`}</Month>
                </Amount>
              </PaymentDetailItemContainer>
            </PaymentDetails>
          )}
          {plan && <Col span={1}></Col>}

          <Col span={7}>
            {plan && (
              <SelectCardMessage>Select the Card for Payment</SelectCardMessage>
            )}
            <CardManagement
              theme={theme}
              setAddCard={setAddCard}
              savedCards={savedCards}
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
              subscriptionDetails={plan}
            />
          </Col>
          <Col span={1}></Col>
          <Col lg={9} xl={7} style={{ marginTop: '45px' }}>
            {selectedCard ? (
              <SavedCard
                facilityId={facilityIds}
                selectedCard={selectedCard}
                theme={theme}
                paymentDetails={plan}
                getPaymentProfiles={getPaymentProfiles}
              />
            ) : (
              <SaveCard
                facilityId={facilityIds}
                theme={theme}
                addCard={addCard}
                selectedCard={selectedCard}
                getPaymentProfiles={getPaymentProfiles}
              />
            )}
          </Col>

          <Col span={1}></Col>

          <Col span={6}></Col>

          <Col span={1}></Col>
        </StyledRow>
      </ContainerRow>
    </>
  );
};

export default Payment;
