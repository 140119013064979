import { Form, Row, Col, Space, notification } from 'antd';
import React, { useState } from 'react';
import {
  CLIENT_KEY,
  NAME,
  NAME_REGEX_CHECK
} from '../../../../Constants/StringConstants';
import { generateOpaqueData } from '../../../../Apis/AuthoriseAPIS';
import FloatLabel from '../../../CustomComponents/FloatingLabel';
import {
  ButtonRow,
  SaveCardContainer,
  StyledButton,
  CardVendorLogo
} from './styledComponents';
import { ReloadOutlined, CreditCardOutlined } from '@ant-design/icons';
import { getCardIcon } from './cardIconMap';
import DefaultIcon from '../../../../Assets/CardVendors/default.svg';

import { saveCard } from '../../../../Apis/Payment';
import moment from 'moment';

const SaveCard = ({
  facilityId,
  theme,
  addCard,
  selectedCard,
  getPaymentProfiles
}) => {
  const [form] = Form.useForm();
  const [cardIcon, setCardIcon] = useState(DefaultIcon);

  const [addLoading, setAddLoading] = useState(false);
  const [validateStatus, setValidateStatus] = useState(false);

  const onCreditCardTypeChanged = type => {
    if (!selectedCard) {
      console.log(type, 'type');
      const icon = getCardIcon(type);
      setCardIcon(icon);
    }
  };

  const saveCardFunction = value => {
    setAddLoading(true);

    let expiryDate = value.expiryDate.split('/').join('20');
    let cardNumber = value.cardNumber.split(' ').join('');

    let data = {
      securePaymentContainerRequest: {
        merchantAuthentication: {
          name: NAME,
          clientKey: CLIENT_KEY
        },
        data: {
          type: 'TOKEN',
          id: facilityId,
          token: {
            cardNumber: cardNumber,
            expirationDate: expiryDate,
            cardCode: value.cvv
          }
        }
      }
    };

    generateOpaqueData(data).then(response => {
      if (response.data.messages.resultCode === 'Ok') {
        let datas = {
          fullName: value.cardHolderName,
          opaqueData: response.data.opaqueData
        };
        saveCard(datas, facilityId).then(saveResponse => {
          if (saveResponse.data.success) {
            notification['success']({
              message: 'Success',
              description: saveResponse.data.message
            });
            getPaymentProfiles();
            setAddLoading(false);
          } else {
            notification['error']({
              message: 'Error',
              description: saveResponse.data.message
            });
            setAddLoading(false);
          }
        });
      } else {
        notification['error']({
          message: 'Error',
          description: 'Add card failed'
        });
        setAddLoading(false);
      }
    });
  };

  const dateValidation = date => {
    let now = moment().format('MM/YY').split('/');
    let momentDate = date.split('/');
    if (
      now[1] < momentDate[1] ||
      (now[1] === momentDate[1] && now[0] <= momentDate[0])
    ) {
      setValidateStatus(false);
    } else {
      setValidateStatus(true);
    }
  };

  return (
    <SaveCardContainer>
      <Form
        layout='vertical'
        onFinish={val => saveCardFunction(val)}
        form={form}
      >
        <Row justify='center' gutter={[24]}>
          <Col span={24}>
            <Form.Item
              name='cardNumber'
              rules={[{ required: true, message: 'Card Number is required' }]}
            >
              <FloatLabel
                label='Card Number'
                primaryColor={theme['@text-color']}
                type='creditCard'
                creditCardProps={{
                  options: {
                    creditCard: true,
                    onCreditCardTypeChanged
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name='cardHolderName'
              rules={[
                { required: true, message: 'Cardholder Name is required' },
                {
                  pattern: new RegExp(NAME_REGEX_CHECK),
                  message: 'Please enter only alphabets'
                }
              ]}
            >
              <FloatLabel
                label='Card Holder Name'
                primaryColor={theme['@text-color']}
                type='input'
              />
            </Form.Item>
          </Col>
          {!selectedCard && (
            <>
              <Col span={12}>
                <Form.Item
                  name='expiryDate'
                  rules={[
                    { required: true, message: 'Expiry Date is required' }
                  ]}
                  validateStatus={validateStatus ? 'error' : null}
                  help={validateStatus && 'Card Expired'}
                  onBlur={e => dateValidation(e.target.value)}
                >
                  <FloatLabel
                    label='Expiry Date'
                    primaryColor={theme['@text-color']}
                    type='creditCard'
                    creditCardProps={{
                      options: {
                        date: true,
                        datePattern: ['m', 'd']
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='cvv'
                  rules={[{ required: true, message: 'CVV is required' }]}
                >
                  <FloatLabel
                    label='CVV'
                    primaryColor={theme['@text-color']}
                    type='creditCard'
                    creditCardProps={{
                      options: {
                        blocks: [3],
                        numericOnly: true,
                        mask: true
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
        <ButtonRow justify='end'>
          <Space size='large'>
            <StyledButton
              type=''
              onClick={() => form.resetFields()}
              icon={<ReloadOutlined />}
            >
              Reset
            </StyledButton>
            <StyledButton
              type='primary'
              htmlType='submit'
              icon={<CreditCardOutlined />}
              loading={addLoading}
              disabled={validateStatus}
            >
              Add Card
            </StyledButton>
          </Space>
        </ButtonRow>
      </Form>
      <CardVendorLogo>
        <img src={cardIcon} alt='Card Icon' />
      </CardVendorLogo>
    </SaveCardContainer>
  );
};

export default SaveCard;
