import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Checkbox, Space, Select } from 'antd';
import FloatLabel from '../../../CustomComponents/FloatingLabel';
import { useSelector } from 'react-redux';
import { CustomRow, SaveButton, CancelButton } from './styledComponents';
import { SaveOutlined, CloseOutlined } from '@ant-design/icons';
import 'react-phone-input-2/lib/style.css';
// import { FacilityRemainingCount } from './styledComponents';
import {
  getFacilites,
  getReviewers,
  getTranscriptionists
} from '../../../../Apis/Facility';
import { FacilityRemainingCount } from '../../Admins/styledComponents';
const { Option } = Select;
const TranscriptionFlow = ({
  saveProfileInformation1,
  selectedItem,
  profile,
  getFacilities,
  facilities,
  edit,
  setEdit,
  saveLoading
}) => {
  const [form] = Form.useForm();
  const [theme] = useSelector(state => [state.themeReducer]);
  const [user] = useSelector(state => [state.userReducer]);

  const [transcricption, setTranscricption] = useState([]);
  const [selfReviewEnabled, setSelfReviewEnabled] = useState();
  const [isSelected, setIsSelected] = useState([]);

  const [transcriptionists, setTranscriptionists] = useState([]);
  const [reviewerslist, setReviewerslist] = useState([]);
  const reviwerscount = [
    { name: '1', value: '1' },
    { name: '2', value: '2' },
    { name: '3', value: '3' },
    { name: '4', value: '4' }
  ];
  const transcriptionflow = [
    { name: 'Transcriptionist only', value: 'TRANSCRIPTION_ONLY' },
    { name: 'Transcriptionist and Reviewer', value: 'TRANSCRIPTION_REVIEWER' }
  ];

  useEffect(() => {
    setSelfReviewEnabled(
      profile.selfReviewEnabled ? profile.selfReviewEnabled : false
    );

    form.setFieldsValue({
      ...profile,

      selfReviewEnabled: profile.selfReviewEnabled
        ? profile.selfReviewEnabled
        : false,
      transcriptionistId: profile.transcriptionistId?.id || '',
      reviewerList: profile.reviewerList?.map(reviewer => reviewer.id) || [],
      transcriptionFlowType: profile.transcriptionFlowType || '',
      reviewerCount:
        profile.reviewerCount !== null && profile.reviewerCount !== undefined
          ? profile.reviewerCount.toString()
          : ' '
    });

    setTranscricption(
      form.getFieldValue(['transcriptionFlowType']) === 'TRANSCRIPTION_ONLY'
    );

    if (profile.transcriptionFlowType === 'TRANSCRIPTION_ONLY') {
      form.setFieldsValue({ reviewerCount: '0' });
    }
  }, [profile]);

  // useEffect(() => {
  //   getTranscriptionists().then(res => {
  //     const data = res.data.result;

  //     const transcriptionist = data.map(item => {
  //       return {
  //         value: item.id,
  //         name: item.fullName
  //       };
  //     });

  //     setTranscriptionists(transcriptionist);
  //   });

  //   getReviewers()
  //     .then(res => {
  //       const data = res.data.result;

  //       const reviewerslists = data.map(item => {
  //         return {
  //           value: item.id,
  //           name: item.fullName
  //         };
  //       });

  //       setReviewerslist(reviewerslists);
  //     })

  //     .catch(error => {
  //       console.error('Error fetching data:', error);
  //     });
  //   setIsSelected(!form.getFieldValue('transcriptionFlowType'));
  // }, []);
  const onFormSubmit = values => {
    let payload = values;

    if (!payload.selfReviewEnabled) {
      payload.reviewerList = form.getFieldValue(['reviewerList']).changedValue
        ? form.getFieldValue(['reviewerList']).changedValue
        : form.getFieldValue(['reviewerList']);
      payload.transcriptionFlowType = form.getFieldValue([
        'transcriptionFlowType'
      ]).changedValue
        ? form.getFieldValue(['transcriptionFlowType']).changedValue
        : form.getFieldValue(['transcriptionFlowType']);
      payload.reviewerCount = form.getFieldValue(['reviewerCount']).changedValue
        ? form.getFieldValue(['reviewerCount']).changedValue
        : form.getFieldValue(['reviewerCount']);
      payload.transcriptionistId = form.getFieldValue(['transcriptionistId'])
        .changedValue
        ? form.getFieldValue(['transcriptionistId']).changedValue
        : form.getFieldValue(['transcriptionistId']);
    }

    saveProfileInformation1(payload);
  };

  const cancelEdit = () => {
    setEdit(false);
    form.resetFields();

    form.setFieldsValue({ ...profile });
  };

  const handleCheckboxChange = e => {
    setSelfReviewEnabled(e.target.checked);
  };

  const transcriptionFlowChange = changedValue => {
    if (changedValue === 'TRANSCRIPTION_ONLY') {
      form.setFieldsValue({ reviewerCount: '0' });
      console.log('hi', form.getFieldValue('transcriptionFlowType'));
      setTranscricption(
        form.getFieldValue('transcriptionFlowType') === 'TRANSCRIPTION_ONLY'
      );
    } else {
      setTranscricption(false);
    }
  };
  const numberOfSelectedItems = 5;
  const remainingCount =
    form.getFieldValue('reviewerCount') - numberOfSelectedItems;
  let facilityIds = [];
  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={values => onFormSubmit(values)}
    >
      <Row justify='center'>
        <Col span={24}>
          <div>
            <br></br>
            <Form.Item
              label='Self Review Enabled'
              name='selfReviewEnabled'
              valuePropName='checked'
            >
              <Checkbox
                checked={selfReviewEnabled}
                onChange={handleCheckboxChange}
                disabled={!edit}
              >
                Enable Self Review
              </Checkbox>
            </Form.Item>
          </div>
        </Col>
      </Row>
      {!selfReviewEnabled ? (
        <Row justify='center' disabled={true}>
          <Col span={24}>
            <Form.Item
              label=' Transcriptionist Flow'
              name='transcriptionFlowType'
              rules={[
                {
                  required: true,
                  message: 'Trascriptionist flow is required'
                }
              ]}
            >
              <Select
                size='large'
                onChange={transcriptionFlowChange}
                disabled={!edit}
              >
                <Option value='TRANSCRIPTION_ONLY'>Transcription Only</Option>
                <Option value='TRANSCRIPTION_REVIEWER'>
                  Transcription and Reviewer
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label='Reviewer Count'
              name='reviewerCount'
              rules={[
                {
                  required: true,
                  message: 'Trascriptionist flow is required'
                }
              ]}
            >
              <Select
                size='large'
                getPopupContainer={trigger => trigger.parentNode}
                disabled={!edit || transcricption}
              >
                <Option value='1'>1</Option>
                <Option value='2'>2</Option>
                <Option value='3'>3</Option>
                <Option value='4'>4</Option>
                <Option value='5'>5</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      ) : (
        <Row></Row>
      )}
      <br />
      {edit === true && (
        <CustomRow justify='end'>
          <Space size={20}>
            <CancelButton onClick={cancelEdit} icon={<CloseOutlined />}>
              Cancel
            </CancelButton>

            <SaveButton
              type='primary'
              htmlType='submit'
              icon={<SaveOutlined />}
              loading={saveLoading}
            >
              Save
            </SaveButton>
          </Space>
        </CustomRow>
      )}
    </Form>
  );
};

export default TranscriptionFlow;
