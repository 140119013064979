import React, { useState, useEffect } from 'react';
import { getUserFacilities } from '../../../Apis/User';
import { Dropdown, notification, Checkbox } from 'antd';
import VectorIcon from '../../../Assets/Icons/VectorIcon';

import {
  DropdownWrapper,
  StyledSearch,
  ActionContainer,
  FacilitiesContainer,
  FacilityItem,
  FacilityName,
  HeadingText,
  StyledButton,
  StyledFilterButton,
  FilterButtonContent,
  FilterButtonTittle,
  FilterCountValue
} from './styledComponents';

const DropDownRender = ({
  facilities,
  setSelectedValues,
  currentValues,
  setCurrentValues,
}) => {
  const [filterValue, setFilterValue] = useState(facilities);
  const [searchValue, setSearchValue] = useState('');

  const onClear = () => {
    setCurrentValues(new Set());
    setSelectedValues(new Set());
    setSearchValue('');
    setFilterValue(facilities);
  };
  const onApply = () => {
    setSelectedValues([...currentValues]);
  };

  const filterSearch = value => {
    setSearchValue(value);
    let filterFacility;
    if (value) {
      filterFacility = facilities.filter(item =>
        item.facilityName.toLowerCase().includes(value.toLowerCase())
      );
    } else {
      filterFacility = facilities;
    }
    setFilterValue(filterFacility);
  };
  const handleFacilityChange = (id) => {
    if (currentValues.has(id)) {
      currentValues.delete(id);
    } else {
      currentValues.add(id);
    }
    setCurrentValues(new Set(currentValues));
  };

  return (
    <DropdownWrapper>
      <HeadingText>Filter by Facility</HeadingText>
      <StyledSearch
        placeholder='Search'
        value={searchValue}
        onChange={event => filterSearch(event.target.value)}
      ></StyledSearch>
      <FacilitiesContainer>
        {filterValue.map(facility => (
          <>
            <FacilityItem selected={currentValues.has(facility.facilityId)}>
              <Checkbox
                checked={currentValues.has(facility.facilityId)}
                onClick={() => handleFacilityChange(facility.facilityId)}
              >
                <FacilityName disabled = {facility.disabled}>{facility.facilityName}</FacilityName>
              </Checkbox>
            </FacilityItem>
          </>
        ))}
      </FacilitiesContainer>

      <ActionContainer>
        <StyledButton apply={false} onClick={onClear}>
          Clear
        </StyledButton>
        <StyledButton apply={true} onClick={onApply}>
          Apply
        </StyledButton>
      </ActionContainer>
    </DropdownWrapper>
  );
};

const FacilityFilter = ({ setSelectedValues }) => {
  const [facilities, setFacilities] = useState([]);
  const [currentValues, setCurrentValues] = useState(new Set());

  const getFacilities = () => {
    getUserFacilities().then(response => {
      if (response.data.success) {
        setFacilities(response.data.result);
      } else {
        notification['error']({
          message: 'User Facilities Fetch Failed',
          description: response.data.message
        });
      }
    });
  };
  useEffect(() => {
    getFacilities();
  }, []);

  return (
    <>
      <Dropdown
        defaultValue='Filters'
        overlay={
          <DropDownRender
            facilities={facilities}
            setSelectedValues={setSelectedValues}
            currentValues={currentValues}
            setCurrentValues={setCurrentValues}
          />
        }
      >
        <StyledFilterButton>
          <FilterButtonContent>
            <FilterButtonTittle>Filters</FilterButtonTittle>
            <VectorIcon />
          </FilterButtonContent>
          {currentValues.size && currentValues.size>0 ?<FilterCountValue>{currentValues.size}</FilterCountValue> : <></>}
        </StyledFilterButton>
      </Dropdown>
    </>
  );
};

export default FacilityFilter;
