import React from 'react';

function CallIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      x='0'
      y='0'
      enableBackground='new 0 0 512.1 512.1'
      version='1.1'
      viewBox='0 0 512.1 512.1'
      xmlSpace='preserve'
      height='18px'
      width='27px'
    >
      <path
        d='M499.6 396L396 326.9c-13.2-8.7-30.8-5.8-40.5 6.6l-30.2 38.8c-3.9 5.1-10.9 6.6-16.5 3.5l-5.7-3.2c-19-10.4-42.7-23.3-90.5-71s-60.7-71.4-71-90.5l-3.1-5.7c-3.2-5.6-1.7-12.7 3.4-16.6l38.8-30.2c12.4-9.7 15.3-27.4 6.6-40.5L118 14.5C109.1 1.1 91.2-3 77.5 5.3l-43.3 26c-13.6 8-23.6 21-27.9 36.2-15.6 56.9-3.9 155 140.7 299.6C262 482.1 347.6 513 406.5 513c13.5.1 27-1.7 40.1-5.2 15.2-4.3 28.2-14.3 36.2-27.9l26.1-43.3c8.2-13.8 4.1-31.6-9.3-40.6zm-5.5 31.9l-26 43.3c-5.7 9.8-15.1 17.1-26 20.2-52.5 14.4-144.2 2.5-283-136.3S8.3 124.6 22.7 72.1c3.1-11 10.4-20.3 20.2-26.1l43.3-26c6-3.6 13.7-1.9 17.6 4l37.5 56.4 31.5 47.3c3.8 5.7 2.5 13.4-2.9 17.6l-38.8 30.2c-11.8 9-15.2 25.4-7.9 38.3l3.1 5.6c10.9 20 24.5 44.9 74 94.4 49.5 49.5 74.4 63.1 94.4 74l5.6 3.1c13 7.3 29.3 3.9 38.3-7.9l30.2-38.8c4.2-5.4 11.9-6.6 17.6-2.9L490 410.4c5.9 3.7 7.7 11.5 4.1 17.5z'
        transform='translate(-1 -1)'
      ></path>
    </svg>
  );
}

export default CallIcon;
