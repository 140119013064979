import React from "react";

function Hl7Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      height="100%"
      width="100%"
    >
      <path
        fill="#E2e5e7"
        d="M128 0c-17.6 0-32 14.4-32 32v448c0 17.6 14.4 32 32 32h320c17.6 0 32-14.4 32-32V128L352 0H128z"
      ></path>
      <path
        fill="#B0B7BD"
        d="M384 128h96L352 0v96c0 17.6 14.4 32 32 32z"
      ></path>
      <path fill="#CAD1D8" d="M480 224L384 128 480 128z"></path>
      <path
        fill="#50BEE8"
        d="M448 416c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V256c0-8.8 7.2-16 16-16h352c8.8 0 16 7.2 16 16v160z"
      ></path>
      <path
        fill="#CAD1D8"
        d="M400 432H96v16h304c8.8 0 16-7.2 16-16v-16c0 8.8-7.2 16-16 16z"
      ></path>
      <g fill="#FFF">
        <path d="M206.7 344.8h-39.5v39.3h-18.5v-92.3h18.5v38h39.5v-38h18.5v92.3h-18.5v-39.3zM262 291.8v77.6h30.4v14.7h-48.9v-92.3H262zM363.4 287.9v13.8l-35.2 82.4h-18.8l35.4-80.3h-45.2v-15.9h63.8z"></path>
      </g>
    </svg>
  );
}

export default Hl7Icon;
