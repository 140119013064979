import styled from 'styled-components';
import { Select, DatePicker, Row, Col, Button } from 'antd';

const { RangePicker } = DatePicker;

export const StatusDiv = styled.div`
  background-color: ${props =>
    props.status === 'Success' ? '#9CF2C0' : '#FFB7B4'};
  border-radius: 40px;
  text-align: center;
  padding: 5px;
  color: ${props => (props.status === 'Success' ? '#14A951' : '#F2554E')};
`;

export const StyledSelect = styled(Select)`
  border-radius: 5px;
  margin-right: 20px;
  width: 140px;
  @media (max-width: 925px) {
    width: 100%;
  }
`;

export const StyledMultiSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 5px;
    padding: 0 8px; /* Adjust padding */
  }

  .ant-select-selection-item {
    border-radius: 5px;
  }

  .ant-select-selection-placeholder {
    border-radius: 5px;
  }

  .ant-select-selection-search-input {
    border-radius: 5px;
  }
  .custom-select {
    width: 200px;
    min-width: 200px;
  }
`;

export const StyledRangePicker = styled(RangePicker)`
  margin-right: 20px;
`;

export const SessionInfoContainer = styled.div`
  background: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
`;
export const BillerHeaderSection = styled.div`
  display: flex;
  align-items: end;
`;
export const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const UserInfoRow = styled(Row)`
  margin: 10px 0;
`;

export const InfoContainer = styled.div`
  display: flex;
  width: 40%;
  max-width: 40%;
  margin-right: 10px;
`;

export const InformationText = styled.p`
  font-size: 13px;
  color: ${props => props.color};
  margin: auto 0;
  text-transform: ${props => props.name && 'capitalize'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InformationTitle = styled.p`
  font-size: 13px;
  font-weight: 600;
  color: ${props => props.color};
  margin-bottom: 0;
`;

export const JobTableContainer = styled.div`
  margin: 10px;
`;

export const JobTableHeadingRow = styled(Row)`
  padding: 15px 0;
  border-bottom: 1px solid #dedede;
`;

export const JobTable = styled.div`
  position: relative;
  max-height: calc(100vh - 341px);
  overflow-y: auto;
  & .ant-spin {
    position: absolute;
    left: 50%;
  }
  @media only print {
    width: auto;
    height: auto;
    overflow: visible !important;
  }
`;

export const JobRow = styled(Row)`
  padding: 10px 0;
  border-bottom: solid 1px #dedede;
`;

export const StyledColumn = styled(Col)`
  display: flex;
  align-items: center;
`;

export const JobStatusDiv = styled.div`
  background-color: #dcdcdc;
  border-radius: 40px;
  text-align: center;
  padding: 5px;
  color: #656565;
  width: 120px;
  font-size: 12px;
  font-weight: 600;
`;

export const SelectViewOptions = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & .ant-select-selector,
  & .ant-input-affix-wrapper,
  & .ant-picker,
  & .ant-input-group-addon {
    border-radius: 6px !important;
  }
  @media (max-width: 1140px) {
    display: contents;
  }
  @media (max-width: 925px) {
    & .ant-input-group-addon {
      width: 50% !important;
    }
  }
`;

export const TabOptions = styled.div`
  display: flex;
  width: 200px;
`;

export const TabOption = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  cursor: pointer;
`;

export const TabName = styled.span`
  font-weight: ${props => (props.selected ? 'bolder' : null)};
  color: ${props => (props.selected ? '#525E6F !important' : null)};
`;

export const StatusLine = styled.div`
  height: 3px;
  width: 100%;
  background: ${props => (props.selected ? '#47a4ea' : 'lightgrey')};
`;

export const PrintButton = styled(Button)`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const StyledViewJobButton = styled(Button)`
  position: absolute;
  top: 20px;
  right: 150px;
`;

export const StyledFacilityContainer = styled.div`
  background-color: transparent;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
`;

export const StyledFacilityBlocks = styled.div`
  background-color: #47a4ea;
  border-radius: 14.3301px;
  color: #fff;
  width: 62.98px;
  font-size: 9px;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 5px;
`;

export const FacilityRemainingCount = styled.div`
  width: 18.8px;
  height: 18.8px;
  border-radius: 14.33px;
  background-color: #47a4ea;
  color: #ffffff;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 9px;
  font-weight: 900;
`;

export const StyledSpan = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 5px;
`;
export const StyledDivOptions = styled.div`
  display: flex;
  align-items: center;
  /* @media (max-width: 925px) {
    display:contents;
  } */
`;
export const FacilityStatusDiv = styled.div`
  background-color: ${props => (props.status ? '#9CF2C0' : '#FFB7B4')};
  border-radius: 40px;
  text-align: center;
  padding: 5px;
  color: ${props => (props.status ? '#14A951' : '#F2554E')};
  width: 140px;
  text-transform: capitalize;
  @media (max-width: 1500px) {
    width: 14vw;
  }

  @media (max-width: 1366px) {
    width: 140px;
  }

  @media (max-width: 994px) {
    width: 140px;
  }
`;
export const FacilityInfoContainer = styled.div`
  background: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
`;

export const FacilityInfoRow = styled(Row)`
  margin: 10px 0;
`;
export const FacilityInformationText = styled.p`
  font-size: 13px;
  color: ${props => props.color};
  margin: auto 0;
  text-transform: ${props => props.name && 'capitalize'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
`;

export const FacilityInformationTitle = styled.p`
  font-size: 13px;
  font-weight: 600;
  color: ${props => props.color};
  margin-bottom: 0;
  padding: 10px;
`;
export const FacilityLogTableContainer = styled.div`
  margin: 10px;
`;

export const FacilityLogTableHeadingRow = styled(Row)`
  padding: 15px 0;
  border-bottom: 1px solid #dedede;
`;

export const FacilityLogTable = styled.div`
  position: relative;
  max-height: calc(100vh - 341px);
  overflow-y: auto;
  & .ant-spin {
    position: absolute;
    left: 50%;
  }
  @media only print {
    width: auto;
    height: auto;
    overflow: visible !important;
  }
`;
export const FacilityLogRow = styled(Row)`
  padding: 10px 0;
  border-bottom: solid 1px #dedede;
`;
