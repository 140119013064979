const transcriptionistJobListSideBarConfig = [
  {
    key: '0',
    name: 'Ready',
    countValueKey: 'availableJobs',
    path: '/dashboard/jobs-list/ready'
  },
  {
    key: '2',
    name: 'In Progress',
    countValueKey: 'inProgressJobs',
    path: '/dashboard/jobs-list/in-progress'
  },
  {
    key: '5',
    name: 'Incomplete',
    countValueKey: 'inCompleteJobs',
    path: '/dashboard/jobs-list/incomplete'
  }
];

const reviewerJobListSideBarConfig = [
  {
    key: '0',
    name: 'Ready',
    countValueKey: 'availableTranscriptions',
    path: '/dashboard/jobs-list/ready'
  },
  {
    key: '2',
    name: 'In Progress',
    countValueKey: 'inProgressTranscriptions',
    path: '/dashboard/jobs-list/in-progress'
  },
  {
    key: '5',
    name: 'Incomplete',
    countValueKey: 'inCompleteTranscriptions',
    path: '/dashboard/jobs-list/incomplete'
  }
];

const jobListContentsMap = new Map([
  ['transcriptionist', transcriptionistJobListSideBarConfig],
  ['reviewer', reviewerJobListSideBarConfig]
]);

export const sideBarContents = userType => jobListContentsMap.get(userType);
