import React from "react";

function DataIcon() {
  return (
    <span className="anticon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
      >
        <g fill="#666" clip-path="url(#clip0)">
          <path d="M9 4.5c-.921 0-9-.063-9-2.25S8.079 0 9 0c.921 0 9 .063 9 2.25S9.921 4.5 9 4.5zM1.191 2.25C1.74 2.676 4.375 3.375 9 3.375s7.26-.699 7.809-1.125C16.26 1.824 13.625 1.125 9 1.125s-7.26.699-7.809 1.125zm15.704.087h.008-.008zM9 9c-.921 0-9-.063-9-2.25a.563.563 0 011.121-.064C1.468 7.1 4.131 7.875 9 7.875c4.87 0 7.532-.775 7.879-1.19A.563.563 0 0118 6.75C18 8.937 9.921 9 9 9zm7.875-2.25zm-15.75 0zM9 13.5c-.921 0-9-.063-9-2.25a.563.563 0 011.121-.065c.347.415 3.01 1.19 7.879 1.19 4.87 0 7.532-.775 7.879-1.19A.563.563 0 0118 11.25c0 2.187-8.079 2.25-9 2.25zm7.875-2.25zm-15.75 0z"></path>
          <path d="M9 18c-.921 0-9-.063-9-2.25V2.25a.563.563 0 011.125 0v13.44c.36.416 3.022 1.185 7.875 1.185 4.852 0 7.514-.769 7.875-1.186V2.25a.563.563 0 011.125 0v13.5C18 17.937 9.921 18 9 18z"></path>
          <path d="M3.75 11.25a.75.75 0 100-1.5.75.75 0 000 1.5zM3.75 6.75a.75.75 0 100-1.5.75.75 0 000 1.5zM3.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5z"></path>
        </g>
        <defs>
          <clipPath id="clip0">
            <path fill="#fff" d="M0 0H18V18H0z"></path>
          </clipPath>
        </defs>
      </svg>
    </span>
  );
}

export default DataIcon;
