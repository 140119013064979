import React, { useState, useEffect } from 'react';
import {
  ContainerRow,
  TitleRow,
  ProfileComponent,
  PicturePreview,
  ProfileStyle,
  PictureComponent,
  DeleteIconButton
} from '../../styledComponents';
import { Popconfirm } from 'antd';
import CustomTypography from '../../../CustomComponents/CustomTypography';
import BreadCrumbComponent from '../../../CustomComponents/BreadCrumbComponent';
import { notification } from 'antd';
import DraggerComponent from './DraggerComponent';
import DefaultPic from '../../../../Assets/Images/default.png';
import ProfileBasicInformation from './ProfileBasicInformation';
import { DeleteOutlined } from '@ant-design/icons';
import { PopoverGlobalStyle } from '../../JobList/styledComponents';
import { ActionButton } from '../../../CustomComponents/Table/styledComponents';
import { Tooltip } from 'antd';
import {
  UserUpdateApi,
  UserTranscriptionFlowApi,
  UserProfileUpdateApi,
  UserApi,
  DeleteProfilePicture,
  FetchProfilePicture
} from '../../../../Apis/User';
import { EmailPasswordReset } from '../../../../Apis/User';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AddUser, ClearUser } from '../../../../Redux/Actions/User';
import PageTitle from '../../../../Utils/PageTitle';
import Spinner from '../../../CustomComponents/Spinner';

const ProfileInformation = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedTab, setSelectedTab] = useState('Basic Information');
  const [uploadLoading, setUploadLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState([]);
  const [edit, setEdit] = useState(false);
  const [passwordEditDisable, setPasswordEditDisable] = useState(false);
  const [isDefault, setisDefault] = useState(false);
  const user = useSelector(state => state.userReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const [theme] = useSelector(state => [state.themeReducer]);

  let BreadCrubItems = [
    {
      name: 'Settings',
      path: '/dashboard/settings'
    },
    {
      name: 'Profile',
      path: '/dashboard/settings/profile'
    },
    {
      name: selectedTab
    }
  ];

  function useeffectFunction() {
    setLoading(true);
    if (user.id) {
      UserApi(user.id)
        .then(response => {
          if (response.data.success === true) {
            let profile = {
              ...response.data.result[0],
              address: {
                ...response.data.result[0].address,
                country: {
                  changedValue:
                    response.data.result[0].address &&
                    response.data.result[0].address.country
                }
              }
            };
            setProfile(profile);
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch(err => {
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    useeffectFunction();
    useeffectFetchImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  function useeffectFetchImage() {
    FetchProfilePicture(user.id)
      .then(response => {
        if (response.data.success === true) {
          let userNew = user;
          userNew.profilePic = response.data.result[0];
          setisDefault(response.data.defaultImage);
        }
      })
      .catch(err => {
        setLoading(false);
      });
  }

  function saveProfileInformation(profileValues) {
    setSaveLoading(true);
    UserUpdateApi(profileValues)
      .then(response => {
        if (response.data.success === true) {
          setSaveLoading(false);
          dispatch(AddUser({ ...user, ...response.data.result[0] }));
          localStorage.setItem(
            'user',
            JSON.stringify({ ...user, ...response.data.result[0] })
          );
          useeffectFunction();
          notification['success']({
            message: 'Update Success',
            description: 'Successfully updated profile '
          });
          setEdit(false);
        } else {
          setSaveLoading(false);
          notification['error']({
            message: 'Update Failed',
            description: 'Unable to update Profile'
          });
        }
      })
      .catch(err => {
        setSaveLoading(false);
      });
  }

  function saveProfileInformation1(profileValues) {
    setSaveLoading(true);
    UserTranscriptionFlowApi(profileValues)
      .then(response => {
        if (response.data.success === true) {
          setSaveLoading(false);
          // dispatch(AddUser({ ...user, ...response.data.result[0] }));
          // localStorage.setItem(
          //   'user',
          //   JSON.stringify({ ...user, ...response.data.result[0] })
          // );
          // useeffectFunction();
          notification['success']({
            message: 'Update Success',
            description: 'Successfully updated profile '
          });
          setEdit(false);
        } else {
          setSaveLoading(false);
          notification['error']({
            message: 'Update Failed',
            description: 'Unable to update Profile'
          });
        }
      })
      .catch(err => {
        setSaveLoading(false);
      });
  }

  const deleteProfilePic = () => {
    setLoading(true);
    DeleteProfilePicture(user.id)
      .then(response => {
        if (response.data.success) {
          setLoading(false);
          useeffectFetchImage();
          notification['success']({
            message: 'Success',
            description: 'Profile photo removed successfully'
          });
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  function saveProfilePicture(file) {
    setUploadLoading(true);
    UserProfileUpdateApi(file, user.id)
      .then(response => {
        if (response.data.success === true) {
          setUploadLoading(false);
          let userNew = user;
          userNew.profilePic = response.data.result[0];
          dispatch(AddUser({ ...userNew }));
          notification['success']({
            message: 'Update Success',
            description: 'Profile photo updated successfully'
          });
          useeffectFunction();
        } else {
          setUploadLoading(false);
          notification['error']({
            message: 'Update Failed',
            description: 'Unable to update Profile Picture'
          });
        }
      })
      .catch(err => {
        setUploadLoading(false);
      });
  }

  function changePassword(passwordValues) {
    EmailPasswordReset(passwordValues).then(response => {
      if (response.data.success === true) {
        notification['success']({
          message: 'Credentials Changed',
          description: 'Please Login To Continue'
        });
        localStorage.clear();
        dispatch(ClearUser());
        history.push('/login');
      } else {
        notification['error']({
          message: 'Error',
          description:
            'Unable to update credentials. ' + response?.data?.message
        });
      }
    });
  }

  return (
    <>
      <PageTitle title='Profile' />
      <ContainerRow>
        <TitleRow span={24}>
          <CustomTypography color={theme['@text-color']}>
            Profile
          </CustomTypography>
        </TitleRow>
        <BreadCrumbComponent items={BreadCrubItems} />
        {loading ? (
          <Spinner />
        ) : (
          <ProfileComponent>
            <PictureComponent>
              {isDefault === false ? (
                <DeleteIconButton>
                  <Popconfirm
                    title='Are you sure you want to delete?'
                    onConfirm={() => deleteProfilePic()}
                    okText='Yes'
                    cancelText='No'
                  >
                    <PopoverGlobalStyle />
                    <Tooltip title='Delete Profile Pic'>
                      <ActionButton
                        icon={<DeleteOutlined />}
                        style={{ background: '#47a4ea', color: '#fff' }}
                      />
                    </Tooltip>
                  </Popconfirm>
                </DeleteIconButton>
              ) : null}

              <PicturePreview
                bgImage={
                  user.profilePic
                    ? user.profilePic
                    : selectedFile
                    ? URL.createObjectURL(selectedFile)
                    : DefaultPic
                }
              />
              <DraggerComponent
                setSelectedFile={setSelectedFile}
                saveProfilePicture={saveProfilePicture}
                uploadLoading={uploadLoading}
              />
            </PictureComponent>
            <ProfileStyle>
              <ProfileBasicInformation
                saveLoading={saveLoading}
                setSelectedTab={setSelectedTab}
                saveProfileInformation={saveProfileInformation}
                saveProfileInformation1={saveProfileInformation1}
                profile={profile}
                changePassword={changePassword}
                edit={edit}
                setEdit={setEdit}
                passwordEditDisable={passwordEditDisable}
                setPasswordEditDisable={setPasswordEditDisable}
              />
            </ProfileStyle>
          </ProfileComponent>
        )}
      </ContainerRow>
    </>
  );
};

export default ProfileInformation;
