import React from 'react';

function WarningIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='80'
      height='80'
      fill='none'
      viewBox='0 0 45 45'
    >
      <rect
        width='43.972'
        height='43.972'
        x='0.34'
        y='0.23'
        fill='#FFE1E1'
        rx='6'
      ></rect>
      <path
        fill='#FF1824'
        d='M9.59 31.211L22.323 9.217 35.057 31.21H9.59zm11.981-6.598h1.736V18.13h-1.736v6.483zm.868 3.212c.251 0 .459-.082.622-.246a.844.844 0 00.246-.622.844.844 0 00-.245-.622.843.843 0 00-.623-.246.843.843 0 00-.622.246.843.843 0 00-.246.622c0 .25.082.458.246.622a.843.843 0 00.622.246zm-9.84 1.65h19.448L22.324 12.69 12.6 29.475z'
      ></path>
    </svg>
  );
}

export default WarningIcon;
