import { Table as AntdTable, Button } from 'antd';
import styled from 'styled-components';

export const Table = styled(AntdTable)`
  & .ant-table table {
    // border: ${props => (props.jobList ? '2px solid #F5F5F5' : '')} ;
    border: '2px solid #F5F5F5' ;

  }
  & .ant-table-thead > tr > th {
    color: ${props => props.headColor};
    font-weight: 600;
    text-align: left;
    // background: ${props => (props.invite || props.jobList ? '#ffffff' : '#f0f2f5')};
    background-color: '#ffffff';
    font-size: 15px;
    // border-bottom: ${props => (props.jobList ? '1px solid #F0F2F5' : '2px solid #dedede')} ;
    border-bottom: '1px solid #F0F2F5';
  }

  & .ant-table-tbody > tr > td {
    // background: ${props => (props.invite || props.jobList ? '#ffffff' : '#f0f2f5')};
    background-color: '#ffffff';
    // border-bottom: ${props => (props.jobList ? '1px solid #F0F2F5' : '2px solid #dedede')};
    border-bottom: '1px solid #F0F2F5';
    font-weight: 500;
    color: ${props => `${props.textColor}cf`};
    font-size: 14px;
  }

  & .ant-table-tbody > tr:hover {
    cursor: pointer;
  }

  @media (max-width: 1500px) {
    & .ant-table-thead > tr > th {
      font-size: 13px;
    }

    & .ant-table-tbody > tr > td {
      font-size: 13px;
    }
  }

  & .ant-table-content {
    max-height: ${props =>
      props.invite
        ? 'calc(90vh - 280px)'
        : props.user
        ? 'calc(100vh - 225px)'
        : 'calc(100vh - 275px)'};
    // background: ${props => (props.invite ? '#fff' : '#f0f2f5')};
    overflow: visible !important;
  }

  & .ant-pagination li {
    border: none;
    background: transparent;
    :hover {
      border: none;
    }
  }

  & .ant-pagination .ant-pagination-prev button,
  & .ant-pagination .ant-pagination-next button {
    border: none;
  }

  & .ant-pagination .ant-pagination-prev:hover button,
  & .ant-pagination .ant-pagination-next:hover button {
    border: none;
  }

  &.ant-table-wrapper {
    margin-top: ${props => (props.jobList ? '0px' : '10px')};
    // background-color: ${props => (props.jobList ? '#F0F2F5' : '')};
    background-color: #F0F2F5;
  }

  & .ant-table {
    height: ${props =>
      props.invite
        ? 'calc(100vh - 370px)'
        : props.user
        ? 'calc(100vh - 225px)'
        : props.payment
        ? 'calc(100vh - 257px)'
        : props.jobList
        ? 'calc(100vh - 365px)'
        : 'calc(100vh - 260px)'};
    min-height: ${props => (props.user ? 'calc(100vh -225px)' : null)};
    // background: ${props => (props.invite ? '#fff' : '#f0f2f5')};
    background-color: #ffffff;
    overflow: auto;
  }

  & .ant-table-pagination.ant-pagination {
    margin: ${props => (props.jobList ? '16px' : '24px 0 0 0')};
  }

  &
    .ant-table-ping-right:not(.ant-table-has-fix-right)
    .ant-table-container::after {
    box-shadow: none;
  }
`;

export const EmptyMessageContainer = styled.div`
  height: calc(100vh - 450px);
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  background: white;
  margin-top: 20px;
`;

export const CapitaliseDiv = styled.div`
  text-transform: capitalize;
`;

export const ActionButton = styled(Button)`
  width: ${props => (props.invite ? '100px !important' : null)};
  &.ant-btn-icon-only {
    border-radius: 7px;
  }
`;
