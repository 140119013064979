import { Alert, Modal, Select } from 'antd';
import styled from 'styled-components';
import PCCSignInIcon from '../../../Assets/Images/pcc_sign_in_button_base_state.svg';
import PCCSignInIconHover from '../../../Assets/Images/pcc_sign_in_button_hover_state.svg';
import PCCSignInIconFocus from '../../../Assets/Images/pcc_sign_in_button_pressed_state.svg';

export const StyledDashboardCardContainer = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const StyledDashboardCard = styled.div`
    min-width: 360px;
    max-width: 500px;
    width: 20vw;
    min-height: 197px;
    margin-left: 20px;
    margin-top: 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
`;

export const StyledDashboardCardHeading = styled.div`
    margin-left : 19px;
    margin-top : 24px;

    font-family: 'Krub';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;


    color: #000000;
`;

export const StyledDashboardCardHeader = styled.div`
    width: 100%;
    min-height: 138px;
    margin-buttom: 25px;
    display: flex;
    flex-direction: row;
`;

export const StyledDashboardCardHeaderLeft = styled.div`
    width: 70%;
    display: flex;
    flex-direction: column;
`;

export const StyledDashboardCardHeaderRight = styled.div`
    width: 30%;
    display: flex;
    justify-content: end;
`;

export const StyledDashboardCardContent = styled.div`
    width: 48px;
    height: 62px;
    margin-left : 19px;
    margin-top: 9px;

    font-family: 'Krub';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 62px;

    color: ${({ color }) => (color)};

`;

export const StyledDashboardCardIcon = styled.div`
    width: 77px;
    height: 80px;

    display: flex;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 24px;
    margin-bottom: 24px;


    justify-content: center;
    align-items: center;

    background: ${({ color }) => (color)};
    border-radius: 5px;

`;

export const StyledDashboardCardFooter = styled.div`
    font-family: 'Krub';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    
    margin-left: 19px;

`;

export const StyledDashboardCardfooterHeading = styled.div`
    color: #C9C9C9;
`;

export const StyledDashboardCardFooterContent = styled.div`
    color: #78828F;
`;

export const StyledDashboardHeading = styled.h1`
    font-size: 22px;
    color: #525e6f;
    font-weight: 800;
`;
export const StyledDashboardHeadingContainer = styled.div`
    margin-top : 20px;
    margin-left : 30px;
    margin-right : 30px;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;

export const StyledDashboardClinicSelectContainer = styled.div`
`;

export const StyledDashboardClinicSelect = styled(Select)`
    width: 280px;
    &.ant-select > .ant-select-selector{
        height: 60px;
`;
export const StyledDashboardClinicSelectHeading = styled.div`
    font-size: 14px;
`;
export const StyledDashboardClinicSelectContent = styled.div`
    color: #C9C9C9;
    font-size: 12px;
`;

export const StyledFacilityCardContainer = styled.div`
    margin-left: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 10px;
`
export const StyledFacilitiesStatusCardContainer = styled.div`
    margin-left: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
`
export const StyledFacilityStatusCard = styled.div`
    min-width: 400px;
    max-width: 555px;
    width: 40vw;
    min-height: 100px;
    margin-left: 20px;
    margin-top: 20px;
    // padding: 10px;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
`
export const StyledFacilityStatusCardHeader = styled.div`
    padding: 16px;
    display: flex;
    gap: 5px;
    justify-content: space-between;
    border-bottom: 0.25px solid rgb(192, 192, 192);
`
export const StyledFacilityStatusCardHeaderFacilityName = styled.h2`
    align-items: center;
    margin: 0px;
    width: 100px;
    width: 100%;
    overflow-wrap: anywhere;
`
export const StyledFacilityStatusCardHeaderFacilityOID = styled.div`
    align-items: center;
    margin: 0px;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`
export const StyledFacilityStatusCardHeaderFacilityIcon = styled.div`
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const StyledFacilityStatusCardHeaderItem = styled.div`
    // max-width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const StyledFacilityStatusCardContent = styled.div`
    margin-left: 10px;
    padding: 10px;
`
export const StyledAlert = styled(Alert)`
    margin: 10px;
    align-items: center;
`;

export const PccSigninButton = styled.button`
    width: 110px;
    height: 50px;
    background : url(${PCCSignInIcon});
    border: none;
    padding: 0px;
    cursor: pointer;
    
    &:hover{
        background : url(${PCCSignInIconHover});
    }

    &:focus{
        background : url(${PCCSignInIconFocus});
    }
`;

export const FacilityDetailsButton = styled.button`
    border: none;
    padding: 0px;
    cursor: pointer;
    background: transparent;
`;

export const FacilityDetailsModal = styled(Modal)`
    & .ant-modal-content {
        border-radius: 8px;
    }

    & .ant-modal-header {
        border-radius: 10px 10px 0 0;
    }
`;