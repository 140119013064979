import { APPLY_THEME } from '../Actions/Theme';

const initialState = {
  '@primary-color': '#47A4EA',
  '@secondary-color': '#C0C0C0',
  '@text-color': '#525E6F',
  '@text-color-secondary': '#8D8989',
  '@label-color': '#8D8989',
  '@table-head-color': '#666666',
  '@table-text-color': ' #3f434a',
};
export const themeReducer = (state = initialState, action) => {
  if (action.type === APPLY_THEME) {
    return { ...state, ...action.color };
  }
  else {
    return state;
  }
  // switch (action.type) {
  //   case APPLY_THEME:
  //     return { ...state, ...action.color };
  //   default:
  //     return state;
  // }
};
