import React, { useState, useEffect, useCallback } from 'react';
import CustomTypography from '../../CustomComponents/CustomTypography';
import { ContainerRow, DividerStyle, TitleRow } from '../styledComponents';
import { useSelector } from 'react-redux';
import PageTitle from '../../../Utils/PageTitle';
import { notification, Tabs } from 'antd';
import { ListDiv } from '../JobList/styledComponents';
import { useLocation } from 'react-router-dom';
import { hl7List } from './SideBarConfig';
import { listHl7Files, uploadFiles, exportHl7File } from '../../../Apis/Clinic';
import { useDebounce } from '../../../Utils/Debounce';
import { SEARCH_DELAY } from '../../../Constants/StringConstants';
import CustomeSideDrawer from '../../CustomComponents/CustomSideDrawer';
import { ExportComments } from './ExportComments';
import ConfirmationModal from '../../CustomComponents/ConfirmationModal';
import ImportBody from './importBody';
import { ModalHeaderFlex } from '../Editor/InferenceEditor/styledComponents';
import SideMenu from './SideMenu';
import ViewController from './ViewController';
import FileInfo from './FileInfo';

const HL7 = () => {
  const location = useLocation();
  const { TabPane } = Tabs;
  const fileTypes = {
    0: 'DICTATION',
    1: 'PATIENT',
    2: 'CALENDAR'
  };
  const [theme] = useSelector(state => [state.themeReducer]);
  const [sideBarItems, setSideBarItems] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [user] = useSelector(state => [state.userReducer]);
  const [fileList, setFileList] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sortKey, setSortKey] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [searchString, setSearchString] = useState('');
  const [comments, setComments] = useState(null);
  const [commentsDrawerVisible, setCommentsDrawerVisible] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [isFolderView, setIsFolderView] = useState(false);
  const [folderImport, setFolderImport] = useState(true);
  const [uploadDocuments, setUploadDocuments] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [selectedFile, setSelectedFile] = useState('');
  const [showDetails, setShowDetails] = useState(false);
  const [listSelection, setListSelection] = useState(false);
  const [fileExportList, setFileExportList] = useState([]);
  const [exportComment, setExportComment] = useState('');
  const [selected, setSelected] = useState([]);

  const [activeKey, setActiveKey] = useState(() => {
    let activeItem;
    if (sideBarItems) {
      activeItem = sideBarItems.find(item => {
        let splitPath = location.pathname.split('/');
        let splitLink = item.path.split('/');
        if (splitPath[3] === splitLink[3]) return item;
        else return null;
      });
    }

    return activeItem && activeItem.key;
  });

  const filterTableChange = (pagination, filters, sorter) => {
    let sortByKey;
    let sortByOrder;
    if (sorter.length > 1) {
      sortByKey =
        sorter &&
        sorter.map(item => {
          return item.columnKey;
        });
      sortByOrder =
        sorter &&
        sorter.map(item => {
          return item.order;
        });

      setSortKey(sortByKey);
      setSortOrder(sortByOrder);
    } else {
      sortByKey = sorter.columnKey;
      sortByOrder = sorter.order;
      if (sorter.columnKey !== undefined) setSortKey(sortByKey);
      else setSortKey('');
      if (sorter.order !== undefined) setSortOrder(sortByOrder);
      else setSortOrder('');
    }
  };

  const exportFileDownload = () => {
    const payload = {
      comment: exportComment,
      fileNames: fileExportList
    };

    exportHl7File(user.clinicId, payload, fileTypes[activeKey])
      .then(response => {
        if (response.data) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `hl7.zip`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setImportModalOpen(false);
          getHl7files();
        }
      })
      .catch(err => {
        console.log(err, 'error');
        notification['error']({
          message: 'Download Failed',
          description: ''
        });
      });
  };

  const getHl7files = () => {
    setLoading(true);
    listHl7Files(
      user.clinicId,
      fileTypes[activeKey],
      searchString,
      currentPage,
      pageSize,
      sortOrder,
      sortKey
    )
      .then(response => {
        if (response.data.result) {
          setTotal(response.data.size);
          setFileList(response.data.result);
          setLoading(false);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
          setLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const onChangeHandler = value => {
    setSearchString(value);
  };

  const debounceOnChange = useCallback(
    useDebounce(onChangeHandler, SEARCH_DELAY)
  );

  const getComments = data => {
    let filteredData = data.filter(item => {
      return item.comment !== null && item.comment !== '';
    });
    setComments(filteredData);
  };

  const changeTabs = key => {
    if (key === '2') setShowDetails(false);
    else setShowDetails(true);
  };

  useEffect(() => {
    setSideBarItems(hl7List);
  }, []);

  useEffect(() => {
    if (activeKey) getHl7files();
    // eslint-disable-next-line
  }, [activeKey, sortKey, sortOrder, searchString, currentPage]);

  useEffect(() => {
    if (sideBarItems) {
      const activeItem = sideBarItems.find(item => {
        let splitPath = location.pathname.split('/');
        let splitLink = item.path.split('/');
        if (splitPath[3] === splitLink[3]) return item;
        else return null;
      });
      if (activeItem) {
        setActiveKey(activeItem.key);
      } else {
        setActiveKey('0');
      }
    }
  }, [sideBarItems, location.pathname]);

  const uploadHandler = () => {
    setUploadLoading(true);
    const formData = new FormData();
    for (let i = 0; i < uploadDocuments.length; i++) {
      formData.append('files', uploadDocuments[i]);
    }
    uploadFiles(formData)
      .then(response => {
        if (response.data.success) {
          setUploadLoading(false);
          setUploadDocuments([]);
          setImportModalOpen(false);
          getHl7files();
          notification['success']({
            message: 'Upload Successful',
            description: response.data.message
          });
        } else {
          setUploadLoading(false);
          notification['error']({
            message: 'Upload Failed',
            description: response.data.message
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <>
      <PageTitle title={'HL7'} />
      <ContainerRow>
        <TitleRow>
          <CustomTypography color={theme['@text-color']}>HL7</CustomTypography>
        </TitleRow>
        <ListDiv>
          <SideMenu
            activeKey={activeKey}
            sideBarItems={sideBarItems}
            theme={theme}
            setActiveKey={setActiveKey}
            setSelected={setSelected}
            setFileExportList={setFileExportList}
            setListSelection={setListSelection}
          />
          <DividerStyle type='vertical' vertical={true} />
          <ViewController
            setImportModalOpen={setImportModalOpen}
            setFolderImport={setFolderImport}
            theme={theme}
            sideBarItems={sideBarItems}
            activeKey={activeKey}
            isFolderView={isFolderView}
            setIsFolderView={setIsFolderView}
            debounceOnChange={debounceOnChange}
            fileList={fileList}
            total={total}
            loading={loading}
            currentPage={currentPage}
            filterTableChange={filterTableChange}
            setCurrentPage={setCurrentPage}
            getComments={getComments}
            setCommentsDrawerVisible={setCommentsDrawerVisible}
            pageSize={pageSize}
            setPageSize={setPageSize}
            setSelectedFile={setSelectedFile}
            setShowDetails={setShowDetails}
            listSelection={listSelection}
            setListSelection={setListSelection}
            setFileExportList={setFileExportList}
            selected={selected}
            setSelected={setSelected}
            getHl7files={getHl7files}
          />
        </ListDiv>
      </ContainerRow>
      <ConfirmationModal
        visible={importModalOpen}
        onCancel={() => {
          setUploadDocuments([]);
          setImportModalOpen(false);
        }}
        onOk={() => {
          listSelection ? exportFileDownload() : uploadHandler();
        }}
        antdModalProps={{ centered: true, width: '700px', closable: false }}
        header={
          <ModalHeaderFlex>
            <CustomTypography color={theme['@text-color']}>
              Import HL7
            </CustomTypography>
          </ModalHeaderFlex>
        }
        importExport={true}
        confirmation={true}
        buttonDisabled={uploadError}
        loading={uploadLoading}
      >
        <ImportBody
          folderImport={folderImport}
          listSelection={listSelection}
          setExportComment={setExportComment}
          uploadDocuments={uploadDocuments}
          setUploadDocuments={setUploadDocuments}
          uploadError={uploadError}
          setUploadError={setUploadError}
        />
      </ConfirmationModal>
      <CustomeSideDrawer
        title={isFolderView ? selectedFile.fileName : 'Comments'}
        placement='right'
        closable={false}
        onClose={() => {
          setComments(null);
          setCommentsDrawerVisible(false);
          setShowDetails(false);
        }}
        visible={commentsDrawerVisible}
        width={350}
        destroyOnClose={true}
      >
        {isFolderView && (
          <Tabs defaultActiveKey='1' onChange={changeTabs}>
            <TabPane tab='Details' key='1' />
            <TabPane tab='Comments' key='2' />
          </Tabs>
        )}
        {showDetails ? (
          <FileInfo selectedFile={selectedFile} />
        ) : (
          comments && (
            <ExportComments comments={comments} isFolderView={isFolderView} />
          )
        )}
      </CustomeSideDrawer>
    </>
  );
};

export default HL7;
