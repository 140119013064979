import React, { useState } from 'react';
import { MenuOutlined, CaretDownOutlined } from '@ant-design/icons';
import {
  StyledHeader,
  MenuButton,
  Username,
  HeaderProfileContainer,
  StyledMenu,
  StyledMenuItem,
  StyledProfileIcon,
  StyledSignOutIcon,
  NotificationProfileColumnStyle,
  StyledNotificationDiv,
  StyledPhoneIcon,
  StyledPrivacyPolicyIcon,
  StyledLoadingIcon
} from './styledComponents';
import { Row, Col, Dropdown, Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ClearUser } from '../../../../Redux/Actions/User';
import CustomAvatar from '../../../CustomComponents/CustomAvatar';
import Notification from '../Notification';
import { disconnectSocket } from '../../../../Socket';
import { logoutAPI } from '../../../../Apis/Auth';

function MainHeader({ toggle, setModalVisible }) {
  const [theme] = useSelector(state => [state.themeReducer]);
  const user = useSelector(state => state.userReducer);
  const history = useHistory();
  const dispatch = useDispatch();
  const [logoutLoading, setLogoutLoading] = useState(false);

  const menu = onDropDownClick => (
    <StyledMenu onClick={onDropDownClick}>
      <StyledMenuItem key='profile' icon={<StyledProfileIcon />}>
        <Username small={true} textColor={theme['@text-color']}>
          My Profile
        </Username>
      </StyledMenuItem>
      <StyledMenu.Divider />

      <StyledMenuItem key='contact' icon={<StyledPhoneIcon />}>
        <Username small={true} textColor={theme['@text-color']} contact={true}>
          Contact Us
        </Username>
      </StyledMenuItem>
      <StyledMenu.Divider />

      <StyledMenuItem key='privacy' icon={<StyledPrivacyPolicyIcon />}>
        <Username small={true} textColor={theme['@text-color']}>
          Privacy Policy
        </Username>
      </StyledMenuItem>
      <StyledMenu.Divider />

      <StyledMenuItem
        key='logout'
        icon={logoutLoading ? <StyledLoadingIcon /> : <StyledSignOutIcon />}
      >
        <Username small={true} textColor={theme['@text-color']}>
          Sign Out
        </Username>
      </StyledMenuItem>
    </StyledMenu>
  );


  const logoutHandler = () => {
    setLogoutLoading(true);
        logoutAPI(user.sessionId)
          .then(response => {
            if (response.data.success) {
              localStorage.clear();
              dispatch(ClearUser());
              history.push('/login');
              disconnectSocket();
            }
          })
          .catch(err => {
            setLogoutLoading(false);
          });
  }

  const clickEvents = {
    profile: () => {
      history.push('/dashboard/settings/profile');
    },
    logout: () => {
      if (user.unsavedStatus) {
        Modal.confirm({
          title: 'Unsaved changes',
          content: 'You have unsaved changes to your document.',
          className: 'warning-modal',
          okText: 'Leave',
          okType: 'danger',
          cancelText: 'Cancel',
          onOk: () => {
            logoutHandler();
          }
        });
      } else {
        logoutHandler();
      }
    },
    contact: () => {
      window.open('https://www.cascade.md/contact/');
    },
    privacy: () => {
      setModalVisible(true);
    }
  };

  const onDrpDownClick = ({ key }) => {
    clickEvents[key]();
  };

  const ProfileDropdown = () => {
    return (
      <HeaderProfileContainer>
        <Row align={'middle'} gutter={12} justify='end'>
          <Col>
            <CustomAvatar size={50} url={user && user.profilePic} />
          </Col>
          <Col>
            <Username textColor={theme['@text-color']}>
              {user && user.firstName + ' ' + user.lastName}
            </Username>
          </Col>
          <Col>
            <CaretDownOutlined style={{ color: theme['@text-color'] }} />
          </Col>
        </Row>
      </HeaderProfileContainer>
    );
  };

  return (
    <StyledHeader>
      <Row justify='space-between'>
        <Col span={3}>
          <MenuButton icon={<MenuOutlined />} onClick={toggle} />
        </Col>

        <NotificationProfileColumnStyle>
          <StyledNotificationDiv>
            <Notification />
          </StyledNotificationDiv>

          <Dropdown
            overlay={menu(onDrpDownClick)}
            placement='bottomRight'
            trigger='click'
          // getPopupContainer={(node) => {
          //   return document.getElementById("pop-cont")
          // }}
          >
            <a href=' ' style={{ width: '100%' }}>
              <ProfileDropdown />
            </a>
          </Dropdown>
        </NotificationProfileColumnStyle>
      </Row>
    </StyledHeader>
  );
}

export default MainHeader;
