import { Button, Input, Popconfirm} from 'antd';
import styled from 'styled-components';
const { Search } = Input;

export const HeadingActionsDiv = styled.div`
  margin: 0 5px;
  position: fixed;
  top: ${props => props.top + 'px'};
  left: ${props => props.left+20 + 'px'};
  opacity: .2;
  transition: all 0.2s ease-in;
  &:hover {
    opacity: 1;
  }
`;

export const HeadingActionsButton = styled(Button)`
`;

export const HeadingDeleteButton = styled.button`
  position: absolute;
  top: 11px;
  right: 33px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  color: #4d4e4f;

  &:hover {
    background: #c3c6c7;
  }
  background: #d2d5d6;
`;


export const StyledFloatingMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  position: fixed;
  top: ${({ position }) => position.top-40 + 'px'};
  left: ${({ position }) => position.right+50 + 'px'};

  margin-top: 30px;
  opacity: 1;
  background-color: #fff;
  color: #585f65;
  border: 1px solid #dadddf;
  border-radius: 3px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  max-height: 200px;
  overflow-y: auto;
`;

export const StyledFloatingMenuItem = styled.div`
  transition: all 0.05s;
  margin: 0;
  padding: 5px 10px;
  border: none;
  text-align: left;
  background-color: #fff;
  color: #585f65;
  cursor: pointer;
  :focus {
    outline: 0;
  }
  :active {
    outline: none;
    border: none;
    color: #585f65;
    background-color: #f5f5f5;
  }
  :hover {
    background-color: #f5f5f5;
  }
  ${props =>
    props.active &&
    `background-color: #f5f5f5;
    color:#000;
  `}
`;

export const FloatingMenuItemContainer = styled.div`
  overflow-y: auto;
`;

export const StyledSearch = styled(Search)`
  padding: 5px;
  width: calc(100% - 10px);
  margin: 5px auto;
`;

export const FloatingMenuControl = styled.div`
  position: absolute;
  top: ${props => props.cursorPosition.top - 15 + 'px'};
`;

export const StyledPopconfirm = styled(Popconfirm)`
`;