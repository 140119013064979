import styled from 'styled-components';
import { Breadcrumb } from 'antd';

export const StyledBreadCrumb = styled(Breadcrumb)`
  padding: 0;
  & .ant-breadcrumb a {
    font-size: 14px;
    color: #525e6f;
    font-weight: 500;
  }

  & .ant-breadcrumb-separator {
    font-size: 14px;
    margin: 0 12px;
  }

  & .ant-breadcrumb > span:last-child a {
    color: rgba(0, 0, 0, 0.85);
    padding-left: 0;
}
`;
