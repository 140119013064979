import styled from 'styled-components';
import { Input } from 'antd';

export const FacilityCardsContainer = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  margin-top: 30px;
  height: calc(100vh - 400px);
  width: 100%;
  overflow: auto;
`;

export const FacilityCardWrapper = styled.div`
  opacity: ${({ disabled }) => (disabled ? .5 : 1)};
  margin: 10px;
  height: 135px;
  width: 260px;
  padding: 20px;
  background: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    transform: scale(1.03);
    transition: 0.25s all linear;
  }
`;

export const FacilityCardHeader = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  & svg {
    margin-right: 15px;
  }
`;
export const FacilityName = styled.div`
  white-space: wrap;

  text-overflow: ellipsis;

  overflow: hidden;
`;

export const FacilityInfo = styled.div`
  display: flex;
  & span {
    width: 50%;
    padding: 5px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const SearchInputStyle = styled(Input)`
  width: 299.56px;
  height: 34px;
  margin: auto 0;
  align-items: center;
  &.ant-input-affix-wrapper {
    background: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
  }
`;
export const PaginationContainer = styled.div`
  list-style: none;
  left: 8%;
  bottom: 25px;
  background: rgb(240, 242, 245);
  width:100%
`;
