import React, { useState, useEffect, useCallback } from 'react';
import {
  TitleRow,
  ContainerRow,
  SearchInputStyle,
  ControlsDiV
} from '../styledComponents';
import CustomTypography from '../../CustomComponents/CustomTypography';
import { useSelector } from 'react-redux';
import PageTitle from '../../../Utils/PageTitle';
import CustomTable from '../../CustomComponents/Table';
import {
  RESULTS_PER_PAGE,
  SEARCH_DELAY
} from '../../../Constants/StringConstants';
import { columns } from './tableConfig';
import AddBillersForm from './AddBillersForm';
import CustomSideDrawer from '../../CustomComponents/CustomSideDrawer';

import { notification } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { useDebounce } from '../../../Utils/Debounce';
import { AddButton } from '../styledComponents';
import { reInviteUser } from '../../../Apis/User';
import FacilityFilter from '../../CustomComponents/FacilityFilter';
import { getUserFacilities } from '../../../Apis/User';
import { disableOrEnableUser } from '../../../Apis/ClinicAdmin';
import { AddBiller, listAll } from '../../../Apis/Biller';
const Billers = () => {
  const [theme] = useSelector(state => [state.themeReducer]);
  const [searchString, setSearchString] = useState('');
  const [total, setTotal] = useState(0);
  const [admins, setAdmins] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [addDrawerVisible, setAddDrawerVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [filterKey, setFilterKey] = useState('name');
  const [sortOrder, setSortOrder] = useState('');
  const [facilityIds, setFacilityIds] = useState([]);
  const [facilities, setFacilities] = useState();
  const userData = useSelector(state => state.userReducer);
  const notClinicAdmin = userData.userType !== 'clinicAdmin';

  const submitHandler = values => {
    setSubmitLoading(true);
    const payload = values;
    values.phoneNumber.number = values.phoneNumber.number.replace(
      values.phoneNumber.countryCode,
      ''
    );

    AddBiller(payload)
      .then(response => {
        if (response.data.success) {
          notification['success']({
            message: 'Added',
            description: 'Biller added successfully'
          });
          setSubmitLoading(false);
          setAddDrawerVisible(false);
          setSelectedItem(null);
          fetchAll(0);
          setCurrentPage(0);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
          setSubmitLoading(false);
        }
      })
      .catch(err => {
        setSubmitLoading(false);
        console.log(err);
      });
  };

  const fetchAll = pageNo => {
    setLoading(true);
    listAll(searchString, RESULTS_PER_PAGE, pageNo, filterKey, sortOrder)
      .then(response => {
        setLoading(false);
        // setFetchAgain(false);
        if (response.data.success) {
          setTotal(response.data.size);
          setAdmins(response.data.result);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
      })
      .catch(err => {
        setLoading(false);
        // setFetchAgain(false);
      });
  };

  const onChangeHandler = value => {
    setCurrentPage(0);
    setSearchString(value);
  };

  const debounceOnChange = useCallback(
    useDebounce(onChangeHandler, SEARCH_DELAY),
    []
  );

  const pageNoChangeHandler = pageNo => {
    fetchAll(pageNo);
  };

  useEffect(() => {
    fetchAll(0);
    // eslint-disable-next-line
  }, [filterKey, searchString, sortOrder, facilityIds]);

  return (
    <>
      <PageTitle title='Billers' />
      <ContainerRow gutter={[0, 40]}>
        <TitleRow justify='space-between'>
          <CustomTypography color={theme['@text-color']}>
            Billers
          </CustomTypography>
          <ControlsDiV>
            <AddButton
              icon={<PlusOutlined />}
              type='primary'
              shape='round'
              onClick={() => setAddDrawerVisible(true)}
            >
              Add
            </AddButton>
          </ControlsDiV>
        </TitleRow>
        <CustomTable
          theme={theme}
          columns={columns(
            // setSelectedItem,
            setAddDrawerVisible,

            notClinicAdmin
          )}
          dataSource={admins}
          pageNoChangeHandler={pageNoChangeHandler}
          loading={loading}
          setPageNo={current => {
            setCurrentPage(current);
          }}
          pageNo={currentPage}
          pageSize={RESULTS_PER_PAGE}
          total={total}
          //   onChange={filterTableChange}
          user={true}
        />

        <CustomSideDrawer
          title={selectedItem ? 'Edit Biller' : 'Add Biller'}
          placement='right'
          closable={false}
          onClose={() => {
            setSelectedItem(null);
            setAddDrawerVisible(false);
          }}
          visible={addDrawerVisible}
          width={400}
          destroyOnClose={true}
        >
          <AddBillersForm
            facilities={facilities}
            selectedItem={selectedItem}
            submitHandler={submitHandler}
            submitLoading={submitLoading}
          />
        </CustomSideDrawer>
      </ContainerRow>
    </>
  );
};

export default Billers;
