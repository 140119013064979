import React from "react";

function PaymentLogsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      fill="none"
      viewBox="0 0 35 35"
    >
      <path
        fill="#00D8D6"
        d="M32.398 6.942h-4.629V2.314A2.314 2.314 0 0025.456 0H4.628a2.314 2.314 0 00-2.314 2.314v27.77H.58a.579.579 0 00-.579.578v1.157a2.314 2.314 0 002.314 2.314h19.67a2.314 2.314 0 002.314-2.314v-6.364h4.629a2.314 2.314 0 002.314-2.314v-5.206h2.892c.32 0 .579-.26.579-.58V9.258a2.314 2.314 0 00-2.314-2.315zM2.314 32.976c-.639 0-1.157-.518-1.157-1.157v-.578H19.67v.578c0 .406.107.806.31 1.157H2.315zM23.141 2.314V31.82a1.157 1.157 0 11-2.314 0v-1.157a.578.578 0 00-.578-.578H3.47V2.314c0-.639.518-1.157 1.157-1.157h18.835c-.208.35-.32.75-.322 1.157zm1.157 21.984V2.314a1.157 1.157 0 112.314 0v20.827c.003.408.114.807.322 1.157h-2.636zm5.786-15.041V23.14a1.157 1.157 0 11-2.314 0V8.1h2.635c-.208.35-.319.75-.321 1.158zm3.47 7.52h-2.313v-7.52a1.157 1.157 0 112.314 0v7.52z"
      ></path>
      <path
        fill="#00D8D6"
        d="M19.092 15.62H5.785v1.157h13.307V15.62zM21.406 13.307H9.256v1.157h12.15v-1.157zM14.967 7.69a1.645 1.645 0 00-.838-.984L13 6.142a.494.494 0 01.22-.936h.17c.272 0 .493.222.494.494v.085h1.157V5.7a1.648 1.648 0 00-1.157-1.567v-.662h-1.157v.662a1.645 1.645 0 00-.245 3.044l1.128.564a.494.494 0 01-.22.936h-.17a.495.495 0 01-.493-.494V8.1H11.57v.084c.002.72.47 1.353 1.157 1.568v.662h1.157v-.662a1.645 1.645 0 001.082-2.06zM8.1 13.307H6.941v1.157H8.1v-1.157zM19.092 20.827H5.785v1.157h13.307v-1.157zM21.406 18.513H9.256v1.157h12.15v-1.157zM8.1 18.513H6.941v1.157H8.1v-1.157zM19.092 26.033H5.785v1.157h13.307v-1.157zM21.406 23.719H9.256v1.157h12.15v-1.157zM8.1 23.719H6.941v1.157H8.1v-1.157z"
      ></path>
    </svg>
  );
}

export default PaymentLogsIcon;
