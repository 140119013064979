import { Row, Typography, Button, Input, Modal, Select } from 'antd';
import styled from 'styled-components';

const { Search } = Input;

export const EditorContainerRow = styled(Row)`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  // min-height: calc(100vh - 243px);
  // max-height: calc(100vh - 243px);
  height: calc(100vh - 300px);
`;

export const EditorTitleRow = styled(Row)`
  height: 60px;
  border-bottom: 1px solid #cdcdcd;
  display: flex;
  justify-content: space-between;
`;

export const EditorNameDiv = styled.div`
  height: 60px;
  border-right: ${props => props.prefix && '1px solid #d3d3d3'};
  border-left: ${props => props.suffix && '1px solid #d3d3d3'};
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px 0 22px;
`;

export const CustomNameStyle = styled(Typography.Text)`
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: ${props => props.color} !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 1280px) {
    font-size: 12px;
    line-height: 12px;
  }
`;

// export const CustomSelectStyle = styled(Select)`
// & .ant-select-item-option-content{
//     font-size: 26px !important;
//   }
// `;
export const CustomConsultationTypeStyle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #878888 !important;
  text-align: left;
  margin-top: 4px;
`;

export const EditorButtonStyle = styled.div`
  width: 122px;
  border-left: 1px solid #d3d3d3;
  display: flex;
  height: inherit;
`;

export const EditorIconStyle = styled(Button)`
  &.ant-btn {
    height: inherit;
    width: 60px;
  }
`;

export const StepButtonStyle = styled.div`
  border-right: 1px solid #d3d3d3;
  height: inherit;
`;

export const EditorToolsRow = styled.div`
  border-bottom: 1px solid #cdcdcd;
  display: flex;
  flex-direction: column;
`;

export const CustomHeadingText = styled(Typography.Text)`
  font-weight: 500;
  line-height: 39px;
  font-size: 30px;
  color: ${props => props.color} !important;
`;

export const CustomJobHeadingText = styled(Typography.Text)`
  font-weight: 600;
  line-height: 39px;
  font-size: 22px;
  color: ${props => props.color} !important;
  display: flex;
  align-items: center;
  padding-left: 10px;
  @media (max-width: 1280px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const CustomJobNameIdStyle = styled.div`
  display: flex;
`;

export const CustomEditorButtonDivStyle = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
`;
export const CustomModalStyle = styled(Modal)`
  .ant-modal-content {
  }
  .ant-modal-title {
    font-size: 18px;
    font-weight: 900;
  }
  .ant-modal-body {
    font-size: 16px;
  }
`;
export const CustomEditorButtonStyle = styled(Button)`
  &.ant-btn {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    background-color: white;
  }
  &.ant-btn[disabled] {
    background: white;
  }
`;

export const QuillEditorContainer = styled.div`
  scroll-behavior: smooth;
  padding: 15px 0 0 0;
  height: calc(100vh - 352px);
  overflow-y: auto;
`;

export const HeadingActionsDiv = styled.div`
  margin: 0 5px;
  position: absolute;
  top: ${props => props.top + 'px'};
  left: ${props => props.left + 20 + 'px'};
  opacity: 0.2;
  transition: all 0.2s ease-in;
  &:hover {
    opacity: 1;
  }
`;

export const HeadingActionsButton = styled(Button)``;

export const StyledFloatingMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  position: absolute;
  top: ${({ position }) => position.top + 'px'};
  left: 20px;

  margin-top: 30px;
  opacity: 1;
  background-color: #fff;
  color: #585f65;
  border: 1px solid #dadddf;
  border-radius: 3px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  max-height: 200px;
  overflow-y: auto;
`;

export const StyledFloatingMenuItem = styled.div`
  transition: all 0.05s;
  margin: 0;
  padding: 5px 10px;
  border: none;
  text-align: left;
  background-color: #fff;
  color: #585f65;
  cursor: pointer;
  :focus {
    outline: 0;
  }
  :active {
    outline: none;
    border: none;
    color: #585f65;
    background-color: #f5f5f5;
  }
  :hover {
    background-color: #f5f5f5;
  }
  ${props =>
    props.active &&
    `background-color: #f5f5f5;
    color:#000;
  `}
`;

export const FloatingMenuItemContainer = styled.div`
  overflow-y: auto;
`;

export const StyledSearch = styled(Search)`
  padding: 5px;
  width: calc(100% - 10px);
  margin: 5px auto;
`;

export const FloatingMenuControl = styled.div`
  position: absolute;
  top: ${props => props.cursorPosition.top - 15 + 'px'};
`;

export const EditorContainer = styled.div`
  position: relative;
  & .section-heading {
    color: ${props => props.headingTextColor};
    text-transform: uppercase;
    overflow-wrap: anywhere;
    margin-bottom: 0;
    padding: 0px 20px 7px 20px;
    border-left: 5px solid transparent;

    & span span {
      font-weight: 600 !important;
    }
  }
  & .section-content {
    overflow-wrap: anywhere;
    color: ${props => props.contentTextColor};
    padding: 0px 20px;
    border-left: 5px solid transparent;
  }

  & .section-heading.selected,
  & .section-heading.selected + .section-content {
    background: #f1f3f4;
    border-left: 5px solid ${props => props.headingTextColor};
  }

  @media (max-width: 1280px) {
    & .section-heading {
      font-size: 14px;
    }
  }
`;

export const StyledToolsContainer = styled.div`
  display: flex;
  margin: 5px;
  flex-grow: 10;
`;
export const RerenderButtonContainer = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &.ant-btn[disabled] {
    background: white;
  }
`;
export const UndoRedoButtonContainer = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;
export const CustomUndoRedoButtonStyle = styled(Button)`
  &.ant-btn {
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50px;
    background-color: white;
  }
  &.ant-btn[disabled] {
    background: white;
  }
`;
export const StyledRerenderButton = styled(Button)`
  display: flex;
`;

export const HeadingDeleteButton = styled.button`
  position: absolute;
  top: 11px;
  right: 33px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  color: #4d4e4f;

  &:hover {
    background: #c3c6c7;
  }
  background: #d2d5d6;
`;

export const DeleteConfirmationMessage = styled.p`
  font-size: 16px;
`;
