import axiosInstance from '../Config/AxiosInstance';

const PREFIX = `/clinic/reviewer`;

export const listAll = (
  facilities,
  name,
  pageSize,
  pageNo,
  sortFitler,
  sortOrder
) => {
  let url =
    PREFIX +
    `?name=${name}&sortKey=${sortFitler}&page=${pageNo}&size=${pageSize}&sortOrder=${sortOrder}`;
  if (facilities.length > 0) {
    url = url + `&facilityIds=${facilities}`;
  }
  return axiosInstance.get(url);
};

export const saveReviewer = data => {
  return axiosInstance.post(PREFIX, data);
};

export const editReviewer = data => {
  return axiosInstance.put(PREFIX, data);
};

export const deleteReviewer = id => {
  return axiosInstance.delete(PREFIX + `/${id}`);
};

export const getReviewerJobs = (
  facilities,
  pageSize,
  pageNo,
  sortFitler,
  sortOrder,
  type,
  searchKey,
  jobStatus,
) => {
  let url =
    PREFIX +
    `/job/${type}?&sortKey=${sortFitler}&page=${pageNo}&size=${pageSize}&sortOrder=${sortOrder}&searchValue=${searchKey}${
      jobStatus ? '&jobStatus=' + jobStatus : ''}`;
  return axiosInstance.get(url);
};

export const reviewerSelfAssignJob = (jobId, sessionId) => {
  return axiosInstance.put(
    PREFIX + `/job/${jobId}/assign?session-id=${sessionId}`
  );
};

export const startReviewerJob = jobId => {
  return axiosInstance.put(PREFIX + `/job/${jobId}/start`);
};

export const completeReviewerJob = (jobId, sessionId) => {
  return axiosInstance.put(
    PREFIX + `/job/${jobId}/complete?session-id=${sessionId}`
  );
};

export const rejectReviewerJob = (jobId, data, sessionId) => {
  return axiosInstance.put(
    PREFIX + `/job/${jobId}/reject?session-id=${sessionId}`,
    data
  );
};
