import React, { useState } from 'react';
import { StyledModal } from './styledComponents';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { DocumentContainer, PageInfo } from './styledComponents';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PrivacyPolicyModal = ({
  modalVisible,
  setModalVisible,
  baaModalVisible,
  setBaaModalVisible
}) => {
  const [totalPages, setTotalPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setTotalPages(numPages);
  };

  const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true
  };

  return (
    <>
      <StyledModal
        visible={modalVisible || baaModalVisible}
        key={modalVisible || baaModalVisible}
        footer={[]}
        title={modalVisible ? 'Privacy Policy' : 'Business Associate Agreement'}
        closeIcon={
          <Button
            icon={<CloseOutlined />}
            onClick={() => {
              modalVisible && setModalVisible(false);
              baaModalVisible && setBaaModalVisible(false);
            }}
          />
        }
        privacy={true}
      >
        <DocumentContainer>
          <Document
            file={
              modalVisible
                ? 'https://default-public.s3.us-east-2.amazonaws.com/PrivacyPolicyCascadeMD.pdf'
                : 'https://default-public.s3.us-east-2.amazonaws.com/CascadeMDBAA.pdf'
            }
            options={options}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(totalPages), (el, index) => (
              <>
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                <PageInfo>
                  {index + 1} / {totalPages}
                </PageInfo>
              </>
            ))}
          </Document>
        </DocumentContainer>
      </StyledModal>
    </>
  );
};

export default PrivacyPolicyModal;
