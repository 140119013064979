import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  TitleRow,
  ContainerRow,
  SearchInputStyle,
  CloseButton
} from '../styledComponents';
import CustomTypography from '../../CustomComponents/CustomTypography';
import { useSelector } from 'react-redux';
import PageTitle from '../../../Utils/PageTitle';
import CustomTable from '../../CustomComponents/Table';
import FacilityFilter from '../../CustomComponents/FacilityFilter';
import { ControlsDiV } from '../styledComponents';
import {
  RESULTS_PER_PAGE,
  SEARCH_DELAY
} from '../../../Constants/StringConstants';
import { columns } from './tableConfig';
import { billiersJobList } from '../../../Apis/Biller';
import { notification, Row, Col } from 'antd';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useDebounce } from '../../../Utils/Debounce';

const Biller = () => {
  const [theme] = useSelector(state => [state.themeReducer]);
  const [searchString, setSearchString] = useState('');
  const [total, setTotal] = useState(0);

  const [cannedText, setCannedTexts] = useState([]);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [billers, setBillers] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [sideBarItems, setSideBarItems] = useState();
  const [activeKey, setActiveKey] = useState(() => {
    let activeItem;
    if (sideBarItems) {
      activeItem = sideBarItems.find(item => {
        let splitPath = location.pathname.split('/');
        let splitLink = item.path.split('/');
        if (splitPath[3] === splitLink[3]) return item;
        else return null;
      });
    }

    return activeItem && activeItem.key;
  });
  const location = useLocation();
  const [filterKey, setFilterKey] = useState('name');
  const [sortOrder, setSortOrder] = useState('');
  const [userType, setUserType] = useState();
  const [sessionId, setSessionId] = useState();
  const [loader, setLoader] = useState(false);
  const user = useSelector(state => state.userReducer);
  const onChangeHandler = value => {
    setCurrentPage(0);
    setSearchString(value);
  };

  const debounceOnChange = useCallback(
    useDebounce(onChangeHandler, SEARCH_DELAY),
    []
  );
  useEffect(() => {
    if (user) {
      setUserType(user.userType);
      setSessionId(user.sessionId);
      // setSideBarItems(sideBarContents(userType));
    }
  }, [user]);
  const pageNoChangeHandler = pageNo => {
    fetchAll(pageNo);
  };
  const SideBarCallsMap = new Map([
    ['0', 'ready'],
    // ['1', `ready`],
    ['2', 'in-progress'],
    ['3', 'completed'],
    ['4', 'rejected'],
    ['5', 'incomplete']
  ]);
  const type = SideBarCallsMap.get(activeKey);
  const viewCompleteBillerJob = jobId => {
    // userType === 'transcriptionist' &&
    //   // startTranscriptorJob(jobId).then(response => {
    //   transcriptorSelfAssignJob(jobId, user.sessionId).then(response => {
    //     if (response.data.success) {
    //       history.push(
    //         `/dashboard/new-editor/${jobId}?jobStage=${SideBarCallsMap.get(
    //           activeKey
    //         )}&userType=${userType}`
    //       );
    //     } else {
    //       notification['error']({
    //         message: 'Failed',
    //         description: response.data.message
    //       });
    //     }
    //   });
    console.log('testrout', jobId, userType);
    history.push(`/dashboard/billers-jobs/${jobId}?sessionId=${sessionId}`);
  };
  useEffect(() => {
    fetchAll(0);
    // eslint-disable-next-line
  }, [filterKey, searchString, sortOrder]);

  function removeHTMLTags(inputString) {
    if (inputString) {
      return inputString.replace(/<[^>]*>/g, '');
    }
  }
  const fetchAll = pageNo => {
    setLoading(true);
    billiersJobList(
      searchString,
      RESULTS_PER_PAGE,
      pageNo,
      filterKey,
      sortOrder
    )
      .then(response => {
        setLoading(false);
        // setFetchAgain(false);
        if (response.data.success) {
          setTotal(response.data.size);

          setBillers(response.data.result);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
      })
      .catch(err => {
        setLoading(false);
        // setFetchAgain(false);
      });
  };
  return (
    <>
      <PageTitle title='Jobs' />
      <ContainerRow gutter={[0, 40]}>
        <TitleRow justify='space-between'>
          <CustomTypography color={theme['@text-color']}>Jobs</CustomTypography>
          <ControlsDiV></ControlsDiV>
        </TitleRow>

        <CustomTable
          theme={theme}
          columns={columns(setSelectedItem, viewCompleteBillerJob)}
          dataSource={billers}
          pageNoChangeHandler={pageNoChangeHandler}
          loading={loading}
          setPageNo={current => {
            setCurrentPage(current);
          }}
          pageNo={currentPage}
          pageSize={RESULTS_PER_PAGE}
          total={total}
          // viewCompleteBillerJob={viewCompleteBillerJob}
          // onChange={filterTableChange}
          user={true}
        />
      </ContainerRow>
    </>
  );
};

export default Biller;
