import styled from "styled-components";

export const SmallCardDiv = styled.div`
  width: 100%;
  border-radius: 12px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  cursor: pointer;
  box-shadow: 0px 0px 8px 5px rgba(218, 218, 218, 0.25);
  transition: all 0.25s ease-in;

  &:hover {
    transform: scale(1.1);
  }
`;

export const SmallCardImageDiv = styled.div`
  background: ${(props) => props.color};
  border-radius: 12px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SmallCardText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin-top: 20px;
`;
