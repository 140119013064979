import React from 'react';
import { Row, Col, Popover } from 'antd';
import {
  AssignButton,
  CommentButton,
  CommentDivTitleStyle,
  PopoverGlobalStyle,
  StyledCommentContent
} from './styledComponents';
import moment from 'moment';
import CommentIcon from '../../../Assets/Icons/CommentIcon';
// import { FilterFilled, FilterTwoTone } from '@ant-design/icons';

import {
  StyledFacilityContainer,
  StyledFacilityBlocks
} from './styledComponents';


export const columns = (
  activeKey,
  assignJobHandler,
  assignButtonLoading,
  startAssignedJob,
  startAssignedJobWithNewEditor,
  resumeJob,
  resumeJobWithNewEditor,
  viewJob,
  releaseJobHandler,
  selectedId,
  activeTab,
  reviewer,
  jobStatus,
  setJobStatus,
  tempStatus,
  setTempStatus,
  user
) => [
    {
      title: 'Job ID',
      dataIndex: ['jobNumber'],
      key: 'id'
    },
    {
      title: 'TAT',
      dataIndex: ['tat'],
      key: 'tat',
      sorter: { multiple: 2 },
      render: val => {
        return <div> {Math.floor(moment.duration(val).asHours())} H</div>;
      }
    },
    {
      title: 'Audio Length',
      dataIndex: ['audioLength'],
      key: 'audioLength'
    },
    {
      title: 'Submitted Time',
      dataIndex: ['submissionTime'],
      key: 'submissionTime',
      sorter: { multiple: 3 },
      render: val => {
        return new Date(val).toLocaleString();
      }
    },
    {
      title: 'Author',
      dataIndex: ['author'],
      key: 'author',
      render: val => {
        return (
          <span title={val}>
            {val?.length > 20 ? val.slice(0, 20) + '...' : val}
          </span>
        );
      }
    },
    {
      title: 'Patient Name',
      dataIndex: ['patientName'],
      key: 'patientName',
      render: val => {
        return (
          <span title={val}>
            {val?.length > 20 ? val.slice(0, 20) + '...' : val}
          </span>
        );
      }
    },
    reviewer
      ? {
        title: 'Reviewer Level',
        dataIndex: ['reviewerCount'],
        key: 'reviewerCount'
      }
      : activeKey === '0'
        ?
        {
          title: 'Status',
           render: row => {
            return (row.transcriptionistRejected || row.reviewerRejected) ? (
              `Rejected`
            ) : (
              `New`
            );
          }
        }
        : { width: '1px' },
    {
      width: '140px',
      title: 'TAT Status',
      dataIndex: ['tatStatus'],
      key: 'tatStatus',
      sorter: { multiple: 1 }
    },
    {
      width: '120px',

      title: 'Facility',
      key: 'facility',
      dataIndex: ['facility'],
      render: FacilityList => {
        return (
          <div>
            {FacilityList !== null && (
              <StyledFacilityContainer>
                <StyledFacilityBlocks disabled={FacilityList.disabled}>
                  {FacilityList.facilityName}
                </StyledFacilityBlocks>
              </StyledFacilityContainer>
            )}
          </div>
        );
      }
    },
    {
      fixed: 'right',
      width: 'fit-content',
      render: row => {
        return (
          <Row gutter={12} justify='end'>
            <Col>
              {activeKey === '0' &&
                row.comments &&
                row.comments[0] &&
                row.comments[0].comment.length > 0 && (
                  <Popover
                    title={
                      <CommentDivTitleStyle>
                        {`${row.comments[0].userType[0].toUpperCase() + row.comments[0].userType.substring(1).toLowerCase()} Comment`}
                      </CommentDivTitleStyle>
                    }
                    content={<StyledCommentContent>{row.comments && row.comments[0].comment}</StyledCommentContent>}
                  >
                    <PopoverGlobalStyle />
                    <CommentButton icon={<CommentIcon />} shape='circle' />
                  </Popover>
                )}
            </Col>
            <Col>
              {activeKey === '0' && (
                <AssignButton
                  type='primary'
                  onClick={() => startAssignedJobWithNewEditor(row.jobId)}
                >
                  Start
                </AssignButton>
              )}
              {activeKey === '2' && (
                <AssignButton
                  type='primary'
                  onClick={() => resumeJobWithNewEditor(row.jobId)}
                >
                  Resume
                </AssignButton>
              )}
              {(activeKey === '4' || activeKey === '3') && (
                <AssignButton
                  type='primary'
                  onClick={() => viewJob(row.jobId)}
                >
                  View
                </AssignButton>
              )}
              {activeKey === '5' && (
                <>
                  <Popover
                    title={<CommentDivTitleStyle>Reason</CommentDivTitleStyle>}
                    content={<div>{row.reasonForFailing}</div>}
                  >
                    <PopoverGlobalStyle />
                    <CommentButton icon={<CommentIcon />} shape='circle' />
                  </Popover>

                </>
              )}
            </Col>
          </Row>
        );
      }
    },
    {
      fixed: 'right',
      width: 'fit-content',
      render: (row) => {
        return (
          <>
            {(activeKey === '1' || activeKey === '2') && (
              <AssignButton
                type='secondary'
                onClick={() => releaseJobHandler(row.jobId)}
              >
                Release
              </AssignButton>
            )}
          </>
        )
      }
    }
  ];
