import React, { useState, useEffect } from 'react';
import { Form, Button, Popconfirm } from 'antd';
import {
  DeleteOutlinedStyle,
  StyledTable,
  CancelOutlinedStyle,
  StyledEditIcon,
  StyledCheckIcon,
  EditorContentPreviewBox
} from './styledComponents';
import { nanoid } from 'nanoid';
import EditableCell from './EditableCell';
import TextHighlighter from './TextHighlighter';

const selectableFieldTypes = ['Dropdown', 'Checkbox', 'Toggle', 'optionBlock'];

const CustomDynamicTable = ({
  columns,
  values,
  setUpdatedDynamicTableData,
  setIsEditing,
  inferenceEdited,
  editFlagHandler,
  tableKey,
  previewData,
  editingKey,
  setEditingKey,
  dataSource,
  setDataSource,
  edit,
  form,
  annotations,
  setDataChanged
}) => {
  const [dataTypes, setDataTypes] = useState(null);
  const [possibleValues, setPossibleValues] = useState(new Map());
  const [startIndex, setStartIndex] = useState(-1);
  const [endIndex, setEndIndex] = useState(-1);
  const isEditing = record => record.key === editingKey;

  const cancel = async() => {
    await form.resetFields();
    setEditingKey('');
    setIsEditing(false);
  };

  const save = async key => {
    try {
      const row = await form.validateFields();
      const newData = [...dataSource];
      const index = newData.findIndex(item => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setDataSource(newData);
        setEditingKey('');
        setIsEditing(false);
        setUpdatedDynamicTableData(newData);
        form.resetFields();
      } else {
        newData.push(row);
        setDataSource(newData);
        setEditingKey('');
        setIsEditing(false);
        form.resetFields();
      }
      setDataChanged(true);
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  useEffect(() => {
    let types = columns.map(column => {
      return [column.fieldName, column.type];
    });
    let mappedTypes = new Map(types);
    let metaData = new Map();
    columns.forEach(column => {
      if (selectableFieldTypes.includes(column.type)) {
        metaData.set(column.fieldName, column.possibleValues);
      }
    });
    setPossibleValues(metaData);
    setDataTypes(mappedTypes);
  }, [columns]);

  useEffect(() => {
    if (values) {
      let updatedData = values.map((value, i) => {
        return {
          ...value,
          key: nanoid(),
          index: i
        };
      });
      setDataSource(updatedData);
    }
    // eslint-disable-next-line
  }, [values]);

  useEffect(() => {
    setUpdatedDynamicTableData(dataSource);
    // eslint-disable-next-line
  }, [dataSource]);

  const highlightInference = (rowIndex, columnIndex) => {
    const index = rowIndex * columns.length + columnIndex;
    if (annotations[index]) {
      setStartIndex(annotations[index].startIndex);
      setEndIndex(annotations[index].endIndex);
    }
  };

  const handleDelete = key => {
    let updatedData = dataSource.filter(item => item.key !== key);
    setDataSource(updatedData);
    setUpdatedDynamicTableData(updatedData);
    setDataChanged(true);
  };

  let updatedColumns = [
    ...columns,
    {
      title: 'Operation',
      dataIndex: 'operation',
      fixed: 'right',
      width: '100px',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span key={record.key}>
            <Button
              type='text'
              shape='circle'
              onClick={() => save(record.key)}
              icon={<StyledCheckIcon />}
            />

            <Button
              type='text'
              shape='circle'
              onClick={cancel}
              icon={<CancelOutlinedStyle />}
            />
          </span>
        ) : (
          <span key={record.key}>
            <Button
              type='text'
              shape='circle'
              // disabled={editingKey !== '' || inferenceEdited}
              onClick={() => edit(record)}
              icon={
                <StyledEditIcon
                // disabled={editingKey !== '' || inferenceEdited}
                />
              }
            />
            <Popconfirm
              title='Are you sure you want to delete this item ?'
              // disabled={editingKey !== '' || inferenceEdited}
              onConfirm={() => handleDelete(record.key)}
            >
              <Button
                type='text'
                shape='circle'
                // disabled={editingKey !== '' || inferenceEdited}
                // onClick={() => handleDelete(record.key)}
                icon={
                  <DeleteOutlinedStyle
                  // disabled={editingKey !== '' || inferenceEdited}
                  />
                }
              />
            </Popconfirm>
          </span>
        );
      }
    }
  ];

  const dataColumns =
    Boolean(updatedColumns) &&
    updatedColumns.map((col, index) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          form: form,
          inputType: dataTypes && dataTypes.get(col.fieldName),
          possibleValues: selectableFieldTypes.includes(col.type)
            ? possibleValues.get(col.fieldName)
            : [],
          editing: isEditing(record),
          mandatory: col.mandatory,
          colIndex: index,
          highlightInference: highlightInference
        })
      };
    });

  return (
    <div>
      <EditorContentPreviewBox id='editor-content-preview'>
        <TextHighlighter
          startIndex={startIndex}
          endIndex={endIndex}
          previewData={previewData}
        />
      </EditorContentPreviewBox>
      <Form form={form} component={false}>
        <StyledTable
          components={{
            body: {
              cell: EditableCell
            }
          }}
          bordered
          dataSource={dataSource}
          columns={dataColumns}
          rowClassName='editable-row'
          pagination={{
            onChange: cancel,
            pageSize: 8
          }}
          scroll={{
            x: dataColumns && dataColumns.length > 4 ? 1300 : 950,
            y: 350
          }}
          sticky
        />
      </Form>
    </div>
  );
};

export default CustomDynamicTable;
