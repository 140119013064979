import React from "react";

function ReviewersIcon({ color, size }) {
  return (
    <span className="anticon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size ? size : "18"}
        height={size ? size : "18"}
        fill="none"
        viewBox="0 0 16 16"
      >
        <g>
          <path
            fill={color ? color : "#666"}
            d="M12.572 9.114a.583.583 0 01-.813-.161 5.084 5.084 0 00-4.234-2.26c-1.699 0-3.279.841-4.226 2.25a.586.586 0 11-.973-.655 6.253 6.253 0 013.083-2.4 3.305 3.305 0 01-1.22-2.564A3.314 3.314 0 017.5.014a3.314 3.314 0 013.31 3.31 3.305 3.305 0 01-1.203 2.552A6.252 6.252 0 0112.733 8.3a.586.586 0 01-.161.813zM7.5 1.186c-1.18 0-2.139.959-2.139 2.138 0 1.18.96 2.139 2.139 2.139 1.18 0 2.139-.96 2.139-2.139 0-1.179-.96-2.138-2.139-2.138zm-2.337 9.203l.874.63c.13.094.184.26.135.411l-.352 1.02a.37.37 0 00-.019.132c.013.274.356.461.608.28l.873-.63a.372.372 0 01.436 0l.873.63c.252.181.597-.006.608-.28a.375.375 0 00-.02-.132l-.35-1.02a.367.367 0 01.134-.411l.874-.63c.29-.21.14-.707-.218-.707h-1.08c-.16 0-.303-.062-.353-.214l-.333-1.026a.364.364 0 00-.353-.255.364.364 0 00-.353.255l-.333 1.026c-.05.152-.192.214-.353.214h-1.08c-.359 0-.508.497-.218.707zm-5.01 2.05l.874.63c.13.094.184.26.135.412l-.352 1.02a.37.37 0 00-.019.132c.013.274.356.461.608.28l.873-.63a.372.372 0 01.436 0l.874.63c.251.181.596-.006.607-.28a.376.376 0 00-.019-.132l-.351-1.02a.367.367 0 01.134-.412l.874-.63c.29-.21.141-.707-.218-.707h-1.08c-.16 0-.303-.061-.352-.213l-.334-1.026a.364.364 0 00-.353-.255.364.364 0 00-.352.255l-.334 1.026c-.05.152-.192.213-.353.213H.371c-.359 0-.508.498-.217.707zm10.02 0l.874.63c.13.094.184.26.134.412l-.351 1.02a.37.37 0 00-.02.132c.013.274.357.461.608.28l.874-.63a.372.372 0 01.436 0l.873.63c.252.181.597-.006.608-.28a.374.374 0 00-.02-.132l-.35-1.02a.367.367 0 01.134-.412l.873-.63c.291-.21.142-.707-.217-.707h-1.08c-.16 0-.303-.061-.353-.213l-.334-1.026a.364.364 0 00-.352-.255.364.364 0 00-.353.255l-.334 1.026c-.05.152-.192.213-.352.213h-1.08c-.36 0-.509.498-.218.707z"
          ></path>
        </g>
        <defs>
          <clipPath>
            <path
              fill="#fff"
              d="M0 0H15V15H0z"
              transform="matrix(-1 0 0 1 15 0)"
            ></path>
          </clipPath>
        </defs>
      </svg>
    </span>
  );
}

export default ReviewersIcon;
