export const hl7List = [
  {
    key: '0',
    name: 'Dictation',
    countValue: '0',
    path: '/dashboard/hl7/dictation'
  },
  {
    key: '1',
    name: 'Patient Details',
    countValue: '0',
    path: '/dashboard/hl7/patient-details'
  },
  {
    key: '2',
    name: 'Calendar',
    countValue: '0',
    path: '/dashboard/hl7/calendar'
  }
];
