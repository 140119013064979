import React from "react";

function EditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      fill="none"
      viewBox="0 0 17 17"
    >
      <path
        fill="#fff"
        d="M16.844 3.876L13.126.157a.531.531 0 00-.751 0L.157 12.375a.53.53 0 00-.156.376L0 16.47a.532.532 0 00.531.53h3.72a.53.53 0 00.375-.156L16.844 4.627a.531.531 0 000-.751zM4.03 15.939H1.061l.001-2.968 8.766-8.765 2.968 2.967L4.03 15.94zm9.517-9.517l-2.968-2.967 2.171-2.171 2.967 2.967-2.17 2.171z"
      ></path>
    </svg>
  );
}

export default EditIcon;
