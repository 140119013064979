import React, { useEffect } from 'react';
import { Input, Tooltip } from 'antd';
import { StyledSelect } from './styledComponents';
import { IconButton, StyledCheckIcon } from '../styledComponents';
import { useState } from 'react';
import { StyledDatePicker } from '../../styledComponents';
import { DATE_FORMAT } from '../../../../Constants/StringConstants';
import moment from 'moment';
import { ElementContainer, StyledDisabledIcon } from './styledComponents';

const InferenceElement = ({
  item,
  updateInferenceData,
  updateInferenceLoading,
  setUpdateInferenceLoading
}) => {
  const [value, setValue] = useState(item.value);
  const [disabled, setDisabled] = useState(false);

  const submitHandler = () => {
    setUpdateInferenceLoading(true);
    let inference = {
      key: item.key,
      value: value
    };

    updateInferenceData(inference);
  };

  useEffect(() => {
    if (item.mandatory) {
      if (value[0] === null || !value[0].length || value[0].length === 0) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
    // eslint-disable-next-line
  }, [value]);

  const onSelectHandler = selectedValue => {
    let selectedValues = value.flat();
    selectedValues.push(selectedValue);
    setValue(selectedValues);
  };

  const onDeselectHandler = deSelectedValue => {
    let selectedValues = value.flat();
    let index = selectedValues.findIndex(val => val === deSelectedValue);
    selectedValues.splice(index, 1);
    setValue(selectedValues);
  };

  const getElement = () => {
    switch (item.type) {
      case 'Text Box':
        return (
          <Input value={value} onChange={e => setValue([e.target.value])} />
        );
      case 'Dropdown':
      case 'Checkbox':
      case 'Toggle':
      case 'Radio Button':
        return (
          <StyledSelect
            defaultValue={value}
            onSelect={val => setValue([val])}
          >
            {item.possibleValues.map(val => (
              <StyledSelect.Option value={val.value} key={val.value}>
                {val.value}
              </StyledSelect.Option>
            ))}
          </StyledSelect>
        );

      case 'List':
        return (
          <StyledSelect
            defaultValue={value}
            onSelect={onSelectHandler}
            mode='tags'
            tokenSeparators={[',']}
            onDeselect={onDeselectHandler}
          >
            {item.possibleValues.map(val => (
              <StyledSelect.Option value={val} key={val}>
                {val}
              </StyledSelect.Option>
            ))}
          </StyledSelect>
        );

      case 'Date/Time':
        return (
          <StyledDatePicker
            format={DATE_FORMAT}
            allowClear={false}
            defaultValue={moment(value[0])}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <ElementContainer>
        {getElement()}
        <Tooltip title={disabled && 'Mandatory Field'}>
          <IconButton
            onClick={submitHandler}
            icon={disabled ? <StyledDisabledIcon /> : <StyledCheckIcon />}
            htmlType='submit'
            border={true}
            disabled={disabled}
            loading={updateInferenceLoading}
          />
        </Tooltip>
      </ElementContainer>
    </>
  );
};
export default InferenceElement;
