import styled from 'styled-components';
import { Upload, Tabs, Row, Button, Col } from 'antd';

const { Dragger } = Upload;

export const DraggerStyle = styled(Dragger)`
  &.ant-upload.ant-upload-drag {
    height: 60px;
    width: 170px;
    padding: 10px 18px;
    background: transparent;
    border: 2px dashed #4796ce;
    border-radius: 8px;

    & .ant-upload-drag-container {
      display: flex;
      & div {
        display: flex;
        align-items: center;
        & p {
          margin-bottom: 0;
          font-size: 14px;
          & svg {
            width: 30px;
            height: 30px;
          }
        }
      }
    }

    & .ant-upload-text {
      color: #4796ce;
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      padding: 4px;
    }

    &.ant-upload.ant-upload-drag .ant-upload {
      padding: 0;
      align-items: center;
      display: flex;
    }
  }
`;

export const DraggerUploadComponent = styled.div`
  width: 250px;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
`;

export const UploadStyledButton = styled(Upload)`
  & .ant-btn {
    background: transparent;
    border: 2px dashed #4796ce;
    border-radius: 8px;
    width: 60px;
    height: 60px;
  }
  & .ant-btn:focus {
    background: transparent;
    border: 2px dashed #4796ce;
  }
`;

export const WarningText = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #aaaaaa;
  text-align: center;
  width: 250px;
  margin-top: 15px;
`;

export const CustomTabs = styled(Tabs)`
  width: 100%;

  & .ant-tabs-nav .ant-tabs-tab {
    padding: 0 0 16px 0;
  }

  & .ant-tabs-nav-wrap {
    border-bottom: 1px solid #c0c0c0;
  }

  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #525e6f;
    font-weight: 600;
  }

  & .ant-tabs-tab .ant-tabs-tab-btn {
    color: #a8a9ad;
    font-weight: 600;
  }
`;

export const CustomRow = styled(Row)`
  width: 100%;
  margin: ${props => (props.button ? '20px 0 ' : '-18px -18px 6px !important')};
  & .ant-col {
    padding: 15px 18px 0 18px !important;
  }
  & .ant-form-item {
  }
`;

export const CustomPhoneNumberCol = styled(Col)`
  &.ant-col {
    padding-top: 20px !important;
  }
  & .ant-col {
    padding: 0 18px !important;
  }

  & .react-tel-input .form-control {
    border: 0;
    border-radius: 0;
    border-bottom: 2px solid #d0d0d0 !important;
    width: 100%;
    background: transparent;
    color: ${props => props.primaryTextColor};
    font-family: krub;
    font-size: 16px;
  }

  & .react-tel-input .selected-flag {
    border-bottom: 2px solid #d0d0d0 !important;
    background: transparent;
  }

  & .react-tel-input .flag-dropdown {
    border: 0;
  }

  & .react-tel-input :disabled {
    color: rgba(0, 0, 0, 0.25);
  }

  &:hover {
    & .react-tel-input .selected-flag {
      background: transparent;
    }
  }

  & .ant-form-item-label > label {
    font-size: 16px;
    color: ${props => props.primaryTextColor};
  }
`;

export const FormComponent = styled.div`
  width: 100%;
  margin-top: 20px;
`;

export const SaveButton = styled(Button)`
  font-weight: 600;
  width: 100px;
  font-size: 14px;
  height: 40px;
  padding: 8px 16px;

  &.ant-btn-primary {
    background: ${props => props.reset && 'red'};
    border-color: ${props => props.reset && 'red'};
    margin-left: 10px;

    &:hover {
      background: ${props => props.reset && '#f38358'};
      border-color: ${props => props.reset && '#f38358'};
    }

    &:active,
    :focus {
      background: ${props => props.reset && '#f38358'};
      border-color: ${props => props.reset && '#f38358'};
    }
  }

  &.ant-btn {
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
  }

  svg {
    height: 20px;
    width: 16px;
  }
`;

export const CancelButton = styled(Button)`
  font-weight: 600;
  width: 100px;
  font-size: 14px;
  height: 40px;
  padding: 8px 16px;

  &.ant-btn {
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    background-color: #c0c0c0;
    font-weight: 600;
    border: 0;
    color: white;
  }

  svg {
    height: 20px;
    width: 16px;
  }
`;

export const ChangePasswordButton = styled(Button)`
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  height: auto;
  padding: 8px 16px;
  width: auto;
  margin-left: 36px;
  margin-top: 10px;

  &.ant-btn {
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
  }
`;

export const EditButton = styled(Button)`
  &.ant-btn {
    border-radius: 5px;
    border: 0;
  }

  &.ant-btn-primary[disabled] {
    background-color: #c1c1c1;
  }

  &:hover {
    &.ant-btn-primary[disabled] {
      background-color: #c1c1c1;
    }
  }
`;

export const EditField = styled.div`
  position: absolute;
  right: 50px;
  top: 0;
`;
