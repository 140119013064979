import React from 'react';

function EmrVerificationIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='21'
      height='21'
      fill='none'
      viewBox='0 0 21 21'
    >
      <path
        fill='#FF1824'
        fillRule='evenodd'
        d='M9.25.012L8.742.07C5.646.424 2.786 2.364 1.216 5.176.643 6.203.227 7.478.05 8.753c-.068.487-.068 2.016 0 2.504.315 2.276 1.28 4.238 2.866 5.824a9.956 9.956 0 006.177 2.898c.637.059 9.312.057 9.593-.002a1.642 1.642 0 001.223-2.126c-.033-.097-.358-.703-.723-1.347s-.664-1.19-.664-1.213c0-.024.053-.128.117-.233.197-.32.649-1.294.8-1.725a9.993 9.993 0 00-2.37-10.405C15.512 1.37 13.63.428 11.402.087c-.39-.06-1.847-.11-2.152-.075zm1.588 1.192a8.908 8.908 0 014.829 2.023c2.781 2.335 3.86 6.123 2.717 9.55-.234.703-.523 1.318-.587 1.25a9.22 9.22 0 01-.36-.61l-.314-.558.15-.459a7.6 7.6 0 00-.417-5.795 7.646 7.646 0 00-9.284-3.869 7.654 7.654 0 00-4.44 3.87 7.658 7.658 0 002.04 9.357c.995.811 2.411 1.443 3.621 1.615.154.021.183.038.161.09-.126.296-.149.407-.15.739 0 .199-.017.361-.036.361-.131 0-1.062-.217-1.435-.334a8.858 8.858 0 01-6.14-7.585C.798 6.783 3.29 2.933 7.157 1.637a8.872 8.872 0 013.68-.433zm-.008 2.364c3.23.43 5.651 3.18 5.658 6.425 0 .409-.094 1.27-.151 1.371-.023.041-.115-.09-.32-.459-.496-.886-.868-1.15-1.622-1.15-.52 0-.755.09-1.1.42-.234.226-.354.424-1.951 3.25a278.08 278.08 0 01-1.732 3.041c-.037.037-.77-.056-1.108-.142a7.117 7.117 0 01-2.266-1.028c-.475-.337-1.196-1.058-1.536-1.535a7.694 7.694 0 01-.89-1.78c-.444-1.402-.403-2.964.113-4.29 1.093-2.809 3.952-4.516 6.905-4.123zm3.802 7.41c.095.057.53.798 2.098 3.57 2.205 3.896 2.106 3.716 2.106 3.83 0 .132-.112.31-.247.392-.122.075-.227.076-4.194.076-3.966 0-4.072-.001-4.194-.076-.135-.082-.246-.26-.246-.393a.59.59 0 01.063-.207c.163-.32 3.985-7.059 4.04-7.123.133-.154.385-.185.574-.07zm-.49 1.99a.701.701 0 00-.225.202c-.087.13-.09.156-.089 1.174 0 .974.005 1.049.08 1.17.079.13.339.28.487.28.149 0 .409-.15.488-.28.074-.121.08-.196.08-1.17 0-1.018-.002-1.044-.09-1.174a.635.635 0 00-.478-.27.688.688 0 00-.253.068zm-.03 3.805a.587.587 0 00-.284.509c0 .577.725.794 1.05.313.125-.187.107-.535-.035-.688-.208-.222-.5-.276-.732-.134z'
        clipRule='evenodd'
      ></path>
      <path
        stroke='#FF1824'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M12.871 6.808L8.38 11.972l-1.925-1.937'
      ></path>
    </svg>
  );
}

export default EmrVerificationIcon;
