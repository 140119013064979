import React, { useState, useEffect } from 'react';
import { StyledModal } from './styledComponents';
import { Button, notification } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import ModalContent from './ModalContent';
import { columns } from './tableConfig';
import { fetchEhrDoctors, saveDoctor } from '../../../Apis/Doctor';

const InviteModal = ({
  modalVisible,
  setModalVisible,
  theme,
  setFetchAgain
}) => {
  const [doctorList, setDoctorlist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [total, setTotal] = useState(0);

  const fetchDoctorList = () => {
    setLoading(true);

    fetchEhrDoctors()
      .then(response => {
        if (response.data.success) {
          setDoctorlist(response.data.result);
          setTotal(response.data.result.length);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };

  const inviteDoctor = data => {
    const payload = {
      email: data.email,
      firstName: data.firstName,
      fullName: data.fullName,
      id: data.id,
      lastName: data.lastName,
      npi: data.npi,
      phoneNumber: data.phoneNumber
    };

    saveDoctor(payload)
      .then(response => {
        if (response.data.success) {
          notification['success']({
            message: 'Success',
            description: 'Doctor Added Successfully'
          });
          doctorList.forEach(doctor => {
            if (doctor.id === payload.id) {
              doctor.invited = true;
              let newData = [...doctorList];
              setDoctorlist(newData);
              setFetchAgain(true);
              return;
            }
          });
        } else {
           notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
      })
      .catch(err => console.log(err));
  };

  const onSearch = value => {
    if (value === '') {
      setTotal(doctorList.length);
      setFilteredData(doctorList);
    } else {
      let data = [];
      doctorList.forEach(doctor => {
        if (doctor.fullName.toLowerCase().includes(value.toLowerCase()))
          data.push(doctor);
      });
      setTotal(data.length);
      setFilteredData(data);
    }
  };

  useEffect(() => {
    setFilteredData(doctorList);
  }, [doctorList]);

  useEffect(() => {
    if (modalVisible) fetchDoctorList();
    else setFilteredData([]);
  }, [modalVisible]);

  return (
    <StyledModal
      visible={modalVisible}
      footer={[]}
      title={'Invite Doctors'}
      closeIcon={
        <Button
          icon={<CloseOutlined />}
          onClick={() => setModalVisible(false)}
        />
      }
    >
      <ModalContent
        pageSize={0}
        columns={columns(inviteDoctor)}
        theme={theme}
        loading={loading}
        onSearch={onSearch}
        filteredData={filteredData}
        total={total}
        key={modalVisible}
      />
    </StyledModal>
  );
};

export default InviteModal;
