import React from 'react';
import {
  CommentsWrapper,
  NoCommentsInfo,
  CommentItemContainer,
  CommentInfoContainer,
  CommentedBy,
  CommentedOn,
  CommentContainer
} from './styledComponents';
import { Divider } from 'antd';
import moment from 'moment';

export const ExportComments = ({ comments, isFolderView }) => {
  return (
    <>
      <CommentsWrapper isFolderView={isFolderView}>
        {comments.length > 0 ? (
          comments &&
          comments.map(item => {
            return (
              <>
                <CommentItemContainer>
                  <CommentInfoContainer>
                    <CommentedBy>{item.exportedBy}</CommentedBy>
                    <CommentedOn>
                      {moment(item.exportedTime).format('DD,MMM YYYY hh:mm')}
                    </CommentedOn>
                  </CommentInfoContainer>
                  <CommentContainer>{item.comment}</CommentContainer>
                </CommentItemContainer>
                <Divider />
              </>
            );
          })
        ) : (
          <NoCommentsInfo>No comments found!</NoCommentsInfo>
        )}
      </CommentsWrapper>
    </>
  );
};
