import React, { useRef } from 'react';
import { StyledModal } from '../../../CustomComponents/ConfirmationModal/styledComponents';
import {
  Container,
  DetailsContainer,
  LogoContainer,
  NameDiv,
  DateDiv,
  TableContainer,
  StyledRow,
  StyledCol,
  PrintButtonDiv,
  PrintButton
} from './styledComponents';
import CascadeLogo from '../../../../Assets/Icons/CascadeLogo';
import Spinner from '../../../CustomComponents/Spinner';
import moment from 'moment';
import CustomTypography from '../../../CustomComponents/CustomTypography';
import { PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import { Empty } from 'antd';
import { DATE_TIME_FORMAT } from '../../../../Constants/StringConstants';

const TransactionDetails = ({
  visible,
  setVisible,
  TranscriptionDetails,
  theme,
  loading,
  record,
  footer,
  payment
}) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  return (
    <StyledModal
      visible={visible}
      centered={true}
      width={'1000px'}
      color={theme['@text-color']}
      footer={footer}
      destroyOnClose
      title={
        <CustomTypography color={theme['@text-color']}>
          Bill Summary
        </CustomTypography>
      }
      onCancel={() => setVisible(false)}
      payment={payment}
    >
      <Container ref={componentRef}>
        <LogoContainer>
          <CascadeLogo />
        </LogoContainer>
        <DetailsContainer>
          <NameDiv>
            Customer: <span> {record.customerName}</span>
          </NameDiv>
          <DateDiv>
            {moment(record.submitTimeLocal).format('DD MMM YY')} <i>to</i>{' '}
            {record.nextTransactionTime
              ? moment(record.nextTransactionTime).format('DD MMM YY')
              : '-'}
          </DateDiv>
        </DetailsContainer>

        <StyledRow>
          <StyledCol span={8} first={true}>
            Job Number
          </StyledCol>
          <StyledCol span={8}>Submitted Time</StyledCol>
          <StyledCol span={8}>Duration</StyledCol>
        </StyledRow>
        {loading ? (
          <Spinner height='250px' />
        ) : (
          <TableContainer>
            {TranscriptionDetails && TranscriptionDetails.length > 0 ? (
              TranscriptionDetails.map(transcription => {
                return (
                  <StyledRow transcription={true}>
                    <StyledCol span={8} first={true} transcription={true}>
                      {transcription.jobNumber}
                    </StyledCol>
                    <StyledCol span={8} transcription={true}>
                      {moment(transcription.submittedTime).format(
                        DATE_TIME_FORMAT
                      )}
                    </StyledCol>
                    <StyledCol span={8} transcription={true}>
                      {transcription.dictationLength}
                    </StyledCol>
                  </StyledRow>
                );
              })
            ) : (
              <Empty
                description='No Transcriptions Completed during the selected period.'
                style={{ marginTop: '20px' }}
              />
            )}
          </TableContainer>
        )}
        <PrintButtonDiv>
          <PrintButton icon={<PrinterOutlined />} onClick={handlePrint}>
            Print
          </PrintButton>
        </PrintButtonDiv>
      </Container>
    </StyledModal>
  );
};

export default TransactionDetails;
