import React from 'react';
import {
  AddCardDiv,
  CardDetailsDiv,
  CardIcon,
  CardManagementContainer,
  CardNumber,
  DisabledCircle
} from './styledComponents';
import { PlusOutlined } from '@ant-design/icons';
import CheckCircle from '../../../../Assets/Icons/CheckCircle';
import { getCardIcon } from './cardIconMap';

const CardManagement = ({
  theme,
  setAddCard,
  savedCards,
  selectedCard,
  setSelectedCard,
  subscriptionDetails
}) => {
  return (
    <CardManagementContainer subscriptionDetails={subscriptionDetails}>
      <AddCardDiv
        color={theme['@primary-color']}
        onClick={() => {
          setAddCard(true);
          setSelectedCard(null);
        }}
      >
        <PlusOutlined />
        Add new Card
      </AddCardDiv>

      {savedCards.map(card => {
        return (
          <CardDetailsDiv
            selected={
              selectedCard &&
              selectedCard.customerPaymentProfileId ===
                card.customerPaymentProfileId
            }
            color={theme['@primary-color']}
            onClick={() => setSelectedCard(card)}
          >
            {selectedCard &&
            selectedCard.customerPaymentProfileId ===
              card.customerPaymentProfileId ? (
              <CheckCircle />
            ) : (
              <DisabledCircle />
            )}
            <CardNumber>
              {card.payment.creditCard.cardNumber.slice(0, 4) +
                '-' +
                card.payment.creditCard.cardNumber.slice(4)}
            </CardNumber>
            <CardIcon
              src={getCardIcon(
                card.payment.creditCard.cardType &&
                  card.payment.creditCard.cardType.toLowerCase()
              )}
            />
          </CardDetailsDiv>
        );
      })}
    </CardManagementContainer>
  );
};

export default CardManagement;
