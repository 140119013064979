import React from 'react';

function RerenderIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='30'
      fill='none'
      viewBox='0 0 71 71'
    >
      <circle cx='35.5' cy='35.5' r='35.5' fill='#47A4EA'></circle>
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M33.827 12.052c-4.377.28-8.93 2.003-12.58 4.76-1.245.941-3.501 3.198-4.442 4.444-6.407 8.483-6.407 20.036 0 28.52.94 1.246 3.197 3.503 4.443 4.444 4.207 3.179 8.991 4.783 14.255 4.78 3.85-.002 6.964-.718 10.368-2.383 6.176-3.021 10.775-8.718 12.441-15.412.916-3.679.917-7.765.004-11.378-1.017-4.027-2.948-7.52-5.831-10.55-4.851-5.1-11.52-7.682-18.658-7.225zm5.042 4.036c8.789 1.424 15.67 9.038 16.295 18.028.4 5.75-1.657 11.24-5.74 15.324-7.391 7.393-19.116 7.76-26.985.845-5.179-4.552-7.615-11.721-6.304-18.557.784-4.09 3.147-8.207 6.304-10.98 4.542-3.993 10.363-5.643 16.43-4.66zm-1.707 1.806c-.657.344-7.299 5.749-7.437 6.053-.201.442-.183 1.316.037 1.741.243.47 7.105 5.959 7.691 6.152 1.257.415 2.58-.74 2.38-2.077-.098-.66-.313-.92-1.61-1.956-.59-.47-1.053-.872-1.031-.894.022-.021.355.043.741.144 2.525.66 4.835 2.572 5.981 4.954.706 1.464.95 2.526.95 4.121-.004 4.36-2.858 8.02-7.11 9.12-1.257.325-3.286.315-4.561-.022-2.697-.713-4.972-2.575-6.156-5.04-.52-1.082-.775-2.081-.903-3.536-.12-1.366-.357-1.832-1.125-2.207-.822-.401-1.572-.244-2.243.47l-.355.379.007 1.503c.006 1.247.062 1.701.327 2.672 1.348 4.94 5.336 8.666 10.288 9.614 1.274.243 3.653.246 4.912.005 4.985-.954 8.936-4.635 10.323-9.619.302-1.082.326-1.333.326-3.362 0-2.03-.024-2.28-.326-3.363-.985-3.537-3.25-6.422-6.454-8.214-.998-.558-3.173-1.31-4.087-1.414-.379-.043-.715-.12-.746-.171-.032-.051.503-.537 1.19-1.08 1.428-1.133 1.791-1.672 1.68-2.498-.164-1.227-1.628-2.03-2.689-1.475z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export default RerenderIcon;
