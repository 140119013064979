import styled from 'styled-components';
import LoginBackgroundImage from '../../Assets/Images/LoginBackgroundImage.jpg';
import { Typography, Form, Col, Button, Row } from 'antd';
import CascadeLogo from '../../Assets/Icons/CascadeLogo';

export const LoginBackground = styled.div`
  background-image: url(${LoginBackgroundImage});
  background-repeat: no-repeat;
  background-position: 100%;
  overflow-x: hidden;
  background-size: cover;
  height: 100vh;
  width: 100%;
`;

export const LogoDiv = styled.div`
  padding: 40px 0 40px 40px;
  & svg {
    /* height: 69px; */
    width: 159px;
  }
`;

export const DetailDiv = styled.div`
  padding: 40px;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LogoStyle = styled(CascadeLogo)`
  height: 100px;
  width: 100px;

  svg {
    height: 100%;
    width: 100%;
  }
`;

export const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const FormStyle = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  width: 85%;
  height: 50%;
`;

export const FormContent = styled.div`
  padding: 30px 0;
`;

export const LoginFormStyle = styled(Form)`
  width: 100%;
`;

export const LoginButtonStyle = styled(Button)`
  font-weight: 500;
  width: 190px;
  font-size: 18px;

  &.ant-btn {
    border-radius: 100px;
    height: auto;
    padding: 5px 20px;
  }
`;

export const SignUpButtonRow = styled(Row)`
  margin-top: 15px;
  width: 100%;
`;

export const ForgotPasswordColumn = styled(Col)`
  display: flex;
  justify-content: flex-end;
  margin: auto;
`;

export const ForgotPasswordStyle = styled(Typography.Text)`
  font-weight: 300;
  line-height: 39px;
  font-size: 16px;
  color: #47a4ea !important;
`;

export const FooterStyle = styled.div`
  bottom: 0;
  background-color: #f4f5f7;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FooterText = styled(Typography.Text)`
  font-weight: 500;
  line-height: 18px;
  font-size: 14px;
  color: #57657e !important;
`;

export const ForgotPasswordText = styled(Typography.Text)`
  margin-top: 10px;
  font-size: 18px;
  line-height: 26px;
  color: #57657e !important;
`;

export const VerifyButton = styled(Button)`
  font-size: 16px;
  font-weight: 600;
`;

export const TermsAndConditionsDiv = styled.span`
  color: ${props => props.color} !important;
  cursor: pointer;
`;

export const CheckboxDiv = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const CheckboxContent = styled.div`
  margin-left: 15px;
`;
