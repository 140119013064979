import React from "react";
import { StyledDrawer } from "./styledComponents";
import { useSelector } from "react-redux";


function CustomSideDrawer(props) {
  const [theme] = useSelector((state) => [state.themeReducer]);

  return <StyledDrawer filter={props?.filter}
   
    extra={props?.extra} bodyStyle={props?.filter ? { padding: 0, margin: 0 } : {}}
    {...props} textColor={theme["@text-color"]}>{props.children}</StyledDrawer>;
}

export default CustomSideDrawer;
