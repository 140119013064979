import React from 'react';
import moment from 'moment';
import { StatusDiv } from './styledComponents';
import { DATE_TIME_FORMAT } from '../../../../Constants/StringConstants';

export const columns = () => [
  {
    title: 'Transaction ID ',
    dataIndex: ['transId'],
    kew: 'transactionId'
  },
  {
    title: 'Date and Time',
    dataIndex: ['submitTimeLocal'],
    key: 'id',
    sorter: true,
    render: dataAndTime => {
      return <div>{moment(dataAndTime).format(DATE_TIME_FORMAT)}</div>;
    }
  },
  {
    title: 'Status',
    dataIndex: ['uiStatus'],
    key: 'uiStatus',
    render: status => {
      return <StatusDiv status={status}>{status}</StatusDiv>;
    }
    // filterMultiple: false,
    // filtered: false,
    // filters: [
    //   { text: 'Success', value: 'Success' },
    //   { text: 'Processing', value: 'Processing' },
    //   { text: 'Failed', value: 'Failed' },
    //   { text: 'Refund processing', value: 'Refund_processing' }
    // ],
    // onFilter: (value, record) => {
    //   setTransactionStatus(value);
    // }
  },
  {
    title: 'Card Type',
    dataIndex: ['cardType'],
    key: 'cardType'
  },
  {
    title: 'Card Number',
    dataIndex: ['cardNumber'],
    key: 'cardNumber'
  },
  {
    title: 'Amount',
    dataIndex: ['settleAmount'],
    key: 'settleAmount',
    render: settleAmount => {
      return <div>{parseFloat(settleAmount).toFixed(2)}</div>;
    }
  }
];
