import React, { useEffect, useState } from 'react';
import { Row, Form, Input, Col, Select, Tag, Checkbox, Divider } from 'antd';
import CustomTypography from '../../CustomComponents/CustomTypography';
import {
  SubmitButton,
  SubmitButtonCol,
  PhoneNumberCol
} from '../styledComponents.js';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import moment from 'moment';
import { NAME_REGEX_CHECK } from '../../../Constants/StringConstants.js';
import { StyledSelectFacility } from './styledComponents.js';
import CancelIcon from '../../../Assets/Icons/CancelIcon.js';

import CheckboxChangeEvent from 'antd/es/checkbox';
const { Option } = Select;

const AddDoctorForm = ({
  selectedItem,
  submitHandler,
  billersList,
  submitLoading,
  facilities
}) => {
  const [form] = Form.useForm();
  const [Updated, setUpdated] = useState({ phone: false, other: false });
  const [changedValues, setchangedValues] = useState();

  useEffect(() => {
    if (selectedItem) {
      const data = selectedItem;
      data.dob = moment(data.dob);
      if (
        data.phoneNumber &&
        data.phoneNumber.countryCode &&
        data.phoneNumber.number
      ) {
        data.phoneNumber.number = data.phoneNumber.countryCode.concat(
          data.phoneNumber.number
        );
      }
      data.facilityIds = data.facilities.map(item => item.facilityId);
      data.biller = data ? data.biller?.id : '';
      form.setFieldsValue({ ...data });

      setchangedValues({ ...data });
    }
    // eslint-disable-next-line
  }, [selectedItem]);

  const phoneNumberChanged = (value, country, e, formattedValue) => {
    let countryCode = country.dialCode;
    form.setFieldsValue({
      phoneNumber: {
        countryCode: countryCode
      }
    });
    let changedPHCode =
      String(selectedItem?.phoneNumber?.countryCode) !==
      String(country.dialCode);
    let changedPH =
      String(selectedItem?.phoneNumber?.number).slice(0, 11) !== String(value);

    setUpdated({ ...Updated, phone: changedPHCode || changedPH });
  };

  const [selfReviewEnabled, setSelfReviewEnabled] = useState(
    selectedItem?.selfReviewEnabled ? selectedItem.selfReviewEnabled : false
  );
  const handleCheckboxChange = e => {
    setSelfReviewEnabled(e.target.checked);
  };

  const onvalueUpdate = values => {
    if (!Boolean(values?.phoneNumber)) {
      setchangedValues({ ...changedValues, ...values });
      setUpdated({
        ...Updated,
        other:
          JSON.stringify({ ...changedValues, ...values }) !==
          JSON.stringify(selectedItem)
      });
    }
  };
  const transcriptionFlowChange = changedValue => {
    if (changedValue === 'TRANSCRIPTION_ONLY') {
      form.setFieldsValue({ reviewerCount: '0' });
    }
  };
  const tagRender = props => {
    const { label, disabled, onClose } = props;

    const onPreventMouseDown = event => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        className={`ant-select-selection-item ${
          disabled ? `disabled-facility-tag` : ``
        }`}
        onMouseDown={onPreventMouseDown}
        closable={true}
        onClose={onClose}
        closeIcon={
          <div className='ant-select-selection-item-remove'>
            <CancelIcon height={12} width={12} />
          </div>
        }
      >
        <p className='ant-select-selection-item-label'>{label}</p>
      </Tag>
    );
  };

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={values => submitHandler(values)}
      onValuesChange={values => onvalueUpdate(values)}
    >
      <Row justify='center'>
        <Col span={24}>
          <Form.Item
            label='First Name'
            name='firstName'
            rules={[
              { required: true, message: 'First Name is required' },
              {
                pattern: new RegExp(NAME_REGEX_CHECK),
                message: 'Please enter only alphabets'
              }
            ]}
          >
            <Input size='large' />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label='Last Name'
            name='lastName'
            rules={[
              { required: true, message: 'Last Name is required' },
              {
                pattern: new RegExp(NAME_REGEX_CHECK),
                message: 'Please enter only alphabets'
              }
            ]}
          >
            <Input size='large' />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label='Facility'
            name='facilityIds'
            rules={[{ required: true, message: 'Facility is required' }]}
          >
            <StyledSelectFacility
              size='large'
              mode='multiple'
              showArrow
              options={facilities}
              optionFilterProp='label'
              getPopupContainer={trigger => trigger.parentNode}
              tagRender={tagRender}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label='Email'
            name='email'
            rules={[
              { required: true, message: 'Email is required' },
              {
                type: 'email',
                message: 'Please enter valid email'
              }
            ]}
          >
            <Input size='large' />
          </Form.Item>
        </Col>
        <PhoneNumberCol span={24}>
          <Form.Item noStyle>
            <Form.Item
              name={['phoneNumber', 'countryCode']}
              noStyle
            ></Form.Item>
            <Form.Item
              label='Phone Number'
              name={['phoneNumber', 'number']}
              rules={[{ required: true, message: 'Phone Number is required' }]}
            >
              <PhoneInput
                country={'us'}
                onChange={phoneNumberChanged}
                placeholder={''}
              />
            </Form.Item>
          </Form.Item>
        </PhoneNumberCol>
        <Col span={24}>
          <Form.Item
            label='NPI'
            name='npi'
            rules={[{ required: true, message: 'NPI number is required' }]}
          >
            <Input size='large' />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label='Privilege'
            name='userPrivilege'
            rules={[{ required: true, message: 'User Privilege is required' }]}
          >
            <Select
              size='large'
              getPopupContainer={trigger => trigger.parentNode}
            >
              <Option value='TRANSCRIPTION_ONLY'>Transcription Only</Option>
              <Option value='REVIEWER_ONLY'>Reviewing Only</Option>
              <Option value='TRANSCRIPTION_REVIEWER'>
                Transcription and Reviewing
              </Option>
              <Option value='NONE'>None</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label=' Billers' name='biller'>
            <Select
              size='large'
              // getPopupContainer={trigger => trigger.parentNode}
              onChange={transcriptionFlowChange}
            >
              {billersList &&
                billersList.map(biller => (
                  <Select.Option key={biller.id} value={biller.id}>
                    {biller.fullName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row justify='left'>
        <Col span={24}>
          <CustomTypography>
            <span style={{ fontWeight: '500', textAlign: 'left' }}>
              Configure Review Process
            </span>
          </CustomTypography>
          <Divider
            style={{ margin: '24px 0 0 -40px', width: 'calc(100% + 76px)' }}
          />
        </Col>
        <Col span={24}>
          <div>
            <br></br>
            <Form.Item
              label='Self Review Enabled'
              name='selfReviewEnabled'
              valuePropName='checked'
            >
              <Checkbox
                checked={selfReviewEnabled}
                onChange={handleCheckboxChange}
              >
                Enable Self Review
              </Checkbox>
            </Form.Item>
          </div>
        </Col>
      </Row>
      {!selfReviewEnabled ? (
        <Row justify='center' disabled={true}>
          <Col span={24}>
            <Form.Item
              label=' Transcriptionist Flow'
              name='transcriptionFlowType'
              rules={[
                { required: true, message: 'Trascriptionist flow is required' }
              ]}
            >
              <Select
                size='large'
                // getPopupContainer={trigger => trigger.parentNode}
                onChange={transcriptionFlowChange}
              >
                <Option value='TRANSCRIPTION_ONLY'>Transcription Only</Option>
                <Option value='TRANSCRIPTION_REVIEWER'>
                  Transcription and Reviewer
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label='Reviewer Count'
              name='reviewerCount'
              rules={[
                { required: true, message: 'Trascriptionist flow is required' }
              ]}
            >
              <Select
                size='large'
                getPopupContainer={trigger => trigger.parentNode}
                disabled={
                  form.getFieldValue('transcriptionFlowType') ===
                  'TRANSCRIPTION_ONLY'
                }
              >
                <Option value='1'>1</Option>
                <Option value='2'>2</Option>
                <Option value='3'>3</Option>
                <Option value='4'>4</Option>
                <Option value='5'>5</Option>
              </Select>
            </Form.Item>
          </Col>
          {/* <Col span={24}>
          <Form.Item
            label='Trascriptionist Flow'
            name='____'
            rules={[{ required: true, message: 'Trascriptionist flow is required' }]}
          >
            <Select
              size='large'
              getPopupContainer={trigger => trigger.parentNode}
            >
              <Option value='TRANSCRIPTION_ONLY'>Transcription Only</Option>
              <Option value='REVIEWER_ONLY'>Reviewing Only</Option>
              <Option value='TRANSCRIPTION_REVIEWER'>
                Transcription and Reviewing
              </Option>
              <Option value='NONE'>None</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label='Trascriptionist Flow'
            name='____'
            rules={[{ required: true, message: 'Trascriptionist flow is required' }]}
          >
            <Select
              size='large'
              getPopupContainer={trigger => trigger.parentNode}
            >
              <Option value='TRANSCRIPTION_ONLY'>Transcription Only</Option>
              <Option value='REVIEWER_ONLY'>Reviewing Only</Option>
              <Option value='TRANSCRIPTION_REVIEWER'>
                Transcription and Reviewing
              </Option>
              <Option value='NONE'>None</Option>
            </Select>
          </Form.Item>
        </Col>
         */}
        </Row>
      ) : (
        // Content for the enabled row
        <Row></Row>
      )}
      <Row>
        <SubmitButtonCol span={24}>
          <SubmitButton
            htmlType='submit'
            type='primary'
            size='large'
            shape='round'
            loading={submitLoading}
            disabled={
              !(Updated.phone || Updated.other) && Boolean(selectedItem)
            }
          >
            Submit
          </SubmitButton>
        </SubmitButtonCol>
      </Row>
    </Form>
  );
};

export default AddDoctorForm;
