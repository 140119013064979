import React from "react";

function CheckCircle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="38"
      fill="none"
      viewBox="0 0 38 38"
    >
      <g filter="url(#filter0_d)">
        <circle cx="17" cy="17" r="15" fill="#47A4EA"></circle>
      </g>
      <path
        fill="#fff"
        d="M23.413 12.2a.68.68 0 00-.963 0l-8.154 8.153-3.134-3.134a.68.68 0 00-.963.963l3.616 3.615a.68.68 0 00.962 0l8.636-8.635a.68.68 0 000-.963z"
      ></path>
      <defs>
        <filter
          id="filter0_d"
          width="38"
          height="38"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="2" dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.303726 0 0 0 0 0.660467 0 0 0 0 0.929123 0 0 0 0.5 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default CheckCircle;
