import { combineReducers } from 'redux';
import { createStore } from 'redux';
import { themeReducer } from './Reducers/ThemeReducer';
import { userReducer } from './Reducers/UserReducer';
import { notificationReducer } from './Reducers/NotificationReducer';

const rootReducer = combineReducers({
  themeReducer,
  userReducer,
  notificationReducer,
});
const store = createStore(rootReducer);
export default store;
