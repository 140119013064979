import React from 'react';

function AuditIcon() {
  return (
    <span className='anticon'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='22'
        height='22'
        fill='#666'
        viewBox='0 0 22 22'
      >
        <g clipPath='url(#clip0)'>
          <path
            fill='#666'
            d='M4.7 8.995h-.5v1h.5v-1zm7.6 1h.5v-1h-.5v1zm-7.6 2.039h-.5v.5h.5v-.5zm7.6 0v.5h.5v-.5h-.5zM4.7 6.967v-.5h-.5v.5h.5zm7.6 0h.5v-.5h-.5v.5zm3.8-2.533h.5v-.207l-.146-.147-.354.354zm-3.8-3.8l.354-.354-.146-.146H12.3v.5zm-7.6 9.36h7.6v-1H4.7v1zm0 2.54h7.6v-1H4.7v1zm0-5.067h7.6v-1H4.7v1zm10.134 10.4H2.167v1h12.667v-1zM1.4 17.1V1.9h-1v15.2h1zM15.6 4.434V17.1h1V4.434h-1zm-13.433-3.3H12.3v-1H2.167v1zm9.78-.147l3.8 3.8.707-.707-3.8-3.8-.707.707zm-9.78 16.88A.767.767 0 011.4 17.1h-1c0 .976.791 1.767 1.767 1.767v-1zm12.667 1c.975 0 1.766-.79 1.766-1.767h-1a.767.767 0 01-.766.767v1zM1.4 1.9c0-.423.344-.766.767-.766v-1C1.191.134.4.924.4 1.9h1zm2.8 5.067v5.067h1V6.967h-1zm3.8 0v5.067h1V6.967H8zm3.8 0v5.067h1V6.967h-1zM4.067 4.934h3.8v-1h-3.8v1zm5.067 10.133h3.8v-1h-3.8v1z'
          ></path>
        </g>
        <defs>
          <clipPath id='clip0'>
            <path fill='#fff' d='M0 0H22V22H0z'></path>
          </clipPath>
        </defs>
      </svg>
    </span>
  );
}

export default AuditIcon;
