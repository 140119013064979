import axiosInstance from '../Config/AxiosInstance';

const PREFIX = `/clinic/transcriptionist`;

export const listAll = (
  facilities,
  name,
  pageSize,
  pageNo,
  sortFitler,
  sortOrder
) => {
  let url =
    PREFIX +
    `?name=${name}&sortKey=${sortFitler}&page=${pageNo}&size=${pageSize}&sortOrder=${sortOrder}`;
  if (facilities.length > 0) {
    url = url + `&facilityIds=${facilities}`;
  }
  return axiosInstance.get(url);
};

export const saveTranscriptionist = data => {
  return axiosInstance.post(PREFIX, data);
};

export const editTranscriptionist = data => {
  return axiosInstance.put(PREFIX, data);
};

export const deleteTranscriptionist = id => {
  return axiosInstance.delete(PREFIX + `/${id}`);
};

export const getJobs = (
  facilities,
  pageSize,
  pageNo,
  sortFitler,
  sortOrder,
  type,
  searchKey,
  jobStatus,
) => {
  let url =
    PREFIX +
    `/job/${type}?&sortKey=${sortFitler}&page=${pageNo}&size=${pageSize}&sortOrder=${sortOrder}${
      jobStatus ? '&jobStatus=' + jobStatus : ''
    }&searchValue=${searchKey}`;
  return axiosInstance.get(url);
};

export const getReadyJobs = (
  facilities,
  pageSize,
  pageNo,
  sortFitler,
  sortOrder,
  type
) => {
  let url =
    PREFIX +
    `/job/${type}?&sortKey=${sortFitler}&page=${pageNo}&size=${pageSize}&sortOrder=${sortOrder}`;
  if (facilities.length > 0) {
    url = url + `&facilityIds=${facilities}`;
  }
  return axiosInstance.get(url);
};

export const transcriptorSelfAssignJob = (jobId, sessionId) => {
  return axiosInstance.put(
    PREFIX + `/job/${jobId}/assign?session-id=${sessionId}`
  );
};

export const startTranscriptorJob = jobId => {
  return axiosInstance.put(PREFIX + `/job/${jobId}/start`);
};

export const completeTranscriptorJob = (jobId, sessionId) => {
  return axiosInstance.put(
    PREFIX + `/job/${jobId}/complete?session-id=${sessionId}`
  );
};

export const rejectTranscriptorJob = (jobId, data, sessionId) => {
  return axiosInstance.put(
    PREFIX + `/job/${jobId}/reject?session-id=${sessionId}`,
    data
  );
};
