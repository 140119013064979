import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Space } from 'antd';
import FloatLabel from '../../../CustomComponents/FloatingLabel';
import { useSelector } from 'react-redux';
import {
  CustomRow,
  SaveButton,
  CancelButton,
  CustomPhoneNumberCol
} from './styledComponents';
import { SaveOutlined, CloseOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { getCountries } from '../../../../Apis/PublicApi';
import {
  NAME_REGEX_CHECK,
  ZIPCODE_REGEX_CHECK
} from '../../../../Constants/StringConstants';

const BasicInformation = ({
  saveProfileInformation,
  profile,
  edit,
  setEdit,
  saveLoading
}) => {
  const [form] = Form.useForm();
  const [theme] = useSelector(state => [state.themeReducer]);
  const [user] = useSelector(state => [state.userReducer]);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    form.setFieldsValue({ ...profile });
    // eslint-disable-next-line
  }, [profile]);

  useEffect(() => {
    getCountries().then(response => {
      setCountries(response.data);
    });
  }, []);

  const onFormSubmit = values => {
    let payload = values;
    payload.address.country = payload.address.country.changedValue;
    payload.id = user.id;
    saveProfileInformation(payload);
  };

  const cancelEdit = () => {
    setEdit(false);
    form.resetFields();
    form.setFieldsValue({ ...profile });
  };

  const phoneNumberChanged = (value, country, e, formattedValue) => {
    let countryCode = country.dialCode;
    form.setFieldsValue({
      phoneNumber: {
        countryCode: countryCode
      }
    });
  };

  return (
    <>
      <Form layout='vertical' form={form} onFinish={onFormSubmit}>
        <Row>
          <CustomRow gutter={[36, 36]}>
            <Col span={12}>
              <Form.Item
                name='firstName'
                rules={[
                  { required: true, message: 'First Name is required' },
                  {
                    pattern: new RegExp(NAME_REGEX_CHECK),
                    message: 'Please enter only alphabets'
                  }
                ]}
              >
                <FloatLabel
                  label='First  Name'
                  primaryColor={theme['@text-color']}
                  type='input'
                  disabled={!edit}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name='lastName'
                rules={[
                  { required: true, message: 'Last Name is required' },
                  {
                    pattern: new RegExp(NAME_REGEX_CHECK),
                    message: 'Please enter only alphabets'
                  }
                ]}
              >
                <FloatLabel
                  label='Last  Name'
                  primaryColor={theme['@text-color']}
                  type='input'
                  disabled={!edit}
                />
              </Form.Item>
            </Col>
          </CustomRow>
          <CustomRow gutter={[36, 36]}>
            <Col span={12}>
              <Form.Item
                name={['address', 'country']}
                rules={[{ required: true, message: 'Country is required' }]}
              >
                <FloatLabel
                  label='Country'
                  primaryColor={theme['@text-color']}
                  type='select'
                  countries={countries}
                  disabled={!edit}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={['address', 'state']}
                rules={[
                  {
                    validator: (_, value) => {
                      if(value.length <= 0){
                        return Promise.reject(new Error('State is required'));
                      }
                      if(!new RegExp(/^([a-zA-Z]+)(\s([a-zA-Z])+)*$/).test(value)){
                        return Promise.reject(new Error('Please enter valid state name'));
                      }
                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <FloatLabel
                  label='State'
                  primaryColor={theme['@text-color']}
                  type='input'
                  disabled={!edit}
                />
              </Form.Item>
            </Col>
          </CustomRow>
          <CustomRow gutter={[36, 36]}>
            <Col span={12}>
              <Form.Item
                name={['address', 'addressLine1']}
                rules={[{ required: true, message: 'Address is required' }]}
              >
                <FloatLabel
                  label='Address Line 1'
                  primaryColor={theme['@text-color']}
                  type='input'
                  disabled={!edit}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={['address', 'addressLine2']}
                rules={[{ required: true, message: 'Address is required' }]}
              >
                <FloatLabel
                  label='Address Line 2'
                  primaryColor={theme['@text-color']}
                  type='input'
                  disabled={!edit}
                />
              </Form.Item>
            </Col>
          </CustomRow>
          <CustomRow gutter={[36, 36]}>
            <Col span={12}>
              <Form.Item
                name={['address', 'city']}
                rules={[
                  {
                    validator: (_, value) => {
                      if(value.length <= 0){
                        return Promise.reject(new Error('City is required'));
                      }
                      if(!new RegExp(/^([a-zA-Z]+)(\s([a-zA-Z])+)*$/).test(value)){
                        return Promise.reject(new Error('Please enter valid city name'));
                      }
                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <FloatLabel
                  label='City'
                  primaryColor={theme['@text-color']}
                  type='input'
                  disabled={!edit}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={['address', 'zipCode']}
                rules={[
                  { required: true, message: 'Zip Code is required' },
                  {
                    pattern: new RegExp(ZIPCODE_REGEX_CHECK),
                    message: 'Please enter valid zip code'
                  }
                ]}
              >
                <FloatLabel
                  label='Zip Code'
                  primaryColor={theme['@text-color']}
                  type='input'
                  disabled={!edit}
                />
              </Form.Item>
            </Col>
          </CustomRow>
          <CustomRow gutter={[36, 36]}>
            <CustomPhoneNumberCol
              span={12}
              primaryTextColor={theme['@text-color']}
            >
              <Form.Item noStyle>
                <Form.Item
                  name={['phoneNumber', 'countryCode']}
                  noStyle
                ></Form.Item>
                <Form.Item
                  name={['phoneNumber', 'number']}
                  rules={[
                    { required: true, message: 'Cell Phone is required' }
                  ]}
                  label='Cell Phone #'
                >
                  <PhoneInput
                    country={'us'}
                    onChange={phoneNumberChanged}
                    disabled={!edit}
                  />
                </Form.Item>
              </Form.Item>
            </CustomPhoneNumberCol>
          </CustomRow>
          {edit === true && (
            <CustomRow justify='end'>
              <Space size={20}>
                <CancelButton onClick={cancelEdit} icon={<CloseOutlined />}>
                  Cancel
                </CancelButton>

                <SaveButton
                  type='primary'
                  htmlType='submit'
                  icon={<SaveOutlined />}
                  loading={saveLoading}
                >
                  Save
                </SaveButton>
              </Space>
            </CustomRow>
          )}
        </Row>
      </Form>
    </>
  );
};

export default BasicInformation;
