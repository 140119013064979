import React from "react";

function PictureUploadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      fill="none"
      viewBox="0 0 27 27"
    >
      <g fill="#47A4EA">
        <path d="M23.143 8.678H18.32a.964.964 0 100 1.929h4.822c.532 0 .964.432.964.964v12.536a.964.964 0 01-.964.964H3.857a.964.964 0 01-.964-.964V11.571c0-.532.431-.964.964-.964h4.821a.964.964 0 100-1.929H3.857a2.893 2.893 0 00-2.893 2.893v12.536A2.893 2.893 0 003.857 27h19.286a2.893 2.893 0 002.893-2.893V11.57a2.893 2.893 0 00-2.893-2.893z"></path>
        <path d="M19.003 5.103L14.182.282a.964.964 0 00-1.364 0L7.998 5.103a.964.964 0 001.364 1.364l3.175-3.176v15.994a.964.964 0 101.929 0V3.291l3.175 3.176a.964.964 0 001.363-1.364z"></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H27V27H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default PictureUploadIcon;
