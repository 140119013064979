import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  text-align: center;
  background: transparent;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
  min-height: ${(props) => props.height || 'calc(100vh - 300px)'};
  display: flex;
  align-content: center;
  align-items: center;
  justify-items: center;
  justify-content: center;
`;

const Spin = keyframes`
 0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const DualRingSpinner = styled.div`
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;

  & div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 48px;
  height: 48px;
  margin: 5px;
  border: 5px solid ${(props) => (props.color ? props.color : '#47A4EA')};
  border-radius: 50%;
  animation: ${Spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${(props) => (props.color ? props.color : '#47A4EA')} transparent transparent transparent;
}
& div:nth-child(1) {
  animation-delay: -0.45s;
}
& div:nth-child(2) {
  animation-delay: -0.3s;
}
& div:nth-child(3) {
  animation-delay: -0.15s;
}
`;
