import { notification } from 'antd';
import moment from 'moment';
import React, { forwardRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchFacilityAudit } from '../../../Apis/Facility';

import { DATE_TIME_FORMAT } from '../../../Constants/StringConstants';
import FacilityAuditLog from './FacilityAuditLog';
import {
  InfoContainer,
  FacilityInfoContainer,
  FacilityInfoRow,
  FacilityInformationTitle,
  FacilityInformationText,
} from './styledComponents';

const FacilityAuditDetails = forwardRef(({ facilityDetails }, ref) => {
  const [theme] = useSelector(state => [state.themeReducer]);
  const [loading, setLoading] = useState(false);
  const [facilityAuditLog, setFacilityAuditLog] = useState([]);


  useEffect(() => {
    setLoading(true);
    fetchFacilityAudit(facilityDetails?.facilityId)
      .then(response => {
        if (response.data.success) {
          setFacilityAuditLog(response.data.result);
          setLoading(false);
        } else {
          notification['error']({
            message: 'Session Fetch Failed',
            description: response.data.message
          });
          setLoading(false);
        }
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
    // eslint-disable-next-line
  }, [facilityDetails]);

  return (
    <>
      {
        facilityDetails ?
          <div ref={ref}>
            <FacilityInfoContainer>
              <FacilityInfoRow>
                <InfoContainer>
                  <FacilityInformationTitle color={theme['@text-color']}>
                    {`Facility Name`}
                  </FacilityInformationTitle>
                  <FacilityInformationText color={theme['@table-text-color']}>
                    {facilityDetails?.facilityName}
                  </FacilityInformationText>
                </InfoContainer>
                <InfoContainer>
                  <FacilityInformationTitle color={theme['@text-color']}>
                    {`Facility Status`}
                  </FacilityInformationTitle>
                  <FacilityInformationText color={theme['@table-text-color']}>
                    {facilityDetails?.disabled?`Disabled`:`Enabled`}
                  </FacilityInformationText>
                </InfoContainer>
              </FacilityInfoRow>
              <FacilityInfoRow>
                <InfoContainer>
                  <FacilityInformationTitle color={theme['@text-color']}>
                    {`Last Update`}
                  </FacilityInformationTitle>
                  <FacilityInformationText color={theme['@table-text-color']}>
                    {
                      facilityDetails?.facilityDisableStatusLastUpdated ?
                        moment(facilityDetails?.facilityDisableStatusLastUpdated).format(DATE_TIME_FORMAT)
                        :
                        `--`
                    }
                  </FacilityInformationText>
                </InfoContainer>
              </FacilityInfoRow>
            </FacilityInfoContainer>
            {
              (facilityAuditLog.length > 0 || loading) ?
                <FacilityAuditLog
                  loading={loading}
                  FacilityAuditData={facilityAuditLog}
                />
                :
                null
            }

          </div>
          : null
      }
    </>
  )
});

export default FacilityAuditDetails;
