import React from 'react';

function SuccessIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 504.12 504.12"
      version="1.1"
      viewBox="0 0 504.12 504.12"
      xmlSpace="preserve"
      width="50px"
      height="50px"
    >
      <circle cx="252.06" cy="252.06" r="252.06" fill="#3DB39E"></circle>
      <path
        fill="#37A18E"
        d="M463.163 114.609L240.246 345.403l.394 24.812h10.24l241.428-194.56c-7.09-21.661-16.936-42.535-29.145-61.046z"
      ></path>
      <path
        fill="#F2F1EF"
        d="M499.397 103.582l-44.505-44.111c-5.908-5.908-15.754-5.908-22.055 0L242.609 256l-82.314-81.132c-5.908-5.908-15.754-5.908-22.055 0l-39.385 38.991c-5.908 5.908-5.908 15.754 0 21.662L230.4 365.883c3.545 3.545 8.271 4.726 12.997 4.332 4.726.394 9.452-.788 12.997-4.332l243.003-240.246c5.908-6.302 5.908-16.148 0-22.055z"
      ></path>
      <path
        fill="#E6E5E3"
        d="M256.394 365.883l243.003-240.246c5.908-5.908 5.908-15.754 0-21.662l-7.089-6.695-249.305 244.972-137.846-134.301-5.908 5.908c-5.908 5.908-5.908 15.754 0 21.662l131.545 130.363c3.545 3.545 8.271 4.726 12.997 4.332 4.332.393 9.058-.788 12.603-4.333z"
      ></path>
    </svg>
  );
}

export default SuccessIcon;
