import DefaultIcon from '../../../../Assets/CardVendors/default.svg';
import MasterCard from '../../../../Assets/CardVendors/masterCard.svg';
import Visa from '../../../../Assets/CardVendors/visa.svg';
import UnionPay from '../../../../Assets/CardVendors/unionPay.svg';
import Discover from '../../../../Assets/CardVendors/discover.svg';
import Amex from '../../../../Assets/CardVendors/amex.svg';
import Jcb from '../../../../Assets/CardVendors/JCB.svg';
import DinersClub from '../../../../Assets/CardVendors/dinersClub.svg';
import Dankort from '../../../../Assets/CardVendors/Dankort.svg';
import Uatp from '../../../../Assets/CardVendors/uatp.svg';
import Mir from '../../../../Assets/CardVendors/mir.svg';
import Maestro from '../../../../Assets/CardVendors/maestro.svg';

export const getCardIcon = card => {
  switch (card) {
    case 'visa':
      return Visa;
    case 'mastercard':
      return MasterCard;
    case 'maestro':
      return Maestro;
    case 'amex':
      return Amex;
    case 'americanexpress':
      return Amex;
    case 'diners':
      return DinersClub;
    case 'discover':
      return Discover;
    case 'jcb':
      return Jcb;
    case 'dankort':
      return Dankort;
    case 'uatp':
      return Uatp;
    case 'mir':
      return Mir;
    case 'unionPay':
      return UnionPay;
    default:
      return DefaultIcon;
  }
};
