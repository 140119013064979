import React from 'react';
import {
  SubContainerRow,
  SubPageTitle,
  ImportExportButton
} from '../styledComponents';
import { PageActionContainer, ChangeFilesViewButton } from './styledComponents';
import { TableDiv, SearchJobListStyle } from '../JobList/styledComponents';
import DocumentTableView from './DocumentTableView';
import DocumentGridView from './DocumentGridView';
import {
  SearchOutlined,
  AppstoreOutlined,
  UnorderedListOutlined,
  UploadOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import { Dropdown, Tooltip } from 'antd';
import {
  StyledMenu as DropDownMenu,
  StyledMenuItem as DropDownMenuItem
} from '../Layout/Header/styledComponents';
import CustomTypography from '../../CustomComponents/CustomTypography';

const ViewController = ({
  setImportModalOpen,
  setFolderImport,
  theme,
  sideBarItems,
  activeKey,
  isFolderView,
  setIsFolderView,
  debounceOnChange,
  fileList,
  total,
  loading,
  currentPage,
  filterTableChange,
  setCurrentPage,
  getComments,
  setCommentsDrawerVisible,
  pageSize,
  setPageSize,
  setSelectedFile,
  setShowDetails,
  listSelection,
  setListSelection,
  setFileExportList,
  selected,
  setSelected,
  getHl7files
}) => {
  const menu = onDropDownClick => (
    <DropDownMenu onClick={onDropDownClick}>
      <DropDownMenuItem key='file'>File Upload</DropDownMenuItem>
      <DropDownMenu.Divider />
      <DropDownMenuItem key='folder'>Folder Upload</DropDownMenuItem>
    </DropDownMenu>
  );

  const clickEvents = {
    file: () => {
      setImportModalOpen(true);
      setFolderImport(false);
    },
    folder: () => {
      setImportModalOpen(true);
      setFolderImport(true);
    }
  };

  const onDrpDownClick = ({ key }) => {
    clickEvents[key]();
  };

  return (
    <TableDiv>
      <SubContainerRow>
        <SubPageTitle justify='space-between'>
          <CustomTypography color={theme['@text-color']}>
            {sideBarItems &&
              sideBarItems[activeKey] &&
              sideBarItems[activeKey].name + ' '}
          </CustomTypography>
          <PageActionContainer>
            {activeKey === '0' ? (
              <Tooltip title={!listSelection && 'Select file(s) to export.'}>
                <ImportExportButton
                  type='primary'
                  icon={<DownloadOutlined />}
                  onClick={() => {
                    setImportModalOpen(true);
                  }}
                  disabled={!listSelection}
                >
                  Export
                </ImportExportButton>
              </Tooltip>
            ) : (
              <Dropdown
                overlay={menu(onDrpDownClick)}
                placement='bottomRight'
                trigger='click'
              >
                <ImportExportButton type='primary' icon={<UploadOutlined />}>
                  Import
                </ImportExportButton>
              </Dropdown>
            )}

            <Tooltip title={isFolderView ? 'List view' : 'Grid view'}>
              <ChangeFilesViewButton
                icon={
                  isFolderView ? (
                    <UnorderedListOutlined />
                  ) : (
                    <AppstoreOutlined />
                  )
                }
                onClick={() => {
                  setListSelection(false);
                  setFileExportList([]);
                  if (isFolderView) {
                    setIsFolderView(false);
                    setPageSize(10);
                    setShowDetails(false);
                    getHl7files();
                  } else {
                    setIsFolderView(true);
                    setPageSize(10);
                    getHl7files();
                  }
                }}
              />
            </Tooltip>
            <SearchJobListStyle
              files={true}
              prefix={<SearchOutlined />}
              placeholder='Search Files'
              onChange={event => {
                debounceOnChange(event.target.value);
              }}
            />
          </PageActionContainer>
        </SubPageTitle>
        {isFolderView ? (
          <DocumentGridView
            fileList={fileList}
            pageSize={pageSize}
            total={total}
            getComments={getComments}
            setCommentsDrawerVisible={setCommentsDrawerVisible}
            setShowDetails={setShowDetails}
            setSelectedFile={setSelectedFile}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setFileExportList={setFileExportList}
            setListSelection={setListSelection}
            selected={selected}
            setSelected={setSelected}
            loading={loading}
          />
        ) : (
          <DocumentTableView
            fileList={fileList}
            total={total}
            loading={loading}
            currentPage={currentPage}
            filterTableChange={filterTableChange}
            setCurrentPage={setCurrentPage}
            getComments={getComments}
            setCommentsDrawerVisible={setCommentsDrawerVisible}
            setListSelection={setListSelection}
            setFileExportList={setFileExportList}
          />
        )}
      </SubContainerRow>
    </TableDiv>
  );
};

export default ViewController;
