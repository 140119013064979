import { Editor, Transforms, Text } from 'slate';
import { nanoid } from 'nanoid';

export const CustomEditor = {
  isBoldMarkActive (editor) {
    const [match] = Editor.nodes(editor, {
      match: n => n.bold === true,
      universal: true
    });
    return !!match;
  },

  isHeadingBlockActive (editor) {
    const [match] = Editor.nodes(editor, {
      match: n => n.type === 'heading'
    });

    return !!match;
  },

  toggleBoldMark (editor) {
    const isActive = CustomEditor.isBoldMarkActive(editor);
    Transforms.setNodes(
      editor,
      { bold: isActive ? null : true },
      { match: n => Text.isText(n), split: true }
    );
  },

  toggleHeadingBlock (editor) {
    const isActive = CustomEditor.isHeadingBlockActive(editor);
    Transforms.setNodes(
      editor,
      {
        type: isActive ? null : 'heading',
        id: 'new-' + nanoid(), //unique 'id' needed for getting position to render button
        class: isActive ? 'section-content' : 'section-heading'
      },

      { match: n => Editor.isBlock(editor, n), split: true }
    );
  }
};
