import React from 'react';

function ReInviteIcon({ disabled }) {
  return (
    <span className='anticon'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 512 512'
        height={16}
        width={16}
        fill={disabled ? '#d9d9d9' : ''}
      >
        <g data-name='Layer 2'>
          <g data-name='Layer 1'>
            <path d='M512 336c-.83 1.78-1.58 3.6-2.49 5.33a20 20 0 01-21.43 10.31c-9-1.91-15.79-9.42-16-18.62-.28-12.16-.11-24.33-.12-36.49v-77.68c-2.45 1.44-4.27 2.46-6 3.56q-82.28 51.16-164.54 102.36c-11.15 6.94-23 11.85-36.18 13.4-19.9 2.34-37.85-2.92-54.62-13.37Q128.34 273.56 46 222.44c-1.68-1.05-3.4-2.06-5.59-3.39A36.89 36.89 0 0040 223v207.44c0 24.39 17.22 41.51 41.65 41.53q174.21.11 348.41.07c22.09 0 41-15.44 41.9-40.46.3-8.2 4.31-14.27 11.8-17.7 6.94-3.17 14-2.7 19.72 2.24 3.57 3.1 5.7 7.86 8.48 11.88v12a28 28 0 00-1 3.29c-4.56 27.78-19.33 48.27-44.6 60.55-7.93 3.85-16.92 5.5-25.43 8.16H71a10.28 10.28 0 00-2.23-.89c-28.11-4.55-48.64-19.59-60.89-45.19C4.16 458.14 2.57 449.33 0 441V184c1.06-4.52 1.9-9.1 3.21-13.54 6-20.22 18.35-35.46 36.05-46.75Q118 73.51 196.57 23C212.87 12.49 229.14 2.24 249 0h14c13.25 2.13 25.81 6.11 37.21 13.41q86.57 55.49 173.18 110.9c14.4 9.22 25.71 21.26 31.95 37.27 3.05 7.82 4.49 16.26 6.66 24.42zm-44.83-161.62c-3.61-7.77-9.69-12.76-16.5-17.12q-85.18-54.52-170.32-109.13c-16.55-10.64-32.52-10.52-49 .14C194.57 72 157.6 95.5 120.71 119.09q-30.28 19.38-60.58 38.77c-6.51 4.17-12 9.23-15.5 16.5 1.76 1.13 3.14 2 4.54 2.92q91.4 56.85 182.75 113.72c16.3 10.19 32 10.08 48.24-.09q91.26-57.06 182.74-113.75c1.39-.85 2.75-1.78 4.27-2.78z'></path>
            <path d='M235 150.05c0-13.76-.19-26.89.06-40 .27-14 13.57-23.3 26.46-18.8 8.18 2.86 13.33 10.22 13.41 19.69.12 12.81 0 25.62 0 39.11H293c7 0 14-.09 21 0 12.15.21 21 8.88 20.89 20.23-.14 11.19-8.93 19.59-20.86 19.72-12.66.14-25.32.11-38 .16-.14 0-.28.13-1.11.53v18.44c0 6.66.09 13.33 0 20-.21 12.19-8.81 21-20.23 20.85-11.24-.13-19.59-8.86-19.7-20.83-.11-12.81 0-25.62 0-38.87-1.89-.09-3.49-.23-5.09-.24-11.5 0-23 .1-34.49 0s-20.24-8.61-20.39-19.64c-.16-11.25 8.47-20.06 20.29-20.25 12.98-.27 25.96-.1 39.69-.1z'></path>
          </g>
        </g>
      </svg>
    </span>
  );
}

export default ReInviteIcon;
