import styled from 'styled-components';
import AudioPlayer from 'react-h5-audio-player';

export const StyledAudioPlayer = styled(AudioPlayer)`
  background: ${(props) => props.background};
  width: 100%;
  border-radius: 50px;
  outline: none;
  height: 40px;

  &.rhap_container {
    padding: 0 5px 0 0;
  }

  & :focus {
    outline: none !important;
  }

  & .rhap_progress-section {
    color: white;
    height: 40px;
    & .rhap_time {
      color: white;
      font-size: 13px;
      font-weight: 500;
      line-height: 17px;

      &.rhap_current-time {
        margin-left: 5px;
      }
    }
  }

  & .rhap_controls-section {
    /* flex-direction: row; */
    display: none;
  }

  & .rhap_main-controls-button {
    color: white;
    height: 30px;
    width: 30px;
  }
  & .rhap_play-pause-button {
    font-size: 30px;
  }
  & .rhap_volume-button {
    color: white;
    font-size: 23px;
    width: 23px;
    height: 23px;
  }

  & .rhap_progress-indicator,
  & .rhap_volume-indicator {
    background: white;
  }

  & .rhap_progress-indicator{
    width: 15px;
    height: 15px;
    top: -5px;
  }

  & .rhap_progress-filled {
    background-color: #1c70ad;
  }

  & .rhap_volume-container {
    flex: 0 1;
    /* flex-direction: row-reverse; */
    /* transition: all 1s ease-in; */
    border-radius: 15px;
    transition: all 0.3s ease-in;
    height: 25px;
  }

  & .rhap_volume-bar-area {
    width: 0;
    opacity: 0;
    transition: all 0.3s ease-in;
  }

  & .rhap_volume-container {
    &:hover {
      background: #00000066;
      padding: 0 15px 0 5px;

      & .rhap_volume-bar-area {
        width: 75px;
        opacity: 1;
      }
    }
  }
`;

export const StyledSeparator = styled.div`
  color: white;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  margin: 0 5px;
`;
