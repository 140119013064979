import axiosInstance from '../Config/AxiosInstance';

const PREFIX = `/clinic/job`;

export const downloadAudio = jobId => {
  return axiosInstance.get(PREFIX + `/${jobId}/audio-url`);
};

export const getDemographics = jobId => {
  return axiosInstance.get(PREFIX + `/${jobId}/demographics`);
};

export const sectionFetch = jobId => {
  return axiosInstance.get(PREFIX + `/${jobId}/sections`);
};

export const getHeadings = jobId => {
  return axiosInstance.get(PREFIX + `/${jobId}/section-headings`);
};

export const editSection = (jobId, data, sessionId) => {
  return axiosInstance.put(
    PREFIX + `/${jobId}/sections?session-id=${sessionId}`,
    data
  );
};
export const billJob = (jobId, sessionId) => {
  return axiosInstance.put(PREFIX + `/${jobId}/bill?sessionId=${sessionId}`);
};

export const deleteSection = (jobId, sectionId) => {
  return axiosInstance.delete(PREFIX + `/${jobId}/sections/${sectionId}`);
};

export const getInference = (jobId, sectionId, newInferenceActive) => {
  if (!newInferenceActive) {
    newInferenceActive = false;
  }
  return axiosInstance.get(
    PREFIX +
      `/${jobId}/inference/${sectionId}?enable-new-inference=${newInferenceActive}`
  );
};

export const forceUpdateJobInference = jobId => {
  return axiosInstance.post(PREFIX + `/resetJob?job-id=${jobId}`);
};

export const updateAudioTime = (jobId, audioTime) => {
  return axiosInstance.put(
    PREFIX + `/${jobId}/audio-time?audioTime=${audioTime}`
  );
};

export const updateInference = (jobId, sectionId, data) => {
  return axiosInstance.put(PREFIX + `/${jobId}/inference/${sectionId}`, data);
};

export const updateInferenceEditFlag = (jobId, sectionId, data) => {
  return axiosInstance.put(
    PREFIX + `/${jobId}/inference/${sectionId}/allow-overwrite`,
    data
  );
};

export const getIncompleteJobs = (
  facilities,
  page,
  size,
  sortKey,
  sortOrder,
  searchKey,
  updateCode
) => {
  let url =
    PREFIX +
    `/in-complete?page=${page}&size=${size}&sortOrder=${sortOrder}&sortKey=${sortKey}&searchValue=${searchKey}`;
  if (updateCode) {
    url = url + `&jobUpdateCode=${updateCode}`;
  }
  return axiosInstance.get(url);
};

export const retryIncompleteJobSubmission = (jobIds, sessionId) => {
  return axiosInstance.post(
    PREFIX + `/retry?job-ids=${jobIds}&session-id=${sessionId}`
  );
};

export const reconvertJob = jobIds => {
  return axiosInstance.post(PREFIX + `/reconvertJob?job-id=${jobIds}`);
};

export const releaseJob = jobId => {
  return axiosInstance.put(PREFIX + `/${jobId}/releaseJob`);
};

export const GetAdminJobCount = () => {
  return axiosInstance.get(PREFIX + `/job-board`);
};
export const GetWorkType = jobId => {
  return axiosInstance.get(PREFIX + `/${jobId}/work-types`);
};
export const updateWorkType = (jobId, sessionId, workType) => {
  return axiosInstance.put(
    PREFIX +
      `/${jobId}/work-types?sessionId=${sessionId}&workType=${workType}&jobId=${jobId}`
  );
};

export const GetJobCount = () => {
  return axiosInstance.get(PREFIX + `/job-count`);
};

export const releaseSection = (jobId, sessionId) => {
  return axiosInstance.put(
    PREFIX + `/release-incomplete-jobs?session-id=${sessionId}`,
    jobId
  );
};
export const updateDate = (jobId, date) => {
  return axiosInstance.put(
    PREFIX + `/${jobId}/appointment-date?encounterDate=${date}`
  );
};

export const getJobsByFacility = (facilityId, jobStatusList) => {
  let queryPart = '';
  jobStatusList.forEach(status => {
    queryPart += `&jobStatus=${status}`;
  });
  return axiosInstance.get(
    PREFIX + `/facility-based-jobs?facilityId=${facilityId}` + queryPart
  );
};
// export const strikeDate = jobId => {
//   return axiosInstance.patch(PREFIX + `/${jobId}/strike`);
// };
export const strikeDate = (jobId, sessionId) => {
  return axiosInstance.patch(
    PREFIX + `/${jobId}/strike?sessionId=${sessionId}`
  );
};
