import React from "react";
import { DualRingSpinner,Container } from "./styledComponents";

const Spinner = ({ height ,color}) => {
  return (
    <Container height={height}>
      <DualRingSpinner color={color} >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        </DualRingSpinner>
    </Container>
  );
};
export default Spinner;
