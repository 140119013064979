import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { DATE_TIME_FORMAT } from '../../../Constants/StringConstants';
import Spinner from '../../CustomComponents/Spinner';
import {
  StyledColumn,
  InformationTitle,
  InformationText,
  FacilityLogTableContainer,
  FacilityLogTableHeadingRow,
  FacilityLogTable,
  FacilityLogRow,
  FacilityStatusDiv
} from './styledComponents';

const FacilityAuditLog = ({
  loading,
  FacilityAuditData
}) => {
  const [theme] = useSelector(state => [state.themeReducer]);

  return (
    <FacilityLogTableContainer>
      {loading ? (
        <Spinner height={'50vh'} />
      ) : (
        <>
          <FacilityLogTableHeadingRow>
            <StyledColumn span={6}>
              <InformationTitle color={theme['@text-color']}>
                Update Time
              </InformationTitle>
            </StyledColumn>
            <StyledColumn span={6}>
              <InformationTitle color={theme['@text-color']}>
                Status
              </InformationTitle>
            </StyledColumn>
          </FacilityLogTableHeadingRow>
          <FacilityLogTable key={FacilityAuditData?.updateDate}>

            {FacilityAuditData.map(data => {
              return (
                <FacilityLogRow>
                  <StyledColumn span={6}>
                    <InformationText name={true}>
                      {moment(data?.updateDate).format(DATE_TIME_FORMAT)}
                    </InformationText>
                  </StyledColumn>
                  <StyledColumn span={6}>
                    <FacilityStatusDiv status={data?.status === 'ENABLE'}>
                      {data?.status}
                    </FacilityStatusDiv>
                  </StyledColumn>
                </FacilityLogRow>
              );
            })}

          </FacilityLogTable>
        </>
      )}
    </FacilityLogTableContainer>
  );
};

export default FacilityAuditLog;
