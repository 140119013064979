import React from 'react';

function VectorIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='12'
      fill='none'
      viewBox='0 0 16 12'
    >
      <path stroke='#666' d='M.516 1.19h15m-12 5h9m-7 5h5'></path>
    </svg>
  );
}

export default VectorIcon;
