import styled from 'styled-components';
import { Button, Row, Card, Col, Modal, Checkbox } from 'antd';

export const TemplateWrapper = styled.div`
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  height: calc(100vh - 62px - 155px - 25px);
  /* width: 100%; */
  & > div {
    width: ${props => (props.fullWidth ? '100%' : null)};
  }
`;

export const EditorArea = styled.div`
  .ql-editor {
    min-height: 100px;
  }
`;
export const ItemsListContainer = styled.div`
  height: 100%;
  width: 300px;
  border-right: 1px solid #c0c0c0;
`;

export const SelectedItemsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - 20px - 251px);
  padding-left: 22px;
  padding-right: 50px;
`;

export const ListTitle = styled.h6`
  margin-top: ${props => (props.second ? '26px' : null)};
  font-size: ${props => (props.selected ? '14px' : '18px')};
  font-weight: 600;
  color: #525e6f;
`;
export const ListTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SelectedHeadingValues = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  padding-top: 25px;
  border-top: 1px solid #c0c0c0;
`;

export const ListDiv = styled.div`
  position: relative;
  height: calc(100% - 30px);
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;
export const ActiveSectionCol = styled(Col)`
  padding-left: 10% !important;
  padding-right: 10% !important;
  .active-k2p-head {
    font-weight: 600;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 24px;
  }
`;
export const InputDiv = styled.div`
  height: 131px;
  width: 100%;
  background: #e7edf2;
  border-radius: 7px;
  padding: 11px 23px;
`;
export const KpCol = styled(Card)`
  width: 293px;

  padding: 0px 3px 1px;
  border-radius: 15px !important;
  border: 1px solid #47a4ea; /* Border color */
  background-color: #f5f7fa !important;
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  cursor: pointer;
  box-shadow: 0px 0px 3px rgba(0.1, 0.1, 0.1, 0.1);

  &:hover {
    background-color: #e4f4ff !important; /* Change background color to blue on hover */
  }
  .keyvalue {
    font-size: 15px;
    margin-left: 8px;
    font-weight: 600;
    color: #2b2b2b;
  }
  .author-box {
    border-radius: 20px;
    border: 1px solid #e1e4eb;
    padding: 0px 8px;
    width: fit-content;
    /* padding: 12px, 12px, 12px, 12px; */
    margin-bottom: 8px;
  }
`;

export const SelectedContainer = styled.div`
  position: absolute;
  top: 220px;
  width: 100%;
`;

export const MappedValues = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  background: white;
  border-radius: 8px;
  height: 65px;
  padding: 8px 0;
  margin-bottom: 8px;
  & > span {
    width: 230px;
    padding: 0 10px;
    font-weight: 700;
    color: #2b2b2b;
  }
  & > :nth-child(4) {
    position: absolute;
    top: 24px;
  }
`;

export const MappedValuesContainer = styled.div`
  // min-height: calc(100vh - 623px);
  width: 100%;
`;

export const FormHeadingBoxes = styled.div`
  background: #e6e8e9;
  border-radius: 2px;
  margin: 8px;
  height: 30px;
`;

export const AddButton = styled(Button)`
  margin-right: 15px;
  font-weight: 600; /* Change font weight */
  background-color: blue; /* Change background color */
  color: red; /* Change font color */
`;

export const CancelButton = styled(Button)`
  margin: auto 0;
  align-items: center;
  margin-right: 15px;
  border: 1px solid #e1e1e1 !important;
  width: 50%;

  &.ant-btn-primary {
    background: #aaaaaa;
    border-radius: 10px;
    &:hover {
      background: #bbc0c4;
    }
  }
`;
export const ConfigDictationRow = styled(Row)`
  /* min-width: 1220px; */
  width: 100%;
`;
export const SectionHeading2 = styled.div`
  margin-top: 10px;
  .headstyle2 {
    font-weight: 600;
    font-size: 14px;
    color: #2b2b2b;
  }
`;
export const SectionHeading = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;
  margin-top: 6px;
  & h6 {
    font-size: 12px;
    color: #979797;
  }
  .headstyle {
    font-weight: 600;
    font-size: 14px;
    color: #2b2b2b;
  }
`;

export const AddHeadingContainer = styled.div`
  width: 100%;
  background-color: white;

  border-radius: 8px;
  & > :nth-child(n) {
    margin-top: 10px;
  }
  & .ant-select {
    height: 40px;

    & .ant-select-selector {
      height: 100%;
    }
    & .ant-select-selection-item {
      padding-top: 4px !important;
    }
    & .ant-select-selection-placeholder {
      padding: 3px;
    }
  }
  .AddheadingOuterSection {
    padding: 10px;
    background-color: white;
    display: flex;
    align-items: center;
    border-radius: 10px;
    /* height: 161px; */
  }
`;

export const TemplateNameContainer = styled.div`
  position: relative;
  margin-top: 10px;
  height: fit-content;
  width: 95%;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  padding: 10px;
  cursor: pointer;
  text-transform: capitalize;
  word-wrap: break-word;

  border: ${props => (props.selected ? '0.5px solid #86ccff' : 'none')};
  border-radius: ${props => (props.selected ? '6px' : 'none')};
  background: ${props => (props.selected ? '#C6E7FF' : 'transparent')};
  color: ${props => (props.selected ? '#215B84' : null)};
  &:hover {
    color: #215b84;
  }
`;

export const TemplateName = styled.div`
  width: 70%;
`;

export const HeadingButtonContainer = styled.div`
  /* float: right;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding-bottom: 20px; */
`;

export const TemplateActionButton = styled.button`
  position: absolute;
  top: 50%;
  right: ${props => (props.delete ? '10px' : '50px')};
  transform: translate(0, -50%);
  cursor: pointer;
  border: none;
  border-radius: 4px;

  &:hover {
    background: #72c2f7;
  }
  background: #88ccfe;
`;

export const SmallCancelButton = styled.button`
  /* font-size: 12px;
  height: 30px;
  width: 100px;
  background: #e6e8e9;
  border-radius: 5px;
  border: none; */

  cursor: pointer;
  &:hover {
    background: #aaaaaa;
  }
`;

export const SmallSaveButton = styled.button`
  font-size: 12px;
  height: 30px;

  background: #c6e7ff;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  // margin-top: 17px;
  // margin-right: 50px;
  &:hover {
    background: #47a4ea;
  }
`;

export const DeleteConfirmationMessage = styled.p`
  font-size: 16px;
`;

export const EmptyMessage = styled.p`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
`;

export const HeadingDeleteButton = styled.button`
  position: absolute;
  top: 21px;
  right: -11px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  color: black;

  &:hover {
    background: #c3c6c7;
  }
  /* background: #d2d5d6; */
`;
export const HeadingTooltipButton = styled.div`
  position: absolute;
  top: 28px;
  right: 1px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  color: #4d4e4f;
  padding: 1px 6px;
  background: #f2f3f4;
  &:hover {
    background: #c3c6c7;
  }
  /* background: #d2d5d6; */
`;
export const DnDColumn = styled.div`
  width: 100%;
  min-height: 400px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  .keyvalue {
    font-size: 15px;
    font-weight: 600;
    color: #2b2b2b;
  }
  .movable-item {
    border-radius: 5px;
    background-color: #ffffff;
    height: 105px;
    margin: 5px 5px 10px 5px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    border: 1px solid rgba(0, 0, 0, 0.06);
    box-shadow: 1px 1px 1px rgba(0.1, 0.1, 0.1, 0.2);
  }
  .movable-item1 {
    border-radius: 5px;

    height: 105px !important;
    margin: 0 0 10px 0;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    border: 1px solid rgba(0, 0, 0, 0.06);
    box-shadow: 1px 1px 1px rgba(0.1, 0.1, 0.1, 0.2);
  }
`;
export const SubmitCol = styled(Card)`
  .activesection {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
export const ActiveCol = styled(Col)`
  padding-left: 50px;
  padding-right: 50px;
  min-height: 500px;
  .active-k2p-head {
    font-size: 15px;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;
export const StyledDiv = styled.div`
  display: flex;
  flex-direction: ${props => props.direction};
  justify-content: ${props => props.justify};
  width: ${props => props.width};

  .movable-item {
    border-radius: 5px;
    background-color: #fafdff;
    height: 105px;
    margin: 0 0 10px 0;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    border: 1px solid rgba(0, 0, 0, 0.06);
    box-shadow: 1px 1px 1px rgba(0.1, 0.1, 0.1, 0.2);
  }
  .keyvalue {
    font-size: 15px;
    font-weight: 600;
    color: #2b2b2b;
    line-height: 18.2px;
  }
  .phrasevalue {
    font-size: 12px;
    font-weight: 400;
    color: #5c5c5c;
  }
`;

export const CustomDictationModal = styled(Modal)`
  .ant-modal-content {
    width: 900px;
  }
  .ant-modal-body {
    background-color: #f5f7fa;
    border-radius: 8px;
  }
  .ant-modal-title {
    font-size: 20px;
    font-weight: 700;
  }
  .ant-input {
    border-radius: 5px;
  }
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-header {
    border-radius: 8px;
  }
`;

export const DictationMineCol = styled(Col)`
  border-right: 1px solid #e1e4eb;
  background-color: white;
`;
export const CustomDictationCheckBox = styled(Checkbox)`
  .ant-checkbox {
    width: 20px;
    height: 20px;
    line-height: 20px;
  }

  .ant-checkbox-wrapper {
    font-size: 14px; /* Adjust this as needed */
  }
`;
export const RestrictColumImport = styled(Col)`
  padding: 15px;
  border-left: 3px solid rgba(0, 0, 0, 0.06);
  background-color: white;
  .configurationHead {
    font-size: 16px;
    font-weight: 600;
    color: #2b2b2b;
    margin-bottom: 10px;
  }
`;

export const HeadRow = styled(Row)`
  padding-top: 25px;
  padding-left: 50px;
  padding-right: 50px;
  .templatecreation {
    font-size: 18px;
    font-weight: 800;
    color: #000000;
  }
`;
export const DictationCol = styled(Col)`
  padding-left: 50px;
  padding-right: 50px;
`;
export const TemplateButton = styled(Button)`
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: center;
  background-color: #f5f7fa;
  margin-top: 15px;
  cursor: pointer;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  width: 100%;
  font-size: 14px;
  font-weight: 700;

  &:hover {
    background-color: #e6f4fb;
  }
  .icon-wrapper svg {
    width: 18px; /* Adjust the size of the SVG icon */
    height: 18px; /* Adjust the size of the SVG icon */
  }
  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 32px;

    color: #47a4ea;

    /* margin-right: 8px;
    margin-left: -15px; */
  }
  &.ant-btn {
    border-radius: 6px;
    color: #47a4ea;
  }
`;
