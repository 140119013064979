import React, { useEffect } from 'react';
import { Space } from 'antd';
import { CustomRow, StyledCol, StyledForm } from './styledComponents';
import { SaveButton, CancelButton } from '../Profile/styledComponents';
import { useState } from 'react';
import {
  SaveOutlined,
  CloseOutlined,
  EditOutlined,
  LoadingOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import FloatLabel from '../../../CustomComponents/FloatingLabel';
import Spinner from '../../../CustomComponents/Spinner';

const transcriptionFlowOptions = [
  {
    value: 'TRANSCRIPTION_ONLY',
    name: 'Transcriptionist Only'
  },
  {
    value: 'TRANSCRIPTION_REVIEWER',
    name: 'Transcriptionist and Reviewer'
  }
];

const reviewerCountOptions = [
  {
    value: '1',
    name: 1
  },
  {
    value: '2',
    name: 2
  },
  {
    value: '3',
    name: 3
  },
  {
    value: '4',
    name: 4
  },
  {
    value: '5',
    name: 5
  }
];

const patientsRequiredOptions = [
  {
    value: 'Yes',
    name: 'Yes'
  },
  {
    value: 'No',
    name: 'No'
  }
];

const EHRDetails = ({
  ehrFields,
  onFormSubmit,
  theme,
  loading,
  ehrFieldData,
  edit,
  setEdit,
  buttonLoading,
  selectedEhr,
  clinicConfigured,
  transcriptionFlow,
  setTranscriptionFlow,
  setResetModal,
  saveDisabled,
  setSaveDisabled,
  showRangeForDischargedPatientsEhrType,

  setShowRangeForDischargedPatients,

  showRangeForDischargedPatients,

  rangeForDischargedPatientsDetails,

  setRangeForDischargedPatientsDetails
}) => {
  const [form] = StyledForm.useForm();

  useEffect(() => {
    if (ehrFieldData) {
      if (ehrFieldData.noteType && ehrFields) {
        let noteTypes =
          ehrFields &&
          ehrFields.find(field => field.field === 'noteType').value;

        const noteType =
          noteTypes &&
          noteTypes.find(note => note.value === ehrFieldData.noteType);

        ehrFieldData.noteType = {
          changedValue: noteType?.name
        };
      }

      let data = {
        ...ehrFieldData,
        transcriptionFlow: {
          changedValue: transcriptionFlow
        },
        reviewerCount: {
          changedValue:
            ehrFieldData.reviewerCount && ehrFieldData.reviewerCount.toString()
        },
        hl7Integration: {
          changedValue: ehrFieldData.hl7Integration?.toString()
        },
        allPatientsRequired: {
          changedValue: ehrFieldData.allPatientsRequired ? 'Yes' : 'No'
        }
      };
      form.setFieldsValue({ ...data });
    }
    // eslint-disable-next-line
  }, [ehrFieldData, ehrFields]);

  const transcriptionFlowChange = changedValue => {
    setTranscriptionFlow(changedValue);
    setSaveDisabled(selectedEhr !== '' && edit ? false : saveDisabled);

    if (changedValue.changedValue === 'TRANSCRIPTION_REVIEWER') {
      form.setFieldsValue({
        reviewerCount: {
          changedValue:
            ehrFieldData && ehrFieldData.reviewerCount
              ? ehrFieldData.reviewerCount.toString()
              : '1'
        }
      });
    } else if (changedValue.changedValue === 'TRANSCRIPTION_ONLY') {
      form.setFieldsValue({ reviewerCount: { changedValue: '0' } });
    }
  };

  useEffect(() => {
    if (transcriptionFlow) {
      transcriptionFlowChange(transcriptionFlow);
    }
    // eslint-disable-next-line
  }, [transcriptionFlow]);

  return loading ? (
    <div style={{ width: '100%' }}>
      <Spinner />
    </div>
  ) : (
    <StyledForm layout='vertical' form={form} onFinish={onFormSubmit}>
      <CustomRow gutter={[36, 36]}>
        {ehrFields &&
          ehrFields.map(field => {
            return (
              <StyledCol span={12} noPadding={true}>
                <StyledForm.Item
                  name={field.field}
                  rules={[
                    {
                      required: true,
                      message: `${field.fieldName} is required`
                    }
                  ]}
                >
                  <FloatLabel
                    label={field.fieldName}
                    primaryColor={theme['@text-color']}
                    type={
                      field.fieldType === 'dropdown'
                        ? 'select'
                        : field.fieldType === 'maskedText'
                        ? 'password'
                        : 'input'
                    }
                    disabled={
                      selectedEhr === 'demo-emr' ||
                      (clinicConfigured &&
                        !['maskedText', 'dropdown'].includes(
                          field.fieldType
                        )) ||
                      (field.fieldType === 'dropdown' && !edit)
                    }
                    clinic={clinicConfigured || selectedEhr !== 'open-emr'}
                    value={field.value}
                    field={field.field}
                    meta={field.value}
                    onChange={() => {
                      setSaveDisabled(
                        selectedEhr !== '' && edit ? false : saveDisabled
                      );
                    }}
                  />
                </StyledForm.Item>
              </StyledCol>
            );
          })}
        <StyledCol span={12} noPadding={true}>
          <StyledForm.Item
            name='transcriptionFlow'
            rules={[
              {
                required: true,
                message: `Transcription Flow is required`
              }
            ]}
          >
            <FloatLabel
              label='Transcription Flow'
              primaryColor={theme['@text-color']}
              type='select'
              disabled={!edit}
              onChange={transcriptionFlowChange}
              meta={transcriptionFlowOptions}
            />
          </StyledForm.Item>
        </StyledCol>
        <StyledCol span={12} noPadding={true}>
          <StyledForm.Item
            name='reviewerCount'
            rules={[
              {
                required: true,
                message: `Reviewer Count is required`
              }
            ]}
          >
            <FloatLabel
              label='Reviewer Count'
              primaryColor={theme['@text-color']}
              type='select'
              disabled={
                !edit ||
                form.getFieldValue('transcriptionFlow')?.changedValue ===
                  'TRANSCRIPTION_ONLY'
              }
              onChange={() => {
                setSaveDisabled(
                  selectedEhr !== '' && edit ? false : saveDisabled
                );
              }}
              meta={reviewerCountOptions}
            />
          </StyledForm.Item>
        </StyledCol>
        <StyledCol span={12} noPadding={true}>
          <StyledForm.Item
            name='allPatientsRequired'
            rules={[
              {
                required: true,
                message: `List all patients value is required`
              }
            ]}
          >
            <FloatLabel
              label='List All Patients'
              primaryColor={theme['@text-color']}
              type='select'
              disabled={!edit}
              meta={patientsRequiredOptions}
              onChange={value => {
                setSaveDisabled(
                  selectedEhr !== '' && edit ? false : saveDisabled
                );
                setShowRangeForDischargedPatients(value.changedValue === 'Yes');
              }}
            />
          </StyledForm.Item>
        </StyledCol>
        {showRangeForDischargedPatients &&
          showRangeForDischargedPatientsEhrType && (
            <StyledCol span={12}>
              <FloatLabel
                label='Get Discharged patients Within (in days)'
                primaryColor={theme['@text-color']}
                type='input'
                value={rangeForDischargedPatientsDetails}
                disabled={!edit}
                onChange={e => {
                  setRangeForDischargedPatientsDetails(e.target.value);

                  setSaveDisabled(
                    selectedEhr !== '' && edit ? false : saveDisabled
                  );
                }}
              />
            </StyledCol>
          )}
      </CustomRow>

      {edit ? (
        <CustomRow justify='end'>
          <Space size={20}>
            {clinicConfigured && (
              <CancelButton
                onClick={() => {
                  setEdit(false);
                  setSaveDisabled(true);
                }}
                icon={<CloseOutlined />}
              >
                Cancel
              </CancelButton>
            )}

            <SaveButton
              type='primary'
              htmlType='submit'
              icon={buttonLoading ? <LoadingOutlined spin /> : <SaveOutlined />}
              loading={loading}
              disabled={saveDisabled}
              onClik={() => {
                setSaveDisabled(true);
              }}
            >
              Save
            </SaveButton>
          </Space>
        </CustomRow>
      ) : (
        <CustomRow justify='end'>
          <SaveButton
            onClick={() => setEdit(true)}
            type='primary'
            icon={<EditOutlined />}
          >
            Edit
          </SaveButton>
          {selectedEhr === 'demo-emr' && (
            <SaveButton
              onClick={() => setResetModal(true)}
              type='primary'
              icon={<ReloadOutlined />}
              reset={true}
            >
              Reset
            </SaveButton>
          )}
        </CustomRow>
      )}
    </StyledForm>
  );
};

export default EHRDetails;
