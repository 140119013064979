import React from 'react';

function CompletedJobsIcon({ size }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ? size : '16'}
      height={size ? size : '16'}
      fill="none"
      viewBox="0 0 30 30"
    >
      <g fill="#79B152">
        <path d="M26.645 0H9.12A1.821 1.821 0 007.3 1.819v4.07c-3.324.952-5.764 4.019-5.764 7.645 0 3.626 2.44 6.692 5.765 7.644v7.003c0 1.003.816 1.82 1.819 1.82h14.322c.17 0 .332-.068.453-.189l4.382-4.382a.64.64 0 00.187-.453V1.82A1.82 1.82 0 0026.645 0zM9.12 1.281h17.525c.297 0 .538.241.538.538v1.933H8.582V1.82c0-.297.241-.538.538-.538zM2.817 13.534a6.676 6.676 0 016.668-6.669 6.676 6.676 0 016.668 6.669 6.676 6.676 0 01-6.668 6.668 6.676 6.676 0 01-6.668-6.668zM8.582 28.18v-6.75a7.933 7.933 0 006.894-2.679h3.926a.64.64 0 100-1.28h-3.013a7.89 7.89 0 001.015-3.25h1.998a.64.64 0 100-1.28h-1.99a7.891 7.891 0 00-.969-3.249h2.959a.64.64 0 000-1.281h-3.843a7.935 7.935 0 00-6.977-2.776v-.602h18.6v19.303H24.62a1.821 1.821 0 00-1.82 1.819v2.563H9.12a.538.538 0 01-.538-.538zm15.5-.368v-1.657c0-.297.241-.538.538-.538h1.657l-2.195 2.195z"></path>
        <path d="M21.919 9.693h2.127a.64.64 0 000-1.281H21.92a.64.64 0 000 1.28zM21.919 14.223h2.127a.64.64 0 000-1.282H21.92a.64.64 0 100 1.282zM21.919 18.753h2.127a.64.64 0 000-1.282H21.92a.64.64 0 100 1.282zM24.046 23.282a.64.64 0 000-1.281h-5.993a.64.64 0 100 1.281h5.993zM10.435 11.291L8.305 13.6l-.74-.803a.867.867 0 00-1.296 0 1.054 1.054 0 000 1.406l1.388 1.506a.882.882 0 00.649.291.882.882 0 00.648-.291l2.777-3.012a1.054 1.054 0 000-1.406.867.867 0 00-1.296 0z"></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H30V30H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default CompletedJobsIcon;
