import styled from 'styled-components';

export const AvatarContainer = styled.div`
  & .anticon-star {
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 15px;
  }
  & svg {
    fill: orange;
  }
`;
