import {
  NEW_JOB_AVAILABLE,
  READY_JOBS_MODIFIED,
  TRANSCRIPTION_WRITE_FAILED,
  NEW_NOTIFICATION,
  CLEAR_JOB_NOTIFICATION,
  JOB_READY_INPROGRESS
} from '../Actions/Notification';

const initialState = {
  jobNotification: {
    reviewerRejected: 0,
    newJobAvailable: 0,
    writeFailed: 0,
    jobReadyToInprogress: 0
  },
  newNotification: {},
  newReadyJobs: []
};

export const notificationReducer = ( state = initialState,action) => {
  switch (action.type) {
    case READY_JOBS_MODIFIED:
      state.jobNotification.reviewerRejected += 1;
      state.jobNotification = { ...state.jobNotification };
      state.newNotification = action.payload;
      return { ...state };
    case NEW_JOB_AVAILABLE:
      state.jobNotification.newJobAvailable += 1;
      state.jobNotification = { ...state.jobNotification };
      state.newNotification = action.payload;
      state.newReadyJobs = [...state.newReadyJobs, action.payload.targetId]
      return { ...state };
    case JOB_READY_INPROGRESS:
      state.jobNotification.jobReadyToInprogress += 1;
      state.jobNotification = { ...state.jobNotification };
      state.newNotification = action.payload;
      state.newReadyJobs = state.newReadyJobs.filter((id)=>{
        return id !== action.payload.targetId;
      })
      return { ...state };
    case TRANSCRIPTION_WRITE_FAILED:
      state.jobNotification.writeFailed += 1;
      state.jobNotification = { ...state.jobNotification };
      state.newNotification = action.payload;
      return { ...state };
    case NEW_NOTIFICATION:
      state.newNotification = action.payload;
      return { ...state };
    case CLEAR_JOB_NOTIFICATION:
      state.jobNotification = { ...initialState.jobNotification };
      return { ...state };
    default:
      return state;
  }
};
