import React, { useState, useMemo, useEffect } from 'react';
import {
  EditorContainerRow,
  EditorTitleRow,
  EditorNameDiv,
  CustomNameStyle,
  CustomConsultationTypeStyle,
  EditorToolsRow,
  CustomJobHeadingText,
  CustomEditorButtonDivStyle,
  CustomEditorButtonStyle,
  QuillEditorContainer,
  CustomJobNameIdStyle,
  StyledToolsContainer,
  RerenderButtonContainer
} from './styledComponents';
import { useSelector } from 'react-redux';
import {
  SyncOutlined,
  CloudUploadOutlined,
  WarningOutlined
} from '@ant-design/icons';
import BrainEditorIcon from '../../../Assets/Icons/BrainEditorIcon';
import HeadingIcon from '../../../Assets/Icons/HeadingIcon';
import SlateEditor from './SlateEditor';
import { createEditor } from 'slate';
import { withReact } from 'slate-react';
import { CustomEditor } from './Helper/EditorHelper';
import { StyledTag } from '../../Content/styledComponents';
import { Tooltip } from 'antd';
import SaveEditorIcon from '../../../Assets/Icons/SaveEditorIcon';
import Spinner from '../Spinner';
import RerenderIcon from '../../../Assets/Icons/RerenderIcon';
import { reconvertJob } from '../../../Apis/Jobs';
import { DeleteConfirmationMessage } from './styledComponents';
import ConfirmationModal from '../../CustomComponents/ConfirmationModal';
import WarningIcon from '../../../Assets/Icons/WarningIcon';

import { notification } from 'antd';

const EditorComponent = props => {
  const editor = useMemo(() => withReact(createEditor()), []);
  const [theme] = useSelector(state => [state.themeReducer]);
  const [headingActive, setHeadingActive] = useState(false);
  const [value, setValue] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);

  const convertAgain = () => {
    props.setEditorLoading(true);
    reconvertJob(props.jobId)
      .then(response => {
        if (response.data.success) {
          props.setEditorData(
            response.data.result[0] && response.data.result[0].sections
          );
          props.setEditorDataPreviewValue(
            response.data.result[0] && response.data.result[0].sections
          );

          props.setActiveSection(
            response.data.result[0].sections &&
              response.data.result[0].sections.length > 0 &&
              response.data.result[0].sections[0].id
          );

          props.saveTimeFormat(response.data.result[0].lastUpdatedTime);
          props.setEditorLoading(false);
          setModalVisible(false);
        } else {
          notification['error']({
            message: 'Section Fetch Failed',
            description: response.data.message
          });
          props.setEditorLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        props.setEditorLoading(false);
      });
  };

  const manualSaveEditorData = data => {
    let savedData = {
      editorData: data,
      jobId: props.jobId
    };
    localStorage.setItem('editorData', JSON.stringify(savedData));
  };

  const checkIconStatus = () => {
    const isActive = CustomEditor.isHeadingBlockActive(editor);
    setHeadingActive(isActive);
  };

  useEffect(() => {
    if (props.saveStatus === 'Saved') {
      setTimeout(() => {
        props.setSaveStatus(null);
      }, 1000);
    }
    // eslint-disable-next-line
  }, [props.saveStatus, props.loading]);

  return (
    <>
      <EditorContainerRow>
        <EditorTitleRow>
          <CustomJobNameIdStyle>
            <EditorNameDiv prefix={true}>
              {props.content.map(item => {
                return (
                  item.key === 'NAME' &&
                  item.value && (
                    <CustomNameStyle color={theme['@table-text-color']}>
                      {item.key === 'NAME' && item.value}
                    </CustomNameStyle>
                  )
                );
              })}

              {props.content.map(item => {
                return (
                  item.key === 'WORK TYPE' &&
                  item.value && (
                    <CustomConsultationTypeStyle>
                      {item.key === 'WORK TYPE' && item.value}
                    </CustomConsultationTypeStyle>
                  )
                );
              })}
            </EditorNameDiv>
            <CustomJobHeadingText color={theme['@primary-color']}>
              Job ID: {props.jobName}
            </CustomJobHeadingText>
          </CustomJobNameIdStyle>

          {/* <EditorButtonStyle>
            <StepButtonStyle>
              <EditorIconStyle type='text' icon={<StepBackwardOutlined />} />
              <EditorIconStyle type='text' icon={<StepForwardOutlined />} />
            </StepButtonStyle>
            <EditorIconStyle type='text' icon={<DownOutlined />} />
          </EditorButtonStyle> */}
          <EditorNameDiv suffix={true}>
            <CustomNameStyle>Last Updated</CustomNameStyle>
            <CustomConsultationTypeStyle>
              {props.lastSavedTime}
            </CustomConsultationTypeStyle>
          </EditorNameDiv>
        </EditorTitleRow>
        <EditorToolsRow>
          {/* <CustomHeadingText color={theme['@table-text-color']}>
            Audio Name 12365478987452336
          </CustomHeadingText> */}
          <CustomEditorButtonDivStyle>
            <StyledToolsContainer>
              <Tooltip placement='bottom' title='Inference Engine'>
                <CustomEditorButtonStyle
                  icon={
                    <BrainEditorIcon
                      fill={props.inferenceActive && theme['@primary-color']}
                    />
                  }
                  onClick={() => {
                    props.setInferenceActive(!props.inferenceActive);
                  }}
                />
              </Tooltip>
              <Tooltip placement='bottom' title='Save'>
                <CustomEditorButtonStyle
                  icon={<SaveEditorIcon fill={theme['@primary-color']} />}
                  onClick={() => manualSaveEditorData(value)}
                />
              </Tooltip>
              <Tooltip placement='bottom' title='Heading Tool'>
                <CustomEditorButtonStyle
                  icon={
                    <HeadingIcon
                      fill={headingActive && theme['@primary-color']}
                    />
                  }
                  onMouseDown={event => {
                    event.preventDefault();
                    CustomEditor.toggleHeadingBlock(editor);
                  }}
                />
              </Tooltip>

              {props.saveStatus && (
                <StyledTag
                  icon={
                    props.saveStatus === 'Saved' ? (
                      <CloudUploadOutlined />
                    ) : props.saveStatus === 'Saving' ? (
                      <SyncOutlined spin />
                    ) : props.saveStatus === 'You are Offline' ? (
                      <WarningOutlined />
                    ) : null
                  }
                >
                  {props.saveStatus}
                </StyledTag>
              )}
            </StyledToolsContainer>

            <RerenderButtonContainer>
              <Tooltip placement='bottom' title='Reconvert'>
                <CustomEditorButtonStyle
                  icon={<RerenderIcon />}
                  onClick={() => {
                    setModalVisible(true);
                  }}
                />
              </Tooltip>
            </RerenderButtonContainer>
          </CustomEditorButtonDivStyle>
        </EditorToolsRow>
        {props.loading ? (
          <Spinner height='calc(100vh - 400px)' />
        ) : (
          <QuillEditorContainer>
            <SlateEditor
              editorData={props.editorData}
              jobId={props.jobId}
              headings={props.headings}
              editor={editor}
              checkIconStatus={checkIconStatus}
              setSaveStatus={props.setSaveStatus}
              setChangeStatus={props.setChangeStatus}
              theme={theme}
              audioRef={props.audioRef}
              deleteSection={props.deleteSection}
              activeSection={props.activeSection}
              setActiveSection={props.setActiveSection}
              value={value}
              setValue={setValue}
              fetchContents={props.fetchContents}
              inferenceActive={props.inferenceActive}
              inferenceAnnotation={props.inferenceAnnotation}
              setInferenceAnnotation={props.setInferenceAnnotation}
              saveTimeFormat={props.saveTimeFormat}
              setEditorDataPreviewValue={props.setEditorDataPreviewValue}
            />
          </QuillEditorContainer>
        )}
      </EditorContainerRow>
      <ConfirmationModal
        visible={modalVisible}
        onOk={() => convertAgain()}
        onCancel={() => {
          setModalVisible(false);
        }}
        loading={props.loading}
        confirmation={true}
        antdModalProps={{ closable: false }}
      >
        <WarningIcon />
        <DeleteConfirmationMessage>
          Are you sure you want to reconvert the dictation?
        </DeleteConfirmationMessage>
      </ConfirmationModal>
    </>
  );
};

export default EditorComponent;
