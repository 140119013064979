import React, { useState, useEffect } from 'react';
import {
  StyledFloatingMenu,
  StyledFloatingMenuItem,
  FloatingMenuItemContainer,
  StyledSearch
} from './styledComponents';

export const FloatingMenu = ({
  cursorPosition,
  headings,
  handleHeadingChange
}) => {
  const [filteredHeadings, setFilteredHeadings] = useState([]);

  useEffect(() => {
    setFilteredHeadings(headings);
  }, [headings]);

  const handleSearch = val => {
    if (val.length === 0) {
      setFilteredHeadings(headings);
    } else {
      let filteredContents = headings.filter(
        item =>
          item && item.customUIHeading.toLowerCase().includes(val.toLowerCase())
      );
      setFilteredHeadings(filteredContents);
    }
  };

  return (
    <StyledFloatingMenu position={cursorPosition}>
      <StyledSearch
        placeholder='Search headings'
        onChange={e => handleSearch(e.target.value)}
        onSearch={handleSearch}
        autoFocus
      />
      <FloatingMenuItemContainer>
        {filteredHeadings?.map((item, index) => {
          return (
            <StyledFloatingMenuItem
              key={item + index}
              onClick={() => {
                handleHeadingChange(item);
              }}
              active={index === item}
            >
              {item.customUIHeading}
            </StyledFloatingMenuItem>
          );
        })}
      </FloatingMenuItemContainer>
    </StyledFloatingMenu>
  );
};
