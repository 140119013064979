import styled from 'styled-components';
import { Modal } from 'antd';

export const StyledModal = styled(Modal)`
  & .ant-modal-title {
    font-size: 28px;
    font-weight: 800;
    color: #525e6f;
  }

  & .ant-modal-content .ant-btn {
    /* border: none !important; */
  }

  & .ant-modal-content {
    border-radius: 8px;
    max-height: 80vh !important;
  }

  & .ant-modal-header {
    border-radius: 10px 10px 0 0;
  }

  & .ant-modal-body {
    text-align: center;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 500;
    color: ${props => props.color};
    & p {
      margin: 10px 0;
    }
  }

  & .ant-col > .ant-btn {
    border-radius: 5px !important;
  }

  & .ant-input-search {
    width: 320px !important;
    height: 34px !important;
  }

  & .ant-modal-footer {
    display: none;
  }

  width: 1000px !important;
`;
