import styled from 'styled-components';
import { Button, Row, Tabs, Col } from 'antd';

export const TemplateWrapper = styled.div`
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  // height: calc(100vh - 62px - 155px - 25px);
  /* width: 100%; */
  & > div {
    width: ${props => (props.fullWidth ? '100%' : null)};
  }
`;

export const ItemsListContainer = styled.div`
  height: 100%;
  width: 300px;
  border-right: 1px solid #c0c0c0;
`;
export const InputTextArea = styled.div`
  margin-top: 5px;
  width: 100%;
`;
export const InputTextOverallArea = styled.div`
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  width: 100%;
`;
export const TemplateSection = styled.div`
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 15px;
`;
export const TemplateDriven = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f7fa;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 15px;
`;
export const TemplateDrivenCol = styled(Col)`
  margin-bottom: 15px;
  background-color: #f5f7fa;
  padding: 15px;
  border-radius: 8px;
  margin-top: 6px;
`;
export const SelectedItemsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  // height: 90%;
  // width: calc(100% - 20px - 251px);
  padding-left: 50px;
  padding-right: 50px;
  width: 99%;
  height: 95%;
  overflow-y: auto;
  overflow-x: hidden;
`;
export const SelectedItemsListContainerOne = styled.div`
  display: flex;
  flex-direction: column;
  /* height: 590px; */
  width: calc(100% - 20px - 251px);
  padding-left: 50px;
  padding-right: 50px;
  height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
`;
export const ListTitle = styled.h6`
  margin-top: ${props => (props.second ? '27px' : null)};
  font-size: ${props => (props.selected ? '14px' : '18px')};
  font-weight: 700;
  color: #2b2b2b;
`;
export const ListTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SelectedHeadingValues = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  padding-top: 25px;
  border-top: 1px solid #c0c0c0;
`;

export const ListDiv = styled.div`
  position: relative;
  /* height: calc(100% - 0px); */
  height: 80%;
  /* height: 100%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const InputDiv = styled.div`
  // height: 131px;
  width: 100%;
  // background: #e7edf2;
  border-radius: 7px;
  padding: 11px 23px;
`;
export const Line = styled.div`
  border-bottom: 1px solid #ccc; /* Specify line color */
  margin: 10px 0; /* Adjust margin as needed */
  padding-bottom: 3px; /* Adjust padding to ensure cursor alignment */
  width: 100%; /* Ensure the line takes up the full width */
  outline: none; /* Remove default focus outline */
  cursor: text; /* Set cursor to text when hovered */
`;
export const SelectedContainer = styled.div`
  position: absolute;
  top: 220px;
  width: 100%;
`;

export const MappedValues = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  /* width: ${props => (props.form ? '591px' : '650px')}; */
  width: 100%;
  height: auto;
  background: white;
  border-radius: 8px;
  height: 65px;
  padding: 8px 0;
  margin-bottom: 8px;
  /* margin-right: 117px; */
  & > span {
    width: 250px;
    padding: 0 10px;
    font-weight: 600;
    color: #2b2b2b;
  }
  & > :nth-child(4) {
    position: absolute;
    top: -10px;
    right: -32px;
  }
`;

export const MappedValuesContainer = styled.div`
  /* min-height: calc(100vh - 623px); */
  text-align: left; /* Ensures text and inline elements are left-aligned */
  display: flex; /* If using flexbox */
  flex-direction: column; /* Ensure items stack vertically */
  align-items: flex-start; /* Align items to the start (left) */
  padding-left: 0; /* Adjust padding/margin as needed */
  margin-left: 0; /* Adjust padding/margin as needed */
`;

export const FormHeadingBoxes = styled.div`
  background: #e6e8e9;
  border-radius: 2px;
  margin: 8px;
  height: 30px;
`;
export const TemplateButton = styled(Button)`
  display: flex;
  align-items: center;
  /* height: 500px; */
  justify-content: flex-start; /* Align content to the start */
  /* padding: 10px 20px; */
  background-color: white;

  cursor: pointer;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  width: 95%;
  font-size: 14px;
  font-weight: 700;

  &:hover {
    background-color: #e6f4fb;
  }
  .icon-wrapper svg {
    width: 18px; /* Adjust the size of the SVG icon */
    height: 18px; /* Adjust the size of the SVG icon */
  }
  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 32px;
    background-color: #47a4ea;
    color: white;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
    margin-right: 8px;
    margin-left: -15px;
  }
  &.ant-btn {
    border-radius: 6px;
    color: #47a4ea;
  }
`;

export const AddButton = styled(Button)`
  margin-right: 15px;
`;

export const CancelButton = styled(Button)`
  margin: auto 0;
  align-items: center;
  margin-right: 15px;
  border: 1px solid #e1e1e1 !important;

  width: auto;

  &.ant-btn-primary {
    background: #aaaaaa;
    border-radius: 10px;
    &:hover {
      background: #bbc0c4;
    }
  }
`;
export const ConfigDictationRow = styled(Row)`
  min-width: 1220px;
  width: 100%;
`;
export const SectionHeading = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  & h6 {
    font-size: 12px;
    color: #979797;
  }
  .headstyle {
    font-weight: 600;
    font-size: 14px;
    color: #000000;
  }
`;

export const AddHeadingContainer = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 8px;
  & > :nth-child(n) {
    margin-top: 10px;
  }
  & .ant-select {
    height: 40px;
    width: 100%;
    & .ant-select-selector {
      height: 100%;
    }
    & .ant-select-selection-item {
      padding-top: 4px !important;
    }
    & .ant-select-selection-placeholder {
      padding: 3px;
    }
  }
  .AddheadingOuterSection {
    padding: 10px;
    background-color: white;
    display: flex;
    align-items: center;
    border-radius: 10px;
    /* height: 65px;
    width: 598px; */
    /* margin-left: 126px; */
  }
`;

export const TemplateNameContainer = styled.div`
  position: relative;
  margin-top: 10px;
  height: fit-content;
  width: 95%;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  padding: 10px;
  cursor: pointer;
  /* text-transform: capitalize; */
  word-wrap: break-word;

  border: ${props => (props.selected ? '0.5px solid #86ccff' : 'none')};
  border-radius: ${props => (props.selected ? '6px' : 'none')};
  background: ${props => (props.selected ? '#C6E7FF' : '#fff')};
  color: ${props => (props.selected ? '#215B84' : null)};
  &:hover {
    color: #215b84;
  }
`;

export const TemplateName = styled.div`
  width: 70%;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
`;

export const HeadingButtonContainer = styled.div`
  float: right;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding-bottom: 20px;
  margin-top: 30px;
`;

export const TemplateActionButton = styled.button`
  position: absolute;
  top: 50%;
  right: ${props => (props.delete ? '10px' : '50px')};
  transform: translate(0, -50%);
  cursor: pointer;
  border: none;
  border-radius: 4px;

  &:hover {
    background: #72c2f7;
  }
  background: #88ccfe;
`;

export const SmallCancelButton = styled.button`
  font-size: 12px;

  height: 30px;
  width: 100px;
  background: #e6e8e9;
  border-radius: 5px;

  border: none;
  cursor: pointer;
  &:hover {
    background: #aaaaaa;
  }
`;

export const SmallSaveButton = styled.button`
  font-size: 12px;
  height: 30px;
  font-weight: 500;
  color: white;
  width: 100px;
  margin-right: -6px;
  background: #47a4ea;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  &:hover {
    background: #c6e7ff;
  }
`;

export const DeleteConfirmationMessage = styled.p`
  font-size: 16px;
`;

export const EmptyMessage = styled.p`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
`;

export const HeadingDeleteButton = styled.button`
  position: absolute;
  top: 20px;
  right: 1px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  color: #4d4e4f;

  &:hover {
    background: #c3c6c7;
  }
  /* background: #d2d5d6; */
`;
export const StyledTabs = styled(Tabs)`
  .ant-tabs-nav-list {
    display: flex;
    gap: 20px;
  }

  .ant-tabs-tab {
    font-family: Krub;
    font-size: 14px;
    font-weight: 600;
    line-height: 18.2px;
    text-align: left;
    color: black;
    margin-left: 30px;
    &:hover {
      color: #0075cd;
    }
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #0075cd !important;
  }
  .ant-tabs-nav-wrap {
    border-bottom: 1px solid #e1e4eb; /* Adjust the thickness and color as needed */
  }
`;

export const LineInput = styled.input`
  border: none; /* Remove default border */
  border-bottom: 1px solid #ccc; /* Add bottom border to simulate a line */
  padding: 5px 0; /* Adjust padding as needed */
  width: 100%; /* Ensure the input takes up the full width */
  outline: none;
  background-color: none;
`;
export const RestrictColumImport = styled(Col)`
  padding: 15px;
  border-left: 3px solid rgba(0, 0, 0, 0.06);
  background-color: white;
  .configurationHead {
    font-size: 16px;
    font-weight: 600;
    color: #2b2b2b;
    margin-bottom: 10px;
  }
`;
export const HeadRow = styled(Row)`
  .templatecreation {
    font-size: 18px;
    font-weight: 800;
    color: #000000;
  }
`;
