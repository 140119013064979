import { Modal, Button } from 'antd';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  & .ant-modal-content {
    border-radius: 8px;
    width: 475px;
  }
  & .ant-modal-header {
    background: #3cb29e;
    text-align: center;
  }
  & .ant-modal-title {
    color: white;
    font-weight: 600;
    font-size: 20px;
  }
  & .ant-modal-body {
    text-align: center;
    padding: 25px 50px;
    font-size: 16px;
    font-weight: 500;
    color: ${(props) => props.color};
    & p {
      margin: 10px 0;
    }
  }
`;

export const StyledButton = styled(Button)`
  &.ant-btn {
    font-size: 16px;
    font-weight: 600;
    color: ${(props) => props.color};
  }
`;
