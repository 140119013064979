import React from 'react';

function BillersIcon() {
  return (
    <span className='anticon'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        x='0'
        y='0'
        enableBackground='new 0 0 512.001 512.001'
        version='1.1'
        viewBox='0 0 512.001 512.001'
        xmlSpace='preserve'
        fill='#666'
      >
        <path d='M4.00008 3.33333H8.33341M1.66675 3.33333H2.00008M1.66675 1H2.00008M1.66675 5.66667H2.00008M4.00008 1H8.33341M4.00008 5.66667H8.33341'></path>
        <path d='M432.733 0H79.267c-8.284 0-15 6.716-15 15v437.816c0 3.978 1.581 7.793 4.394 10.607l44.184 44.184c5.857 5.858 15.355 5.858 21.213 0l33.577-33.577 33.576 33.577a15 15 0 0021.214 0L256 474.03l33.577 33.577a15 15 0 0021.213 0l33.576-33.577 33.577 33.577c2.929 2.929 6.767 4.394 10.606 4.394s7.678-1.464 10.607-4.394l44.184-44.184a15.002 15.002 0 004.394-10.607V15c-.001-8.284-6.716-15-15.001-15zm-44.184 475.787l-33.577-33.577a15.002 15.002 0 00-21.214 0l-33.576 33.577-33.577-33.577c-5.857-5.858-15.355-5.858-21.213 0l-33.577 33.577-33.576-33.577a15 15 0 00-21.213 0l-33.577 33.577-29.184-29.184V30h323.467v416.603h.001l-29.184 29.184z'></path>
      </svg>
    </span>
  );
}

export default BillersIcon;
