import React from "react";

function SignoutIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 233.92 233.92"
      height="25px"
      width="25px"
    >
      <path
        fill="#5d5d5d"
        d="M115.9 204.71c-29.85-1.26-53.42-14.64-68.29-41.49-20.52-37-7.18-83.88 29.28-105.28 2.88-1.68 5.35-1.42 6.79.76s.74 4.77-2.27 6.59A71.33 71.33 0 0052.9 96.9c-14.48 31.18-2.61 70.4 26.73 88.28 26.12 15.93 57.48 14.4 81.25-4.89 19.42-15.77 28.09-36.72 25.58-61.69-2.3-22.74-13.56-40.23-33-52.35-3.22-2-4-4.6-2.22-7 1.46-1.93 3.64-2.19 6.36-.63a69.35 69.35 0 0121.77 19.26c17.83 24.31 21.61 50.94 9.95 78.6C178 183.35 157 199 128.07 203.73c-1.18.19-2.35.4-3.52.48-2.64.19-5.28.31-8.65.5z"
      ></path>
      <path
        fill="#5d5d5d"
        d="M121.18 64.51v29.55a14.5 14.5 0 01-.09 2.46c-.39 2.21-1.64 3.62-4 3.72a4 4 0 01-4.35-3.34 12.33 12.33 0 01-.22-3V35.07v-1.64c.19-2.83 1.94-4.75 4.3-4.79 2.57 0 4.35 1.88 4.36 4.93.03 10.31 0 20.62 0 30.94z"
      ></path>
    </svg>
  );
}

export default SignoutIcon;
