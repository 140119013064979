import React from 'react';

export const HeadingElement = (props) => {
  return (
    <h2 {...props.attributes} {...props.element}>
      {props.children}
    </h2>
  );
};

export const DefaultElement = (props) => {
  return (
    <p {...props.element} {...props.attributes}>
      {props.children}
    </p>
  );
};
