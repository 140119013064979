import React from 'react';

const Leaf = props => {
  return (
    <span
      {...props.attributes}
      style={{
        fontWeight: props.leaf.bold ? 'bold' : 'normal',
        backgroundColor: props.leaf.highlight ? '#FFFF00' : 'transparent'
      }}
    >
      {props.children}
    </span>
  );
};

export default Leaf;
