import React from 'react';
import { Row, Col, Popconfirm, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { ActionButton } from '../../CustomComponents/Table/styledComponents';
import { PopoverGlobalStyle } from '../JobList/styledComponents';
import { AssignButton } from '../JobList/styledComponents';
import { Popover } from 'antd';
import {
  StyledFacilityBlocks,
  StyledSpan,
  StyledFacilityContainer,
  FacilityRemainingCount
} from '../Doctors/styledComponents';
function removeHTMLTags(inputString) {
  return inputString.replace(/<[^>]*>/g, '');
}

export const columns = (setSelectedItem, viewCompleteBillerJob) => [
  {
    title: 'Job ID',
    dataIndex: ['jobNumber'],
    key: 'id'
  },

  // {
  //   title: 'Facility',
  //   key: 'facility',
  //   dataIndex: ['facilities'],
  //   render: FacilityList => {
  //     return (
  //       <div>
  //         {FacilityList.length > '2' && (
  //           <div
  //             style={{
  //               display: 'flex',
  //               flexDirection: 'row '
  //             }}
  //           >
  //             <StyledFacilityContainer>
  //               {FacilityList.slice(0, 2).map(item => {
  //                 return (
  //                   <Popover
  //                     placement='bottom'
  //                     content={<h5>{item.facilityName}</h5>}
  //                   >
  //                     <StyledFacilityBlocks disabled={item.disabled}>
  //                       <StyledSpan>{item.facilityName}</StyledSpan>
  //                     </StyledFacilityBlocks>
  //                   </Popover>
  //                 );
  //               })}
  //             </StyledFacilityContainer>
  //             <Popover
  //               placement='bottom'
  //               content={
  //                 <div>
  //                   {FacilityList.slice(2, FacilityList.length).map(item => {
  //                     return <h5>{item.facilityName}</h5>;
  //                   })}
  //                 </div>
  //               }
  //             >
  //               <FacilityRemainingCount>
  //                 +{FacilityList.length - 2}
  //               </FacilityRemainingCount>
  //             </Popover>
  //           </div>
  //         )}
  //         {FacilityList.length < '3' && (
  //           <div
  //             style={{
  //               display: 'flex',
  //               flexDirection: 'row '
  //             }}
  //           >
  //             <StyledFacilityContainer>
  //               {FacilityList.map(item => {
  //                 return (
  //                   <Popover
  //                     placement='bottom'
  //                     content={<h5>{item.facilityName}</h5>}
  //                   >
  //                     <StyledFacilityBlocks disabled={item.disabled}>
  //                       <StyledSpan>{item.facilityName}</StyledSpan>
  //                     </StyledFacilityBlocks>
  //                   </Popover>
  //                 );
  //               })}
  //             </StyledFacilityContainer>
  //           </div>
  //         )}
  //       </div>
  //     );
  //   }
  // },
  {
    title: 'Facility Name',
    dataIndex: ['facility', 'facilityName'], // Accessing nested property
    key: 'facilityName',
    render: val => {
      return (
        <Popover placement='bottom' content={<h5>{val}</h5>}>
          <StyledFacilityBlocks>
            <StyledSpan>{val}</StyledSpan>
          </StyledFacilityBlocks>
        </Popover>
      );
    }
  },
  {
    title: 'Author',
    dataIndex: ['author'],
    key: 'author',
    render: val => {
      return (
        <span title={val}>
          {val?.length > 20 ? val.slice(0, 20) + '...' : val}
        </span>
      );
    }
  },
  {
    title: 'Submitted Time',
    dataIndex: ['submissionTime'],
    key: 'submissionTime',
    sorter: { multiple: 3 },
    render: val => {
      return new Date(val).toLocaleString();
    }
  },
  {
    title: 'Action',
    width: '180px',
    render: row => {
      return (
        <AssignButton
          type='primary'
          onClick={() => viewCompleteBillerJob(row.jobId)}
        >
          View
        </AssignButton>
      );
    }
  }
];
