import { Menu, Button, Badge, Input, Tabs } from 'antd';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { Select, Checkbox } from 'antd';

export const StyledResetButton = styled(Button)`
  font-family: 'Krub';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: #ff4d4f;
`;

export const StyledMenu = styled(Menu)`
  & .ant-menu-item {
    height: 50px;
  }

  &.ant-menu-root.ant-menu-inline {
    background: transparent;
  }

  & .ant-menu-item-selected {
    color: #414141;
    font-size: 14px;
  }

  &:not(.ant-menu-horizontal) {
    .ant-menu-item.ant-menu-item-selected {
      background: #ffffff;
      box-shadow: 0px 0px 8px 5px rgba(238, 238, 238, 0.25);
      border-radius: 7px;
      padding-top: 4px;
      font-weight: 600;
    }
  }
`;

export const FilterButtonTittle = styled.div`
  margin-right: 10px;
  color: #666666;
  font-weight: 500;
  font-family: Krub;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const ConfigureButton = styled(Button)`
  margin: auto 0;
  align-items: center;
  width: auto;
  &.ant-btn-round {
    border-radius: 6px;
  }
`;

export const FilterBadge = styled.div`
  width: 8.28px;
  height: 8.28px;
  background: #47a4ea;
  border: 1px solid #ffffff;
  border-radius: 4px;
  position: absolute;
  right: 7px;
  top: -3px;
`;

export const StyledCheckBoxContainer = styled.div`
  position: absolute;
  right: 2%;
  margin-top: 1.5%;
`;
export const StyledCheckBox = styled(Checkbox)``;

export const StyledMenuItem = styled(StyledMenu.Item)`
  &.ant-menu-item::after {
    border-right: none !important;
  }
`;

export const ListDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const MenuDiv = styled.div`
  width: 300px;

  @media (max-width: 1500px) {
    width: 200px;
  }
  flex-grow: 2;
`;

export const TableDiv = styled.div`
  width: 100%;
  flex-grow: 10;
  overflow-x: auto;
  overflow-y: hidden;
  // max-height: calc(100vh - 160px);
  background-color: #ffffff;
`;

export const AssignButton = styled(Button)`
  transition: all 0.3s ease-in-out;
  &.ant-btn {
    border-radius: 26px;
    font-weight: 600;
    width: fit-content;
  }
  &.ant-btn-primary {
    ${props =>
      !props.active &&
      `background-color: #d5d5d5;
    color:#757575;
    border: #d0d0d0;
  `}
  }
`;

const commentAnimation = keyframes`
 0% {
    transform: rotateZ(0);
  }
  25% {
    transform: rotateZ(25deg);
  }
  50% {
    transform: rotateZ(0);
  }
  75% {
    transform: rotateZ(-25deg);
  }
  100% {
    transform: rotateZ(0);
  }
  `;

export const CommentButton = styled(Button)`
  &.ant-btn {
    background: transparent;
    &:hover {
      animation: ${commentAnimation} 0.35s 1;
      animation-delay: 0.3s;
    }
  }
  & svg path {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  &:hover > svg path {
    fill: #47a4ea !important;
  }
`;

export const MenuItemFlex = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BadgeStyle = styled(Badge)`
  margin-right: -14px;
  & .ant-badge-dot {
    background-color: ${props => `${props.bgColor}`};
  }
`;

export const SearchJobListStyle = styled(Input)`
  width: ${props => (props.files ? '250px' : '299.56px')};
  height: 34px;
  margin: auto 0;
  align-items: center;
  &.ant-input-affix-wrapper {
    background: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
  }

  & .ant-input:placeholder-shown {
    margin-left: 10px;
  }
`;

export const StyledJobListSubTabRow = styled.div``;

export const StyledJobListSubTab = styled(Tabs)`
  & .ant-tabs-nav {
    width: ${props => props.tabswidth};
  }
  &.ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border: none;
  }
  & .ant-tabs-tab {
    padding: 10px !important;
    font-style: normal;
    color: #78828f;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    /* min-width:150px; */
  }
  & .ant-tabs-tab-active {
    background: #47a4ea1a;
    border-radius: 5px;
    color: #47a4ea;
  }

  & .ant-tabs-ink-bar {
    height: 0px;
    background: transparent !important;
  }

  & .ant-tabs-nav {
    margin-bottom: 0px;
  }
`;

export const CommentDivTitleStyle = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: #47a4ea;
`;

export const StyledCommentContent = styled.div``;

export const PopoverGlobalStyle = createGlobalStyle`
  
  body {



    & .ant-popover-title {
      padding: 8px 16px;
      width: ${props => props.video && '170px'} ;
    }

    & .ant-popover-inner-content{
      padding: 8px 16px;
      width: ${props => props.video && '170px'} ;
    }
  }
`;

export const ReadyJobsTabsFlexStyle = styled.div`
  display: flex;
`;

export const ReadyJobsTabsTitleStyle = styled.p`
  margin-bottom: 0;
  margin-right: 10px;
`;

export const StyledResubmitButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 50px;
`;
export const ResubmitButton = styled(Button)``;
export const StyledRealseButtonContainer = styled.div`
  display: flex;

  justify-content: center;

  align-items: center;

  padding-left: 80px;
`;

export const RealseButton = styled(Button)``;
export const SubJobListTabContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const AssignButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > span {
    margin-right: 5px;
    display: flex;
    justify-content: center;
  }
`;
export const StyledSelect = styled(Select)`
  border-radius: 5px;
  margin-right: 20px;
  margin-left: 15px;
  width: 96.01px;
`;

export const StyledFacilityContainer = styled.div`
  background-color: transparent;
  display: flex;

  flex-direction: row;
`;

export const StyledFacilityBlocks = styled.div`
  background-color: ${({ disabled }) => (disabled ? '#c0c0c0' : '#47a4ea')};
  border-radius: 14.3301px;
  color: #ffffff;
  width: 62.98px;
  font-size: 9px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 5px;
`;

export const FacilityRemainingCount = styled.div`
  width: 18.8px;
  height: 18.8px;
  border-radius: 14.33px;
  background-color: #47a4ea;
  color: #ffffff;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 9px;
  font-weight: 900;
`;

// New joblist
export const StyledJobListTab = styled(Tabs)`
  background-color: #ffffff;
  margin-top: 10px;
  &.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    border-bottom: 1px solid #dadada;
  }
  & .ant-tabs-tab-active .tab-content-text {
    color: #47a4ea;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
  }
  & .ant-tabs-tab-active .tab-content-count {
    background: #47a4ea;
    color: #ffffff;
  }
  & .tab-content-count {
    padding: 2px 10px;
    display: flex;
    align-items: center;
  }
  & .ant-tabs-ink-bar {
    height: 3px;
  }
`;

export const StyledJobListTabPane = styled(Tabs.TabPane)``;
export const StyledJobListTabPaneContent = styled.div`
  margin-left: 20px;
  margin-right: 20px;

  display: flex;
`;
export const StyledJobListTabPaneContentText = styled.span`
  display: flex;
  justify-content: center;
  color: #525e6f;
  text-align: center;
`;

export const StyledJobListTabPaneContentCount = styled.span`
  min-width: 23px;
  height: 23px;
  margin-left: 5px;
  display: flex;
  justify-content: center;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;

  text-align: center;

  border-radius: 200px;
  background: #525e6f1a;
  color: #525e6f;
`;

export const StyledSubContainer = styled.div``;

export const StyledTableControlContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  // margin: 10px;
  background-color: #f0f2f5;
  flex-wrap: wrap;
`;
export const StyledTableControlSubContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  flex-wrap: wrap;
`;

export const StyledJobListTableContainer = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;

  border: 2px solid #f0f2f5;

  height: fit-content;
`;
