import React, { useState, useEffect } from 'react';
import {
  DemographicsContainer,
  DemographicsTitleDiv,
  DemographicsContentDiv,
  SourceDiv,
  DemographicsItem,
  ItemTitle,
  ItemValue,
  ItemValueDiv,
  ItemsContainer,
  DemographicsIconDiv,
  IconButton,
  StyledSearchIcon,
  StyledCloseIcon,
  StyledReloadIcon,
  StyledConfirmationIcon,
  ItemTitleDiv,
  EditTextButton,
  ConfirmationIconWrapper
} from './styledComponents';

import CustomInput from '../../CustomComponents/CustomInput';
import InferenceEditor from './InferenceEditor';
import { Empty, Spin, Tooltip } from 'antd';
import { StyledAsterisk } from './InferenceEditor/styledComponents';

const Demographics = ({
  theme,
  content,
  inference,
  loading,
  fetchContents,
  updateInferenceData,
  updateInferenceFlag,
  setInferenceAnnotation,
  inferenceAnnotation,
  editorData,
  activeSection,
  emptySection,
  updateInferenceLoading,
  setUpdateInferenceLoading
}) => {
  const [searchClicked, setSearchClicked] = useState(false);
  const [previewData, setPreviewData] = useState('');
  const [demographicsContents, setDemographicsContents] = useState([]);
  const [editKey, setEditKey] = useState(null);

  useEffect(() => {
    setDemographicsContents(content);
    setEditKey(false);
  }, [content]);

  useEffect(() => {
    editorData.forEach(item => {
      if (activeSection === item.id) {
        setPreviewData(item.content && item.content[0].value);
      }
    });
  });

  const searchValues = val => {
    let filteredContents;
    if (inference) {
      filteredContents = content.filter(
        item => item.key && item.key.toLowerCase().includes(val.toLowerCase())
      );
    } else {
      filteredContents = content.filter(
        item =>
          (item.key && item.key.toLowerCase().includes(val.toLowerCase())) ||
          (item.value && item.value.toLowerCase().includes(val.toLowerCase()))
      );
    }

    setDemographicsContents(filteredContents);
    if (val.length === 0) {
      setDemographicsContents(content);
    }
  };

  useEffect(() => {
    if (searchClicked === false) {
      setDemographicsContents(content);
    }
    // eslint-disable-next-line
  }, [searchClicked]);

  const editFlagHandler = (key, isEdited) => {
    if (isEdited) {
      let inferenceObject = {
        key: key,
        edited: false
      };
      updateInferenceFlag(inferenceObject);
    } else {
      setEditKey(key);
    }
  };

  const renderDemographicsItem = item => {
    const annotationAvailable =
      item.annotations &&
      item.annotations.length > 0 &&
      item.annotations[0].startIndex !== item.annotations[0].endIndex;
    const isEmpty = !inference && item.value === null;
    return item.type !== 'Dynamic Table' && item.key !== editKey ? (
      !isEmpty && (
        <DemographicsItem
          selected={inferenceAnnotation && inferenceAnnotation.key === item.key}
          headingTextColor={theme['@primary-color']}
          onClick={() => {
            inference && annotationAvailable && setInferenceAnnotation(item);
          }}
          inference={inference}
          annotationAvailable={annotationAvailable}
        >
          <ItemTitleDiv>
            <ItemTitle textColor={theme['@primary-color']}>
              {inference && item.edited && (
                <Tooltip title='Updated Inference'>
                  <ConfirmationIconWrapper>
                    <StyledConfirmationIcon height={'16'} width={'16'} />
                  </ConfirmationIconWrapper>
                </Tooltip>
              )}
              {item.key}
              {item.mandatory && <StyledAsterisk>*</StyledAsterisk>}
            </ItemTitle>
            {inference && (
              <EditTextButton
                type='text'
                textColor={theme['@text-color']}
                onClick={e => {
                  e.stopPropagation();
                  editFlagHandler(item.key, item.edited);
                }}
              >
                {item.edited ? 'Allow Edit' : 'Edit'}
              </EditTextButton>
            )}
          </ItemTitleDiv>
          <ItemValueDiv>
            <ItemValue textColor={theme['@text-color']}>
              {item.value && item.value.toString()}
            </ItemValue>
          </ItemValueDiv>
        </DemographicsItem>
      )
    ) : (
      <InferenceEditor
        theme={theme}
        inferences={[item]}
        updateInferenceData={updateInferenceData}
        updateInferenceFlag={updateInferenceFlag}
        setEditKey={setEditKey}
        previewData={previewData}
        updateInferenceLoading={updateInferenceLoading}
        setUpdateInferenceLoading={setUpdateInferenceLoading}
        activeSection={activeSection}
        fetchContents={fetchContents}
      />
    );
  };
  return (
    <DemographicsContainer>
      <DemographicsTitleDiv background={theme && theme['@text-color']}>
        {searchClicked ? (
          <CustomInput
            onChange={e => searchValues(e.target.value)}
            color='#fff'
            placeholder={
              inference ? 'Search Inferences' : 'Search demographics'
            }
            autoFocus={true}
            inference={inference}
          />
        ) : inference ? (
          'Inference'
        ) : (
          'Demographics'
        )}

        <DemographicsIconDiv>
          <IconButton
            onClick={() => setSearchClicked(!searchClicked)}
            type='text'
            icon={
              searchClicked ? (
                <Tooltip title='Close Search'>
                  <StyledCloseIcon />
                </Tooltip>
              ) : (
                <Tooltip title='Search Inferences'>
                  <StyledSearchIcon />
                </Tooltip>
              )
            }
          />
        </DemographicsIconDiv>
      </DemographicsTitleDiv>
      <DemographicsContentDiv>
        <SourceDiv inference={inference}>
          Source: {inference ? 'Inference Engine' : 'HL7 Feed'}
          {inference && (
            <Tooltip title='Refresh Inferences'>
              <IconButton
                onClick={() => fetchContents(activeSection)}
                type='text'
                shape='circle'
                icon={<StyledReloadIcon />}
              />
            </Tooltip>
          )}
        </SourceDiv>
        <ItemsContainer
          loading={loading}
          emptySection={emptySection && inference}
        >
          {loading ? (
            <Spin />
          ) : emptySection && inference ? (
            <div>
              <Empty description={'No Inferences Found'} />
            </div>
          ) : (
            demographicsContents &&
            demographicsContents.map(item => {
              return renderDemographicsItem(item);
            })
          )}
        </ItemsContainer>
      </DemographicsContentDiv>
    </DemographicsContainer>
  );
};

export default Demographics;
