import ProfileFingerPrint from '../../../Assets/Icons/ProfileFingerPrint';

import PhoneIcon from '../../../Assets/Icons/PhoneIcon';
import KeyToPhraseIcon from '../../../Assets/Icons/KeyToPhraseIcon';
import DictationIcon from '../../../Assets/Icons/DictationIcon';
import FacilityIcon from '../../../Assets/Icons/FacilityIcon';

const ADMIN_SETTINGS_CONTENTS = [
  {
    title: 'Profile',
    background: '#FAF2CE',
    iconColor: '#F9A218',
    icon: ProfileFingerPrint,
    path: '/dashboard/settings/profile'
  },

  {
    title: 'Device Config',
    background: '#D9F8C4',
    iconColor: '#9DC383',
    icon: PhoneIcon,
    path: '/dashboard/settings/device-configuration'
  },
  {
    title: 'Key to Phrase',
    background: '#C2FFF9',
    iconColor: '#4d948d',
    icon: KeyToPhraseIcon,
    path: '/dashboard/settings/key-to-phrase'
  },
  {
    title: 'Dictation Template',
    background: '#c2ffcf',
    iconColor: '#4d8759',
    icon: DictationIcon,
    path: '/dashboard/settings/dictation'
  }
];

const CLINICADMIN_SETTINGS_CONTENTS = [
  {
    title: 'Profile',
    background: '#FAF2CE',
    iconColor: '#F9A218',
    icon: ProfileFingerPrint,
    path: '/dashboard/settings/profile'
  },
  {
    title: 'Device Config',
    background: '#D9F8C4',
    iconColor: '#9DC383',
    icon: PhoneIcon,
    path: '/dashboard/settings/device-configuration'
  },

  {
    title: 'Key to Phrase',
    background: '#C2FFF9',
    iconColor: '#4d948d',
    icon: KeyToPhraseIcon,
    path: '/dashboard/settings/key-to-phrase'
  },
  {
    title: 'Dictation Template',
    background: '#c2ffcf',
    iconColor: '#4d8759',
    icon: DictationIcon,
    path: '/dashboard/settings/dictation'
  },
  {
    title: 'Facility',
    background: '#c2ffcf',
    iconColor: '#4d8759',
    icon: FacilityIcon,
    path: '/dashboard/settings/facility'
  }
];

const NORMAL_SETTINGS_CONTENTS = [
  {
    title: 'Profile',
    background: '#FAF2CE',
    iconColor: '#F9A218',
    icon: ProfileFingerPrint,
    path: '/dashboard/settings/profile'
  }

  // {
  //   title: 'Device Config',
  //   background: '#D9F8C4',
  //   iconColor: '#9DC383',
  //   icon: PhoneIcon,
  //   path: '/dashboard/settings/device-configuration'
  // }
  // ,
  // {
  //   title: 'Key to Phrase',
  //   background: '#C2FFF9',
  //   iconColor: '#4d948d',
  //   icon: KeyToPhraseIcon,
  //   path: '/dashboard/settings/key-to-phrase'
  // },
  // {
  //   title: 'Dictation Template',
  //   background: '#c2ffcf',
  //   iconColor: '#4d8759',
  //   icon: DictationIcon,
  //   path: '/dashboard/settings/dictation'
  // }
];
const NORMAL_SETTINGS_CONTENTS_DOCTOR = [
  {
    title: 'Profile',
    background: '#FAF2CE',
    iconColor: '#F9A218',
    icon: ProfileFingerPrint,
    path: '/dashboard/settings/profile'
  },

  // {
  //   title: 'Device Config',
  //   background: '#D9F8C4',
  //   iconColor: '#9DC383',
  //   icon: PhoneIcon,
  //   path: '/dashboard/settings/device-configuration'
  // },
  {
    title: 'Key to Phrase',
    background: '#C2FFF9',
    iconColor: '#4d948d',
    icon: KeyToPhraseIcon,
    path: '/dashboard/settings/key-to-phrase'
  },
  {
    title: 'Dictation Template',
    background: '#c2ffcf',
    iconColor: '#4d8759',
    icon: DictationIcon,
    path: '/dashboard/settings/dictation'
  }
];

const settingsConfigMap = new Map([
  ['admin', ADMIN_SETTINGS_CONTENTS],
  ['transcriptionist', NORMAL_SETTINGS_CONTENTS],
  ['reviewer', NORMAL_SETTINGS_CONTENTS],
  ['doctor', NORMAL_SETTINGS_CONTENTS_DOCTOR],
  ['clinicAdmin', CLINICADMIN_SETTINGS_CONTENTS]
]);

export const settingsContents = userType => settingsConfigMap.get(userType);
