import React from 'react';

function AvailableJobsIcon({ size,color }) {
  return (
    <span className='anticon'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={size ? size : '16'}
        height={size ? size : '16'}
        fill='none'
        viewBox='0 0 34 30'
      >
        <path
          fill={color ? color : '#666'}
          d='M6.122 29.614h21.76a5.302 5.302 0 005.318-5.318V6.888c0-.82-.63-1.45-1.45-1.45h-7.494v-.486A4.336 4.336 0 0019.904.6H14.1a4.336 4.336 0 00-4.352 4.352v.485H2.25c-.82 0-1.451.63-1.451 1.45v17.409a5.306 5.306 0 005.322 5.318zM12.65 4.952c0-.82.63-1.45 1.451-1.45h5.803c.82 0 1.45.63 1.45 1.45v.485H12.65v-.485zM3.701 8.34H30.3v3.867c0 1.015-.63 1.935-1.596 2.27l-10.88 3.868c-.53.195-1.11.195-1.596 0l-10.88-3.867a2.4 2.4 0 01-1.596-2.271V8.338h-.05zm0 8.604c.195.095.436.195.63.29l10.88 3.867c.58.195 1.161.29 1.791.29.63 0 1.21-.095 1.791-.29l10.88-3.867c.24-.095.435-.195.63-.29v7.348a2.393 2.393 0 01-2.416 2.417H6.127a2.393 2.393 0 01-2.417-2.416v-7.35h-.009z'
        ></path>
      </svg>
    </span>
  );
}

export default AvailableJobsIcon;
