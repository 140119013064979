import React from 'react';

function RejectIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="66"
      height="66"
      fill="none"
      viewBox="0 0 66 66"
    >
      <path
        fill="#F9A218"
        d="M33 66c18.225 0 33-14.775 33-33S51.225 0 33 0 0 14.775 0 33s14.775 33 33 33z"
      ></path>
      <path
        fill="#fff"
        d="M47.086 47.086C43.313 50.859 38.32 53 33 53c-10.989 0-20-8.993-20-20 0-11.012 8.99-20 20-20 10.961 0 20 8.952 20 20 0 5.32-2.14 10.313-5.914 14.086zM33 17.766c-8.42 0-15.234 6.795-15.234 15.234 0 3.281 1.03 6.398 2.93 8.977l21.28-21.258c-2.578-1.922-5.695-2.953-8.976-2.953zm12.281 6.258l-21.258 21.28A15.071 15.071 0 0033 48.235c8.421 0 15.234-6.795 15.234-15.234 0-3.281-1.03-6.398-2.953-8.977z"
      ></path>
    </svg>
  );
}

export default RejectIcon;
