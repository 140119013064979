import React, { useEffect, useState } from 'react';
import {
  StyledMenu,
  StyledMenuItem,
  StyledWarningIcon
} from './styledComponents';
import { useSelector } from 'react-redux';
import { sideMenuContents } from './SideMenuConfig';
import { Link, useLocation } from 'react-router-dom';
import { Tooltip } from 'antd';

function SideMenu({ collapsed }) {
  const [theme] = useSelector(state => [state.themeReducer]);
  const location = useLocation();
  const [sideMenuItems, setSideMenuItems] = useState([]);
  const [user] = useSelector(state => [state.userReducer]);
  const [activeKey, setActiveKey] = useState(() => {
    const activeItem = sideMenuItems.find(item => {
      let splitPath = location.pathname.split('/');
      let splitLink = item.link.split('/');
      if (splitPath[2] === splitLink[2]) return item;
      else return null;
    });
    return activeItem ? activeItem.key : '0';
  });

  useEffect(() => {
    if (user) setSideMenuItems(sideMenuContents(user.userType));
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    const activeItem =
      sideMenuItems &&
      sideMenuItems.find(item => {
        let splitPath = location.pathname.split('/');
        let splitLink = item.link.split('/');
        if (splitPath[2] === splitLink[2]) return item;
        else return null;
      });
    if (activeItem) {
      setActiveKey(activeItem.key);
    } else {
      setActiveKey('0');
    }
  }, [sideMenuItems, location.pathname]);

  const onSelect = param => {
    setActiveKey(param.key);
  };

  return (
    <StyledMenu
      mode='inline'
      defaultSelectedKeys={activeKey}
      theme='light'
      color={theme['@primary-color']}
      textColor={theme['@text-color']}
      collapsed={collapsed}
      onSelect={onSelect}
      key={activeKey}
    >
      {sideMenuItems &&
        sideMenuItems.map(config => {
          return (
            <StyledMenuItem
              key={config.key}
              icon={<config.icon />}
              color={theme['@primary-color']}
              disabled={
                config.disabled ||
                (config.name === 'HL7' && true)
              }
            >
              <Link to={config.link}>{config.name}</Link>
              {config.name === 'Settings' &&
                user.userType === 'admin' &&
                !user.clinicConfigured && (
                  <Link to={config.link}>
                    <Tooltip title='Ehr not Configured.'>
                      <StyledWarningIcon />
                    </Tooltip>
                  </Link>
                )}
            </StyledMenuItem>
          );
        })}
    </StyledMenu>
  );
}

export default SideMenu;
