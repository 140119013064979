import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { Typography, Row, Col } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';

export const GlobalStyle = createGlobalStyle`
  body {
    & .ant-popover-title {
      padding: 8px 16px;
    }

    & .ant-popover-inner-content{
      padding: 0;
    }
  }
`;

export const NotificationTitle = styled(Typography.Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${props => props.color} !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const NotificationRowComponent = styled(Row)`
  position: relative;
  border-bottom: 1px solid #e6e7e9;
  padding: 10px;
  background: ${props => (props.readStatus ? 'white' : '#f2f4f5')};
  cursor: pointer;
  &:hover {
    background: #f5f5f5;
  }
`;

export const NotificationColumnContentStyle = styled(Col)`
  padding-left: 10px;
`;

export const NotificationContentDiv = styled.div`
  width: 400px;
  min-height: 300px;
  max-height: 316px;
  overflow-y: auto;
  overflow-x: hidden;
  ${props =>
    props.loading &&
    `display:flex;
  justify-content:center;
  align-items:center;
  `}
`;

export const NotificationElementStyle = styled.div`
  font-size: ${props =>
    props.messageProps ? (props.timeProps ? '10px' : '12px') : '13.5px'};
  font-weight: ${props => (props.messageProps ? '400' : '600')};
  color: #2d353c;
  word-wrap: break-word;
  width: 250px;
`;

export const NotificationProfilePictureStyle = styled(Avatar)`
  &.ant-avatar > img {
    border-radius: 10px;
  }
`;
export const PopOverFooterDiv = styled.div`
  padding: 8px 16px;
  text-align: center;
  border-top: 1px solid #e6e7e9;
  font-weight: 600;

  & p {
    cursor: pointer;
    margin-bottom: 0;
    width: fit-content;
    margin: auto;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const bellAnimation = keyframes`
 0% {
    transform: rotateZ(0);
  }
  25% {
    transform: rotateZ(25deg);
  }
  50% {
    transform: rotateZ(0);
  }
  75% {
    transform: rotateZ(-25deg);
  }
  100% {
    transform: rotateZ(0);
  }
  `;

export const StyledAvatar = styled(Avatar)`
  cursor: pointer;
  :hover {
    animation: ${bellAnimation} 0.35s 1;
  }
`;

export const EmrIconContainer = styled.div`
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translate(0%, -50%);
`;
