import { Button, Select, Typography, Switch } from 'antd';
import styled from 'styled-components';
import { WarningOutlined, StopOutlined } from '@ant-design/icons';

export const StyledSelect = styled(Select)`
  width: 100%;
`;

export const ElementContainer = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
`;

export const DynamicTableDivStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DrugButtonStyle = styled(Button)`
  &.ant-btn {
    background-color: ${props => props.borderColor};

    &:hover {
      background-color: #599dd04a !important;
      transition: all 0.2s ease-in;
      border: 0;
      path {
        fill: ${props => props.borderColor};
        transition: all 0.1s ease-in;
      }
    }

    &:active,
    &:focus {
      background-color: #47a4ea;
      border: 0;
    }
  }
`;

export const StyledModalHeader = styled.h1`
  margin-bottom: 0;
`;

export const TableHeaderMandatoryStyle = styled.div`
  display: flex;
  justify-content: space-between;
  height: 18px;
  font-size: 14px;
`;

export const StyledAsterisk = styled(Typography.Text)`
  font-size: 14px;
  color: #f44336 !important;
`;

export const WarningButtonStyle = styled(Button)`
  &.ant-btn {
    border: 1px solid red;
    margin-top: -5px;
    color: red;
    padding: 0px;
    height: 22px;
  }

  &.ant-btn-circle,
  .ant-btn-circle-outline {
    min-width: 22px;
  }
`;

export const StyledWarningIcon = styled(WarningOutlined)`
  font-size: 22px;
  color: #ffae42 !important;
`;

export const StyledDisabledIcon = styled(StopOutlined)`
  color: #f44336 !important;
`;

export const PopoverContent = styled.div`
  width: 250px;
  color: ${props => (props.color ? props.color : 'red')};
`;

export const StyledSwitch = styled(Switch)`
  height: 35px;
  width: 165px;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 600;

  &:after {
    width: 26px;
    height: 26px;
  }

  .ant-switch-inner {
    margin: ${props => (props.defaultChecked ? '0 24px 0 6px' : '0 0 0 28px')};
    font-size: 16px;
    font-weight: 600;
  }

  & .ant-switch-handle::before {
    height: 29px;
    width: 29px;
    left: ${props => (props.defaultChecked ? '-12px' : '1px')};
    top: 0;
    border-radius: 14px;
  }
`;

export const ModalHeaderFlex = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InferenceValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
