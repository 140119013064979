import React from "react";

function ToolIcon() {
  return (
    <span className="anticon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <g fill="#666" clip-path="url(#clip0)">
          <path d="M17.314 14.011c-1.432-1.431-1.338-1.34-1.379-1.37l-3.79-2.8 1.47-1.47c1.183.422 2.521.13 3.422-.77a3.286 3.286 0 00.641-3.734.469.469 0 00-.754-.13L15.712 4.95a.47.47 0 01-.663-.663l1.212-1.212a.469.469 0 00-.13-.754 3.286 3.286 0 00-3.733.64 3.287 3.287 0 00-.77 3.422L9.93 8.08c-.345-.268.11.19-3.141-3.258l.34-.34a.469.469 0 00-.09-.734L4.53 2.24a1.666 1.666 0 00-2.038.251 1.667 1.667 0 00-.251 2.039l1.506 2.51a.469.469 0 00.733.09l.321-.322c3.236 3.43 2.81 2.965 3.065 3.294l.02.022-1.504 1.503a3.287 3.287 0 00-3.422.771 3.286 3.286 0 00-.64 3.734c.139.291.525.358.754.13l1.212-1.213a.47.47 0 01.662.663l-1.212 1.212a.469.469 0 00.13.754 3.285 3.285 0 003.734-.641 3.287 3.287 0 00.77-3.422l1.284-1.284 2.99 3.634c.023.028-.052-.05 1.286 1.288.486.487 1.134.74 1.77.74.467 0 .928-.137 1.316-.416a2.345 2.345 0 00.298-3.566zM5.152 5.816a.469.469 0 00-.672-.01l-.239.239-1.197-1.995a.73.73 0 011.004-1.004l1.996 1.197-.239.238a.469.469 0 00-.01.653c3.737 3.962 3.15 3.366 3.468 3.614l-.71.711c-.246-.316.324.307-3.4-3.643zm2.334 7.36a.469.469 0 00-.091.533 2.347 2.347 0 01-.458 2.667 2.346 2.346 0 01-1.989.663l.663-.663a1.405 1.405 0 10-1.988-1.988l-.663.663a2.348 2.348 0 01.663-1.988 2.347 2.347 0 012.667-.458c.18.085.393.049.533-.092l5.688-5.687a.469.469 0 00.092-.534 2.347 2.347 0 01.458-2.667 2.348 2.348 0 011.988-.663l-.663.663a1.397 1.397 0 00-.412.995 1.407 1.407 0 002.4.994l.663-.663a2.348 2.348 0 01-.663 1.988 2.347 2.347 0 01-2.667.458.469.469 0 00-.533.092l-5.688 5.687zm2.832-1.507l1.157-1.156 1.178.87-1.408 1.409c-.957-1.164-.898-1.094-.927-1.123zm6.15 5.15c-.547.393-1.353.296-1.876-.227l-1.238-1.238-1.51-1.835 1.572-1.573 1.934 1.429 1.301 1.3a1.406 1.406 0 01-.183 2.144z"></path>
          <path d="M15.98 15.256l-1.874-1.874a.468.468 0 10-.663.663l1.875 1.874a.469.469 0 00.662-.663z"></path>
        </g>
        <defs>
          <clipPath id="clip0">
            <path
              fill="#fff"
              d="M0 0H16V16H0z"
              transform="translate(2 2)"
            ></path>
          </clipPath>
        </defs>
      </svg>
    </span>
  );
}

export default ToolIcon;
