import React from 'react';
import { DemoVideoWrap, DemoVideoELementWrap } from './styledComponents';

const DemoVideoElement = () => {
  return (
    <>
      <DemoVideoWrap>
        <DemoVideoELementWrap
          width='100%'
          height='100% '
          src='https://www.cascade.md/wp-content/uploads/2021/03/Cascade_MD.mov'
          controls
        ></DemoVideoELementWrap>
      </DemoVideoWrap>
    </>
  );
};

export default DemoVideoElement;
