import React, { useCallback, useEffect, useState, useRef } from 'react';
import CustomTypography from '../../CustomComponents/CustomTypography';
import PageTitle from '../../../Utils/PageTitle';
import CustomTable from '../../CustomComponents/Table';
import moment from 'moment';
import CustomSideDrawer from '../../CustomComponents/CustomSideDrawer';
import SessionDetails from './SessionDetails';
import { TitleRow, ContainerRow, SearchInputStyle } from '../styledComponents';
import { useSelector } from 'react-redux';
import { columns } from './tableConfig';
import { RESULTS_PER_PAGE } from '../../../Constants/StringConstants';
import { useDebounce } from '../../../Utils/Debounce';
import { SearchOutlined, PrinterOutlined } from '@ant-design/icons';
import { strikeDate } from '../../../Apis/Jobs';
import ConfirmationModal from '../../CustomComponents/ConfirmationModal';
import {
  StyledRangePicker,
  StyledSelect,
  SelectViewOptions,
  TabOptions,
  TabOption,
  TabName,
  StatusLine,
  PrintButton,
  StyledViewJobButton,
  StyledDivOptions
} from './styledComponents';
import { notification } from 'antd';

import {
  GetSessionDetails,
  fetchJobsForAudit,
  fetchFacilityAudits
} from '../../../Apis/Facility';
import { useReactToPrint } from 'react-to-print';
import FacilityFilter from '../../CustomComponents/FacilityFilter';
import FacilityAuditDetails from './FacilityAuditDetails';
import { useHistory } from 'react-router-dom';

const { Option } = StyledSelect;
const pageSize = RESULTS_PER_PAGE;

const Audit = () => {
  const [theme] = useSelector(state => [state.themeReducer]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [searchKey, setSearchKey] = useState('name');
  const [dateRange, setDateRange] = useState(null);
  const [sortOrder, setSortOrder] = useState('DESCEND');
  const [userTypeFilter, setUserTypeFilter] = useState('');
  const [sortKey, setSortKey] = useState('loginTime');
  const [selectedSession, setSelectedSession] = useState(null);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [sessionData, setSessionData] = useState([]);
  const [facilityAuditData, setFacilityAuditData] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const firstRender = useRef(true);
  const [datePickerValue, setDatePickerValue] = useState([]);
  const [facilities, setFacilities] = useState();
  const componentRef = useRef();
  const history = useHistory();
  const user = useSelector(state => state.userReducer);
  const userString = localStorage.getItem('user');

  // Parse the user data from JSON string to object
  const userType = userString ? JSON.parse(userString) : null;
  const sessionId = userType ? userType.sessionId : null;

  const [selectedTab, setSelectedTab] = useState(
    userType?.userType === 'doctor' ? 2 : 1
  );
  const [releasetModalVisible, setReleaseModalVisible] = useState(false);
  const [rowToBeStriked, setRowToBeStriked] = useState(null);
  const [currentJobId, setCurrentJobId] = useState(null);
  // const [currentSessionId, setCurrentSessionId] = useState(null);

  const [releaseModalLoading, setReleaseModalLoading] = useState(false);
  const openModal = () => {
    if (currentJobId && sessionId) {
      strikeDate(currentJobId, sessionId)
        .then(response => {
          if (response.data.success) {
            notification['success']({
              message: 'Successful',
              description: 'Job has been stricken out successfully.'
            });
            fetchAll(currentPage);
          }
        })

        .catch(error => {
          // Handle error if needed
          console.error('API call error', error);

          // Show error notification
          notification['error']({
            message: 'Strike Failed',
            description:
              'Failed to perform the strike operation. Please try again.'
          });
        });
    }
    setReleaseModalVisible(false);
  };
  const handleStartButtonClick = jobId => {
    setCurrentJobId(jobId);
    // setCurrentSessionId(sessionId);
    setReleaseModalVisible(true);
  };

  const fetchAll = pageNo => {
    setLoading(true);
    let payload = {
      page: pageNo,
      searchKey: searchKey,
      searchValue: searchValue,
      size: pageSize,
      sortKey: [sortKey],
      sortOrder: [sortOrder],
      userTypeFilter: userTypeFilter
    };
    if (facilities && facilities.length > 0) {
      payload['facilityIds'] = facilities;
    }
    if (selectedTab === 1) {
      payload.loginTime = dateRange ? dateRange.startTimeFilter : 0;
      payload.logoutTime = dateRange ? dateRange.endTimeFilter : 0;
      GetSessionDetails(payload)
        .then(response => {
          if (response.data.success) {
            setSessionData(response.data.result);
            setTotal(response.data.size);
            setLoading(false);
          } else {
            notification['error']({
              message: 'Session Fetch Failed',
              description: response.data.message
            });
            setLoading(false);
          }
        })
        .catch(err => {
          setLoading(false);
          console.log(err);
        });
    } else if (selectedTab === 2) {
      payload.submitTimeFrom = dateRange ? dateRange.startTimeFilter : 0;
      payload.submitTimeTo = dateRange ? dateRange.endTimeFilter : 0;
      payload.sortKey = ['submissionTime'];
      fetchJobsForAudit(payload)
        .then(response => {
          if (response.data.success) {
            setSessionData(response.data.result);
            setTotal(response.data.size);
            setLoading(false);
          } else {
            notification['error']({
              message: 'Session Fetch Failed',
              description: response.data.message
            });
            setLoading(false);
          }
        })

        .catch(err => {
          setLoading(false);
          console.log(err);
        });
    } else {
      fetchFacilityAudits(pageNo, pageSize)
        .then(response => {
          if (response.data.success) {
            setFacilityAuditData(response.data.result);
            setTotal(response.data.size);
            setLoading(false);
          } else {
            notification['error']({
              message: 'Session Fetch Failed',
              description: response.data.message
            });
            setLoading(false);
          }
        })
        .catch(err => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const pageNoChangeHandler = pageNo => {
    fetchAll(pageNo);
  };

  const onSearchChangeHandler = value => {
    setCurrentPage(0);
    setSearchValue(value);
  };

  const debounceOnChange = useCallback(
    useDebounce(onSearchChangeHandler, 1000),
    []
  );

  const disabledDate = current => {
    return current && current > moment().endOf('day');
  };

  const dateRangeChangeHandler = date => {
    if (date) {
      setDatePickerValue(date);
      let range = {
        startTimeFilter: parseFloat(date[0].startOf('day').format('x')),
        endTimeFilter: parseFloat(date[1].endOf('day').format('x'))
      };
      setDateRange(range);
    } else {
      setDatePickerValue([]);
      setDateRange(null);
    }
  };

  const handleUserFilterChange = value => {
    setUserTypeFilter(value ? value : '');
  };

  const tableChangeHandler = (pagination, filters, sorter) => {
    if (sorter.field !== undefined) setSortKey(sorter.field && sorter.field[0]);
    if (sorter.order !== undefined) setSortOrder(sorter.order);
  };

  const onRow = (record, index, isModalVisible) => {
    return {
      onClick: event => {
        const clickedElement = event.target;

        if (clickedElement.innerText != 'Strike Out') {
          setSelectedSession(record);
          setDrawerVisible(true);
        }
      }
    };
  };

  const handleSelectedFacility = (record, index) => {
    return {
      onClick: event => {
        setSelectedFacility(record);
        setDrawerVisible(true);
      }
    };
  };

  const selectAfter = (
    <StyledSelect
      defaultValue='name'
      className='select-after'
      onChange={setSearchKey}
    >
      <Option value='name'>User Name</Option>
      <Option value='jobName'>Job Name</Option>
      <Option value='status'>Status</Option>
    </StyledSelect>
  );

  useEffect(() => {
    if (firstRender.current === false) {
      fetchAll(0);
      setCurrentPage(0);
    }
    // eslint-disable-next-line
  }, [
    dateRange,
    searchValue,
    sortOrder,
    userTypeFilter,
    searchKey,
    sortKey,
    facilities
  ]);

  useEffect(() => {
    if (
      firstRender.current === false &&
      (userTypeFilter.length > 0 ||
        dateRange !== null ||
        searchValue.length > 0 ||
        searchKey === 'jobName')
    ) {
      setUserTypeFilter('');
      setDateRange(null);
      setSearchValue('');
      setSearchKey('name');
      setDatePickerValue([]);
    } else {
      fetchAll(0);
      setCurrentPage(0);
    }
    setSessionData([]);
    firstRender.current = false;
    // eslint-disable-next-line
  }, [selectedTab]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const viewJob = jobId => {
    history.push(
      `/dashboard/new-editor/${jobId}?jobStage=completed&userType=${user?.userType}`
    );
  };

  return (
    <>
      <PageTitle title='Audit' />
      <ContainerRow gutter={[0, 40]}>
        <TitleRow span={24}>
          <CustomTypography color={theme['@text-color']}>
            Audit
          </CustomTypography>
        </TitleRow>
        <SelectViewOptions>
          {/* <TabOptions>
            <TabOption onClick={() => setSelectedTab(1)}>
              <TabName selected={selectedTab === 1}>Users</TabName>
              <StatusLine selected={selectedTab === 1} />
            </TabOption>
            <TabOption onClick={() => setSelectedTab(2)}>
              <TabName selected={selectedTab === 2}>Jobs</TabName>
              <StatusLine selected={selectedTab === 2} />
            </TabOption>
            <TabOption onClick={() => setSelectedTab(3)}>
              <TabName selected={selectedTab === 3}>Facilities</TabName>
              <StatusLine selected={selectedTab === 3} />
            </TabOption>
          </TabOptions> */}
          <TabOptions>
            {userType?.userType !== 'doctor' && (
              <>
                <TabOption onClick={() => setSelectedTab(1)}>
                  <TabName selected={selectedTab === 1}>Users</TabName>
                  <StatusLine selected={selectedTab === 1} />
                </TabOption>
                <TabOption onClick={() => setSelectedTab(2)}>
                  <TabName selected={selectedTab === 2}>Jobs</TabName>
                  <StatusLine selected={selectedTab === 2} />
                </TabOption>
                <TabOption onClick={() => setSelectedTab(3)}>
                  <TabName selected={selectedTab === 3}>Facilities</TabName>
                  <StatusLine selected={selectedTab === 3} />
                </TabOption>
              </>
            )}

            {userType?.userType === 'doctor' && (
              <TabOption onClick={() => setSelectedTab(2)}>
                <TabName selected={selectedTab === 2}>Jobs</TabName>
                <StatusLine selected={selectedTab === 2} />
              </TabOption>
            )}
          </TabOptions>
          <StyledDivOptions>
            {selectedTab === 1 && (
              <StyledSelect
                placeholder='User Type'
                onChange={handleUserFilterChange}
                allowClear
              >
                <Option value='admin'>Admin</Option>
                <Option value='transcriptionist'>Transcriptionist</Option>
                <Option value='reviewer'>Reviewer</Option>
                <Option value='doctor'>Doctor</Option>
              </StyledSelect>
            )}
            {selectedTab === 2 ? (
              <FacilityFilter setSelectedValues={setFacilities} />
            ) : null}
            {selectedTab === 1 || selectedTab === 2 ? (
              <>
                <StyledRangePicker
                  disabledDate={disabledDate}
                  style={{ marginRight: selectedTab === 2 && 0 }}
                  onChange={dateRangeChangeHandler}
                  value={datePickerValue}
                />
                <SearchInputStyle
                  placeholder='Search'
                  key={selectedTab}
                  prefix={<SearchOutlined />}
                  onChange={event => debounceOnChange(event.target.value)}
                  addonAfter={selectAfter}
                  allowClear={true}
                />
              </>
            ) : null}
          </StyledDivOptions>
        </SelectViewOptions>

        <CustomTable
          theme={theme}
          // columns={columns(selectedTab)}
          columns={columns(selectedTab, handleStartButtonClick)}
          dataSource={
            selectedTab === 1 || selectedTab === 2
              ? sessionData
              : facilityAuditData
          }
          pageNoChangeHandler={pageNoChangeHandler}
          loading={loading}
          setPageNo={current => {
            setCurrentPage(current);
          }}
          pageNo={currentPage}
          pageSize={RESULTS_PER_PAGE}
          total={total}
          onChange={tableChangeHandler}
          onRow={
            selectedTab === 1 || selectedTab === 2
              ? onRow
              : handleSelectedFacility
          }
        />
        <CustomSideDrawer
          title={selectedTab === 3 ? 'Facility Audit Log' : 'Session Details'}
          placement='right'
          closable={false}
          onClose={() => {
            setSelectedSession(null);
            setDrawerVisible(false);
          }}
          visible={drawerVisible}
          width={800}
          destroyOnClose={true}
        >
          {selectedTab === 1 || selectedTab === 2 ? (
            <SessionDetails
              session={selectedSession}
              searchValue={searchKey === 'jobName' ? searchValue : ''}
              selectedTab={selectedTab}
              ref={componentRef}
            />
          ) : selectedTab === 3 ? (
            <FacilityAuditDetails
              facilityDetails={selectedFacility}
              ref={componentRef}
            />
          ) : null}

          {selectedTab === 2 ? (
            <PrintButton icon={<PrinterOutlined />} onClick={handlePrint}>
              Print
            </PrintButton>
          ) : null}
          {selectedTab === 2 && selectedSession?.status === 'Completed' ? (
            <StyledViewJobButton
              onClick={() => {
                viewJob(selectedSession?.jobId);
              }}
            >
              View
            </StyledViewJobButton>
          ) : null}
        </CustomSideDrawer>
        <ConfirmationModal
          antdModalProps={{
            centered: true,
            width: '300',
            closable: false
          }}
          visible={releasetModalVisible}
          onCancel={() => {
            setReleaseModalVisible(false);
          }}
          onOk={() => openModal()}
          header={
            <CustomTypography color={theme['@text-color']}>
              Confirm
            </CustomTypography>
          }
          confirmation={true}
          loading={releaseModalLoading}
        >
          <h1
            style={{ fontSize: '18px' }}
          >{`Are you sure you want to strike out the job? This action cannot be undone.`}</h1>
        </ConfirmationModal>
      </ContainerRow>
    </>
  );
};

export default Audit;
