import React from 'react';
import { Link } from 'react-router-dom';
import { StyledDashboardCard, StyledDashboardCardContent, StyledDashboardCardFooter, StyledDashboardCardFooterContent, StyledDashboardCardfooterHeading, StyledDashboardCardHeader, StyledDashboardCardHeaderLeft, StyledDashboardCardHeaderRight, StyledDashboardCardHeading, StyledDashboardCardIcon } from './styledComponent';

const DashboardCard = ({ item }) => {
    return (
        <>
            <Link to={item.path} style={{pointerEvents: item.path === '#' ? 'none' : ''}} >
                <StyledDashboardCard>
                    <StyledDashboardCardHeader>
                        <StyledDashboardCardHeaderLeft>
                            <StyledDashboardCardHeading>
                                {item.heading}
                            </StyledDashboardCardHeading>

                            <StyledDashboardCardContent color={'#000000'}>
                                {item.content ? item.content : '--'}
                            </StyledDashboardCardContent>
                        </StyledDashboardCardHeaderLeft>
                        <StyledDashboardCardHeaderRight>
                            <StyledDashboardCardIcon color={`${item.color}1A`}>
                                <item.icon color={item.color} size={40} />
                            </StyledDashboardCardIcon>
                        </StyledDashboardCardHeaderRight>
                    </StyledDashboardCardHeader>
                    <StyledDashboardCardFooter>
                        <StyledDashboardCardfooterHeading>
                            {item.footerHeading}
                        </StyledDashboardCardfooterHeading>
                        <StyledDashboardCardFooterContent>
                            {item.footerContent}
                        </StyledDashboardCardFooterContent>
                    </StyledDashboardCardFooter>
                </StyledDashboardCard>
            </Link>
        </>
    )
}

export default DashboardCard;
