import React from 'react';
import { StyledModal, StyledButton } from './styledComponents';
import { useSelector } from 'react-redux';
import SuccessIcon from '../../../Assets/Icons/SuccessIcon';

const SuccessModal = ({ visible, onOk, antdModalProps, message }) => {
  const [theme] = useSelector((state) => [state.themeReducer]);
  return (
    <StyledModal
      visible={visible}
      {...antdModalProps}
      color={theme['@text-color']}
      footer={[
        <StyledButton
          key="submit"
          onClick={onOk}
          type="text"
          color={theme['@primary-color']}
        >
          Ok
        </StyledButton>,
      ]}
      destroyOnClose
      title="Success!"
    >
      <SuccessIcon />
      <p>{message}</p>
    </StyledModal>
  );
};

export default SuccessModal;
