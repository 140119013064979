import React, { useState, useEffect, useCallback } from 'react';
import PageTitle from '../../../../Utils/PageTitle';
import Spinner from '../../../CustomComponents/Spinner';
import BreadCrumbComponent from '../../../CustomComponents/BreadCrumbComponent';
import CustomTypography from '../../../CustomComponents/CustomTypography';
import FloatLabel from '../../../CustomComponents/FloatingLabel';
import { Alert, notification, Tabs, Tooltip } from 'antd';
import {
  getFacilityDetails,
  getEhrFields,
  getEhrList,
  updateFacilityConfig,
  resetDemoConfiguration,
  addFacilityConfig
} from '../../../../Apis/Facility';
import {
  ContainerRow,
  TitleRow,
  ProfileComponent
} from '../../styledComponents';
import {
  CustomRow,
  StyledCol,
  InfoButton,
  StyledDisableButton,
  StyledEnableButton,
  StyledTable,
  StyledDisableFacilityModalDiv
} from './styledComponents';
import { useSelector, useDispatch } from 'react-redux';
import { AddUser } from '../../../../Redux/Actions/User';
import { useHistory, useParams } from 'react-router-dom';
import EHRDetails from './EHRDetails';
import ConfirmationModal from '../../../CustomComponents/ConfirmationModal';
import DemoVideoElement from './DemoVideoElement';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import LinkoffIcon from '../../../../Assets/Icons/LinkoffIcon';
import LinkIcon from '../../../../Assets/Icons/LinkIcon';
import { getUserOnlyAssocautedWithFacilitie } from '../../../../Apis/User';
import { disableFacility, enableFacility } from '../../../../Apis/Admin';
import { getJobsByFacility } from '../../../../Apis/Jobs';
import { useDebounce } from '../../../../Utils/Debounce';
import {
  API_CALL_DELAY,
  DATE_TIME_FORMAT
} from '../../../../Constants/StringConstants';
import moment from 'moment';

const EhrConfiguration = () => {
  const [loading, setLoading] = useState(false);
  const [ehrDataLoading, setEhrDataLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [ehrList, setEhrList] = useState([]);
  const [selectedEhr, setSelectedEhr] = useState('');
  const [theme] = useSelector(state => [state.themeReducer]);
  const [ehrFieldData, setEhrFieldData] = useState(null);
  const [ehrFields, setEhrFields] = useState(null);
  const [edit, setEdit] = useState(false);
  const [clinicConfigured, setClinicConfigured] = useState(false);
  const [videoModalOpen, setVideoModalOpen] = useState();
  const [transcriptionFlow, setTranscriptionFlow] = useState(null);
  const [resetModal, setResetModal] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [disableModalUserTableLoading, setDisableModalUserTableLoading] =
    useState(false);
  const [disableModalJobTableLoading, setDisableModalJobTableLoading] =
    useState(false);
  const [facilityName, setFacilityName] = useState('');
  const [
    rangeForDischargedPatientsDetails,

    setRangeForDischargedPatientsDetails
  ] = useState('30');
  const [facilityDisabled, setFacilityDisabled] = useState(null);
  const [saveDisabled, setSaveDisabled] = useState(null);
  const [facilityDisableModalVisible, setFacilityDisableModalVisible] =
    useState(false);
  const [userAssociatedOnlyWithFacility, setUserAssociatedOnlyWithFacility] =
    useState(null);
  const [pendingJobsWithFacility, setPendingJobsWithFacility] = useState(null);
  const [
    showRangeForDischargedPatientsEhrType,

    setShowRangeForDischargedPatientsEhrType
  ] = useState(false);

  const [showRangeForDischargedPatients, setShowRangeForDischargedPatients] =
    useState(false);
  const user = JSON.parse(localStorage.getItem('user'));
  const { facilityId } = useParams();

  const history = useHistory();
  const dispatch = useDispatch();

  let BreadCrubItems = [
    {
      name: 'Settings',
      path: '/dashboard/settings'
    },
    {
      name: 'Facility',
      path: '/dashboard/settings/Facility'
    },
    {
      name: 'EHR Configuration'
    }
  ];

  useEffect(() => {
    getEhrAndClinicList();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {}, [
    showRangeForDischargedPatientsEhrType,
    showRangeForDischargedPatients
  ]);
  useEffect(() => {
    if (ehrFieldData) {
      setShowRangeForDischargedPatients(ehrFieldData.allPatientsRequired);

      if (ehrFieldData.ehrType) {
        setShowRangeForDischargedPatientsEhrType(
          ehrFieldData.ehrType === 'pointHealth-emr'
        );
      }

      if (ehrFieldData.rangeForDischargedPatients) {
        setRangeForDischargedPatientsDetails(
          ehrFieldData.rangeForDischargedPatients.toString()
        );
      }
    }
  }, [ehrFieldData]);

  useEffect(() => {
    if (facilityId === 'new') {
      setClinicConfigured(false);
      setEdit(true);
    } else {
      setClinicConfigured(true);
    }
    // eslint-disable-next-line
  }, []);

  const getEhrAndClinicList = () => {
    setLoading(true);
    if (user && ['admin', 'clinicAdmin'].includes(user.userType)) {
      getEhrList()
        .then(response => {
          if (response.data.success) {
            let data = response.data.result.map(field => {
              return {
                name: field.ehrName,
                value: field.ehrType
              };
            });
            setEhrList(data);
          } else {
            notification['error']({
              message: 'Failed',
              description: response.data.message
            });
            setLoading(false);
          }
        })
        .catch(err => {
          console.log(err);
          setLoading(false);
        });

      if (facilityId === 'new') {
        setLoading(false);
      } else {
        getFacilityDetails(facilityId)
          .then(response => {
            if (response.data.success) {
              setFacilityName(response.data.result[0].facilityName);
              setFacilityDisabled(response.data.result[0].disabled);
              const data = response.data.result[0].ehrConfiguration;
              setSelectedEhr(data.ehrType);
              setTranscriptionFlow(data.transcriptionFlow);
              if (data.ehrType.length > 0)
                getEhrFieldDetails(data.ehrType, data);
              setEhrFieldData(data);
              setLoading(false);
            } else {
              notification['error']({
                message: 'Failed',
                description: response.data.message
              });
              setLoading(false);
            }
          })
          .catch(err => {
            console.log(err);
            setLoading(false);
          });
      }
    } else {
      history.push('/dashboard');
    }
  };

  const getEhrFieldDetails = (type, clinicInfo) => {
    setEhrDataLoading(true);
    getEhrFields(facilityId, type)
      .then(response => {
        if (response.data.success) {
          const data = response.data.result;
          if (type === 'pointHealth-emr') {
            let maskedData = {};
            data.forEach(item => {
              if (item.fieldType === 'maskedText') {
                maskedData[item.field] = window.atob(item.value);
              }
            });

            setEhrFieldData({
              ...clinicInfo,
              ...maskedData,
              transcriptionFlow: transcriptionFlow
                ? transcriptionFlow
                : user.transcriptionFlow
            });
          } else if (type === 'open-emr') {
            let ehrBaseUrl = null;
            let ehrUserName = null;
            let ehrPassword = null;
            if (clinicInfo?.ehrBaseUrl) {
              ehrBaseUrl = clinicInfo.ehrBaseUrl;
              ehrUserName = window.atob(clinicInfo.ehrUserName);
              ehrPassword = window.atob(clinicInfo.ehrPassword);
              delete clinicInfo.ehrBaseUrl;
              delete clinicInfo.ehrUserName;
              delete clinicInfo.ehrPassword;
            }
            setEhrFieldData({
              ...clinicInfo,
              ehrBaseUrl: ehrBaseUrl,
              ehrUserName: ehrUserName,
              ehrPassword: ehrPassword,
              transcriptionFlow: transcriptionFlow
                ? transcriptionFlow
                : user.transcriptionFlow
            });
          } else {
            let ehrBaseUrl = null;
            let ehrUserName = null;
            let ehrPassword = null;
            data.forEach(item => {
              if (item.field === 'ehrBaseUrl') {
                ehrBaseUrl = item.value;
              } else if (
                item.field === 'ehrUserName' &&
                item.value.length > 0
              ) {
                ehrUserName = window.atob(item.value);
              } else if (
                item.field === 'ehrPassword' &&
                item.value.length > 0
              ) {
                ehrPassword = window.atob(item.value);
              } else {
                return;
              }
            });
            setEhrFieldData({
              ...clinicInfo,
              ehrBaseUrl: ehrBaseUrl,
              ehrUserName: ehrUserName,
              ehrPassword: ehrPassword,
              transcriptionFlow: transcriptionFlow
                ? transcriptionFlow
                : user.transcriptionFlow
            });
          }

          setEhrFields(data);
          setEhrDataLoading(false);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
          setEhrDataLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setEhrDataLoading(false);
      });
  };

  const ehrSelectHanlder = ({ changedValue }) => {
    setShowRangeForDischargedPatientsEhrType(
      changedValue === 'pointHealth-emr'
    );
    setSaveDisabled(false);
    const ehr = ehrList.find(item => item.value === changedValue);
    setSelectedEhr(ehr.value);
    getEhrFieldDetails(ehr.value);
  };

  const facilityNameChangeHandler = e => {
    setFacilityName(e.target.value);
    setSaveDisabled(selectedEhr !== '' && edit ? false : saveDisabled);
  };

  const onFormSubmit = values => {
    values.rangeForDischargedPatients = parseInt(
      rangeForDischargedPatientsDetails
    );
    if (values.transcriptionFlow.changedValue == null) {
      notification['error']({
        message: 'Please select Transcription Flow'
      });
      return;
    }
    setSaveDisabled(true);
    if (facilityName.length > 0) {
      setUpdateLoading(true);

      ehrFields.forEach(item => {
        if (item.fieldType === 'maskedText') {
          values[item.field] = window.btoa(values[item.field]);
        }
      });

      if (values.noteType) {
        let noteTypes =
          ehrFields &&
          ehrFields.find(field => field.field === 'noteType').value;
        let selectedNoteType = noteTypes.find(
          item =>
            item.value === values.noteType.changedValue ||
            item.name === values.noteType.changedValue
        );
        values.noteType = selectedNoteType.value;
      }

      const payload = {
        ehrConfiguration: {
          ehrType: selectedEhr,
          ...values,
          transcriptionFlow: values.transcriptionFlow.changedValue,
          reviewerCount: parseInt(values.reviewerCount.changedValue),
          allPatientsRequired:
            values.allPatientsRequired.changedValue === 'Yes' ? true : false,
          hl7Integration: selectedEhr === 'hl7-integration'
        },
        facilityName: facilityName
      };

      if (facilityId !== 'new') {
        payload.ehrConfigured = true;
        payload.id = facilityId;
        notification['success']({
          message: 'Updated',
          description: 'Facility updated successfully'
        });
      }

      (facilityId === 'new'
        ? addFacilityConfig(payload)
        : updateFacilityConfig(payload)
      )
        .then(response => {
          if (response.data.success) {
            if (facilityId === 'new') {
              notification['success']({
                message: 'Added',
                description: 'Facility  added successfully'
              });
            }

            setUpdateLoading(false);
            setClinicConfigured(true);
            setEdit(false);
            if (
              response.data.result[0].ehrConfiguration.transcriptionFlow ===
              'TRANSCRIPTION_REVIEWER'
            ) {
              user.transcriptionFlow = 'TRANSCRIPTION_REVIEWER';
            } else {
              user.transcriptionFlow = 'TRANSCRIPTION_ONLY';
            }
            user.clinicConfigured = true;
            user.hl7Integration =
              response.data.result[0].ehrConfiguration.hl7Integration;
            user.emrHandlingData = response.data.emrHandlingData;
            dispatch(AddUser(user));
            localStorage.setItem('user', JSON.stringify(user));
            history.push('/dashboard/settings/facility');
          } else {
            setSaveDisabled(false);
            notification['error']({
              message: 'Failed',
              description: response.data.message
            });
            setUpdateLoading(false);
          }
        })
        .catch(err => {
          console.log(err);
          setUpdateLoading(false);
        });
    } else {
      notification['error']({
        message: 'Facility Name required'
      });
      setUpdateLoading(false);
    }
  };

  const resetDemoEmr = () => {
    setResetLoading(true);
    resetDemoConfiguration()
      .then(response => {
        if (response.data.success) {
          user.clinicConfigured = false;
          dispatch(AddUser(user));
          localStorage.setItem('user', JSON.stringify(user));
          setResetLoading(false);
          setResetModal(false);
          setEhrFields(null);
          setClinicConfigured(false);
          setEdit(true);
          getEhrAndClinicList();
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
          setResetLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setResetLoading(false);
      });
  };

  // Handle enable facility
  const handleEnableFacility = () => {
    enableFacility(facilityId)
      .then(response => {
        if (response.data.success) {
          notification['success']({
            message: 'Facility enable',
            description: response.data.message
          });
          setFacilityDisabled(false);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const debounceEnableFacility = useCallback(
    useDebounce(handleEnableFacility, API_CALL_DELAY)
  );
  // Handle disable facility
  const handleDisableFacility = () => {
    setDisableModalJobTableLoading(true);
    setDisableModalUserTableLoading(true);
    setFacilityDisableModalVisible(true);
    getJobsByFacility(facilityId, ['Available', 'Ready', 'InProgress'])
      .then(response => {
        if (response.data.success) {
          setPendingJobsWithFacility(response.data.result);
          setDisableModalJobTableLoading(false);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
      })
      .catch(error => {
        console.log(error);
      });

    getUserOnlyAssocautedWithFacilitie(facilityId)
      .then(response => {
        if (response.data.success) {
          setUserAssociatedOnlyWithFacility(response.data.result);
          setDisableModalUserTableLoading(false);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
          setDisableModalUserTableLoading(false);
          setFacilityDisableModalVisible(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleDisableFacilityConfirm = () => {
    disableFacility(facilityId)
      .then(response => {
        if (response.data.success) {
          notification['success']({
            message: 'Facility disabled',
            description: response.data.message
          });
          setFacilityDisabled(true);
          setFacilityDisableModalVisible(false);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const debounceDisableFacilityConfirm = useCallback(
    useDebounce(handleDisableFacilityConfirm, API_CALL_DELAY)
  );

  return (
    <>
      <PageTitle title='EHR Configuration' />
      <ContainerRow>
        <TitleRow span={24}>
          <CustomTypography color={theme['@text-color']}>
            EHR Configuration
          </CustomTypography>
          <Tooltip title='Configuration Demo'>
            <InfoButton
              icon={<ExclamationCircleOutlined />}
              onClick={() => {
                setVideoModalOpen(true);
              }}
            />
          </Tooltip>
        </TitleRow>
        <BreadCrumbComponent items={BreadCrubItems} />

        {loading ? (
          <Spinner />
        ) : (
          <>
            <CustomRow align='end'>
              {user.userType === 'clinicAdmin' && facilityDisabled !== null ? (
                facilityDisabled ? (
                  <StyledEnableButton
                    type='primary'
                    icon={<LinkIcon />}
                    onClick={debounceEnableFacility}
                  >
                    Enable
                  </StyledEnableButton>
                ) : (
                  <StyledDisableButton
                    type='primary'
                    danger
                    icon={<LinkoffIcon />}
                    onClick={handleDisableFacility}
                  >
                    Disable
                  </StyledDisableButton>
                )
              ) : null}
            </CustomRow>
            <ProfileComponent>
              <CustomRow gutter={[36, 36]}>
                <StyledCol span={12}>
                  <FloatLabel
                    label='EHR Type'
                    primaryColor={theme['@text-color']}
                    type='select'
                    meta={ehrList}
                    onChange={ehrSelectHanlder}
                    value={{ changedValue: selectedEhr }}
                    disabled={clinicConfigured ? true : edit ? false : true}
                  />
                </StyledCol>
                <StyledCol span={12}>
                  <FloatLabel
                    label='Facility Name'
                    primaryColor={theme['@text-color']}
                    type='input'
                    onChange={facilityNameChangeHandler}
                    value={facilityName}
                    disabled={edit ? false : true}
                  />
                </StyledCol>
              </CustomRow>
              <EHRDetails
                theme={theme}
                ehrFields={ehrFields}
                onFormSubmit={onFormSubmit}
                buttonLoading={updateLoading}
                ehrFieldData={ehrFieldData}
                loading={ehrDataLoading}
                edit={edit}
                setEdit={setEdit}
                selectedEhr={selectedEhr}
                clinicConfigured={clinicConfigured}
                transcriptionFlow={transcriptionFlow}
                setTranscriptionFlow={setTranscriptionFlow}
                setResetModal={setResetModal}
                resetLoading={resetLoading}
                ehrSelectHanlder={ehrSelectHanlder}
                ehrList={ehrList}
                setFacilityName={setFacilityName}
                facilityName={facilityName}
                saveDisabled={saveDisabled}
                setSaveDisabled={setSaveDisabled}
                showRangeForDischargedPatientsEhrType={
                  showRangeForDischargedPatientsEhrType
                }
                showRangeForDischargedPatients={showRangeForDischargedPatients}
                setShowRangeForDischargedPatients={
                  setShowRangeForDischargedPatients
                }
                rangeForDischargedPatientsDetails={
                  rangeForDischargedPatientsDetails
                }
                setRangeForDischargedPatientsDetails={
                  setRangeForDischargedPatientsDetails
                }
              />
            </ProfileComponent>
          </>
        )}
      </ContainerRow>
      <ConfirmationModal
        antdModalProps={{ centered: true, width: '1000px', closable: false }}
        visible={videoModalOpen}
        onCancel={() => {
          setVideoModalOpen(false);
        }}
        header={
          <CustomTypography color={theme['@text-color']}>
            Configuration Demo
          </CustomTypography>
        }
        video={true}
      >
        <DemoVideoElement />
      </ConfirmationModal>
      <ConfirmationModal
        antdModalProps={{ centered: true, width: '300', closable: false }}
        visible={resetModal}
        onCancel={() => {
          setResetModal(false);
        }}
        onOk={() => resetDemoEmr()}
        header={
          <CustomTypography color={theme['@text-color']}>
            Reset Demo-EMR
          </CustomTypography>
        }
        confirmation={true}
        loading={resetLoading}
      >
        Demo EMR once reset cannot be set again .<br /> Are you sure you want to
        continue?
      </ConfirmationModal>
      <ConfirmationModal
        antdModalProps={{ centered: true, width: '500', closable: false }}
        visible={facilityDisableModalVisible}
        onCancel={() => {
          setFacilityDisableModalVisible(false);
        }}
        onOk={() => {
          debounceDisableFacilityConfirm();
        }}
        header={
          <CustomTypography color={theme['@text-color']}>
            Are you sure?
          </CustomTypography>
        }
        confirmation={true}
        buttonAlignCenter={true}
        buttonDisabled={
          disableModalJobTableLoading || disableModalUserTableLoading
        }
      >
        <StyledDisableFacilityModalDiv>
          <h1>{`Do you really want to disable ${facilityName}?`}</h1>

          {disableModalUserTableLoading && disableModalJobTableLoading ? (
            <Spinner height={100} />
          ) : (
            <>
              <Tabs defaultActiveKey='users'>
                {userAssociatedOnlyWithFacility?.length > 0 ? (
                  <>
                    <Tabs.TabPane tab='Users' key='users'>
                      <Alert
                        message={<b>Warning</b>}
                        description='The following users are only associated with this facility.'
                        type='warning'
                        showIcon
                        closable={false}
                      />
                      <StyledTable
                        scroll={{ x: 400, y: 300 }}
                        columns={[
                          {
                            title: () => {
                              return <b>Name</b>;
                            },
                            dataIndex: 'fullName',
                            key: 'fullName'
                          },
                          {
                            title: () => {
                              return <b>Email</b>;
                            },
                            dataIndex: 'email',
                            key: 'email'
                          }
                        ]}
                        dataSource={userAssociatedOnlyWithFacility}
                        pagination={false}
                        loading={disableModalUserTableLoading}
                      />
                    </Tabs.TabPane>
                  </>
                ) : null}
                {pendingJobsWithFacility?.length > 0 ? (
                  <>
                    <Tabs.TabPane tab='Jobs' key='jobs'>
                      <Alert
                        message={<b>Warning</b>}
                        description='The following jobs are associated with this facility.'
                        type='warning'
                        showIcon
                        closable={false}
                      />
                      <StyledTable
                        scroll={{ x: 400, y: 300 }}
                        columns={[
                          {
                            title: () => {
                              return <b>Job ID</b>;
                            },
                            dataIndex: 'jobNumber',
                            key: 'jobNumber'
                          },
                          {
                            title: () => {
                              return <b>Submitted Time</b>;
                            },
                            dataIndex: 'submissionTime',
                            key: 'submissionTime',
                            render: loginTime => {
                              return (
                                <div>
                                  {moment(loginTime).format(DATE_TIME_FORMAT)}
                                </div>
                              );
                            }
                          },
                          {
                            title: () => {
                              return <b>Author</b>;
                            },
                            dataIndex: 'author',
                            key: 'author'
                          },
                          {
                            title: () => {
                              return <b>Status</b>;
                            },
                            dataIndex: 'status',
                            key: 'status'
                          }
                        ]}
                        dataSource={pendingJobsWithFacility}
                        pagination={false}
                        loading={disableModalJobTableLoading}
                      />
                    </Tabs.TabPane>
                  </>
                ) : null}
              </Tabs>
            </>
          )}
        </StyledDisableFacilityModalDiv>
      </ConfirmationModal>
    </>
  );
};

export default EhrConfiguration;
