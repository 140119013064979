import React from 'react';

function DeleteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="66"
      height="66"
      fill="none"
      viewBox="0 0 66 66"
    >
      <path
        fill="#EE9D9D"
        d="M33 66c18.225 0 33-14.775 33-33S51.225 0 33 0 0 14.775 0 33s14.775 33 33 33z"
      ></path>
      <path
        fill="#fff"
        d="M46.488 18.02h-6.901v-1.256A3.764 3.764 0 0035.822 13h-5.02a3.764 3.764 0 00-3.764 3.764v1.255h-6.901A3.137 3.137 0 0017 21.156v2.51c0 .693.562 1.255 1.255 1.255H48.37c.693 0 1.255-.562 1.255-1.255v-2.51a3.137 3.137 0 00-3.137-3.137zm-16.94-1.256c0-.691.563-1.254 1.255-1.254h5.02c.69 0 1.254.563 1.254 1.254v1.255h-7.529v-1.255zM19.381 27.43a.392.392 0 00-.391.411l1.035 21.727a3.76 3.76 0 003.76 3.586H42.84a3.76 3.76 0 003.76-3.586l1.035-21.727a.392.392 0 00-.391-.41H19.38zm18.95 3.137a1.254 1.254 0 112.51 0V46.88a1.254 1.254 0 11-2.51 0V30.567zm-6.273 0a1.255 1.255 0 112.51 0V46.88a1.254 1.254 0 11-2.51 0V30.567zm-6.274 0a1.254 1.254 0 112.51 0V46.88a1.254 1.254 0 11-2.51 0V30.567z"
      ></path>
    </svg>
  );
}

export default DeleteIcon;
