import React from 'react';
import {
  FacilityCardWrapper,
  FacilityCardHeader,
  FacilityInfo,
  FacilityName
} from './styledComponents';
import HeartIcon from '../../../Assets/Icons/HeartIcon';
import { useHistory } from 'react-router-dom';
import { Popover } from 'antd';

function FacilityCard({ facility }) {
  const history = useHistory();
  const gotToDetailedView = id => {
    history.push(`/dashboard/facility/${id}`);
  };

  return (
    <FacilityCardWrapper disabled = {facility.disabled} onClick={() => gotToDetailedView(facility.facilityId)}>
      <FacilityCardHeader>
        <div>
          <HeartIcon />
        </div>{' '}
        <Popover placement='bottom' content={facility.facilityName}>
          <FacilityName>{facility.facilityName}</FacilityName>
        </Popover>
      </FacilityCardHeader>
      {facility.fields.map(field => (
        <FacilityInfo key={field.fieldName}>
          <span>{field.fieldName}</span>
          <span title={field.fieldValue}>{field.fieldValue}</span>
        </FacilityInfo>
      ))}
    </FacilityCardWrapper>
  );
}

export default FacilityCard;
