import styled from 'styled-components';
import { Select } from 'antd';

export const StyledFacilityContainer = styled.div`
  background-color: transparent;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
`;

export const StyledFacilityBlocks = styled.div`
  background-color: ${({ disabled }) => (disabled ? '#c0c0c0' : '#47a4ea')};
  border-radius: 14.3301px;
  color: #ffffff;
  width: 62.98px;
  height: 18.8px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  font-weight: 500;
  margin-right: 2.02px;
`;

export const FacilityRemainingCount = styled.div`
  width: 18.8px;
  height: 18.8px;
  border-radius: 14.33px;
  background-color: #47a4ea;
  color: #ffffff;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 9px;
  font-weight: 900;
`;
export const StyledSelectFacility = styled(Select)`
  width: 100%;

  .ant-select-selection-item {
    color: #ffffff;
    background: #47a4ea !important;
    border: 1px solid #47a4ea !important;
    border-radius: 14.33px !important;
    padding: 0 10px 0 10px;
    font-size: 14px;
    font-weight: 500;
    max-width: 90px;
  }
  .ant-select-selection-item-remove {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 5px 0 5px;
    & svg path {
      color: white !important;
    }
  }
  .ant-select-selection-item-label {
    text-overflow: ellipsis;
    overflow: hidden;
    height: 100%;
  }
  .ant-select-selection-item-disabe {
    background: #c0c0c0 !important;
    border: 1px solid #c0c0c0 !important;
  }
  .disabled-facility-tag {
    background: #c0c0c0 !important;
    border: 1px solid #c0c0c0 !important;
  }
  .disabled-facility-option {
    display: none;
  }
`;

export const StyledSpan = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 5px;
`;
