import React from 'react';

function AlertIcon({width,height}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      enableBackground="new 0 0 512 512"
      viewBox="0 0 512 512"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        fill="#ffd366"
        d="M512 256c0 68.11-26.441 132.21-74.45 180.5-47.98 48.25-111.85 75.059-179.87 75.488-.168 0-.352.012-.52 0-.379.012-.77.012-1.16.012-68.371 0-132.66-26.621-181.012-74.98C26.628 388.672 0 324.379 0 256S26.629 123.34 74.988 74.98C123.34 26.63 187.628 0 256 0c.39 0 .77 0 1.16.012.18 0 .36 0 .54.008 68.01.44 131.878 27.242 179.85 75.492C485.56 123.8 512 187.898 512 256zm0 0"
        data-original="#ff771a"
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        fill="#ffd366"
        d="M512 256c0 68.11-26.441 132.21-74.45 180.5-47.98 48.25-111.85 75.059-179.87 75.488-.168 0-.352.012-.52 0V.012c.18 0 .36 0 .54.008 68.01.44 131.878 27.242 179.85 75.492C485.56 123.8 512 187.898 512 256zm0 0"
        data-original="#ff4a05"
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M257.266 107.805c.418-.008-.364 0 0 0zm0 0"
        data-original="#000000"
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        fill="#fff"
        d="M299.922 150.46v114.438c0 23.524-19.133 42.66-42.652 42.66h-.11c-10.988-.32-22.332-4.757-30.058-12.488-8.051-8.058-12.493-18.77-12.493-30.172V150.461c0-23.262 19.313-41.992 42.551-42.66h.11c23.52 0 42.652 19.14 42.652 42.66zm0 0"
        data-original="#ffffff"
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M257.266 307.563c-.364 0 .363.007 0 0zm0 0"
        data-original="#000000"
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        fill="#fff"
        d="M297.64 361.64c0 22.27-18.109 40.38-40.378 40.38h-.102c-22.21-.051-40.27-18.141-40.27-40.38 0-22.23 18.06-40.32 40.27-40.37h.102c22.27 0 40.379 18.109 40.379 40.37zm0 0"
        data-original="#ffffff"
      ></path>
      <g xmlns="http://www.w3.org/2000/svg" fill="#fff">
        <path
          d="M297.64 361.64c0 22.27-18.109 40.38-40.378 40.38h-.102v-80.75h.102c22.27 0 40.379 18.109 40.379 40.37zm0 0M299.922 150.46v114.438c0 23.524-19.133 42.66-42.652 42.66h-.11V107.802h.11c23.52 0 42.652 19.14 42.652 42.66zm0 0"
          data-original="#ddebf0"
        ></path>
      </g>
    </svg>
  );
}

export default AlertIcon;
