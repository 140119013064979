import styled from 'styled-components';
import { Typography } from 'antd';
export const StyledText = styled(Typography.Text)`
  font-weight: 800;
  line-height: 30px;
  font-size: ${props => (props.hl7 ? '18px' : '24px')};
  color: ${props => props.color} !important;
  display: ${props => props.hl7 && 'flex'};

  @media (max-width: 1368px) {
    font-size: 22px;
    line-height: 25px;
  }

  @media (max-width: 1368px) {
    font-size: 22px;
    line-height: 25px;
  }
`;
