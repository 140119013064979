import axiosInstance from '../Config/AxiosInstance';
const AUTH_PREFIX = '/authenticate';

export const loginAPI = data => {
  return axiosInstance.post(AUTH_PREFIX, data);
};

export const logoutAPI = sessionId => {
  return axiosInstance.get(AUTH_PREFIX + `/logout?session-id=${sessionId}`);
};

export const forgotPassword = email => {
  return axiosInstance.post(AUTH_PREFIX + `/forgot-password?email=${email}`);
};

export const resetPassword = data => {
  return axiosInstance.post(AUTH_PREFIX + '/reset-password', data);
};

export const signUp = data => {
  return axiosInstance.post(AUTH_PREFIX + '/sign-up', data);
};

export const verifyOtp = data => {
  return axiosInstance.post(AUTH_PREFIX + '/sign-up/verify', data);
};

export const authenticateEmrUser = (clinicId, userId, code, state) => {
  return axiosInstance.post(
    AUTH_PREFIX +
      `/emr/${clinicId}/user/${userId}/verify-emr?code=${code}&state=${state}`
  );
};
