import React from 'react';
import { Link } from 'react-router-dom';
import {
  MenuDiv,
  StyledMenu,
  MenuItemFlex,
  StyledMenuItem
} from '../JobList/styledComponents';

const SideMenu = ({
  activeKey,
  theme,
  sideBarItems,
  setActiveKey,
  setSelected,
  setFileExportList,
  setListSelection
}) => {
  const onSelect = param => {
    setActiveKey(param.key);
    setSelected([]);
    setListSelection(false);
    setFileExportList([]);
  };
  return (
    <MenuDiv>
      <StyledMenu
        mode='inline'
        defaultSelectedKeys={activeKey}
        selectedKeys={[activeKey]}
        theme='light'
        color={theme['@primary-color']}
        textColor={theme['@text-color']}
        onSelect={onSelect}
        key={activeKey}
      >
        {sideBarItems &&
          sideBarItems.map(config => {
            return (
              <StyledMenuItem key={config.key} color={theme['@primary-color']}>
                <Link to={config.path} onClick={() => {}}>
                  <MenuItemFlex>
                    <div>{config.name}</div>
                    <div>{config.count}</div>
                  </MenuItemFlex>
                </Link>
              </StyledMenuItem>
            );
          })}
      </StyledMenu>
    </MenuDiv>
  );
};

export default SideMenu;
