import { Tooltip, Form } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import DrugIcon from '../../../../Assets/Icons/DrugIcon';
import ConfirmationModal from '../../../CustomComponents/ConfirmationModal';
import CustomTypography from '../../../CustomComponents/CustomTypography';
import CustomDynamicTable from '../../../CustomComponents/DynamicTable';
import { AddRowButtonStyle } from '../../../CustomComponents/DynamicTable/styledComponents';
import { PlusCircleOutlined } from '@ant-design/icons';
import { nanoid } from 'nanoid';

import {
  DemographicsItem,
  EditTextButton,
  ItemTitle,
  ItemTitleDiv,
  ItemValueDiv
} from '../styledComponents';
import InferenceElement from './InferenceElement';
import {
  DrugButtonStyle,
  DynamicTableDivStyle,
  TableHeaderMandatoryStyle,
  StyledAsterisk,
  ModalHeaderFlex,
  InferenceValuesContainer
} from './styledComponents';

const InferenceEditor = ({
  theme,
  inferences,
  updateInferenceData,
  setEditKey,
  updateInferenceFlag,
  previewData,
  updateInferenceLoading,
  setUpdateInferenceLoading,
  activeSection,
  fetchContents,
  viewOnly
}) => {
  const [drugModalOpen, setDrugModalOpen] = useState(false);
  const [dynamicTableData, setDynamicTableData] = useState(null);
  const [updatedDynamicTableData, setUpdatedDynamicTableData] = useState(null);
  const [tableKey, setTableKey] = useState(null);
  const [dynamicTableColumns, setDynamicTableColumns] = useState(null);
  const [inferenceEdited, setInferenceEdited] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [modalTheme] = useSelector(state => [state.themeReducer]);
  const [editingKey, setEditingKey] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [annotations, setAnnotations] = useState([]);
  const [dataChanged, setDataChanged] = useState(false);
  const [switchChange, setSwitchChange] = useState(false);

  const [form] = Form.useForm();

  const dynamicTableDisplayTrigger = item => {
    setAnnotations(item.annotations);
    setDynamicTableData(item.value);
    setTableKey(item.key);
    let newColumns = item.columns.map(col => {
      return {
        title: col.mandatory ? (
          <>
            <TableHeaderMandatoryStyle>
              <TableHeaderMandatoryStyle>
                {col.fieldUIName} <StyledAsterisk>*</StyledAsterisk>
              </TableHeaderMandatoryStyle>
              {/* <Popover
                content={
                  <>
                    <PopoverContent>
                      These fields are set to mandatory. If these fields are not
                      updated, default values will be set to this field.
                    </PopoverContent>
                  </>
                }
              >
                <PopoverGlobalStyle />
                <StyledWarningIcon />
              </Popover> */}
            </TableHeaderMandatoryStyle>
          </>
        ) : (
          col.fieldUIName
        ),
        dataIndex: col.fieldUIName,
        type: col.fieldUIElement,
        mandatory: col.mandatory,
        editable: true,
        fieldName: col.fieldUIName,
        possibleValues: col.possibleValues
      };
    });
    setInferenceEdited(item.edited);
    setDynamicTableColumns(newColumns);
    setDrugModalOpen(true);
  };

  const edit = record => {
    form.resetFields();
    form.setFieldsValue({
      ...record
    });
    setEditingKey(record.key);
    setIsEditing(true);
  };

  const handleAdd = () => {
    const newData = {
      key: nanoid()
    };
    setDataSource([...dataSource, newData]);
    edit(newData);
    setDataChanged(true);
  };

  const tableSubmitHandler = () => {
    const payloadData = updatedDynamicTableData.map(data => {
      delete data['index'];
      delete data['key'];
      return {
        ...data
      };
    });
    let inference = {
      key: tableKey,
      value: payloadData
    };
    updateInferenceData(inference);
    setDataChanged(false);
  };

  const editFlagHandler = key => {
    const inference = {
      key: key,
      edited: false
    };
    updateInferenceFlag(inference, true);
    setInferenceEdited(false);
    setSwitchChange(true);
  };

  const obtainInferenceValues = (item, val) => {
    let filteredColumn = val.filter(item => {
      return item.fieldUIShowList;
    });

    return filteredColumn.length === 0
      ? null
      : filteredColumn.length === 1
      ? item[filteredColumn[0]?.fieldUIName]
      : `${item[filteredColumn[0]?.fieldUIName]}` +
        '/' +
        `${item[filteredColumn[1]?.fieldUIName]}`;
  };

  return (
    <>
      {inferences.map(inference => {
        return (
          <DemographicsItem>
            {inference.type !== 'Dynamic Table' ? (
              <>
                <ItemTitleDiv>
                  <ItemTitle textColor={theme['@primary-color']}>
                    {inference.key}
                    {inference.mandatory && <StyledAsterisk>*</StyledAsterisk>}
                  </ItemTitle>
                  <EditTextButton
                    type='text'
                    textColor={theme['@text-color']}
                    onClick={() => {
                      setEditKey(null);
                    }}
                  >
                    Close
                  </EditTextButton>
                </ItemTitleDiv>
                <ItemValueDiv>
                  <InferenceElement
                    item={inference}
                    updateInferenceData={updateInferenceData}
                    updateInferenceLoading={updateInferenceLoading}
                    setUpdateInferenceLoading={setUpdateInferenceLoading}
                  />
                </ItemValueDiv>
              </>
            ) : (
              <>
                <DynamicTableDivStyle>
                  <div>
                    <ItemTitle textColor={theme['@primary-color']}>
                      {inference.key}
                    </ItemTitle>
                  </div>
                  <div>
                    <Tooltip title='Click to see table'>
                      <DrugButtonStyle
                        icon={<DrugIcon />}
                        shape='circle'
                        borderColor={theme['@primary-color']}
                        onClick={() => dynamicTableDisplayTrigger(inference)}
                        hidden = {viewOnly}
                      />
                    </Tooltip>
                  </div>
                </DynamicTableDivStyle>
                <InferenceValuesContainer>
                  {inference.value.map(item => {
                    return (
                      <span>
                        {obtainInferenceValues(item, inference.columns)}
                      </span>
                    );
                  })}
                </InferenceValuesContainer>
              </>
            )}
          </DemographicsItem>
        );
      })}
      <ConfirmationModal
        visible={drugModalOpen}
        onCancel={() => {
          setDrugModalOpen(false);
          setDataChanged(false);
          switchChange && fetchContents(activeSection);
          setSwitchChange(false);
        }}
        onOk={tableSubmitHandler}
        antdModalProps={{ centered: true, closable: false, width: '1000px' }}
        header={
          <ModalHeaderFlex>
            <>
              <div>
                <CustomTypography color={modalTheme['@text-color']}>
                  {inferences[0] ? inferences[0].key : ''}
                </CustomTypography>
              </div>
              <div>
                <>
                  {/* <>
                    <StyledSwitch
                      checkedChildren='Allow override'
                      unCheckedChildren='Allow override'
                      disabled={!inferenceEdited}
                      defaultChecked={!inferenceEdited}
                      onClick={() => editFlagHandler(tableKey, true)}
                    />
                  </> */}

                  <AddRowButtonStyle
                    onClick={handleAdd}
                    type='primary'
                    icon={<PlusCircleOutlined />}
                  >
                    Add Row
                  </AddRowButtonStyle>
                </>
              </div>
            </>
          </ModalHeaderFlex>
        }
        inference={true}
        buttonDisabled={isEditing}
        inferenceEdited={inferenceEdited}
        dataChanged={dataChanged}
      >
        <CustomDynamicTable
          values={dynamicTableData}
          setUpdatedDynamicTableData={setUpdatedDynamicTableData}
          columns={dynamicTableColumns}
          setIsEditing={setIsEditing}
          inferenceEdited={inferenceEdited}
          editFlagHandler={editFlagHandler}
          tableKey={tableKey}
          previewData={previewData}
          editingKey={editingKey}
          setEditingKey={setEditingKey}
          edit={edit}
          form={form}
          dataSource={dataSource}
          setDataSource={setDataSource}
          annotations={annotations}
          setDataChanged={setDataChanged}
        />
      </ConfirmationModal>
    </>
  );
};

export default InferenceEditor;
