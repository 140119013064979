import React from 'react';

const CardIcon = ({ size, color }) => {
  return (
    <span className="anticon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        width={size ? size : '37'}
        height={size ? size : '24'}
        viewBox="0 0 37 24"
      >
        <path
          fill={color ? color : '#05b3e2'}
          d="M4.54 19.92H5v.53a3.09 3.09 0 003.08 3.08h24.38a3 3 0 001.18-.24 3.13 3.13 0 001.67-1.66 3.22 3.22 0 00.23-1.18V6.93a3 3 0 00-.9-2.18 3.17 3.17 0 00-1-.66 3 3 0 00-1.18-.24H32v-.49A3.12 3.12 0 0028.91.25H4.54a3.11 3.11 0 00-3.08 3.11v13.51A3.13 3.13 0 001.69 18a3.13 3.13 0 001.67 1.7 3 3 0 001.18.22zm30.17.5a2.28 2.28 0 01-2.25 2.28H8.09a2.3 2.3 0 01-2.27-2.28v-9.16h28.89zm0-10H5.82V8.15h28.89zm-2.25-5.74a2.27 2.27 0 01.86.17 2.21 2.21 0 01.73.48 2.36 2.36 0 01.49.72 2.31 2.31 0 01.17.86v.41H5.82v-.41a2.25 2.25 0 012.27-2.23zM2.29 3.36a2.28 2.28 0 012.25-2.28h24.37a2.3 2.3 0 012.27 2.28v.49H8.07A3.07 3.07 0 005 6.93v12.16h-.46a2.27 2.27 0 01-.86-.16 2.37 2.37 0 01-.68-.48 2.4 2.4 0 01-.49-.73 2.26 2.26 0 01-.17-.85z"
        ></path>
        <path
          fill={color ? color : '#05b3e2'}
          d="M3.61 3.49A.41.41 0 004 3.08v-.3a.38.38 0 00-.12-.29.41.41 0 00-.29-.13.43.43 0 00-.3.13.38.38 0 00-.12.29v.3a.4.4 0 00.12.29.42.42 0 00.32.12zM3.61 10.21A.41.41 0 004 9.8V4.53a.41.41 0 00-.41-.41.42.42 0 00-.3.12.4.4 0 00-.12.29V9.8a.4.4 0 00.12.29.42.42 0 00.32.12zM25.86 20.2h4.62a2.22 2.22 0 100-4.43h-4.62a2.21 2.21 0 00-1.57.65 2.21 2.21 0 000 3.13 2.21 2.21 0 001.57.65zm0-3.6h4.62a1.38 1.38 0 011 .4 1.39 1.39 0 01.4 1 1.38 1.38 0 01-1.38 1.39h-4.64A1.4 1.4 0 0124.47 18a1.4 1.4 0 01.41-1 1.38 1.38 0 01.98-.4zM8.26 17.43h6.12a.4.4 0 00.29-.12.42.42 0 00.12-.3.41.41 0 00-.41-.41H8.26a.42.42 0 00-.3.12.4.4 0 00-.12.29.41.41 0 00.42.42zM16.24 17.43h.58a.4.4 0 00.29-.12.42.42 0 00.12-.3.41.41 0 00-.41-.41h-.58a.42.42 0 00-.3.12.4.4 0 00-.12.29.41.41 0 00.42.42zM8.26 19.44h.86a.43.43 0 00.29-.12.43.43 0 00.13-.3.41.41 0 00-.13-.29.38.38 0 00-.29-.12h-.86a.42.42 0 00-.3.12.4.4 0 00-.12.29.42.42 0 00.12.3.47.47 0 00.3.12zM12.81 18.61H11a.42.42 0 00-.3.12.4.4 0 00-.12.29.42.42 0 00.12.3.47.47 0 00.3.12h1.77a.45.45 0 00.29-.12.42.42 0 00.12-.3.41.41 0 00-.41-.41zM16.82 18.61h-2.15a.42.42 0 00-.3.12.4.4 0 00-.12.29.42.42 0 00.12.3.47.47 0 00.3.12h2.15a.45.45 0 00.29-.12.42.42 0 00.12-.3.41.41 0 00-.41-.41z"
        ></path>
      </svg>
    </span>
  );
};

export default CardIcon;
