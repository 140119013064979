import React from 'react';
import {
  CommentsWrapper,
  FileInfoItem,
  InfoType,
  InfoValue
} from './styledComponents';
import moment from 'moment';

const FileInfo = ({ selectedFile }) => {
  return (
    <>
      {console.log(selectedFile)}
      <CommentsWrapper isFolderView={true}>
        <FileInfoItem>
          <InfoType>Name </InfoType>
          <InfoValue>{selectedFile.fileName}</InfoValue>
        </FileInfoItem>
        <FileInfoItem>
          <InfoType>Size </InfoType>
          <InfoValue>{selectedFile.fileSize}</InfoValue>
        </FileInfoItem>
        <FileInfoItem>
          <InfoType>Type </InfoType>
          <InfoValue>{selectedFile.fileType}</InfoValue>
        </FileInfoItem>
        <FileInfoItem>
          <InfoType>No of times exported </InfoType>
          <InfoValue>{selectedFile.noOfTimesExported}</InfoValue>
        </FileInfoItem>
        <FileInfoItem>
          <InfoType>Uploaded by </InfoType>
          <InfoValue>{selectedFile.uploadedBy}</InfoValue>
        </FileInfoItem>
        <FileInfoItem>
          <InfoType>Uploaded time </InfoType>
          <InfoValue>
            {moment(selectedFile.uploadedTime).format('DD, MMM YYYY hh:mm')}
          </InfoValue>
        </FileInfoItem>
      </CommentsWrapper>
    </>
  );
};

export default FileInfo;
