import React from 'react';
import { Layout } from 'antd';
import { useState } from 'react';
import {
  StyledContent,
  StyledLogo,
  LogoContainer,
  StyledSider,
  StyledMicLogo
} from './styledComponents';
import SideMenu from './SideMenu';
import MainHeader from './Header';
import { useEffect } from 'react';
import { FetchProfilePicture } from '../../../Apis/User';
import { useDispatch } from 'react-redux';
import { AddUser } from '../../../Redux/Actions/User';
import { connectSocket } from '../../../Socket';
import PrivacyPolicyModal from '../../CustomComponents/PrivacyPolicyModal';

const LayOut = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const dispatch = useDispatch();

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const onCollapse = collapse => {
    setCollapsed(collapse);
  };

  useEffect(() => {
    connectSocket();

    const userState = JSON.parse(localStorage.getItem('user'));
    if (userState && userState.id) {
      FetchProfilePicture(userState.id).then(response => {
        if (response.data.success === true) {
          let userNew = userState;
          userNew.profilePic = response.data.result[0];
          dispatch(AddUser({ ...userNew }));
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <StyledSider
        trigger={null}
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        theme='light'
        width={250}
        collapsedWidth={100}
        breakpoint='xxl'
      >
        <LogoContainer collapsed={collapsed}>
          {collapsed ? <StyledMicLogo /> : <StyledLogo />}
        </LogoContainer>
        <SideMenu collapsed={collapsed} />
      </StyledSider>
      <Layout>
        <MainHeader toggle={toggle} setModalVisible={setModalVisible} />
        <StyledContent>{children}</StyledContent>
      </Layout>
      <PrivacyPolicyModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
    </Layout>
  );
};
export default LayOut;
