import React from "react";

function SubscriptionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <path
        fill="#6440FF"
        d="M1.216 12.162a.405.405 0 01-.246-.727l4.865-3.722a.405.405 0 01.492.644l-4.865 3.722a.4.4 0 01-.246.083z"
      ></path>
      <path
        fill="#6440FF"
        d="M28.784 29.19H1.216a.405.405 0 01-.405-.406V11.757a.405.405 0 11.81 0v16.622h26.757V11.757a.405.405 0 11.811 0v17.027a.405.405 0 01-.405.405z"
      ></path>
      <path
        fill="#6440FF"
        d="M15 21.815a6.132 6.132 0 01-3.905-1.397L.96 12.07a.405.405 0 11.516-.626l10.135 8.348a5.34 5.34 0 006.778 0l10.136-8.348a.406.406 0 01.515.626l-10.135 8.348c-1.1.905-2.48 1.399-3.905 1.397z"
      ></path>
      <path
        fill="#6440FF"
        d="M28.784 29.189a.406.406 0 01-.258-.093l-10.338-8.513a.403.403 0 01-.055-.571.406.406 0 01.571-.055l10.338 8.513a.406.406 0 01-.258.719zM1.216 29.189a.406.406 0 01-.258-.719l10.338-8.513a.406.406 0 11.516.626L1.474 29.096a.406.406 0 01-.258.093zM19.597 5.136a.405.405 0 01-.246-.084L15 1.726l-4.35 3.326a.406.406 0 01-.494-.644L14.755.894a.405.405 0 01.492 0l4.597 3.514a.405.405 0 01-.246.73v-.002zM28.784 12.162a.4.4 0 01-.246-.084l-4.865-3.721a.405.405 0 01.493-.644l4.864 3.722a.406.406 0 01-.246.727z"
      ></path>
      <path
        fill="#6440FF"
        d="M23.919 16.167a.405.405 0 01-.405-.405V5.136H6.487v10.626a.406.406 0 01-.811 0V4.73a.405.405 0 01.405-.406H23.92a.405.405 0 01.405.406v11.03a.406.406 0 01-.405.406z"
      ></path>
      <path
        fill="#6440FF"
        d="M20.574 8.92H9.426a.405.405 0 010-.812h11.148a.405.405 0 110 .811zM20.574 11.858H9.426a.405.405 0 110-.811h11.148a.406.406 0 110 .81zM20.574 14.797H9.426a.406.406 0 110-.81h11.148a.405.405 0 110 .81z"
      ></path>
    </svg>
  );
}

export default SubscriptionIcon;
