import React from 'react';

const TextHighlighter = ({ startIndex, endIndex, previewData }) => {
  return startIndex === -1 && endIndex === -1 ? (
    <span>{previewData}</span>
  ) : (
    <span>
      {previewData.substr(0, startIndex)}
      <mark> {previewData.substr(startIndex, endIndex - startIndex)} </mark>
      {previewData.substr(endIndex)}
    </span>
  );
};

export default TextHighlighter;
