import React from 'react';
import { Table } from './styledComponents.js';
import { Button, Tooltip } from 'antd';
import Spinner from '../Spinner/index.jsx';
import {
  LeftOutlined,
  RightOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined
} from '@ant-design/icons';
import { RESULTS_PER_PAGE } from '../../../Constants/StringConstants.js';

function CustomTable({
  theme,
  columns,
  dataSource,
  pageNoChangeHandler,
  loading,
  setPageNo,
  pageSize,
  total,
  pageNo,
  onChange,
  onRow,
  invite,
  jobList,
  user,
  hl7,
  rowSelection,
  payment,
  ready,
  hover,
  selectRow,
  rowKey
}) {
  const itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return (
        <>
          <Tooltip title='First Page'>
            <Button
              type='text'
              icon={<DoubleLeftOutlined />}
              onClick={e => {
                e.stopPropagation();
                setPageNo(0);
                !invite && !hl7 && pageNoChangeHandler(0);
              }}
            />
          </Tooltip>
          <Tooltip title='Previous Page'>
            <Button type='text' icon={<LeftOutlined />} />
          </Tooltip>
        </>
      );
    }
    if (type === 'next') {
      return (
        <>
          <Tooltip title='Next Page'>
            <Button type='text' icon={<RightOutlined />} />
          </Tooltip>
          <Tooltip title='Last Page'>
            <Button
              type='text'
              icon={<DoubleRightOutlined />}
              onClick={e => {
                e.stopPropagation();
                setPageNo(Math.floor(total / RESULTS_PER_PAGE));
                !invite &&
                  !hl7 &&
                  pageNoChangeHandler(Math.floor(total / RESULTS_PER_PAGE));
              }}
            />
          </Tooltip>
        </>
      );
    }
    return originalElement;
  };

  return !payment && !dataSource.length > 0 && loading ? (
    <Spinner />
  ) : (
    <Table
      headColor={theme['@table-head-color']}
      textColor={theme['@table-text-color']}
      columns={columns}
      dataSource={dataSource ? dataSource : []}
      loading={loading}
      invite={invite}
      jobList={jobList}
      user={user}
      scroll={dataSource.length > 0 && { x: 'max-content' }}
        pagination={total/pageSize <=1 ? false : {
          onChange: cur => {
          setPageNo(cur - 1);
          // if(columns[1].title === 'TAT')
          !invite && !hl7 && pageNoChangeHandler(cur - 1);
        },
        pageSize: pageSize,
        total: total,
        current: pageNo + 1,
        itemRender: itemRender,
        position: ['bottomLeft'],
        showSizeChanger: false
      }}
      onChange={onChange}
      onRow={onRow}
      rowSelection={
        hl7 || selectRow ? { type: 'checkbox', ...rowSelection } : false
      }
      payment={payment}
      ready={ready}
      hover={hover}
      rowKey={rowKey}
    />
  );
}

export default CustomTable;
