import styled from 'styled-components';
import { Form } from 'antd';
import PCCLogin from '../../../Assets/Images/PCCLogin.svg';
import PCCLoginHover from '../../../Assets/Images/PCCLoginHover.svg';
import PCCLoginFocus from '../../../Assets/Images/PCCLoginFocus.svg';

export const StyledText = styled.p`
  font-weight: bold;
  font-size: 20px;
  color: #525e6f;
`;

export const StyledForm = styled(Form)`
  width: 600px;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 140px);
  width: 100%;
`;

export const FormContainer = styled.div``;

export const PCCVerifyButton = styled.button`
  height: 50px;
  width: 244px;
  border: none;
  padding: 0;
  background: url(${PCCLogin});
  cursor: pointer;
  &:hover {
    background: url(${PCCLoginHover});
  }
  &:focus {
    background: url(${PCCLoginFocus});
  }
`;

export const VerifyButtonWrapper = styled.a`
  height: 50px;
  width: 244px;
  background: url(${PCCLogin});
`;
