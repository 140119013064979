import React from 'react'
import {
  DraggerStyle,
  DraggerUploadComponent,
  UploadStyledButton,
  WarningText
} from './styledComponents'
import { notification, Button } from 'antd'
import DragIcon from '../../../../Assets/Icons/DragIcon'
import PictureUploadIcon from '../../../../Assets/Icons/PictureUploadIcon'
import ImgCrop from 'antd-img-crop'

const DraggerComponent = ({
  setSelectedFile,
  saveProfilePicture,
  uploadLoading
}) => {
  const props = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    customRequest: () => {},

    beforeUpload: (file, fileList) => {
      if (
        file.type === 'image/png' ||
        ('image/jpeg' && file.type !== 'image/svg+xml')
      ) {
        var img = document.createElement('img')
        img.src = URL.createObjectURL(file)
        img.onload = function () {
          var width = img.width
          var height = img.height
          if (width > 150 && height > 150) {
            setSelectedFile(file)
            const formData = new FormData()
            formData.append('multipartFile', file)
            saveProfilePicture(formData)
          } else {
            notification['error']({
              message: 'Error',
              description: 'Minimum image size is 150px by 150px'
            })
          }
        }
      } else {
        notification['error']({
          message: 'Error',
          description: 'You can only upload images of .png and .jpeg format'
        })
      }
      return false
    }
  }

  return (
    <>
      <DraggerUploadComponent>
        <ImgCrop rotate>
          <UploadStyledButton {...props}>
            <Button icon={<PictureUploadIcon />} loading={uploadLoading} />
          </UploadStyledButton>
        </ImgCrop>
        <ImgCrop rotate>
          <DraggerStyle {...props}>
            <div>
              <p className='ant-upload-drag-icon'>
                <DragIcon />
              </p>
            </div>
            <div>
              <p className='ant-upload-text'>Drag & drop your image</p>
            </div>
          </DraggerStyle>
        </ImgCrop>
      </DraggerUploadComponent>
      <WarningText>Minimum Image Size is 150px by 150px</WarningText>
    </>
  )
}

export default DraggerComponent
