import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.core.css';

import './index.css';
import {
  FloatingMenuControl,
  HeadingActionsButton,
  HeadingActionsDiv
} from './styledComponents';
import { CaretDownOutlined, DeleteOutlined } from '@ant-design/icons';
import { FloatingMenu } from './FloatingMenu';
import { editSection } from '../../../Apis/Jobs';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from '../../../Utils/Debounce';
import { AUTO_SAVE_DELAY } from '../../../Constants/StringConstants';
import { nanoid } from 'nanoid';
import { setUserUnsavedStatus } from '../../../Redux/Actions/User';

// Regster custom heading
var BlockBlot = Quill.import('blots/block');
var InlineBlot = Quill.import('blots/inline');

class HeadingBlot extends BlockBlot {
  static create(data) {
    const node = super.create(data);
    if (!data) {
      return node;
    }
    // node.setAttribute('id', data.id);
    node.setAttribute('data-id', data.id);
    node.setAttribute('data-type', 'heading');

    node.setAttribute('class', `heading`);

    node.innerHTML = `<strong>${data.text || ''}</strong>`;
    return node;
  }
  static value(domNode) {
    const { id, text } = domNode.dataset;
    return { id, text };
  }
}
HeadingBlot.blotName = 'heading';
HeadingBlot.className = `heading`;
HeadingBlot.tagName = 'h1';
Quill.register({ 'formats/heading': HeadingBlot });

// Register custom paragraph
class paragraphBlot extends BlockBlot {
  static create(data) {
    const node = super.create(data);
    if (!data) {
      return node;
    }
    node.setAttribute('class', `paragraph`);
    node.setAttribute('data-id', data.id);
    node.setAttribute('data-type', 'paragraph');
    node.innerHTML = `${data.text}`;
    return node;
  }
  static value(domNode) {
    const { id, text } = domNode.dataset;
    return { id, text };
  }
}
paragraphBlot.blotName = 'paragraph';
paragraphBlot.className = 'paragraph';
paragraphBlot.tagName = 'p';
Quill.register({ 'formats/paragraph': paragraphBlot });

let strike = Quill.import('formats/strike');
strike.tagName = 'strike';
Quill.register(strike, true);

class dfnBlot extends InlineBlot {
  static create(value) {
    const node = super.create(value);
    node.innerText = value;
    return node;
  }
}
dfnBlot.blotName = 'dfn';
dfnBlot.className = 'dfn';
dfnBlot.tagName = 'dfn';
Quill.register({ 'formats/dfn': dfnBlot });

const QuillEditor = (
  {
    sections,
    headings,
    jobId,
    jobName,
    setSaveStatus,
    saveStatus,
    isHeading,
    setIsHeading,
    setLastUpdateTime,
    loading,
    setEditorLoading,
    deleteSectionHandler,
    activeSection,
    setActiveSection,
    changeStatus,
    setChangeStatus,
    actieSectionContent,
    setActiveSectionContent,
    viewOnly,
    localSectionIdMap
  },
  ref
) => {
  const editorRef = useRef(null);
  const editorContainerRef = useRef(null);
  const editorScrollContainerRef = useRef(null);
  const [headingFloatingMenuHidden, setHeadingFloatingMenuHidden] =
    useState(true);
  const [currentHeading, setCurrentHeading] = useState(null);
  const [currentSection, setCurrentSection] = useState(null);
  const user = useSelector(state => state.userReducer);
  const [changedSections, setChangeSections] = useState([]);
  const [editorTextChangeStatus, setEditorTextChangeStatus] = useState(false);
  const [editorData, setEditorData] = useState(
    sections?.map(data => {
      return {
        id: data.id,
        heading: data.heading,
        content: data.content
      };
    })
  );

  const dispatch = useDispatch();

  //Detect cursor position and handle editor change
  useEffect(() => {
    const currentEditor = editorRef.current.getEditor();

    currentEditor.on('selection-change', function (range, oldRange, source) {
      const sectionId = currentEditor
        ?.getLine(range?.index)[0]
        ?.domNode?.getAttribute('data-id');
      setCurrentSection({
        id: sectionId
      });
      if (sectionId) {
        setActiveSection(sectionId);
        setActiveSectionContent(
          editorData.find(section => section.id === sectionId)?.content[0]
        );
      }
      if (range) {
        if (
          currentEditor
            ?.getLine(range?.index)[0]
            ?.domNode?.getAttribute('data-type') === 'heading'
        ) {
          setCurrentHeading({
            position: currentEditor
              ?.getLine(range?.index)[0]
              ?.domNode?.children[0]?.getBoundingClientRect(),
            text: currentEditor?.getLine(range?.index)[0]?.domNode?.innerText,
            id: currentEditor
              ?.getLine(range?.index)[0]
              ?.domNode?.getAttribute('data-id')
          });
          setHeadingFloatingMenuHidden(true);
          setIsHeading(true);
          // Hide tooltip for heading
          editorContainerRef.current
            .querySelector('.ql-tooltip')
            .classList.add('ql-hidden');
        } else {
          setIsHeading(false);
          setCurrentHeading(null);
        }
      }
    });
    currentEditor.on('text-change', function () {
      setEditorTextChangeStatus(true);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set initial editor data
  useEffect(() => {
    setEditorLoading(true);
    const currentEditor = editorRef?.current?.getEditor();
    if (sections?.length > 0) {
      currentEditor.deleteText(0, currentEditor.getLength());

      sections.forEach((section, index) => {
        currentEditor.insertEmbed(
          currentEditor.getLength() - 1,
          'heading',
          {
            id: section?.id,
            text: section?.heading?.customUIHeading.toUpperCase()
          },
          'api'
        );
        // eslint-disable-next-line no-unused-expressions
        section?.content?.forEach(contentData => {
          const stringWithNewlines = contentData?.value.replace(
            /<br\s*\/?>/g,

            '\n'
          );
          // currentEditor.insertText(currentEditor.getLength() - 1, contentData.value, 'api');
          currentEditor.insertEmbed(
            currentEditor.getLength() - 1,
            'paragraph',
            {
              id: section?.id,
              text: stringWithNewlines
            },
            'api'
          );
          // // eslint-disable-next-line no-unused-expressions
          // contentData?.value?.split('\n').forEach((contentLine) => {
          //   currentEditor.insertText(currentEditor.getLength() - 1, contentLine, 'api');
          // })
        });
      });
      updateSectionId();
    }
    currentEditor.history.clear();
    setEditorLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections]);

  // Handle current selection class change
  useEffect(() => {
    if (currentSection) {
      const currentEditor = editorRef?.current?.getEditor();
      currentEditor.getLines().forEach(line => {
        if (line?.domNode?.getAttribute('data-id') === currentSection?.id) {
          line.domNode.classList.add('active-section');
        } else {
          line.domNode.classList.remove('active-section');
        }
      });
    }
  }, [currentSection]);

  useEffect(() => {
    saveEditor(editorData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorData]);

  useEffect(() => {
    if (editorTextChangeStatus) {
      handleTextChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorTextChangeStatus]);

  useEffect(() => {
    if (headingFloatingMenuHidden || !currentHeading) {
      editorScrollContainerRef.current.style.overflowY = 'scroll';
    } else {
      editorScrollContainerRef.current.style.overflowY = 'hidden';
    }
  }, [headingFloatingMenuHidden, currentHeading]);

  const handleTextChange = () => {
    if (!loading && changedSections.length !== 0 && !viewOnly) {
      // set unsaved status
      dispatch(setUserUnsavedStatus(true));
    }

    const currentEditor = editorRef?.current?.getEditor();

    currentSection && changedSections.push(currentSection?.id);
    updateSectionId();
    const lines = currentEditor.getLines();
    let i = 0;
    let currentId = '';
    let data = [];
    let currentNode = {};

    while (i < lines.length) {
      if (lines[i]?.domNode?.getAttribute('data-type') === 'heading') {
        if (lines[i]?.domNode?.getAttribute('data-id') === currentId) {
          currentNode.heading.customUIHeading += lines[i]?.domNode?.innerText;
        } else {
          // eslint-disable-next-line no-loop-func
          let matchedSystemHeading = headings?.find(
            heading =>
              heading?.customUIHeading.toUpperCase() ===
              currentNode.heading?.customUIHeading.trim().toUpperCase()
          );
          if (matchedSystemHeading) {
            currentNode.heading.systemHeading =
              matchedSystemHeading.systemHeading;
          }

          if (currentId !== '') {
            data.push(currentNode);
          }
          currentId = lines[i]?.domNode?.getAttribute('data-id');
          currentNode = {
            id: currentId,
            heading: {
              customUIHeading: lines[i]?.domNode?.innerText.toUpperCase(),
              systemHeading: 'NONE'
            },
            content: [
              {
                type: 'text',
                value: ''
              }
            ]
          };
        }
      } else {
        // eslint-disable-next-line no-unused-expressions
        // currentNode?.content?.push({
        //   type: 'text',
        //   value: lines[i].domNode.innerHTML
        // })
        if (currentNode && currentNode.content) {
          currentNode.content[0].value += lines[i].domNode.innerHTML;
          if (
            currentNode.content[0].value[
              currentNode.content[0].value.length - 1
            ] !== '\n'
          ) {
            currentNode.content[0].value += '\n';
          }
          currentNode.content[0].value = currentNode.content[0].value
            .replaceAll('<br>', '')
            .replaceAll('&nbsp;', '');
        }
      }
      i++;
    }
    // eslint-disable-next-line no-loop-func
    let matchedSystemHeading = headings?.find(
      heading =>
        heading?.customUIHeading.toUpperCase() ===
        currentNode.heading?.customUIHeading.trim().toUpperCase()
    );
    if (matchedSystemHeading) {
      currentNode.heading.systemHeading = matchedSystemHeading.systemHeading;
    }

    if (currentId !== '') {
      data.push(currentNode);
    }

    setEditorTextChangeStatus(false);
    setEditorData(data);
  };

  const updateSectionId = () => {
    const currentEditor = editorRef.current.getEditor();
    const lines = currentEditor.getLines();
    let currentId = '';
    let i = 0;
    while (i < lines.length) {
      if (lines[i]?.domNode?.getAttribute('data-type') === 'heading') {
        if (lines[i]?.domNode?.children.length === 0) {
          let headingText = lines[i]?.domNode?.innerText.toUpperCase();
          lines[i].domNode.innerHTML = `<strong>${headingText}</strong>`;
        }
        currentId = lines[i]?.domNode?.getAttribute('data-id');
        if (currentId === 'undefined') {
          currentId = `new-${nanoid()}`;
          lines[i].domNode.setAttribute('data-id', currentId);
        }
      } else if (lines[i]?.domNode?.getAttribute('data-type') === 'paragraph') {
        lines[i].domNode.setAttribute('data-id', currentId);
      } else if (lines[i]?.domNode?.nodeName === 'P') {
        lines[i].domNode.setAttribute('data-id', currentId);
        lines[i].domNode.setAttribute('data-type', 'paragraph');
        lines[i].domNode.setAttribute('class', 'paragraph');
      }
      i++;
    }
    currentEditor
      .getLine(currentEditor.getLength())[0]
      .domNode.setAttribute('data-id', currentId);
  };

  // Handling heading
  const headingHandler = () => {
    const currentEditor = editorRef.current.getEditor();
    let range = editorRef.current.getEditor().getSelection();
    const newId = `new-${nanoid()}`;

    let currentText = currentEditor
      ?.getText(range?.index, range?.length)
      .trim();
    if (range?.length === 0) {
      const line = currentEditor.getLine(range?.index)[0];
      const lineIndex = currentEditor.getIndex(line);
      currentText = currentEditor?.getText(lineIndex, line.cache.length).trim();
      currentEditor.deleteText(lineIndex, line.cache.length);
      if (isHeading) {
        currentEditor.insertEmbed(
          lineIndex,
          'paragraph',
          {
            id: newId,
            text: currentText
          },
          'user'
        );
        setCurrentHeading(null);
      } else {
        currentEditor.insertEmbed(
          lineIndex,
          'heading',
          {
            id: newId,
            text: currentText.toUpperCase()
          },
          'user'
        );
      }
    } else {
      if (isHeading) {
        if (
          currentEditor
            .getLine(range?.index + range?.index + 1)[0]
            ?.domNode?.getAttribute('data-type') === 'heading'
        ) {
          currentEditor.deleteText(range?.index, range?.length);
        } else {
          currentEditor.deleteText(range?.index, range?.length + 1);
        }
        currentEditor.insertEmbed(
          range?.index,
          'paragraph',
          {
            id: newId,
            text: currentText
          },
          'user'
        );
        setCurrentHeading(null);
      } else {
        currentEditor.deleteText(range?.index, range?.length);
        currentEditor.insertEmbed(
          range?.index,
          'heading',
          {
            id: newId,
            text: currentText.toUpperCase()
          },
          'user'
        );
      }
    }

    updateSectionId();

    setCurrentSection({ id: newId });
    setIsHeading(!isHeading);
  };

  // Heading change hadler
  const handleHeadingChange = item => {
    const currentEditor = editorRef.current.getEditor();
    let currentHeadingLines = currentEditor?.getLines()?.filter(line => {
      if (
        line?.domNode?.getAttribute('data-type') === 'heading' &&
        line?.domNode?.getAttribute('data-id') === currentHeading.id
      ) {
        return true;
      }
      return false;
    });

    if (currentHeading.length === 0) {
      currentHeadingLines = currentEditor?.getLines()?.filter(line => {
        if (
          line?.domNode?.getAttribute('data-type') === 'heading' &&
          line?.domNode?.getAttribute('data-id') ===
            localSectionIdMap.get(currentHeading.id)
        ) {
          return true;
        }
        return false;
      });
    }

    if (currentHeading.length === 0) {
      return;
    }
    try {
      let index = currentEditor?.getIndex(currentHeadingLines[0]);
      let offset = currentHeadingLines[0]?.cache?.length;
      currentEditor.deleteText(index, offset);
      currentEditor.insertEmbed(
        index,
        'heading',
        {
          id: currentHeading?.id,
          text: item?.customUIHeading.toUpperCase()
        },
        'user'
      );

      for (let i = 1; i < currentHeadingLines?.length; i++) {
        index = currentEditor.getIndex(currentHeadingLines[i]);
        offset = currentHeadingLines[i]?.cache?.length;
        currentEditor.deleteText(index, offset);
      }
    } catch (error) {
      console.log(error);
    }

    setHeadingFloatingMenuHidden(true);
    setCurrentHeading(null);
  };

  // Save editor data
  const saveData = data => {
    if (loading || changedSections.length === 0 || viewOnly) {
      return;
    }
    if (!data) {
      data = editorData;
    }
    setSaveStatus('Saving');
    let newSections = data?.filter(item => item.id.startsWith('new'));
    const payload = {
      sections: data,
      sectionIds: Array.from(new Set(changedSections))
    };
    editSection(jobId, payload, user.sessionId)
      .then(response => {
        if (response.data.success) {
          setChangeSections([]);
          setSaveStatus('Saved');

          // Set unsaved status
          dispatch(setUserUnsavedStatus(false));

          if (response.data.result) {
            if (response.data.result[0].newSection) {
              let newSectionsIdIndex = 0;
              const currentEditor = editorRef?.current?.getEditor();
              const lines = currentEditor?.getLines();
              newSections.forEach(section => {
                lines
                  .filter(
                    line => line.domNode.getAttribute('data-id') === section.id
                  )
                  .forEach(line => {
                    line.domNode.setAttribute(
                      'data-id',
                      response.data.result[0].newSectionsId[newSectionsIdIndex]
                    );
                    localSectionIdMap.set(
                      section.id,
                      response.data.result[0].newSectionsId[newSectionsIdIndex]
                    );
                  });
                newSectionsIdIndex++;
              });
            }
            setLastUpdateTime(response.data.result[0].lastUpdatedTime);
          }
        }
      })
      .catch(err => {
        console.log(err);
        setSaveStatus('You are Offline');
      });
  };

  const saveEditor = useCallback(useDebounce(saveData, AUTO_SAVE_DELAY), []);

  const handleUndo = () => {
    const currentEditor = editorRef.current.getEditor();
    currentEditor.history.undo();
  };

  const handleRedo = () => {
    const currentEditor = editorRef.current.getEditor();
    currentEditor.history.redo();
  };

  const editorScrollHandler = e => {
    if (e.target['id'] === 'quill-editor-scroll-container') {
      setCurrentHeading(null);
      editorRef.current.blur();
    }
  };

  const modules = {
    toolbar: ['bold', 'italic', 'underline', 'strike'],
    clipboard: {
      matchVisual: false
    }
  };

  const formats = [
    'heading',
    'paragraph',
    'bold',
    'italic',
    'strike',
    'underline'
  ];

  useImperativeHandle(ref, () => ({
    headingHandler,
    saveData,
    handleUndo,
    handleRedo
  }));

  return (
    <div
      id='quill-editor-scroll-container'
      ref={editorScrollContainerRef}
      onScroll={editorScrollHandler}
    >
      <div id='quill-editor-container' ref={editorContainerRef}>
        <ReactQuill
          ref={editorRef}
          modules={modules}
          theme={'bubble'}
          readOnly={viewOnly}
          formats={formats}
        />
        {currentHeading &&
          currentHeading?.position?.top &&
          currentHeading?.position?.right && (
            <HeadingActionsDiv
              top={currentHeading?.position?.top}
              left={currentHeading?.position?.right}
            >
              <a href='#bottom'>
                <HeadingActionsButton
                  type='text'
                  icon={<CaretDownOutlined />}
                  onClick={() => {
                    setHeadingFloatingMenuHidden(false);
                  }}
                  hidden={viewOnly}
                />
              </a>

              <HeadingActionsButton
                type='text'
                danger
                onClick={() => {
                  deleteSectionHandler(currentSection?.id);
                }}
                icon={<DeleteOutlined />}
                hidden={viewOnly}
              />
            </HeadingActionsDiv>
          )}
        {!headingFloatingMenuHidden && currentHeading && (
          <>
            <FloatingMenu
              cursorPosition={{
                top:
                  currentHeading.position.top + 200 >
                  editorScrollContainerRef.current.getBoundingClientRect()
                    .bottom
                    ? editorScrollContainerRef.current.getBoundingClientRect()
                        .bottom - 200
                    : currentHeading.position.top,
                right: currentHeading.position.right
              }}
              headings={headings}
              handleHeadingChange={handleHeadingChange}
            />
            <FloatingMenuControl
              id='bottom'
              cursorPosition={currentHeading.position}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default forwardRef(QuillEditor);
