import React from "react";

function DoctorsIcon({ size, color }) {
  return (
    <span className="anticon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size ? size : "16"}
        height={size ? size : "16"}
        fill="none"
        viewBox="0 0 16 16"
      >
        <g fill={color ? color : "#666"} clip-path="url(#clip0)">
          <path d="M2.863 2.813h.855a1.408 1.408 0 000-2.813c-.775 0-1.406.63-1.406 1.406v.62C1.17 2.53.552 3.454.562 4.66c.02 2.324 2.41 5.243 4.562 5.639v1.482A4.224 4.224 0 009.344 16a4.224 4.224 0 004.218-4.219v-.547a2.348 2.348 0 001.875-2.296 2.346 2.346 0 00-2.344-2.344 2.346 2.346 0 00-2.344 2.343c0 1.132.807 2.08 1.875 2.297v.547a3.285 3.285 0 01-3.28 3.281 3.285 3.285 0 01-3.282-3.28V10.3c2.01-.365 4.143-2.886 4.502-5.01.253-1.495-.36-2.665-1.69-3.259v-.625C8.874.631 8.244 0 7.468 0c-.775 0-1.406.63-1.406 1.406 0 .776.63 1.407 1.406 1.407h.842c1.075.4 1.523 1.18 1.33 2.32-.354 2.092-2.6 4.273-4.047 4.273-1.673 0-4.076-2.717-4.094-4.754-.007-.88.452-1.5 1.364-1.84zm.386-1.407a.47.47 0 11.47.469h-.47v-.469zm8.438 7.532c0-.776.63-1.407 1.406-1.407a1.408 1.408 0 010 2.813c-.775 0-1.406-.631-1.406-1.407zm-3.75-7.063h-.469a.47.47 0 11.469-.469v.469z"></path>
          <path d="M12.624 8.469h.938v.937h-.938V8.47z"></path>
        </g>
        <defs>
          <clipPath id="clip0">
            <path fill="#fff" d="M0 0H16V16H0z"></path>
          </clipPath>
        </defs>
      </svg>
    </span>
  );
}

export default DoctorsIcon;
