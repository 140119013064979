import React, { useState } from 'react';
import {
  FormStyle,
  FormDiv,
  FormContent,
  LoginFormStyle,
  LoginButtonStyle,
  SignUpButtonRow,
  FooterStyle,
  FooterText,
} from './styledComponents';
import { Form, Row, Col, notification } from 'antd';
import FloatLabel from '../CustomComponents/FloatingLabel';
import { resetPassword } from '../../Apis/Auth';
import { useParams, useHistory } from 'react-router-dom';
import CustomTypography from '../CustomComponents/CustomTypography';
import { Link } from "react-router-dom";

const ResetPasswordForm = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { token } = useParams();

  const SubmitForm = (values) => {
    setLoading(true);
    const payload = {
      password: values.password,
      token: token,
    };
    resetPassword(payload)
      .then((response) => {
        if (response.data.success === true) {
          notification['success']({
            message: 'Success',
            description: 'Please use your new password to login.',
          });
          history.push('/login');
          setLoading(false);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message,
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <FormDiv>
      <FormStyle
        span={16}
        xs={{ span: 14 }}
        sm={{ span: 14 }}
        md={{ span: 10 }}
        lg={{ span: 10 }}
      >
        <CustomTypography color="#525e6f">Reset password</CustomTypography>

        <FormContent>
          <Row justify="center">
            <Col span={24}>
              <LoginFormStyle
                layout="vertical"
                form={form}
                onFinish={SubmitForm}
              >
                <Row gutter={[24, 12]}>
                  <Col span={24}>
                    <Form.Item
                      name="password"
                      rules={[
                        { required: true, message: 'Password is required' },
                      ]}
                      hasFeedback
                    >
                      <FloatLabel
                        label="Password"
                        type="password"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="confirm"
                      dependencies={['password']}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              'The two passwords that you entered do not match!'
                            );
                          },
                        }),
                      ]}
                    >
                      <FloatLabel
                        label="Confirm Password"
                        type="password"
                      />
                    </Form.Item>
                  </Col>
                  <SignUpButtonRow>
                    <Col span={12}>
                      <LoginButtonStyle
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                      >
                        Reset
                      </LoginButtonStyle>
                    </Col>
                  </SignUpButtonRow>
                </Row>
              </LoginFormStyle>
            </Col>
          </Row>
        </FormContent>
      </FormStyle>
      <FooterStyle>
        <FooterText>
          Dont have an account?<Link to="/signup"> Sign Up</Link>
        </FooterText>
      </FooterStyle>
    </FormDiv>
  );
};

export default ResetPasswordForm;
