import React from 'react';

import CustomAvatar from '../../CustomComponents/CustomAvatar';

export const columns = (
  setSelectedItem,
  setAddDrawerVisible,
  disableUser,
  reInvite,
  notClinicAdmin
) => [
  {
    title: ' ',
    dataIndex: ['signedUrl'],
    width: '35px',
    render: (signedUrl, user) => {
      return <CustomAvatar url={signedUrl} size={35} />;
    }
  },
  {
    title: 'Name',
    dataIndex: ['fullName'],
    key: 'id',
    sorter: true,
    render: (val, row) => {
      return (
        <span title={val}>
          {val?.length > 30 ? val.slice(0, 30) + '...' : val}
        </span>
      );
    }
  },
  {
    title: 'Email',
    dataIndex: ['email'],
    key: 'email'
  },
  {
    title: 'Phone Number',
    dataIndex: ['phoneNumber'],
    key: 'phoneNumber',
    render: value => {
      return value && (value.countryCode === null || value.number === null)
        ? ''
        : value && <div>+{value.countryCode + '-' + value.number}</div>;
    }
  }
];
