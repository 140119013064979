import React from 'react';
import { StyledSelect, StyledInputNumber } from './styledComponents';
import { StyledDatePicker } from '../../Content/styledComponents';
import { DATE_FORMAT } from '../../../Constants/StringConstants';
import { CaretDownOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import moment from 'moment';

const DynamicTableElement = React.forwardRef(
  ({ value = {}, onChange, inputType, possibleValues, form, dataIndex }, ref) => {
    const triggerChange = changedValue => {
      if (onChange) {
        onChange({ ...value, ...changedValue });
      }
    };

    const onSelectHandler = changedValue => {
      if (onChange) {
        onChange(changedValue);
      }
    };

    const triggerDateChange = (date, changedValue, dataIndx) => {
      if (onChange) {
        if (dataIndx === 'Start date' && !form.getFieldValue('End date')) {
          form.setFieldsValue({
            'End date': moment(date).format(DATE_FORMAT)
          });
        }
        if (dataIndx === 'Start Date of Chief Complaint' && !form.getFieldValue('End Date of Chief Complaint')) {
          form.setFieldsValue({
            'End Date of Chief Complaint': moment(date).format(DATE_FORMAT)
          });
        }
        if (dataIndx === 'Start Date of Surgery/Procedure' && !form.getFieldValue('End Date of Surgery/Procedure')) {
          form.setFieldsValue({
            'End Date of Surgery/Procedure': moment(date).format(DATE_FORMAT)
          });
        }

        onChange(moment(date).format(DATE_FORMAT));
      }
    };

    const disabledDate = val => {
      return (
        val &&
        val <
        moment(form.getFieldValue('Start date'), 'MM/DD/YY').startOf('day')
      );
    };
    const disabledStartDate = val => {
      return (
        val &&
        val >
        moment(form.getFieldValue('End date'), 'MM/DD/YY').endOf('day')
      );
    };

    const disabledDateOfChiefComplaint = val => {
      return (
        val &&
        val <
        moment(form.getFieldValue('Start Date of Chief Complaint'), 'MM/DD/YY').startOf('day')
      );
    };

    const disabledStartDateOfChiefComplaint = val => {
      return (
        val &&
        val >
        moment(form.getFieldValue('End Date of Chief Complaint'), 'MM/DD/YY').endOf('day')
      );
    };

    const disabledDateOfSurgeryProcedure = val => {
      return (
        val &&
        val <
        moment(form.getFieldValue('Start Date of Surgery/Procedure'), 'MM/DD/YY').startOf('day')
      );
    };

    const disabledStartDateOfSurgeryProcedure = val => {
      return (
        val &&
        val >
        moment(form.getFieldValue('End Date of Surgery/Procedure'), 'MM/DD/YY').endOf('day')
      );
    };


    switch (inputType) {
      case 'Text Box':
        return (
          <Input
            onChange={triggerChange}
            value={value && value.length > 0 ? value : ''}
          />
        );
      case 'number':
        return (
          <StyledInputNumber
            onChange={triggerChange}
            value={value && value.length > 0 ? value : ''}
          />
        );

      case 'Dropdown':
      case 'CheckBox':
      case 'Toggle':
      case 'Radio Button':
        return (
          <StyledSelect
            suffixIcon={<CaretDownOutlined />}
            onSelect={onSelectHandler}
            defaultValue={value.length ? value : ''}
          >
            {possibleValues &&
              possibleValues.map(val => (
                <StyledSelect.Option value={val.value} key={val.value}>
                  {val.value}
                </StyledSelect.Option>
              ))}
          </StyledSelect>
        );

      case 'Date/Time':
        return (
          <StyledDatePicker
            format={DATE_FORMAT}
            allowClear={false}
            value={value && value.length > 0 && moment(value)}
            onChange={(date, dateString) =>
              triggerDateChange(date, dateString, dataIndex)
            }
            disabledDate={current => {
              if (dataIndex === 'End date') {
                return disabledDate(current);
              } else if (dataIndex === 'End Date of Chief Complaint') {
                return disabledDateOfChiefComplaint(current);
              } else if (dataIndex === 'End Date of Surgery/Procedure') {
                return disabledDateOfSurgeryProcedure(current);
              } else if (dataIndex === 'Start date') {
                return disabledStartDate(current);
              }else if (dataIndex === 'Start Date of Chief Complaint') {
                return disabledStartDateOfChiefComplaint(current);
              } else if (dataIndex === 'Start Date of Surgery/Procedure') {
                return disabledStartDateOfSurgeryProcedure(current);
              } else {
                return null;
              }
            }

            }
          />
        );
      default:
        return null;
    }
  }
);
export default DynamicTableElement;
