import axiosInstance from '../Config/AxiosInstance';

const PREFIX = '/clinic/facility';

export const getFacilites = (page, searchValue, size) => {
  return axiosInstance.get(
    PREFIX + `?page=${page}&searchValue=${searchValue}&size=${size}`
  );
};

export const getEhrList = () => {
  return axiosInstance.get(PREFIX + `/ehr-integration`);
};

export const getFacilityDetails = id => {
  return axiosInstance.get(PREFIX + `/${id}`);
};

export const getEhrFields = (facilityId, ehrType) => {
  return axiosInstance.get(PREFIX + `/ehr-configuration?ehrType=${ehrType}`);
};

export const addFacilityConfig = payload => {
  return axiosInstance.post(PREFIX, payload);
};

export const updateFacilityConfig = payload => {
  return axiosInstance.put(PREFIX, payload);
};

export const resetDemoConfiguration = () => {
  return axiosInstance.get(PREFIX + `/ehr-integration`);
};

export const GetSessionDetails = payload => {
  return axiosInstance.post(PREFIX + `/login-data`, payload);
};

export const GetSessionJobDetails = (
  sessionId,
  pageNo,
  pageSize,
  searchValue
) => {
  return axiosInstance.get(
    PREFIX +
      `/job-auditing-data/${sessionId}?page=${pageNo}&searchValue=${searchValue}&size=${pageSize}`
  );
};

export const fetchJobsForAudit = payload => {
  return axiosInstance.post(PREFIX + `/audit/jobs`, payload);
};

export const fetchJobAuditDataByJobId = jobId => {
  return axiosInstance.get(PREFIX + `/audit/jobs/${jobId}`);
};

export const getAvailableEhrFields = facilityId => {
  return axiosInstance.get(PREFIX + `/${facilityId}/available-ehr-fields`);
};

export const saveEhrFields = (data, facilityId, workType) => {
  return axiosInstance.post(
    PREFIX + `/${facilityId}/save-ehr-fields?workType=${workType}`,
    data
  );
};

export const getFacilityWorkTypes = facilityId => {
  return axiosInstance.get(PREFIX + `/${facilityId}/work-types`);
};
export const updateFacilityWorkType = (facilityId, workType) => {
  return axiosInstance.put(
    PREFIX + `/${facilityId}/work-type?workType=${workType}`
  );
};
export const fetchFacilityAudits = (pageNo, pageSize) => {
  return axiosInstance.get(
    PREFIX + `/list-audit-facilty?page=${pageNo}&size=${pageSize}`
  );
};

export const fetchFacilityAudit = facilityId => {
  return axiosInstance.get(
    PREFIX + `/facility-audit?facility-id=${facilityId}`
  );
};
