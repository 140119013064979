import { CustomEditor } from './EditorHelper';
// import { ReactEditor } from 'slate-react';

export const KeyDownEvents = (
  event,
  editor,
  undoRedo,
  setValue,
  pointer,
  setPointer,
  onChange
) => {
  // const setEditorState = type => {
  //   switch (type) {
  //     case 'undo': {
  //       if (pointer > 1) {
  //         onChange(undoRedo[pointer - 2], true);
  //         setPointer(pointer - 1);
  //       }
  //       break;
  //     }
  //     case 'redo': {
  //       if (pointer < 50) {
  //         onChange(undoRedo[pointer + 1], true);
  //         setPointer(pointer + 1);
  //       }
  //       break;
  //     }
  //     default:
  //       break;
  //   }
  // };

  if (event.ctrlKey) {
    switch (event.key) {
      case 'h': {
        event.preventDefault();
        CustomEditor.toggleHeadingBlock(editor);
        break;
      }

      case 'b': {
        event.preventDefault();
        CustomEditor.toggleBoldMark(editor);
        break;
      }

      // case 'z': {
      //   event.preventDefault();
      //   console.log(undoRedo, pointer);
      //   ReactEditor.deselect(editor);
      //   setEditorState('undo');
      //   break;
      // }

      // case 'y': {
      //   event.preventDefault();
      //   console.log(undoRedo, pointer);
      //   ReactEditor.deselect(editor);
      //   setEditorState('redo');
      //   break;
      // }
      default:
        break;
    }
  } else if (event.key === 'Enter') {
    event.preventDefault();
    editor.insertText('\n');
  }
};
