import React, { useEffect } from 'react'
import { Form, Col, Space } from 'antd'
import {
  CustomRow,
  ChangePasswordButton,
  SaveButton,
  CancelButton
} from './styledComponents'
import FloatLabel from '../../../CustomComponents/FloatingLabel'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { SaveOutlined, CloseOutlined } from '@ant-design/icons'

const UserCredentials = ({
  profile,
  changePassword,
  edit,
  setEdit,
  setPasswordEditDisable,
  passwordEditDisable
}) => {
  const [form] = Form.useForm()
  const [theme] = useSelector(state => [state.themeReducer])
  const [user] = useSelector(state => [state.userReducer])
  const [passwordBoolean, setPasswordBoolean] = useState(false)

  useEffect(() => {
    form.setFieldsValue({ ...profile })
    // eslint-disable-next-line
  }, [profile, edit])

  const handleChangePassword = () => {
    setPasswordBoolean(true)
    setPasswordEditDisable(true)
  }

  const CancelResetPassword = () => {
    setPasswordBoolean(false)
    setPasswordEditDisable(false)
    setEdit(false)
    form.setFieldsValue({ [`oldPassword`]: '' })
    form.setFieldsValue({ [`newPassword`]: '' })
  }

  const onSubmit = values => {
    let payload = values
    payload.id = user.id
    changePassword(payload)
  }

  return (
    <>
      <Form layout='vertical' form={form} onFinish={onSubmit}>
        <CustomRow gutter={[36, 36]}>
          <Col span={12}>
            <Form.Item
              name='email'
              rules={[
                { required: true, message: 'Email is required' },
                {
                  type: 'email',
                  message: 'Please enter valid Email'
                }
              ]}
            >
              <FloatLabel
                label='E-mail Address'
                primaryColor={theme['@text-color']}
                type='input'
                disabled={!edit}
              />
            </Form.Item>
          </Col>
          {edit === true && (
            <Col span={12}>
              <Form.Item
                name='oldPassword'
                rules={[{ required: true, message: 'Password is required' }]}
              >
                <FloatLabel
                  label='Password'
                  primaryColor={theme['@text-color']}
                  type='password'
                  disabled={!edit}
                />
              </Form.Item>
            </Col>
          )}
        </CustomRow>
        <CustomRow gutter={[36, 36]}>
          {passwordBoolean === false && edit === false && (
            <ChangePasswordButton type='primary' onClick={handleChangePassword}>
              Change Password
            </ChangePasswordButton>
          )}
          {passwordBoolean === true && (
            <>
              <Col span={12}>
                <Form.Item
                  name='oldPassword'
                  rules={[
                    { required: true, message: 'Current Password is required' }
                  ]}
                >
                  <FloatLabel
                    label='Current Password'
                    primaryColor={theme['@text-color']}
                    type='password'
                    disabled={!passwordEditDisable}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='newPassword'
                  rules={[
                    { required: true, message: 'New Password is required' }
                  ]}
                >
                  <FloatLabel
                    label='New Password'
                    primaryColor={theme['@text-color']}
                    type='password'
                    disabled={!passwordEditDisable}
                  />
                </Form.Item>
              </Col>
            </>
          )}
        </CustomRow>
        {(passwordBoolean === true || edit === true) && (
          <>
            <CustomRow justify='end' button={true}>
              <Space size={20}>
                <CancelButton
                  onClick={CancelResetPassword}
                  icon={<CloseOutlined />}
                >
                  Cancel
                </CancelButton>
                <SaveButton
                  type='primary'
                  htmlType='submit'
                  icon={<SaveOutlined />}
                >
                  Save
                </SaveButton>
              </Space>
            </CustomRow>
          </>
        )}
      </Form>
    </>
  )
}

export default UserCredentials
