import React, { useEffect, useState } from 'react';
import FloatLabel from '../../../CustomComponents/FloatingLabel';
import {
  ButtonRow,
  SaveCardContainer,
  StyledButton,
  CardVendorLogo
} from './styledComponents';
import { Row, Col, Space, notification } from 'antd';
import {
  DeleteOutlined,
  CreditCardOutlined,
  CloseOutlined
} from '@ant-design/icons';
import { getCardIcon } from './cardIconMap';
import DefaultIcon from '../../../../Assets/CardVendors/default.svg';
import { deleteCard } from '../../../../Apis/Payment';
import { createSubscription } from '../../../../Apis/Payment';
import ConfirmationModal from '../../../CustomComponents/ConfirmationModal';
import DeleteIcon from '../../../../Assets/Icons/DeleteIcon';
import { useHistory } from 'react-router-dom';

const SavedCard = ({
  facilityId,
  theme,
  selectedCard,
  paymentDetails,
  getPaymentProfiles
}) => {
  const [cardIcon, setCardIcon] = useState(DefaultIcon);
  const [cardHolderName, setCardHolderName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [payLoading, setPayLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const history = useHistory();

  const removeCard = id => {
    setRemoveLoading(true);
    deleteCard(id, facilityId)
      .then(response => {
        if (response.data.success) {
          notification['success']({
            message: 'Success',
            description: response.data.message
          });
          getPaymentProfiles();
          setRemoveLoading(false);
          setModalVisible(false);
        } else {
          notification['error']({
            message: 'Error',
            description: response.data.message
          });
          setRemoveLoading(false);
          setModalVisible(false);
        }
      })
      .catch(err => {
        console.log(err);
        setRemoveLoading(false);
        setModalVisible(false);
      });
  };

  const pay = id => {
    setPayLoading(true);

    let data = {
      paymentProfileId: id,
      planId: paymentDetails.id
    };

    createSubscription(data, facilityId)
      .then(response => {
        if (response.data.success) {
          notification['success']({
            message: 'Success',
            description: response.data.message
          });
          setPayLoading(false);
          history.push(`/dashboard/billing/manage-subscription`,{currentFacilityId: facilityId});
        } else {
          notification['error']({
            message: 'Error',
            description: response.data.message
          });
          setPayLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setPayLoading(false);
      });
  };

  const onCancel = () => {
    setModalVisible(false);
  };

  const onOk = () => {
    removeCard(selectedCard.customerPaymentProfileId);
  };

  useEffect(() => {
    if (selectedCard) {
      let selectedCardNo =
        selectedCard.payment.creditCard.cardNumber.slice(0, 4) +
        '-' +
        selectedCard.payment.creditCard.cardNumber.slice(4);
      let selectedCardHolderName =
        selectedCard.firstName +
        ' ' +
        (selectedCard.lastName === 'N/A' ? '' : selectedCard.lastName);

      const icon = getCardIcon(
        selectedCard.payment.creditCard.cardType &&
          selectedCard.payment.creditCard.cardType.toLowerCase()
      );
      setCardIcon(icon);
      setCardHolderName(selectedCardHolderName);
      setCardNumber(selectedCardNo);
    }
  }, [selectedCard]);

  return (
    <SaveCardContainer saved={true}>
      <div>
        <Row justify='center' gutter={[24]}>
          <Col span={24}>
            <FloatLabel
              label='Card Number'
              primaryColor={theme['@text-color']}
              type='input'
              disabled={true}
              value={cardNumber}
            />
          </Col>
          <Col span={24} style={{ marginTop: '40px' }}>
            <FloatLabel
              label='Card Holder Name'
              primaryColor={theme['@text-color']}
              type='input'
              disabled={true}
              value={cardHolderName}
            />
          </Col>
        </Row>
        <ButtonRow justify='end' style={{ marginTop: '25px' }}>
          <Space size='large'>
            {selectedCard &&
              (paymentDetails ? (
                <StyledButton
                  type='primary'
                  cancel={true}
                  onClick={() =>
                    history.push(`/dashboard/billing/manage-subscription`)
                  }
                  icon={<CloseOutlined />}
                >
                  Cancel
                </StyledButton>
              ) : (
                <StyledButton
                  type='danger'
                  onClick={() => setModalVisible(true)}
                  icon={<DeleteOutlined />}
                >
                  Remove
                </StyledButton>
              ))}

            {paymentDetails && selectedCard && (
              <StyledButton
                type='primary'
                htmlType='submit'
                icon={<CreditCardOutlined />}
                loading={payLoading}
                onClick={() => pay(selectedCard.customerPaymentProfileId)}
              >
                Pay Now
              </StyledButton>
            )}
          </Space>
        </ButtonRow>
      </div>
      <CardVendorLogo>
        <img src={cardIcon} alt='Card Icon' />
      </CardVendorLogo>
      <ConfirmationModal
        visible={modalVisible}
        onOk={onOk}
        onCancel={onCancel}
        antdModalProps={{ centered: true, width: '300', closable: false }}
        deleteCard={true}
        loading={removeLoading}
        confirmation={true}
      >
        <DeleteIcon />
        <p>Are you sure you want to remove this card?</p>
      </ConfirmationModal>
    </SaveCardContainer>
  );
};

export default SavedCard;
