import { Table, Select, InputNumber, Form, Button } from 'antd';
import styled from 'styled-components';
import {
  DeleteOutlined,
  CheckOutlined,
  EditOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';

export const StyledTable = styled(Table)`
  & .ant-table {
    min-height: 429px;
    max-height: 429px;
  }

  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 21px 28px;
    width: 200px;
    /* height: 32px; */
    cursor: pointer;
  }

  [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
  }

  .cell-edit {
    width: 10px;
  }

  &
    .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td {
    padding: 10px;
  }

  & .ant-table-pagination.ant-pagination {
    margin: 16px 0 0;
  }

  & .ant-table-tbody > tr > td {
    padding: 0;
  }
`;
export const StyledSelect = styled(Select)`
  width: 100%;
`;

export const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

export const EditableFormItemStyle = styled(Form.Item)`
  margin: 0;
  padding: 16px;
`;

export const EditableCellValueDivStyle = styled.div`
  padding-right: 24px;
`;

export const DeleteOutlinedStyle = styled(DeleteOutlined)`
  color: ${(props) => (props.disabled ? '#00000040' : 'red')} !important;
`;

export const CancelOutlinedStyle = styled(CloseCircleOutlined)`
  color: ${(props) => (props.disabled ? '#00000040' : 'red')} !important;
`;

export const StyledCheckIcon = styled(CheckOutlined)`
  color: ${(props) => (props.disabled ? '#00000040' : '#4b9460')} !important;
`;
export const StyledEditIcon = styled(EditOutlined)`
  color: ${(props) => (props.disabled ? '#00000040' : '#47a4ea')} !important;
`;

export const AddRowButtonStyle = styled(Button)`
  &.ant-btn {
    margin-bottom: 16px;
    float: right;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 7px;
    width: 110px;
    height: 35px;
    padding: 0;
  }
`;

export const EditorContentPreviewBox = styled.div`
  max-height: 100px;
  overflow-y: scroll;
  border: 1px solid #f0f0f0;
  margin-bottom: 10px;
  border-radius: 2px;
  text-align: left;
  padding: 5px 5px 5px 15px;
  font-size: 14px;
  color: #525e6f;
  & mark {
    background-color: yellow;
  }
`;
