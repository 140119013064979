import React from 'react';
import { RHAP_UI } from 'react-h5-audio-player';
import { StyledAudioPlayer, StyledSeparator } from './styledComponents';
import 'react-h5-audio-player/lib/styles.css';

const CustomAudioPlayer = React.forwardRef((props, ref) => {
  return (
    <>
      <StyledAudioPlayer
        {...props}
        layout='horizontal-reverse'
        customProgressBarSection={[
          RHAP_UI.MAIN_CONTROLS,
          RHAP_UI.CURRENT_TIME,
          <StyledSeparator>/</StyledSeparator>,
          RHAP_UI.DURATION,
          RHAP_UI.PROGRESS_BAR,
          RHAP_UI.VOLUME
        ]}
        customAdditionalControls={[]}
        showJumpControls={false}
        customVolumeControls={[]}
        ref={ref}
        // preload="metadata"
      />
    </>
  );
});

export default CustomAudioPlayer;
