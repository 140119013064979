import { Modal, Button } from 'antd';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  & .ant-modal-content {
    border-radius: 8px;
  }

  & .ant-modal-header {
    border-radius: 10px 10px 0 0;
  }

  & .ant-modal-body {
    text-align: center;
    padding: ${props =>
      props.inference
        ? '10px 16px'
        : props.video
        ? ''
        : props.payment
        ? '20px 25px '
        : '25px 50px 16px'};
    font-size: 16px;
    font-weight: 500;
    color: ${props => props.color};
    & p {
      margin: 10px 0;
    }

    height: ${props => props.video && '600px'};

    @media (max-height: 800px) {
      height: ${props => props.video && '70vh'};
    }
  }
  & .ant-modal-footer {
    text-align: center;
    display: flex;
    justify-content: ${props => props.buttonAlignCenter? 'center':
      props.inference || props.video ? 'flex-end' : 'space-evenly'};
    border-top: 0;
    padding: 10px 16px 25px;
  }
`;

export const StyledButton = styled(Button)`
  &.ant-btn {
    font-size: 16px;
    font-weight: 600;
    color: ${props => props.action === 'cancel' && '#8D8989'};
    background-color: ${props =>
      props.action === 'cancel' ? '#CFD3D6' : null};
    border-radius: 7px;
    width: 110px;
    height: 35px;
    &:hover,
    :focus {
      background-color: ${props =>
        props.action === 'cancel' ? '#CFD3D6aa' : null};
      color: ${props => props.action === 'cancel' && '#8D8989'};
    }
  }
`;
