import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CustomTable from '../../CustomComponents/Table';
import {
  RESULTS_PER_PAGE,
  SEARCH_DELAY
} from '../../../Constants/StringConstants';
import ConfirmationModal from '../../CustomComponents/ConfirmationModal';
import { columns } from './tableConfig';
import { sideBarContents } from './SidebarConfig';
import { SearchOutlined } from '@ant-design/icons';
import { useDebounce } from '../../../Utils/Debounce';
import { useCallback } from 'react';
// import ConfirmationModal from '../../CustomComponents/ConfirmationModal';
import CustomTypography from '../../CustomComponents/CustomTypography';
import {
  SearchJobListStyle,
  BadgeStyle,
  ReadyJobsTabsFlexStyle,
  ReadyJobsTabsTitleStyle,
  ResubmitButton,
  RealseButton,
  SubJobListTabContainer,
  StyledJobListSubTab,
  StyledJobListSubTabRow,
  StyledSubContainer,
  StyledTableControlContainer,
  StyledTableControlSubContainer,
  StyledJobListTableContainer,
  StyledResubmitButtonContainer,
  StyledRealseButtonContainer
} from './styledComponents';
import { Button, Tabs, notification } from 'antd';
import { releaseSection } from '../../../Apis/Jobs';

const { TabPane } = Tabs;

const JobListTable = ({
  setselectedTab,
  setFacilities,
  titleKey,
  currentPage,
  total,
  setSortOrder,
  setCurrentPage,
  pageNoChangeHandler,
  setFilterKey,
  setSearchKey,
  availableJobs,
  activeKey,
  assignToSelf,
  assignButtonLoading,
  startAssignedJob,
  startAssignedJobWithNewEditor,
  resumeJob,
  resumeJobWithNewEditor,
  viewJob,
  releaseJobHandler,
  tableLoading,
  activeTab,
  setActivetab,
  activeIncompleteTab,
  setActiveIncompletetab,
  fetchJobList,
  setUserType,
  userType,
  setJobStatus,
  jobStatus,
  counts,
  selfAssignTranscriptorBadge,
  setSelfAssignedTranscriptorBadge,
  selfAssignReviewerBadge,
  setSelfAssignedReviewerBadge,
  searchVal,
  setSearchVal,
  type,
  resubmit
}) => {
  const [theme] = useSelector(state => [state.themeReducer]);
  const [tableTitle, setTableTitle] = useState();
  const user = useSelector(state => state.userReducer);
  const [selectedId, setSelectedId] = useState();
  const [tempStatus, setTempStatus] = useState(jobStatus);
  const [selectedJobIds, setSelectedJobIds] = useState([]);
  const [releasetModalVisible, setReleaseModalVisible] = useState(false);
  const [releaseJobIds, setReleaseJobIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [releaseLoading, setReleaseLoading] = useState(false);
  const [releaseModalLoading, setReleaseModalLoading] = useState(false);

  useEffect(() => {
    setTableTitle(sideBarContents('transcriptionist'));
    // eslint-disable-next-line
  }, []);
  const releaseIncompleteJobs = jobId => {
    setReleaseJobIds(jobId);

    setReleaseModalLoading(true);

    setReleaseLoading(true);

    // fetchJobList(0);

    setReleaseModalVisible(true);

    setReleaseModalLoading(false);
  };

  const handleReleaseConfirm = jobIds => {
    setReleaseModalLoading(true);

    releaseSection(jobIds, user.sessionId)
      .then(response => {
        if (response.data.success) {
          // fetchSidebarList();

          // fetchJobList(0);

          notification['success']({
            message: 'Success',

            description: response.data.message
          });

          setReleaseModalVisible(false);

          fetchJobList(0);
        } else {
          notification['error']({
            message: 'Failed',

            description: response.data.message
          });
        }

        setReleaseModalLoading(false);

        setReleaseLoading(false);
      })

      .catch(err => {
        setReleaseLoading(false);
      });
  };
  const onChangeHandler = value => {
    setSearchKey(value);
    setCurrentPage(0);
  };

  const debounceOnChange = useCallback(
    useDebounce(onChangeHandler, SEARCH_DELAY),
    []
  );

  const filterTableChange = (pagination, filters, sorter, event, event2) => {
    let sortByKey;
    let sortByOrder;
    if (sorter.length > 1) {
      sortByKey =
        sorter &&
        sorter.map(item => {
          return item.columnKey;
        });
      sortByOrder =
        sorter &&
        sorter.map(item => {
          return item.order;
        });

      setFilterKey(sortByKey);
      setSortOrder(sortByOrder);
    } else {
      sortByKey = sorter.columnKey;
      sortByOrder = sorter.order;
      if (sorter.columnKey !== undefined) setFilterKey(sortByKey);
      if (sorter.order !== undefined) setSortOrder(sortByOrder);
    }
  };

  const assignJobHandler = id => {
    assignToSelf(id);
    setSelectedId(id);
    activeTab === 'transcriptionist' && setSelfAssignedTranscriptorBadge(true);
    activeTab === 'reviewer' && setSelfAssignedReviewerBadge(true);
  };

  const handleChangeTab = activeKey => {
    setActivetab(activeKey);
    setUserType(activeKey);
    setCurrentPage(0);
    fetchJobList(0, '', activeKey);
    activeKey === 'transcriptionist' && setSelfAssignedTranscriptorBadge(false);
    activeKey === 'reviewer' && setSelfAssignedReviewerBadge(false);
  };

  const handleChangeIncompleteTab = activeKey => {
    setselectedTab(activeKey);
    setActiveIncompletetab(activeKey);
    fetchJobList(0, activeKey);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedJobIds(selectedRowKeys);
    },
    getCheckboxProps: record => ({
      name: record.id
    })
  };
  return (
    <>
      <StyledSubContainer>
        <StyledJobListTableContainer>
          <StyledTableControlContainer>
            <StyledTableControlSubContainer>
              <StyledJobListSubTabRow>
                {((user && user.userType === 'admin') ||
                  (user && user.userType === 'clinicAdmin') ||
                  (user.userType === 'doctor' &&
                    user.userPrivilege === 'TRANSCRIPTION_REVIEWER')) &&
                  type !== 'incomplete' && (
                    <SubJobListTabContainer>
                      <StyledJobListSubTab
                        tabswidth={'500px'}
                        defaultActiveKey='ready'
                        activeKey={activeTab}
                        onChange={handleChangeTab}
                      >
                        <TabPane
                          tab={
                            <ReadyJobsTabsFlexStyle>
                              <ReadyJobsTabsTitleStyle>
                                Transcriptionist -{' '}
                                <b>
                                  {tableTitle &&
                                    titleKey &&
                                    tableTitle.find(
                                      item => item.key === titleKey
                                    ) &&
                                    counts[
                                      tableTitle.find(
                                        item => item.key === titleKey
                                      ).countValueKey
                                    ] &&
                                    counts[
                                      tableTitle.find(
                                        item => item.key === titleKey
                                      ).countValueKey
                                    ].transcriptionistJobCount}
                                </b>
                              </ReadyJobsTabsTitleStyle>
                              {selfAssignTranscriptorBadge &&
                                activeKey === '1' && (
                                  <BadgeStyle
                                    dot={true}
                                    color={theme['@primary-color']}
                                  />
                                )}
                            </ReadyJobsTabsFlexStyle>
                          }
                          key='transcriptionist'
                          // key='ready'
                        />

                        <TabPane
                          tab={
                            <ReadyJobsTabsFlexStyle>
                              <ReadyJobsTabsTitleStyle>
                                Reviewer -{' '}
                                <b>
                                  {tableTitle &&
                                    titleKey &&
                                    tableTitle.find(
                                      item => item.key === titleKey
                                    ) &&
                                    counts[
                                      tableTitle.find(
                                        item => item.key === titleKey
                                      ).countValueKey
                                    ] &&
                                    counts[
                                      tableTitle.find(
                                        item => item.key === titleKey
                                      ).countValueKey
                                    ].reviewerJobCount}
                                </b>
                              </ReadyJobsTabsTitleStyle>
                              {selfAssignReviewerBadge && activeKey === '1' && (
                                <BadgeStyle
                                  dot={true}
                                  color={theme['@primary-color']}
                                />
                              )}
                            </ReadyJobsTabsFlexStyle>
                          }
                          key='reviewer'
                          // key='reviewer-rejected'
                        />
                      </StyledJobListSubTab>
                    </SubJobListTabContainer>
                  )}

                {type === 'incomplete' && (
                  <SubJobListTabContainer>
                    <StyledJobListSubTab
                      tabswidth={
                        (counts['failedJob']?.transcriptionistJobCount ||
                          counts['failedJob']?.reviewerJobCount) > 0
                          ? '300px'
                          : '200px'
                      }
                      defaultActiveKey='unsuccessful'
                      size='large'
                      activeKey={activeIncompleteTab}
                      onChange={handleChangeIncompleteTab}
                    >
                      <TabPane
                        tab={
                          <ReadyJobsTabsFlexStyle>
                            <ReadyJobsTabsTitleStyle>
                              Unsuccessful -{' '}
                              <b>
                                {(user && user.userType === 'admin') ||
                                (user && user.userType === 'clinicAdmin') ||
                                (user.userType === 'doctor' &&
                                  user.userPrivilege ===
                                    'TRANSCRIPTION_REVIEWER')
                                  ? counts['unSuccefulJob']
                                      ?.transcriptionistJobCount +
                                    counts['unSuccefulJob']?.reviewerJobCount
                                  : counts['unSuccefulJob'] ||
                                    counts['unSuccessfulJob']}
                              </b>
                            </ReadyJobsTabsTitleStyle>
                            {selfAssignTranscriptorBadge &&
                              activeKey === '1' && (
                                <BadgeStyle
                                  dot={true}
                                  color={theme['@primary-color']}
                                />
                              )}
                          </ReadyJobsTabsFlexStyle>
                        }
                        key='unsuccessful'
                      />
                      {counts['failedJob']?.transcriptionistJobCount +
                        counts['failedJob']?.reviewerJobCount >
                      0 ? (
                        <TabPane
                          tab={
                            <ReadyJobsTabsFlexStyle>
                              <ReadyJobsTabsTitleStyle>
                                Failed -{' '}
                                <b>
                                  {(user && user.userType === 'admin') ||
                                  (user && user.userType === 'clinicAdmin') ||
                                  (user.userType === 'doctor' &&
                                    user.userPrivilege ===
                                      'TRANSCRIPTION_REVIEWER')
                                    ? counts['failedJob']
                                        ?.transcriptionistJobCount +
                                      counts['failedJob']?.reviewerJobCount
                                    : counts['failedJob']}
                                </b>
                              </ReadyJobsTabsTitleStyle>
                              {selfAssignReviewerBadge && activeKey === '1' && (
                                <BadgeStyle
                                  dot={true}
                                  color={theme['@primary-color']}
                                />
                              )}
                            </ReadyJobsTabsFlexStyle>
                          }
                          key='failed'
                        />
                      ) : null}
                    </StyledJobListSubTab>
                    <StyledResubmitButtonContainer>
                      <ResubmitButton
                        type='primary'
                        shape='round'
                        onClick={() => resubmit(selectedJobIds)}
                        disabled={selectedJobIds < 1}
                        loading={releaseLoading}
                      >
                        Resubmit
                      </ResubmitButton>
                    </StyledResubmitButtonContainer>
                    <StyledRealseButtonContainer>
                      <RealseButton
                        type='primary'
                        shape='round'
                        onClick={() => releaseIncompleteJobs(selectedJobIds)}
                        disabled={selectedJobIds < 1}
                        loading={releaseLoading}
                      >
                        Release
                      </RealseButton>
                    </StyledRealseButtonContainer>

                    <ConfirmationModal
                      antdModalProps={{
                        centered: true,

                        width: '300',

                        closable: false
                      }}
                      visible={releasetModalVisible}
                      onCancel={() => {
                        // setResubmitModalVisible(false);

                        setReleaseModalVisible(false);

                        setReleaseLoading(false);
                      }}
                      onOk={() => handleReleaseConfirm(releaseJobIds)}
                      header={
                        <CustomTypography color={theme['@text-color']}>
                          Are you sure?
                        </CustomTypography>
                      }
                      confirmation={true}
                      loading={releaseModalLoading}
                    >
                      <h1>{`Do you really want to release?`}</h1>
                    </ConfirmationModal>
                  </SubJobListTabContainer>
                )}
              </StyledJobListSubTabRow>
            </StyledTableControlSubContainer>
            <StyledTableControlSubContainer>
              <SearchJobListStyle
                prefix={<SearchOutlined />}
                placeholder='Search Jobs'
                onChange={event => {
                  setSearchVal(event.target.value);
                  debounceOnChange(event.target.value);
                }}
                value={searchVal}
              />
              {/* <FacilityFilter setSelectedValues={setFacilities} /> */}
            </StyledTableControlSubContainer>
          </StyledTableControlContainer>

          <CustomTable
            theme={theme}
            jobList={true}
            setPageNo={current => {
              setCurrentPage(current);
            }}
            pageNo={currentPage}
            pageNoChangeHandler={pageNoChangeHandler}
            pageSize={RESULTS_PER_PAGE}
            total={total}
            dataSource={availableJobs}
            columns={columns(
              activeKey,
              assignJobHandler,
              assignButtonLoading,
              startAssignedJob,
              startAssignedJobWithNewEditor,
              resumeJob,
              resumeJobWithNewEditor,
              viewJob,
              releaseJobHandler,
              selectedId,
              activeTab,
              userType === 'reviewer',
              jobStatus,
              setJobStatus,
              tempStatus,
              setTempStatus,
              user
            )}
            onChange={filterTableChange}
            loading={tableLoading}
            ready={true}
            selectRow={type === 'incomplete'}
            rowSelection={rowSelection}
            rowKey='jobId'
          />
        </StyledJobListTableContainer>
      </StyledSubContainer>
    </>
  );
};

export default JobListTable;
