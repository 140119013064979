import React, { useEffect, useState, useCallback, useRef } from 'react';
import { CustomEditor } from '../EditorComponent/Helper/EditorHelper';
import { Slate, Editable, ReactEditor } from 'slate-react';
import { Text, Node } from 'slate';
import Leaf from './Helper/Leaf';
import { EditorDataFormatter } from './Helper/sectionResponseModifier';
import { DefaultElement, HeadingElement } from './Helper/Elements';
import { KeyDownEvents } from './Helper/KeyDownEvents';
import {
  HeadingActionsDiv,
  HeadingActionsButton,
  EditorContainer,
  FloatingMenuControl
} from './styledComponents';
import { CaretDownOutlined, DeleteOutlined } from '@ant-design/icons';
import { FloatingMenu } from './Helper/FloatingMenu';
import { Editor, Transforms } from 'slate';
import { ObjectDeSerializer } from './Helper/ObjectDeserializer';
import { useDebounce } from '../../../Utils/Debounce';
import { AUTO_SAVE_DELAY } from '../../../Constants/StringConstants';
import { editSection } from '../../../Apis/Jobs';
import { AddUser } from '../../../Redux/Actions/User';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from 'nanoid';

const SlateEditor = ({
  editorData,
  headings,
  jobId,
  editor,
  checkIconStatus,
  setSaveStatus,
  theme,
  audioRef,
  deleteSection,
  activeSection,
  setActiveSection,
  setChangeStatus,
  value,
  setValue,
  fetchContents,
  inferenceActive,
  inferenceAnnotation,
  setInferenceAnnotation,
  saveTimeFormat,
  setEditorDataPreviewValue
}) => {
  const [nodeList, setNodeList] = useState([]);
  const [positions, setPositions] = useState(null);
  const [headerPosition, setHeaderPosition] = useState(null);
  const [currentWordRange, setCurrentWordRange] = useState(null);
  const [cursorPosition, setCursorPosition] = useState(null);
  const [selectedHeadingId, setSelectedHeadingId] = useState(null);
  const editorRef = useRef();
  const [initialEditorValue, setInitialEditorValue] = useState([]);
  const [hightlightValue, setHighlightValue] = useState(null);
  const [undoRedo, setUndoRedo] = useState([]);
  const [pointer, setPointer] = useState(-1);
  const dispatch = useDispatch();
  const user = useSelector(state => state.userReducer);
  const [headingMap, setHeadingMap] = useState(new Map());
  const headingMapRef = useRef();

  const decorate = useCallback(
    ([node, path]) => {
      const ranges = [];
      inferenceActive === false && setHighlightValue(null);
      if (
        hightlightValue &&
        path.length !== 0 &&
        Node.isNode(node) &&
        !Text.isText(node)
      ) {
        const parentNode = Node.parent(node, path);
        if (
          parentNode.id === activeSection &&
          parentNode.type === 'paragraph'
        ) {
          hightlightValue.forEach(val => {
            ranges.push({
              anchor: {
                path,
                offset: val.startIndex
              },
              focus: {
                path,
                offset: val.endIndex + 1
              },
              highlight: inferenceActive ? true : false
            });
          });
        }
      }
      return ranges;
    },
    // eslint-disable-next-line
    [hightlightValue, inferenceActive]
  );

  const renderElement = useCallback(props => {
    if (props.element.type === 'heading') {
      return <HeadingElement {...props} />;
    } else {
        return <DefaultElement {...props} />;
    }
  }, []);

  const callAPI = ({
    jobId,
    data,
    changedIdArray,
    currentWordRange,
    setSaveStatus,
    audioRef,
    setChangeStatus,
    inferenceActive,
    activeSection,
    cursorPosition
  }) => {
    setSaveStatus('Saving');
    let offset = null;
    data.forEach(item => {
      let mappedValue = headingMapRef.current.get(item.heading.toUpperCase());
      if (mappedValue) {
        item.heading = mappedValue;
      } else {
        item.heading = {
          customUIHeading: item.heading,
          systemHeading: 'NONE'
        };
      }
    });
    const payload = {
      sections: data,
      sectionIds: Array.from(new Set(changedIdArray)),
      audioTime: audioRef.current && audioRef.current.audio.current.currentTime
    };

    const wordPosition = currentWordRange;
    editSection(jobId, payload, user.sessionId)
      .then(response => {
        if (response.data.success) {
          setSaveStatus('Saved');
          setChangeStatus(false);
          saveTimeFormat(response.data.result[0].lastUpdatedTime);
          if (offset === null) offset = getIndexLastCharOfLine();
          setEditorDataPreviewValue(
            response.data.result && response.data.result[0].sections
          );
          if (response.data.result) {
            ReactEditor.deselect(editor);
            setEditorValues(
              response.data.result && response.data.result[0].sections,
              wordPosition,
              offset,
              inferenceActive,
              activeSection,
              cursorPosition,
              response.data.result && response.data.result[0].newSectionsId
            );
            let newData = user;
            newData.unsavedStatus = false;
            dispatch(AddUser({ ...newData }));
            localStorage.removeItem('editorData');
          } else {
            localStorage.removeItem('editorData');
          }
        }
      })
      .catch(err => {
        console.log(err);
        setSaveStatus('You are Offline');
      });
  };

  const debouceSerializer = useCallback(
    useDebounce(callAPI, AUTO_SAVE_DELAY),
    []
  );

  const sectionEdit = (data, val) => {
    let changedIdArray = initialEditorValue
      .map(initialValue => {
        let element = val.find(
          item => item.id === initialValue.id && item.type === initialValue.type
        );
        if (element) {
          if (element.children[0].text !== initialValue.children[0].text)
            return initialValue.id;
          else return null;
        } else {
          return null;
        }
      })
      .filter(item => item !== null);

    if (initialEditorValue[0].children[0].text === '') {
      if (data.length > 0) {
        changedIdArray = [data[0].id];
      }
    }
    if (changedIdArray.length > 0 || val.length > initialEditorValue.length) {
      user.unsavedStatus = true;
      dispatch(AddUser({ ...user }));
      debouceSerializer({
        jobId,
        data,
        changedIdArray,
        currentWordRange,
        setSaveStatus,
        audioRef,
        setChangeStatus,
        inferenceActive,
        activeSection,
        cursorPosition
      });
    }
  };

  const saveUndoRedoState = val => {
    if (undoRedo.length === 50) undoRedo.shift();
    if (pointer !== 50) setPointer(pointer + 1);

    undoRedo.push(val);

    setUndoRedo(undoRedo);
  };

  const onChange = (val, func) => {
    setChangeStatus(true);
    if (!func) saveUndoRedoState(val);
    let data = ObjectDeSerializer(val);
    updateCurrentWord();
    setValue(val);
    sectionEdit(data, val);
    checkIconStatus();
  };

  const setEditorValues = async (
    apiResponseData,
    wordPosition,
    offset,
    inferenceActive,
    activeSection,
    cursorPosition,
    newSectionsIds
  ) => {
    let data = EditorDataFormatter(apiResponseData);
    let flattenData = data.flatMap(item => item);
    let manualSavedEditorData = JSON.parse(localStorage.getItem('editorData'));
    if (data.length < 1) {
      data = [
        {
          id: 'new-' + nanoid(),
          type: 'heading',
          class: 'section-heading',
          children: [{ text: '' }]
        }
      ];
    }
    if (manualSavedEditorData && manualSavedEditorData.jobId === jobId) {
      setValue(manualSavedEditorData.editorData);
      setInitialEditorValue(manualSavedEditorData.editorData);
    } else {
      setValue(data.flatMap(item => item));
      setInitialEditorValue(data.flatMap(item => item));
    }

    if (wordPosition) {
      let newSectionId = newSectionsIds.pop();
      let paragraphOrHeading = cursorPosition ? 'heading' : 'paragraph';
      let activeSectionIndex = flattenData.findIndex(
        item => item.id === activeSection && item.type === paragraphOrHeading
      );
      if (activeSectionIndex < 0 && activeSection.includes('new')) {
        if (wordPosition.anchor.path[0] < flattenData.length) {
          let index = flattenData.findIndex(
            item => item.id === newSectionId && item.type === paragraphOrHeading
          );
          activeSectionIndex = index;
        } else {
          activeSectionIndex = flattenData.length - 1;
        }
      }
      let activeSectionContent = flattenData[activeSectionIndex];

      let sectionTextLength = activeSectionContent
        ? activeSectionContent.children[0].text.length
        : 0;
      let newPath = [activeSectionIndex < 0 ? 0 : activeSectionIndex, 0];
      let newParagraph = wordPosition.anchor.path[0] > activeSectionIndex;

      Transforms.select(editor, {
        path: newPath,
        offset:
          offset > sectionTextLength || newParagraph
            ? sectionTextLength
            : offset
      });
      // focus back on the editor
      ReactEditor.focus(editor);
    }
    if (inferenceActive && offset) fetchContents(activeSection, true);
  };

  const getIndexLastCharOfLine = () => {
    const selection = window.getSelection();
    const range = selection && selection.getRangeAt(0);
    const caretIndex = range && range.startOffset;
    return caretIndex;
  };

  useEffect(() => {
    if (editorData) {
      setEditorValues(editorData);
    }
    // eslint-disable-next-line
  }, [editorData]);

  useEffect(() => {
    if (nodeList.length > 0) {
      findPositions();
    }
    // eslint-disable-next-line
  }, [nodeList.length, initialEditorValue]);

  const findPositions = () => {
    // create a map containing id and position of each headings
    const positionMap = Array.from(nodeList).reduce(
      (positionMp, headingNode) => {
        const id = headingNode.getAttribute('id');
        const rect = headingNode.getBoundingClientRect();
        if (headingNode.parentElement) {
          const newRect = {
            top:
              rect.top - headingNode.parentElement.getBoundingClientRect().top,
            right: headingNode.firstElementChild.getBoundingClientRect().width,
            bottom:
              headingNode.firstElementChild.getBoundingClientRect().bottom,
            left: headingNode.firstElementChild.getBoundingClientRect().left
          };
          positionMp.set(id, newRect);
        }

        return positionMp;
      },
      new Map()
    );
    setPositions(positionMap);
  };

  useEffect(() => {
    // get all heading nodes
    let all = editorRef.current.querySelectorAll('.section-heading');
    if (all.length === 0) {
      CustomEditor.toggleHeadingBlock(editor);
    }
    setNodeList(all);
    // eslint-disable-next-line
  }, [value, document.querySelectorAll('.section-heading').length]);

  useEffect(() => {
    const sel = window.getSelection();
    if (!sel || sel.rangeCount === 0 || sel.anchorNode.localName === 'body')
      return;
    const range = sel.getRangeAt(0).cloneRange();
    const offset = currentWordRange ? currentWordRange.anchor.offset : 0;
    range.setStart(sel.anchorNode, offset);
    range.collapse(true);
    const headingNode =
      sel.focusNode.parentElement.parentElement.parentElement.parentElement;
    const headingId = headingNode.getAttribute('id');
    const type = headingNode.getAttribute('type');
    if (headingId) setActiveSection(headingId);
    if (type === 'heading') {
      // if (headingNode.classList.contains('selected')) {
      //   headingNode.classList.remove('selected');
      // } else {
      //   headingNode.classList.add('selected');
      // }
      const rect = headingNode.getBoundingClientRect();
      const newRect = {
        top: rect.top - headingNode.parentElement.getBoundingClientRect().top,
        right: headingNode.firstElementChild.getBoundingClientRect().width,
        bottom: headingNode.firstElementChild.getBoundingClientRect().bottom,
        left: headingNode.firstElementChild.getBoundingClientRect().left
      };
      if (
        positions &&
        positions.get(headingId) &&
        positions.get(headingId).top === newRect.top
      ) {
        setCursorPosition(newRect);
        setSelectedHeadingId(headingId);
        setHeaderPosition(null);
      } else {
        setCursorPosition(null);
        setSelectedHeadingId(null);
        setHeaderPosition(null);
      }
    } else {
      setCursorPosition(null);
      setSelectedHeadingId(null);
      setHeaderPosition(null);
    }

    // eslint-disable-next-line
  }, [currentWordRange]);

  const updateCurrentWord = () => {
    if (!editor.selection) return;
    const [node] = Editor.node(editor, editor.selection);
    if (!node.text) return;
    const currentWord = node.text;
    setCurrentWordRange({
      anchor: {
        path: editor.selection.anchor.path,
        offset: 0
      },
      focus: {
        path: editor.selection.focus.path,
        offset: 0 + currentWord.length
      },
      word: currentWord
    });
  };

  const handleHeadingChange = heading => {
    if (!currentWordRange) throw new Error('No Range found!');

    const stringToInsert = heading.customUIHeading;
    // replace the word with the newly selected one:
    Transforms.insertText(editor, stringToInsert, {
      at: currentWordRange
    });

    // set the selection to be at the end of the newly inserted word.
    Transforms.select(editor, {
      path: currentWordRange.anchor.path,
      offset: currentWordRange.anchor.offset + stringToInsert.length
    });

    // focus back on the editor
    ReactEditor.focus(editor);

    // update the current word to reflect the change
    updateCurrentWord();
    setHeaderPosition(null);
  };

  useEffect(() => {
    if (inferenceAnnotation.value) {
      let val = {};
      if (inferenceAnnotation.type === 'List') {
        val = {
          startIndex: inferenceAnnotation.annotations[0].startIndex,
          endIndex:
            inferenceAnnotation.annotations[
              inferenceAnnotation.annotations.length - 1
            ].endIndex
        };
      } else {
        val = {
          startIndex: inferenceAnnotation.annotations[0].startIndex,
          endIndex: inferenceAnnotation.annotations[0].endIndex
        };
      }
      if (val.startIndex === val.endIndex) {
        setHighlightValue(null);
      } else {
        setHighlightValue(inferenceAnnotation.annotations);
      }
    }
  }, [inferenceAnnotation]);

  useEffect(() => {
    setHighlightValue(null);
    setInferenceAnnotation([]);
    if (inferenceActive) fetchContents(activeSection);
    // eslint-disable-next-line
  }, [activeSection]);

  useEffect(() => {
    if (headings.length > 0) {
      let headingArray = headings.map(item => {
        return [item.customUIHeading.toUpperCase(), item];
      });
      setHeadingMap(new Map(headingArray));
    }
  }, [headings]);

  useEffect(() => {
    headingMapRef.current = headingMap;
  });

  return (
    <EditorContainer
      ref={editorRef}
      headingTextColor={theme['@primary-color']}
      contentTextColor={theme['@text-color']}
    >
      <Slate
        editor={editor}
        value={value}
        onChange={newValue => onChange(newValue)}
      >
        <Editable
          renderElement={renderElement}
          renderLeaf={props => <Leaf {...props} />}
          onKeyDown={event => {
            KeyDownEvents(
              event,
              editor,
              undoRedo,
              setValue,
              pointer,
              setPointer,
              onChange
            );
          }}
          decorate={decorate}
        />
      </Slate>
      {/* render heading operation buttons */}
      {cursorPosition && (
        <HeadingActionsDiv top={cursorPosition.top} left={cursorPosition.right}>
          <a href='#bottom'>
            <HeadingActionsButton
              type='text'
              icon={<CaretDownOutlined />}
              onClick={() => {
                if (headerPosition) {
                  setHeaderPosition(null);
                } else {
                  setHeaderPosition(cursorPosition);
                  ReactEditor.focus(editor);
                }
              }}
            />
          </a>
          <HeadingActionsButton
            type='text'
            danger
            onClick={() => deleteSection(selectedHeadingId)}
            icon={<DeleteOutlined />}
          />
        </HeadingActionsDiv>
      )}
      {headerPosition && (
        <>
          <FloatingMenu
            cursorPosition={headerPosition}
            headings={headings}
            handleHeadingChange={handleHeadingChange}
          />
          <FloatingMenuControl id='bottom' cursorPosition={cursorPosition} />
        </>
      )}
    </EditorContainer>
  );
};

export default SlateEditor;
