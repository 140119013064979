import styled from 'styled-components';
import { Layout, Button, Menu, Col } from 'antd';
import ProfileIcon from '../../../../Assets/Icons/ProfileIcon';
import SignOutIcon from '../../../../Assets/Icons/SignoutIcon';
import CallIcon from '../../../../Assets/Icons/CallIcon';
import PrivacyPolicyIcon from '../../../../Assets/Icons/PrivacyPolicyIcon';
import {LoadingOutlined} from '@ant-design/icons';

const { Header } = Layout;

export const StyledHeader = styled(Header)`
  &.ant-layout-header {
    background: #fff;
    height: 70px;
    padding-left: 10px;
  }
`;

export const MenuButton = styled(Button)`
  &.ant-btn {
    border: none;
    box-shadow: none;
    margin: 19px 0;
  }
  & .anticon svg {
    height: 25px;
    width: 25px;
  }
  &.ant-btn:active,
  &.ant-btn:hover,
  &.ant-btn:focus {
    color: black;
  }
  &.ant-btn:hover {
    transform: scale(1.1);
    transition: 0.25s ease-in-out;
  }

  @media (max-width: 1200px) {
    display: none;
  }
`;

export const Username = styled.div`
  color: ${props => props.textColor};
  font-weight: ${props => (props.small ? 500 : 600)};
  font-size: ${props => (props.small ? '16px' : '18px')};
  text-align: center;
  margin-left: ${props => (props.small && props.contact ? '12px' : '15px')};
  max-width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 1480px) {
    font-size: 14px;
  }
`;

export const HeaderProfileContainer = styled.div`
  height: 70px;
  line-height: 70px;
`;

export const StyledProfileIcon = styled(ProfileIcon)`
  height: 25px;
  width: 25px;
  margin-right: 20px;
`;

export const StyledSignOutIcon = styled(SignOutIcon)`
  height: 25px;
  width: 25px;
  margin-right: 20px;
`;

export const StyledLoadingIcon = styled(LoadingOutlined)`
  height: 25px;
  width: 25px;
  margin-right: 20px;
`;

export const StyledPrivacyPolicyIcon = styled(PrivacyPolicyIcon)`
  height: 25px;
  width: 25px;
  margin-right: 20px;
`;

export const StyledPhoneIcon = styled(CallIcon)`
  height: 25px;
  width: 25px;
  margin-right: 20px;
`;

export const StyledMenu = styled(Menu)`
  width: 180px;
  position: absolute;
  right: 0;
`;

export const StyledMenuItem = styled(Menu.Item)`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  width: 100%;
`;

export const NotificationProfileColumnStyle = styled(Col)`
  display: flex;
`;

export const StyledNotificationDiv = styled.div`
  margin-right: 20px;
`;
