import React from 'react';
import { StyledModal, StyledButton } from './styledComponents';
import { useSelector } from 'react-redux';
const ConfirmationModal = ({
  visible,
  onOk,
  antdModalProps,
  children,
  onCancel,
  loading,
  header,
  inference,
  buttonDisabled,
  confirmation,
  dataChanged,
  video,
  importExport,
  buttonAlignCenter
}) => {
  const [theme] = useSelector(state => [state.themeReducer]);
  return (
    <StyledModal
      visible={visible}
      {...antdModalProps}
      color={theme['@text-color']}
      footer={[
        dataChanged || confirmation ? (
          <>
            <StyledButton
              key='back'
              type='text'
              onClick={onCancel}
              action='cancel'
            >
              Cancel
            </StyledButton>
            <StyledButton
              key='submit'
              loading={loading}
              onClick={onOk}
              type='primary'
              disabled={buttonDisabled}
              delete={true}
            >
              Confirm
            </StyledButton>
          </>
        ) : (
          <>
            <StyledButton
              key='back'
              type='text'
              onClick={onCancel}
              action='cancel'
            >
              Close
            </StyledButton>
          </>
        )
      ]}
      destroyOnClose
      title={header}
      inference={inference}
      video={video}
      importExport={importExport}
      buttonAlignCenter = {buttonAlignCenter}
      centered={true}
    >
      {children}
    </StyledModal>
  );
};

export default ConfirmationModal;
