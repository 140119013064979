import React from "react";

function DragIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="43"
      height="43"
      fill="none"
      viewBox="0 0 43 43"
    >
      <path
        fill="#47A4EA"
        d="M38.52 16.125h-8.958a.896.896 0 000 1.792h8.959a2.69 2.69 0 012.687 2.687v17.917a2.69 2.69 0 01-2.687 2.687H20.604a2.69 2.69 0 01-2.687-2.687v-7.167a.896.896 0 00-1.792 0v7.167c0 2.469 2.01 4.479 4.48 4.479H38.52C40.99 43 43 40.99 43 38.52V20.605c0-2.469-2.01-4.479-4.48-4.479zM13.438 25.084h-.896a.896.896 0 000 1.791h.896a.896.896 0 000-1.791zM5.269 0h-.79C4.05 0 3.628.06 3.22.18a.897.897 0 10.503 1.72c.244-.073.497-.108.755-.108h.79a.895.895 0 100-1.792zM1.792 21.602v-1.811a.896.896 0 00-1.792 0v1.811a.896.896 0 001.792 0zM.896 17.064a.896.896 0 00.896-.896v-1.812a.896.896 0 00-1.792 0v1.812c0 .494.401.896.896.896zM.896 6.198a.896.896 0 00.896-.896V4.48c0-.25.034-.495.1-.731a.895.895 0 00-.618-1.106.89.89 0 00-1.106.618A4.513 4.513 0 000 4.48v.822c0 .494.401.896.896.896zM.896 11.631a.896.896 0 00.896-.895V8.924a.896.896 0 00-1.792 0v1.812c0 .494.401.895.896.895zM3.745 24.982a2.695 2.695 0 01-1.158-.68.896.896 0 00-1.263 1.271 4.474 4.474 0 001.932 1.132.895.895 0 001.105-.616.894.894 0 00-.616-1.107zM25.834 4.364a.896.896 0 00.86-1.148 4.482 4.482 0 00-1.148-1.923.895.895 0 10-1.26 1.274c.322.323.563.72.688 1.152.115.39.471.645.86.645zM21.57 0h-1.811a.896.896 0 000 1.792h1.811a.896.896 0 000-1.792zM8.92 25.084H7.11a.896.896 0 000 1.791h1.81a.896.896 0 000-1.791zM25.98 6.181a.896.896 0 00-.897.896v1.811a.896.896 0 001.792 0V7.077a.895.895 0 00-.896-.896zM16.137 0h-1.81a.895.895 0 100 1.792h1.809A.897.897 0 1016.138 0zM10.704 0H8.892a.896.896 0 000 1.792h1.812a.896.896 0 000-1.792zM25.98 11.646a.896.896 0 00-.897.896v.896a.896.896 0 001.792 0v-.896a.896.896 0 00-.896-.896z"
      ></path>
      <path
        fill="#47A4EA"
        d="M29.39 15.58l-2.777-2.776a2.8 2.8 0 00-3.954 0 2.782 2.782 0 00-.686 1.127 2.85 2.85 0 00-2.897.665c-.41.41-.665.917-.767 1.447a2.82 2.82 0 00-2.369.792 2.79 2.79 0 00-.811 2.172 2.782 2.782 0 00-1.599.793 2.778 2.778 0 00-.818 1.978c0 .748.29 1.452.818 1.978l.803.803v3.212c0 2.47 2.01 4.48 4.48 4.48h3.672c5.384 0 9.765-4.381 9.765-9.765a9.708 9.708 0 00-2.86-6.905zM22.485 30.46h-3.673a2.69 2.69 0 01-2.687-2.688v-1.42l2.055 2.055a.896.896 0 001.267-1.269l-4.65-4.65a.995.995 0 01-.294-.709c0-.267.104-.52.294-.71.38-.379 1.041-.379 1.421 0l1.962 1.963a.896.896 0 001.267-1.267l-2.24-2.24a1.005 1.005 0 010-1.42c.38-.38 1.041-.38 1.42 0l1.345 1.343a.897.897 0 001.266-1.266l-.896-.896a1.004 1.004 0 010-1.421c.38-.38 1.041-.38 1.421 0l.896.896a.897.897 0 101.265-1.269.991.991 0 01-.294-.71 1.005 1.005 0 011.716-.71l2.778 2.778a7.922 7.922 0 012.334 5.637c0 4.397-3.576 7.973-7.973 7.973z"
      ></path>
    </svg>
  );
}

export default DragIcon;
