import React, { useEffect, useState } from 'react';
import { TitleRow, ContainerRow } from '../styledComponents';
import CustomTypography from '../../CustomComponents/CustomTypography';
import { useSelector } from 'react-redux';
import MediumCard from '../../CustomComponents/MediumCard';
import { Col, Row, notification } from 'antd';
import { dashboardContents } from './DashboardConfig';
import { Link } from 'react-router-dom';
import PageTitle from '../../../Utils/PageTitle';
import { GetCount } from '../../../Apis/Dashboard';
import { StyledDashboardCardContainer } from '../NewDashboard/styledComponent';
import DashboardCard from '../NewDashboard/DashboardCard';

import { GetAdminJobCount } from '../../../Apis/Jobs';
import Spinner from '../../CustomComponents/Spinner';

import { StyledFCardContainer } from './styledComponents';
import { getUserNotificationDashboard } from '../../../Apis/User';
import FacilityStatusCard from '../NewDashboard/FacilityStatusCard';

import { getSuperAdminDashboardConfig } from './AdminDashboardConfig';
const doctorPrivilege = new Map([
  ['TRANSCRIPTION_ONLY', 'transcriptionist'],
  ['REVIEWER_ONLY', 'reviewer'],
  ['TRANSCRIPTION_REVIEWER', 'transcriptionist'],
  ['NONE', 'none']
]);

const Dashboard = () => {
  const [theme] = useSelector(state => [state.themeReducer]);
  const user = useSelector(state => state.userReducer);
  const [loading, setLoading] = useState(false);
  const [dashboardLoading, setdashboardLoading] = useState(true);
  const [dashboardItems, setDashboardItems] = useState();
  const [dashboardData, setDashboardData] = useState([]);
  const [dashboardValues, setDashboardValues] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const notificationStatus = useSelector(state => state.notificationReducer);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user'));

    setDashboardItems(
      dashboardContents(
        userData.userType === 'doctor'
          ? doctorPrivilege.get(userData.userPrivilege)
          : userData.userType
      )
    );
  }, []);

  useEffect(() => {}, [loading, dashboardLoading, dashboardItems]);

  const adminCount = () => {
    if (dashboardItems) {
      setLoading(true);
      if (
        // user.userType === 'cascadeGod' ||
        user.userType === 'doctor' &&
        user.userPrivilege === 'TRANSCRIPTION_REVIEWER'
      ) {
        GetAdminJobCount()
          .then(response => {
            if (response.data.success) {
              const counts = response.data.result[0];
              dashboardItems.map(item => {
                if (counts[item.countValue] !== undefined) {
                  item.count =
                    typeof counts[item.countValue] === 'object'
                      ? counts[item.countValue].reviewerJobCount +
                        counts[item.countValue].transcriptionistJobCount
                      : counts[item.countValue];
                } else return;
              });
              setDashboardValues([...dashboardItems]);
              setdashboardLoading(false);
            } else {
              setdashboardLoading(false);
            }
          })
          .catch(err => {
            setdashboardLoading(false);
          });
      } else {
        GetCount(
          // user.userType === 'cascadeGod' ||
          user.userType === 'doctor'
            ? doctorPrivilege.get(user.userPrivilege)
            : user.userType
        )
          .then(response => {
            if (response.data.success) {
              const counts = response.data.result[0];
              dashboardItems.map(
                item => (item.count = counts[item.countValue])
              );
              if (
                user.userType === 'clinicAdmin' ||
                user.userType === 'admin'
              ) {
                const transformData = input => {
                  const userData = input[0].userData;
                  const jobData = input[0].jobData;
                  const facilityData = input[0].facilityData;

                  return {
                    facilityCount: facilityData.facilityCount,
                    openJobCount: jobData.openJobsCount,
                    completedJobsCount: jobData.completedJobsCount,
                    doctorCount: userData.doctorCount,
                    transcriptionistCount: userData.transcriptionistCount,
                    reviewerCount: userData.reviewerCount,
                    adminCount: userData.adminCount
                  };
                };
                setDashboardData(
                  getSuperAdminDashboardConfig(
                    transformData(response.data.result)
                  )
                );
              } else {
                setDashboardValues([...dashboardItems]);
              }
              setdashboardLoading(false);
            } else {
              setdashboardLoading(false);
            }
          })
          .catch(err => {
            setdashboardLoading(false);
          });
      }
    }
  };

  const userNotificationDashboard = () => {
    setLoading(true);
    getUserNotificationDashboard(user.id)
      .then(response => {
        if (response.data.success) {
          setFacilities(response.data.result);
          setLoading(false);
          setdashboardLoading(false);
        } else {
          notification['error']({
            message: 'Failed',
            description: response.data.message
          });

          setLoading(false);
        }
      })
      .catch(err => {
        setLoading(false);
      });
  };

  useEffect(() => {
    userNotificationDashboard();
    // eslint-disable-next-line
  }, [notificationStatus]);

  useEffect(() => {
    adminCount();
    // eslint-disable-next-line
  }, [dashboardItems]);

  return loading || dashboardLoading ? (
    <Spinner />
  ) : (
    <>
      <PageTitle title='Dashboard' />
      <ContainerRow gutter={[0, 40]}>
        <TitleRow span={24}>
          <CustomTypography color={theme['@text-color']}>
            Hello {user && user.firstName + ' ' + user.lastName}
          </CustomTypography>
        </TitleRow>
        <Col span={24}>
          <Row gutter={[30, 30]}>
            {
              // eslint-disable-next-line
              user.userType === 'clinicAdmin' || user.userType === 'admin' ? (
                <StyledDashboardCardContainer>
                  {dashboardData.map(item => {
                    return <DashboardCard item={item} key={item.heading} />;
                  })}
                </StyledDashboardCardContainer>
              ) : (
                dashboardValues.map(item => {
                  if (
                    !(
                      item.title === 'Reviewers' &&
                      user.transcriptionFlow === 'TRANSCRIPTION_ONLY'
                    ) &&
                    !(
                      (user.userType === 'reviewer' ||
                        user.userType === 'transcriptionist') &&
                      item.countValue === 'myJobs'
                    )
                  ) {
                    return (
                      <Col xxl={5} xl={8} lg={8} md={12} sm={12} xs={24}>
                        <Link to={item.path}>
                          <MediumCard theme={theme} item={item} />
                        </Link>
                      </Col>
                    );
                  }
                })
              )
            }
          </Row>
        </Col>
        {facilities.length > 0 && (
          <>
            <TitleRow span={24}>
              <CustomTypography color={theme['@text-color']}>
                Facility
              </CustomTypography>
            </TitleRow>

            <StyledFCardContainer>
              <Row gutter={[24, 24]}>
                {facilities
                  .filter(item => item.facility.disabled === false)
                  .map(item => (
                    <FacilityStatusCard
                      clinicFacilityStatus={{
                        facilityId: item.facility.facilityId,
                        facilityName: item.facility.facilityName,
                        facilitySubcriptionExpiredOn:
                          item.facility.facilityExpireDate,
                        emrHandlingData: item.emrHandlingData
                      }}
                      key={item.facility.facilityName}
                    />
                  ))}
              </Row>
            </StyledFCardContainer>
          </>
        )}
      </ContainerRow>
    </>
  );
};
export default Dashboard;
