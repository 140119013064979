import React from 'react';

function PhoneIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='21'
      height='33'
      fill='none'
      viewBox='0 0 21 33'
    >
      <path
        fill='#9DC383'
        d='M18.308 0c.204.064.413.119.616.19.596.21 1.113.593 1.483 1.098.37.505.573 1.108.585 1.73.018 1.031 0 2.062 0 3.094V29.76c0 1.547-.884 2.738-2.327 3.123-.296.076-.6.113-.905.112-4.844.007-9.686.007-14.526 0C1.37 32.995.002 31.65 0 29.82V3.18C0 1.605.987.407 2.545.061a.884.884 0 00.145-.06L18.308 0zM5.296 2.062H3.414c-.948 0-1.316.358-1.316 1.277v26.322c0 .902.375 1.275 1.287 1.275H17.59c.94 0 1.307-.361 1.307-1.286V3.355 3c-.04-.475-.416-.906-.896-.927-.75-.034-1.503-.01-2.294-.01v1.428c0 .734-.417 1.14-1.157 1.142H6.447c-.717 0-1.137-.415-1.144-1.121-.013-.462-.007-.933-.007-1.45zm8.288.018H7.42v.476h6.163l.002-.476z'
      ></path>
    </svg>
  );
}

export default PhoneIcon;
