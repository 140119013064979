import styled from 'styled-components';

export const FloatLabel = styled.div`
  position: relative;
  margin-bottom: 12px;
  margin-top: 13px;
  & .ant-input[disabled] {
    background: transparent;
  }

  & .ant-input-password {
    background: transparent;
    border: none !important;
    border-bottom: 2px solid #d0d0d0 !important;
    color: ${props => props.primaryTextColor};
    box-shadow: none !important;
    ::placeholder {
      color: ${props => props.secondaryTextColor};
    }
  }

  & .ant-input-affix-wrapper > input.ant-input {
    background: transparent;
  }

  & .cleave-input {
    background: transparent;
    border: none !important;
    border-bottom: 2px solid #d0d0d0 !important;
    color: ${props => props.primaryTextColor};
    box-shadow: none !important;
    ::placeholder {
      color: ${props => props.secondaryTextColor};
    }
    width: 100%;
    height: 40px;
    outline: none !important;
  }
`;

export const Label = styled.label`
  font-size: ${({ focus, value }) =>
    focus || (value && value.length > 0) ? '16px' : '18px'} !important ;
  font-weight: normal;
  position: absolute;
  pointer-events: none;

  left: 0px;
  top: ${({ focus, value }) =>
    focus || (value && value.length > 0) ? '-24px' : '10px'};
  transition: 0.2s ease all;
  color: ${({ focus, value, secondaryTextColor, primaryColor }) =>
    focus || (value && value.length !== 0 && Object.entries(value).length !== 0)
      ? primaryColor
      : secondaryTextColor};

  &:before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    line-height: 1;
    content: '*';
  }

  @media (max-width: 1200px) {
    font-size: 2vmin !important;
  }
`;
