import { Checkbox,Collapse,Input } from 'antd';
import styled from 'styled-components';

export const StyledContainer = styled.div`

`;

export const StyledMessage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Krub';
    font-weight: 600;
    font-size: 14px;
`;

export const StyledContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    font-family: 'Krub';

`;


export const StyledSearch = styled(Input)`
    margin: 3% 0% 5% 5%;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    border-radius: 5px;
    width: 95%;
   & .anticon.anticon-search {
    color: #666666;
}

    `;

export const StyledCircle = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: #5BA1E4;
    margin-right: 5px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-family: 'Krub';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    margin-right: 10px;
`;

export const StyledCheckBox = styled(Checkbox)`
    padding: 2%;
`;

export const StyledAccordian = styled(Collapse)`

& .ant-collapse-header {
    background-color: white;
}
& .ant-collapse-content-box {
    background-color: #F0F2F5;
}
& .ant-collapse-header-text {
    margin-left: 5%;
}
& .ant-collapse-content-box {
    font-family: 'Krub'; 
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
}
`;

export const StyledRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 5%;
    padding-top:2%;
}
`;

export const StyledPanel = styled(Collapse.Panel)`

& .ant-collapse-header-text {
    font-family: 'Krub';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
}
& .ant-collapse-content-box {
    max-height: 400px;
    overflow: auto;
}
& .ant-collapse-expand-icon {
    color:${props=>props.disabled? ' ':' #47a4ea'};
}
`;
