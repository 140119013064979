import axiosInstance from '../Config/AxiosInstance';

const PREFIX = `private/clinic`;

export const getClinicDetails = clinicId => {
  return axiosInstance.get(PREFIX + `/${clinicId}`);
};

export const getEhrList = () => {
  return axiosInstance.get(PREFIX + `/ehr-integration`);
};

export const getEhrFields = (clinicId, ehrType) => {
  return axiosInstance.get(
    PREFIX + `/${clinicId}/ehr-configuration?ehrType=${ehrType}`
  );
};

export const updateClnicConfig = (clinicId, payload) => {
  return axiosInstance.put(PREFIX + `/${clinicId}/ehr-configuration`, payload);
};

export const listHl7Files = (
  clinicId,
  fileType,
  searchString,
  currentPage,
  pageSize,
  sortOrder,
  sortKey
) => {
  return axiosInstance.get(
    PREFIX +
      `/${clinicId}/hl7-files?fileType=${fileType}&searchValue=${searchString}&page=${currentPage}&size=${pageSize}&sortKey=${sortKey}&sortOrder=${sortOrder}`
  );
};

export const exportHl7File = (clinicId, payload, fileType) => {
  return axiosInstance.post(
    PREFIX + `/${clinicId}/export-hl7-file?fileType=${fileType}`,
    payload,
    { responseType: 'blob' }
  );
};

export const uploadFiles = files => {
  return axiosInstance.post(PREFIX + `/clinicId/import-hl7-file`, files);
};

export const resetDemoConfiguration = () => {
  return axiosInstance.delete(PREFIX + `/clinicId/reset-ehr`);
};
