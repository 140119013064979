import React from 'react';

function BiDirectionalArrow() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='11'
      height='9'
      fill='none'
      viewBox='0 0 11 9'
    >
      <path
        fill='#89919D'
        fillRule='evenodd'
        d='M7.714.022a.528.528 0 00-.212.21c-.03.058-.037.164-.042.733l-.007.666-1.435.006-1.435.006-.076.054c-.3.215-.217.663.139.74.081.017.596.023 1.715.019 1.745-.007 1.657 0 1.776-.134.096-.107.114-.195.114-.572v-.353l.897.935.898.935-.898.934-.897.935v-.353c0-.451-.032-.546-.22-.646-.076-.04-.163-.042-2.282-.042H3.547L3.54 3.43c-.007-.641-.009-.67-.055-.76a.404.404 0 00-.493-.185C2.902 2.519.089 5.45.038 5.562a.387.387 0 000 .336c.028.06.495.561 1.467 1.57 1.534 1.594 1.485 1.55 1.688 1.525.11-.013.23-.102.297-.22.04-.07.043-.124.05-.742l.007-.666 1.435-.006 1.434-.006.076-.054c.301-.215.217-.663-.138-.74-.082-.017-.596-.023-1.716-.018-1.744.007-1.659 0-1.774.133-.095.11-.105.152-.116.54l-.01.375-.898-.935-.897-.936.903-.94.902-.94v.364c0 .464.031.557.22.657.077.04.163.042 2.282.042h2.203l.007.666c.006.641.008.67.055.76.088.173.31.256.493.186.09-.035 2.903-2.967 2.953-3.079.05-.109.052-.21.008-.32C10.924 3 8.147.1 8.025.04a.42.42 0 00-.311-.018z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export default BiDirectionalArrow;
